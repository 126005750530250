import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import { connect } from "react-redux";
import { getAllDeansOffices } from "./duck/operations.js";

import DeansOfficesDisplayContainer from "../DeansOfficesDisplayContainer/DeansOfficesDisplayContainer";

import SearchBtn from "../../assets/search-btn.svg";
import NoData from "../../assets/no-data.svg";

const DeansOffices = ({ deansOffices, getAllDeansOffices }) => {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const headingRef = useRef(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResultsCount, setShowSearchResultsCount] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${process.env.REACT_APP_API_URL}/content/?id=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    getAllDeansOffices();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (window.location.hash === "") {
      setTimeout(() => {
        headingRef.current?.focus();
        headingRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    } else {
      setTimeout(() => {
        let hashItem = document.getElementById(
          window.location.hash.substring(1)
        );

        hashItem.focus();
        hashItem.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        setIsSearchInputOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("deans_offices:title");
  }, []);

  const handleClickSearch = () => {
    const searchItems =
      Array.from(deansOffices.list).length > 0
        ? deansOffices.list.filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
        : null;
    setSearchResults(searchItems);
    setShowSearchResults(true);
    setShowSearchResultsCount(true);

    let jumpLink = document.getElementById("filter-result-info");
    jumpLink.focus();
    jumpLink.scrollIntoView({ behavior: "smooth" });
  };

  const searchResultsList =
    Array.from(deansOffices.list).length > 0
      ? deansOffices.list
          .filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
          .slice(0, 8)
          .map((item) => {
            return (
              <li key={item.id} className="c-dropdown-search-results__item">
                <Link
                  to={window.location.pathname + `#${item.id}`}
                  className="c-dropdown-search-results___link"
                  onClick={() => {
                    setSearchTerm(item.translations[cookies.i18next].name);

                    const targetElement =
                      document.getElementById(`search-input`);
                    targetElement.value =
                      item.translations[cookies.i18next].name;

                    const targetElement1 = document.getElementById(
                      `${item.id}`
                    );
                    targetElement1.focus();
                    targetElement1.scrollIntoView({ behavior: "smooth" });

                    setIsSearchInputOpen(false);
                  }}
                >
                  {item.translations[cookies.i18next].name}
                </Link>
              </li>
            );
          })
      : null;

  return (
    <>
      {item.translations && (
        <>
          <main id="content">
            <div className="container">
              <h2
                id="js-category-name"
                className="text--header text--category-header text--center"
                tabIndex={-1}
                ref={headingRef}
              >
                {t("deans_offices:page_header_deans_offices")}
              </h2>
              <div className="l-description-container">
                <div className="l-description-box">
                  {parse(
                    String(
                      item.translations[cookies.i18next]
                        .deans_offices_page_content
                    )
                  )}
                  <div className="l-search-container">
                    <form role="search" className="c-search">
                      <label
                        htmlFor="search-input"
                        className="c-search__label"
                        role="heading"
                        aria-level="2"
                      >
                        <span className="sr-only">
                          {t("deans_offices:search_input_placeholder")}
                        </span>
                      </label>
                      <input
                        type="text"
                        id="search-input"
                        className="c-search__input"
                        placeholder={t(
                          "deans_offices:search_input_placeholder"
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSearchInputOpen(true);
                        }}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setIsSearchInputOpen(true);
                          setShowSearchResultsCount(false);
                        }}
                      />
                      <div className="c-search__btn-container">
                        <button
                          type="button"
                          className="search__btn"
                          onClick={handleClickSearch}
                          aria-label={t("general:search_button")}
                        >
                          <img src={SearchBtn} alt="" />
                        </button>
                      </div>
                    </form>
                    <div
                      className={`c-dropdown-search-results ${
                        searchTerm && isSearchInputOpen ? "open" : ""
                      }`}
                      ref={dropdownRef}
                    >
                      <ul>
                        {searchResultsList && searchResultsList.length > 0 ? (
                          searchResultsList
                        ) : (
                          <li className="c-dropdown-search-no-data__item">
                            {t("general:search_dropdown_no_data_result")}
                          </li>
                        )}
                      </ul>
                    </div>
                    {searchTerm && isSearchInputOpen ? (
                      <div
                        aria-live="assertive"
                        aria-atomic="true"
                        className="sr-only"
                      >
                        {t("general:search_input_result_suggestion_label", {
                          search_results_number:
                            searchResultsList && searchResultsList.length > 0
                              ? searchResultsList?.length
                              : 0,
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div
                    id="filter-result-info"
                    className="c-filter-result-info"
                    aria-live={showSearchResultsCount ? "assertive" : null}
                    aria-atomic={showSearchResultsCount ? "true" : null}
                  >
                    <>
                      {t("general:filter_result_info_part_1")}{" "}
                      <span>
                        {t("general:filter_result_info_part_2", {
                          results:
                            showSearchResults && searchTerm !== ""
                              ? Array.from(searchResults).length
                              : Array.from(deansOffices.list).length,
                        })}
                      </span>
                    </>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  {searchTerm !== "" &&
                  showSearchResults &&
                  Array.from(searchResults).length > 0 ? (
                    searchResults.map((item) => {
                      return (
                        <DeansOfficesDisplayContainer
                          key={item.id}
                          item={item}
                        />
                      );
                    })
                  ) : (!showSearchResults || searchTerm === "") &&
                    Array.from(deansOffices.list).length > 0 ? (
                    deansOffices.list.map((item) => {
                      return (
                        <DeansOfficesDisplayContainer
                          key={item.id}
                          item={item}
                        />
                      );
                    })
                  ) : Array.from(deansOffices.list).length === 0 ||
                    (showSearchResults &&
                      Array.from(searchResults).length === 0) ? (
                    <>
                      <div className="no-data-info">
                        <img src={NoData} alt="" />
                        <span>{t("deans_offices:no-data-info")}</span>
                        <span>{t("home:no-data-sub-info")}</span>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deansOffices: state.deansOffices,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDeansOffices: () => dispatch(getAllDeansOffices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeansOffices);
