import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import $ from "jquery";

import "./Accordion.css";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightArrow from "../../../assets/right-arrow-forward.svg";
import DownArrow from "../../../assets/down-arrow-forward.svg";

function AccordionRoomStairs({
  id,
  parentObjectName,
  parentObjectIndex,
  roomStairsIndex,
  mode,
}) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${process.env.REACT_APP_API_URL}/room_stairs/?id=${id}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [id]);

  const toggleAccordion = () => {
    setActive(!isActive);

    if (!isActive) {
      let parentObject =
        parentObjectName && parentObjectIndex
          ? "-" + parentObjectName + "-" + parentObjectIndex
          : "";
      setTimeout(() => {
        let targetElement;

        if (mode) {
          $(
            `#accordion${parentObject}-room_stairs-${roomStairsIndex} ul > :first-child`
          ).attr(
            "id",
            `accordion${parentObject}-first-list-room_stairs-element-${roomStairsIndex}`
          );
          $(
            `#accordion${parentObject}-room_stairs-${roomStairsIndex} ul > :first-child`
          ).attr("tabindex", "-1");

          targetElement = document.getElementById(
            `accordion${parentObject}-first-list-room_stairs-element-${roomStairsIndex}`
          );
        } else {
          $(
            `#accordion-mobile${parentObject}-room_stairs-${roomStairsIndex} ul > :first-child`
          ).attr(
            "id",
            `accordion-mobile${parentObject}-first-list-room_stairs-element-${roomStairsIndex}`
          );
          $(
            `#accordion-mobile${parentObject}-room_stairs-${roomStairsIndex} ul > :first-child`
          ).attr("tabindex", "-1");

          targetElement = document.getElementById(
            `accordion-mobile${parentObject}-first-list-room_stairs-element-${roomStairsIndex}`
          );
        }

        targetElement.focus();
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <div className="c-accordion">
            <div className="c-accordion__title">
              <button
                type="button"
                onClick={toggleAccordion}
                aria-expanded={isActive ? "true" : "false"}
              >
                <span>{t("building__room_stairs:label")}</span>{" "}
                <img src={isActive ? DownArrow : RightArrow} alt="" />
              </button>
            </div>
            <div
              id={
                mode
                  ? `accordion${
                      parentObjectName && parentObjectIndex
                        ? "-" + parentObjectName + "-" + parentObjectIndex
                        : ""
                    }-room_stairs-${roomStairsIndex}`
                  : `accordion-mobile${
                      parentObjectName && parentObjectIndex
                        ? "-" + parentObjectName + "-" + parentObjectIndex
                        : ""
                    }-room_stairs-${roomStairsIndex}`
              }
              className={
                isActive ? "c-accordion__content--open" : "c-accordion__content"
              }
            >
              <ul>
                {(() => {
                  return (
                    <>
                      {item.translations[cookies.i18next].comment ===
                      "" ? null : (
                        <li className="text">
                          {item.translations[cookies.i18next].comment}
                        </li>
                      )}
                      {item.translations[cookies.i18next].location ===
                      "" ? null : (
                        <li className="text">
                          {t("building__room_stairs:location", {
                            location:
                              item.translations[cookies.i18next].location,
                          })}
                        </li>
                      )}
                      {item.is_nonslip_surface === "Unknown" ? null : (
                        <li className="text">
                          {item.is_nonslip_surface === "True"
                            ? t(
                                "building__room_stairs:is_nonslip_surface__true",
                                {
                                  is_nonslip_surface_comment:
                                    item.translations[cookies.i18next]
                                      .is_nonslip_surface_comment,
                                }
                              )
                            : t(
                                "building__room_stairs:is_nonslip_surface__false",
                                {
                                  is_nonslip_surface_comment:
                                    item.translations[cookies.i18next]
                                      .is_nonslip_surface_comment,
                                }
                              )}
                        </li>
                      )}
                    </>
                  );
                })()}
              </ul>
              {(() => {
                if (cookies.value.length > 0) {
                  return (
                    <>
                      <article
                        className="object-category-profile-description"
                        aria-label={t("general:profile_description_label")}
                      >
                        <div className="object-category-profile-sign">
                          <img
                            className="object-category-profile-sign__icon"
                            src={
                              cookies.value.length > 1
                                ? ProfileWhite
                                : getProfilePictogram(cookies.value[0])
                            }
                            alt=""
                          />
                          <span className="object-category-profile-sign__label">
                            {t("general:profile_description_title")}
                          </span>
                        </div>
                        <ul>
                          {(() => {
                            return (
                              <>
                                {checker(cookies.value, [2]) ? (
                                  item.stairs_width ? (
                                    <li className="text">
                                      {t("building__room_stairs:stairs_width", {
                                        stairs_width: item.stairs_width,
                                      })}
                                    </li>
                                  ) : null
                                ) : null}
                                {checker(cookies.value, [3]) ? (
                                  item.is_good_lit === "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_good_lit === "True"
                                        ? t(
                                            "building__room_stairs:is_good_lit__true",
                                            {
                                              is_good_lit_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_good_lit_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_good_lit__false",
                                            {
                                              is_good_lit_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_good_lit_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [3, 6]) ? (
                                  item.is_stairs_surface_shiny ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_stairs_surface_shiny === "True"
                                        ? t(
                                            "building__room_stairs:is_stairs_surface_shiny__true",
                                            {
                                              is_stairs_surface_shiny_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_stairs_surface_shiny_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_stairs_surface_shiny__false",
                                            {
                                              is_stairs_surface_shiny_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_stairs_surface_shiny_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [3]) ? (
                                  item.is_good_stairs_wall_contrast ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_good_stairs_wall_contrast ===
                                      "True"
                                        ? t(
                                            "building__room_stairs:is_good_stairs_wall_contrast__true",
                                            {
                                              is_good_stairs_wall_contrast_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_good_stairs_wall_contrast_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_good_stairs_wall_contrast__false",
                                            {
                                              is_good_stairs_wall_contrast_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_good_stairs_wall_contrast_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [3]) ? (
                                  item.are_steps_edges_marked_in_contrasted_way ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_steps_edges_marked_in_contrasted_way ===
                                      "True"
                                        ? t(
                                            "building__room_stairs:are_steps_edges_marked_in_contrasted_way__true",
                                            {
                                              are_steps_edges_marked_in_contrasted_way_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_steps_edges_marked_in_contrasted_way_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:are_steps_edges_marked_in_contrasted_way__false",
                                            {
                                              are_steps_edges_marked_in_contrasted_way_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_steps_edges_marked_in_contrasted_way_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2, 3]) ? (
                                  item.are_all_steps_have_same_dimensions ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_all_steps_have_same_dimensions ===
                                      "True"
                                        ? t(
                                            "building__room_stairs:are_all_steps_have_same_dimensions__true",
                                            {
                                              are_all_steps_have_same_dimensions_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_all_steps_have_same_dimensions_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:are_all_steps_have_same_dimensions__false",
                                            {
                                              are_all_steps_have_same_dimensions_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_all_steps_have_same_dimensions_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2, 3]) ? (
                                  item.is_railing === "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_railing === "True"
                                        ? t(
                                            "building__room_stairs:is_railing__true",
                                            {
                                              is_railing_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_railing_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_railing__false",
                                            {
                                              is_railing_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_railing_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2]) ? (
                                  item.railing_height ? null : (
                                    <li className="text">
                                      {t(
                                        "building__room_stairs:railing_height",
                                        {
                                          railing_height: item.railing_height,
                                        }
                                      )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [3]) ? (
                                  item.is_good_railing_contrast ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_good_railing_contrast === "True"
                                        ? t(
                                            "building__room_stairs:is_good_railing_contrast__true",
                                            {
                                              is_good_railing_contrast_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_good_railing_contrast_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_good_railing_contrast__false",
                                            {
                                              is_good_railing_contrast_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_good_railing_contrast_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2, 3]) ? (
                                  item.is_round_cross_section_railing ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_round_cross_section_railing ===
                                      "True"
                                        ? t(
                                            "building__room_stairs:is_round_cross_section_railing__true",
                                            {
                                              is_round_cross_section_railing_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_round_cross_section_railing_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_round_cross_section_railing__false",
                                            {
                                              is_round_cross_section_railing_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_round_cross_section_railing_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0]) ? (
                                  item.translations[cookies.i18next].is_ramp ===
                                  "" ? null : (
                                    <li className="text">
                                      {t("building__room_stairs:is_ramp", {
                                        is_ramp:
                                          item.translations[cookies.i18next]
                                            .is_ramp,
                                      })}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0]) ? (
                                  item.is_railing_on_ramp ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_railing_on_ramp === "True"
                                        ? t(
                                            "building__room_stairs:is_railing_on_ramp__true",
                                            {
                                              is_railing_on_ramp_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_railing_on_ramp_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_railing_on_ramp__false",
                                            {
                                              is_railing_on_ramp_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_railing_on_ramp_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0]) ? (
                                  item.is_edge_between_ramp_row_entrance_marked ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_edge_between_ramp_row_entrance_marked ===
                                      "True"
                                        ? t(
                                            "building__room_stairs:is_edge_between_ramp_row_entrance_marked__true",
                                            {
                                              is_edge_between_ramp_row_entrance_marked_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_edge_between_ramp_row_entrance_marked_comment,
                                            }
                                          )
                                        : t(
                                            "building__room_stairs:is_edge_between_ramp_row_entrance_marked__false",
                                            {
                                              is_edge_between_ramp_row_entrance_marked_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_edge_between_ramp_row_entrance_marked_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                              </>
                            );
                          })()}
                        </ul>
                      </article>
                    </>
                  );
                }
              })()}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AccordionRoomStairs;
