import { useState, useRef } from "react";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import "./FAQAccordion.css";

import UpArrow from "../../assets/faq-accordion/up-arrow-forward.svg";
import DownArrow from "../../assets/faq-accordion/down-arrow-forward.svg";

import Info from "../../assets/faq-accordion/info.svg";
import ActiveInfo from "../../assets/faq-accordion/active-info.svg";

function FAQAccordion({ item }) {
  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [isActive, setActive] = useState(false);

  const headingFaqRef = useRef(null);
  /**
   * The function toggles an accordion and scrolls to the target element if it is not active.
   */
  const toggleAccordion = () => {
    setActive(!isActive);
    if (!isActive) {
      setTimeout(() => {
        headingFaqRef.current?.focus();
        headingFaqRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  return (
    <>
      <div className="c-faq-accordion" role="listitem">
        <div className="c-faq-accordion__title">
          <button
            type="button"
            onClick={toggleAccordion}
            aria-expanded={isActive ? "true" : "false"}
          >
            <span>
              <img src={isActive ? ActiveInfo : Info} alt="" />
              {item.translations[cookies.i18next].name}
            </span>
            <img src={isActive ? DownArrow : UpArrow} alt="" />
          </button>
        </div>
        <div
          className={
            isActive
              ? "c-faq-accordion__content--open"
              : "c-faq-accordion__content"
          }
        >
          <div ref={headingFaqRef} tabIndex={-1}>
            {parse(String(item.translations[cookies.i18next].description))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQAccordion;
