import actions from "./actions.js";
import i18n from "../../../i18n";

import { Cookies } from "react-cookie";

import generalInformationIcon from "../../../assets/sidebar/general-information-icon.svg";
import generalInformationActiveIcon from "../../../assets/sidebar/general-information-active-icon.svg";
import accessibilityAmenitiesIcon from "../../../assets/sidebar/accessibility-amenities-icon.svg";
import accessibilityAmenitiesActiveIcon from "../../../assets/sidebar/accessibility-amenities-active-icon.svg";

export const fetchObjectDetails = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/objects/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  if (!response.ok) {
    return;
  }
  return json;
};

const fetchObjectImages = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/images/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

const fetchObjectCategoriesDetails = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/objects_category/?id=&object=${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  if (!response.ok) {
    return;
  }
  return json;
};

export const getAllObjectDetails = () => async (dispatch, getState) => {
  dispatch(actions.setLoadingState());

  const state = getState();

  const cookies = new Cookies();

  const objectDetails = await fetchObjectDetails(state.objectDetails.match);

  const objectCategoriesDetails = await fetchObjectCategoriesDetails(
    state.objectDetails.match
  );

  if (objectDetails) {
    dispatch(actions.resetObjectImages());

    dispatch(actions.addObjectDetails(objectDetails));

    dispatch(actions.resetSubsidebarItem());

    objectDetails.images.forEach(async (image) => {
      const objectImages = await fetchObjectImages(image);
      dispatch(actions.addObjectImages(objectImages));
    });

    const item = {};
    item.name = i18n.t("sidebar:sidebar_general_information");
    item.icon = generalInformationIcon;
    item.activeIcon = generalInformationActiveIcon;
    item.to = i18n.t("routes:object_general_information", {
      id: state.objectDetails.match,
    });
    item.subSidebar = [];
    dispatch(actions.addSubsidebarItem(item));

    const item1 = {};
    item1.name = i18n.t("sidebar:sidebar_accessibility_amenities");
    item1.icon = accessibilityAmenitiesIcon;
    item1.activeIcon = accessibilityAmenitiesActiveIcon;
    item1.to = i18n.t("routes:object_accessible_information", {
      id: state.objectDetails.match,
    });
    item1.subSidebar = [];
    dispatch(actions.addSubsidebarItem(item1));

    for (const category of objectCategoriesDetails) {
      const item = {};
      item.name = category.translations[cookies.get("i18next")].name;
      item.icon = category.icon;
      item.activeIcon = category.active_icon;
      item.to = i18n.t("routes:object_category_details", {
        id: state.objectDetails.match,
        category_name: category.translations[cookies.get("i18next")].name
          .toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-"),
        category_id: category.id,
      });
      dispatch(
        actions.addCategoryItem({
          id: category.id,
          name: category.translations[cookies.get("i18next")].name,
        })
      );
      item.subSidebar = [];
      dispatch(actions.addSubsidebarItem(item));
    }

    dispatch(actions.resetLoadingState());

    return true;
  } else {
    return false;
  }
};

export const setMatchParams = (match) => async (dispatch) => {
  dispatch(actions.addObjectMatch(match));
};
