import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingMarkersDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__micronavigation:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      <section aria-label={t("building__micronavigation:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__micronavigation:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name mt-3">
          {t("building__micronavigation:category_header")}
        </h3>
        <ul className="c-clickable-list">
          {item.markers &&
            item.markers.length > 0 &&
            item.markers
              .sort((a, b) => a.id - b.id)
              .map((marker) => (
                <li key={marker.id} className="c-clickable-list__item">
                  <Link
                    className="c-clickable-list__link"
                    to={{
                      pathname: `${t("routes:building_micronavigation", {
                        id: item.general.id,
                        marker_id: marker.id,
                      })}`,
                      state: {
                        item: marker,
                      },
                    }}
                  >
                    <span>{marker.name_override[cookies.i18next]}</span>
                    <img src={RightArrow} alt="" />
                  </Link>
                </li>
              ))}
        </ul>
      </section>
    </>
  );
}

export default BuildingMarkersDetails;
