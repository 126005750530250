import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import BuildingLevelRoomsDetails from "./BuildingLevelRoomsDetails";

/* A function that is called BuildingRoomsDetails and it takes props as an argument. */
function BuildingLevelsRoomsDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [levelRooms, setLevelRooms] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    let levelRoomsDist = [];

    /* Setting the title of the page. */
    document.title = t("building__room:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    fetch(
      `${process.env.REACT_APP_API_URL}/levels/?building=${item.general.id}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.forEach((data) => {
          let levelName = data.translations[cookies.i18next].name;
          let floorNumber = data.floor_number;

          const item1 = {};

          item1.name = levelName;
          item1.floor_number = floorNumber;
          item1.data = [];

          data.regions.forEach((id) => {
            fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${id}`, {
              signal,
              method: "GET",
              credentials: "include",
              headers: {
                Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                "Content-Type": "application/json",
              },
            })
              .then((response) => response.json())
              .then((data) => {
                data[0].rooms.forEach((id) => {
                  item1.data.push(id);
                });
              });
          });

          levelRoomsDist.push(item1);
        });

        const sortedLevelRooms = levelRoomsDist.sort(
          (a, b) => a["floor_number"] - b["floor_number"]
        );

        setLevelRooms(sortedLevelRooms);
      });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {levelRooms.length > 0 ? (
        <>
          <section aria-label={t("building__room:section_description")}>
            <span
              id={mode ? "js-jump-link" : "js-jump-link-mobile"}
              className="sr-only"
              tabIndex={0}
            >
              {t("building__room:startup_message", {
                building_name: item.general.translations[cookies.i18next].name,
              })}
            </span>
            <h3 className="object-category-header-name">
              {t("building__room:category_header")}
            </h3>
            {levelRooms && levelRooms.length > 0
              ? levelRooms
                  .sort((a, b) => a.order_number - b.order_number)
                  .map(
                    (levelRoom, index) =>
                      levelRoom.data && (
                        <BuildingLevelRoomsDetails
                          key={index}
                          item={levelRoom}
                          building_name={
                            item.general.translations[cookies.i18next].name
                          }
                        />
                      )
                  )
              : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default BuildingLevelsRoomsDetails;
