import types from "./types.js";

const INITIAL_TYPHLOMAP_AREAS_DETAILS = {
  match: 0,
  typhlomap: 0,
  building: 0,
  building_name: "",
  area: {},
  list: [],
  index: 0,
};

const tyflomapAreasDetailsReducer = (
  state = INITIAL_TYPHLOMAP_AREAS_DETAILS,
  action
) => {
  switch (action.type) {
    case types.ADD_AREA_MATCH:
      return {
        ...state,
        match: action.item,
      };
    case types.ADD_TYPHLOMAP:
      return {
        ...state,
        typhlomap: action.item,
      };
    case types.ADD_TYPHLOMAP_BUILDING:
      return {
        ...state,
        building: action.item,
      };
    case types.ADD_TYPHLOMAP_BUILDING_NAME:
      return {
        ...state,
        building_name: action.item,
      };
    case types.ADD_TYPHLOMAP_AREA_DETAILS:
      return {
        ...state,
        area: action.item,
      };
    case types.RESET_TYPHLOMAP_AREA_DETAILS:
      return {
        ...state,
        area: {},
      };
    case types.ADD_TYPHLOMAP_AREAS:
      return {
        ...state,
        list: action.item,
      };
    case types.RESET_TYPHLOMAP_AREAS:
      return {
        ...state,
        list: [],
      };
    case types.ADD_TYPHLOMAP_AREA_INDEX:
      return {
        ...state,
        index: action.item,
      };
    default:
      return state;
  }
};

export default tyflomapAreasDetailsReducer;
