import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import Popup from "../Popup/Popup";

import "./Gallery.css";

function Gallery({ item }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [imageIndex, setImageIndex] = useState(0);

  const [images, setImages] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const prevImage = (e) => {
    e.stopPropagation();
    if (imageIndex === 0) {
      setImageIndex(item.length - 1);
    } else if (imageIndex !== 0) {
      setImageIndex((prevState) => prevState.item.length - 1);
    }
  };

  const nextImage = (e) => {
    e.stopPropagation();
    if (imageIndex !== item.length - 1) {
      setImageIndex((prevState) => prevState.item.length + 1);
    } else if (imageIndex === item.length - 1) {
      setImageIndex(0);
    }
  };

  const handlePopup = () => {
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    setImages([]);

    item?.forEach((data) => {
      setImages((oldImages) => [...oldImages, data]);
    });
  }, [item]);

  return (
    <>
      <div className="gallery-container mt-2 mb-4">
        <div className="gallery-container-box row d-flex justify-content-center">
          <div className="col-auto col-sm-auto col-md-auto col-lg-auto d-flex justify-content-center align-items-center">
            <button
              className="prev-image-btn"
              type="button"
              aria-label={t("gallery:button-prev-image-label")}
              onClick={prevImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.988"
                height="16.822"
                viewBox="0 0 9.988 16.822"
              >
                <path
                  id="Icon_ionic-ios-arrow-forward"
                  data-name="Icon ionic-ios-arrow-forward"
                  d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                  transform="translate(9.988 16.822) rotate(180)"
                />
              </svg>
            </button>
          </div>
          <div className="col d-flex justify-content-center">
            {item && item.length > 0 ? (
              <div
                role="img"
                aria-label={
                  item[imageIndex].translations[cookies.i18next]
                    .alternative_description
                }
                style={{
                  backgroundImage: `url(${item[imageIndex].image_960w})`,
                }}
                key={item[imageIndex].id}
                className="main-img"
                onClick={handlePopup}
              >
                <div className="image-btn-mobile-container">
                  {item.length > 1 ? (
                    <>
                      <div className="col-auto col-sm-auto col-md-auto col-lg-auto d-flex justify-content-center align-items-center">
                        <button
                          className="prev-image-btn-mobile"
                          type="button"
                          aria-label={t("gallery:button-prev-image-label")}
                          onClick={prevImage}
                          aria-hidden={true}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.988"
                            height="16.822"
                            viewBox="0 0 9.988 16.822"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-forward"
                              data-name="Icon ionic-ios-arrow-forward"
                              d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                              transform="translate(9.988 16.822) rotate(180)"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="col-auto col-sm-auto col-md-auto col-lg-auto d-flex justify-content-center align-items-center">
                        <button
                          className="next-image-btn-mobile"
                          type="button"
                          aria-label={t("gallery:button-next-image-label")}
                          onClick={nextImage}
                          aria-hidden={true}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.988"
                            height="16.822"
                            viewBox="0 0 9.988 16.822"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-forward"
                              data-name="Icon ionic-ios-arrow-forward"
                              d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="gallery-navigation-container">
                  <ul className="gallery-navigation-container__list">
                    {item.length > 1 &&
                      item?.map((item) => {
                        return parseInt(item.id) ===
                          parseInt(imageIndex) + 1 ? (
                          <li
                            className="gallery-navigation-dot"
                            key={item.id}
                          ></li>
                        ) : (
                          <li
                            className="gallery-current-image-navigation-dot"
                            key={item.id}
                          ></li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-auto col-sm-auto col-md-auto col-lg-auto d-flex justify-content-center align-items-center">
            <button
              className="next-image-btn"
              type="button"
              aria-label={t("gallery:button-next-image-label")}
              onClick={nextImage}
              aria-hidden={true}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.988"
                height="16.822"
                viewBox="0 0 9.988 16.822"
              >
                <path
                  id="Icon_ionic-ios-arrow-forward"
                  data-name="Icon ionic-ios-arrow-forward"
                  d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                />
              </svg>
            </button>
          </div>
        </div>
        {item.length > 1 ? (
          <>
            <div className="gallery-container-photos-collection-box row p-2 ml-3 mr-3 mt-2">
              <div className="col-auto col-sm-auto col-md-auto col-lg-auto d-flex justify-content-center align-items-center">
                <button
                  className="prev-image-btn"
                  type="button"
                  aria-label={t("gallery:button-prev-image-label")}
                  onClick={prevImage}
                  aria-hidden={true}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.988"
                    height="16.822"
                    viewBox="0 0 9.988 16.822"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                      transform="translate(9.988 16.822) rotate(180)"
                    />
                  </svg>
                </button>
              </div>
              {item
                ?.sort((a, b) => a.id - b.id)
                .map((image, index) => (
                  <div
                    role="img"
                    className={
                      index > 3
                        ? "col-2 col-sm-2 col-md-2 col-lg-2 small-img-none"
                        : "col-2 col-sm-2 col-md-2 col-lg-2 small-img"
                    }
                    aria-label={
                      image.translations[cookies.i18next]
                        .alternative_description
                    }
                    style={{
                      backgroundImage: `url(${image.image_320w})`,
                    }}
                    onClick={() => {
                      setImageIndex(index);
                    }}
                    key={image.id}
                  ></div>
                ))}
              <div className="col-auto col-sm-auto col-md-auto col-lg-auto d-flex justify-content-center align-items-center">
                <button
                  className="next-image-btn"
                  type="button"
                  aria-label={t("gallery:button-next-image-label")}
                  onClick={nextImage}
                  aria-hidden={true}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.988"
                    height="16.822"
                    viewBox="0 0 9.988 16.822"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {isModalVisible ? (
        <Popup
          images={images}
          index={imageIndex}
          image={item[imageIndex]}
          closePopup={handlePopup}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Gallery;
