import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import Popup from "../Popup/Popup";

import "./CategoryGallery.css";

import MorePhotos from "../../assets/more-photos.svg";

function CategoryGallery({ item }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [images, setImages] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [popupImage, setPopupImage] = useState("");
  const [popupImageIndex, setPopupImageIndex] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setImages([]);

    item?.forEach((data) => {
      fetch(`${process.env.REACT_APP_API_URL}/images/?id=${data}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((data) => {
            setImages((oldImages) => [...oldImages, data]);
          });
        });
    });

    return () => {
      controller.abort();
    };
  }, [item]);

  const getPopupImage = (image, index) => {
    setPopupImage(image);
    setPopupImageIndex(index);
    setIsModalVisible(!isModalVisible);
  };

  const handlePopup = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <div className="l-small-gallery-container">
        <div className="c-small-gallery-start-container">
          {images
            ?.slice(0, 3)
            .sort((a, b) => a.id - b.id)
            .map((image, index) => (
              <div
                key={image.id}
                role="img"
                className="c-small-gallery-start-container__box m-2"
                onClick={() => getPopupImage(image, index)}
                style={{ backgroundImage: `url(${image.image_960w})` }}
                alt={
                  image.translations &&
                  image.translations[cookies.i18next].alternative_description
                }
                tabIndex={0}
              >
                {index === 2 && images.length > 3 ? (
                  <div className="c-small-gallery-start-more-btn-container">
                    <button
                      type="button"
                      className="c-small-gallery-start-more-btn-container__btn"
                      aria-label={t("gallery:show-more-images-label")}
                    >
                      <img src={MorePhotos} alt="" />
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          {isModalVisible ? (
            <Popup
              images={images}
              index={popupImageIndex}
              image={popupImage}
              closePopup={handlePopup}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default CategoryGallery;
