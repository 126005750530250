import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ArticleCard from "../ArticleCard/ArticleCard";

function ArticlesContainer() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access the history object. */
  const history = useHistory();

  /* Setting the state of the articles to an empty array. */
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Fetching data from the API. */
    fetch(`${process.env.REACT_APP_API_URL}/news/`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        /* Setting the state of the articles to the data that is returned from the API. */
        setArticles(data);
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {Object.keys(articles).length >= 3 ? (
        <>
          <h2 className="text--home-header">{t("home:news_header")}</h2>
          <div className="row">
            {articles
              .sort((a, b) => a.id - b.id)
              .reverse()
              .slice(0, 3)
              .map((article) => (
                <ArticleCard key={article.id} item={article} />
              ))}
          </div>
          <div className="row justify-content-center align-items-center">
            <button
              className="btn--more-news"
              type="button"
              onClick={() => history.push(t("routes:article_no_id"))}
            >
              {t("news:all_news_button")}
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ArticlesContainer;
