import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./NewsArticleContainer.css";

import DefaultImage960 from "../../assets/default-image-960w.png";

import RightMoreButton from "../../assets/left-more-button.svg";

function NewsArticleContainer({ item }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [images, setImages] = useState(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    item.images?.forEach((data) => {
      fetch(`${process.env.REACT_APP_API_URL}/images/?id=${data}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach(async (data) => {
            setImages(data);
          });
        });
    });

    return () => {
      controller.abort();
    };
  }, []);

  const catDescription = (text, count) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  };

  return (
    <>
      {item ? (
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-10 col-lg-10 c-news-article-container">
            <Link
              to={`${t("routes:article", { id: item.id })}`}
              aria-label={`${t("general:read_more_button")} ${
                item.translations[cookies.i18next].name
              }`}
            >
              <div className="c-card c-card--direction">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <img
                    className="c-card__img--direction"
                    src={images ? images.image_960w : DefaultImage960}
                    alt=""
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                  <div className="c-card__body">
                    <h3 className="c-card__title">
                      {catDescription(
                        item.translations[cookies.i18next].name,
                        30
                      )}
                    </h3>
                    <span className="c-card__date">
                      {item.date.replaceAll("-", ".")}
                    </span>
                    <p className="c-card__content">
                      {catDescription(
                        item.translations[cookies.i18next].description,
                        200
                      )}
                    </p>
                    <div className="c-card__btn-more">
                      <img src={RightMoreButton} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default NewsArticleContainer;
