import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./Pagination.css";

import LeftPaginationArrow from "../../assets/pagination-prev-page-arrow.svg";
import RightPaginationArrow from "../../assets/pagination-next-page-arrow.svg";

import LeftMobilePaginationArrow from "../../assets/left-arrow-pagination.svg";
import RightMobilePaginationArrow from "../../assets/right-arrow-pagination.svg";

function Pagination(props, ref) {
  const {
    prevPageClick,
    nextPageClick,
    currentPage,
    arrOfCurrButtons,
    numOfButtons,
    setCurrentPage,
  } = props;
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* Setting the width of the window to the width of the window. */
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateDimensions);

    return () => {
      document.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <>
      <nav
        className="l-pagination"
        aria-label={t("news:pagination_nav_description")}
      >
        <div className="c-pagination">
          <ul className="c-pagination__list">
            <li
              className={`c-pagination__item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                aria-label={t("pagination:button-prev-page-label")}
                className="c-pagination__prev-page-btn"
                onClick={prevPageClick}
                disabled={currentPage === 1}
                aria-disabled={currentPage === 1}
              >
                <img
                  src={
                    width > 991
                      ? LeftPaginationArrow
                      : LeftMobilePaginationArrow
                  }
                  alt=""
                />
              </button>
            </li>
            {arrOfCurrButtons.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`c-pagination__item ${
                    currentPage === item ? "active" : ""
                  }`}
                >
                  <a
                    className="c-pagination__link"
                    onClick={() => {
                      setCurrentPage(item);

                      ref.current?.focus();
                      ref.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    onKeyDown={() => {
                      setCurrentPage(item);

                      ref.current?.focus();
                      ref.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    aria-label={
                      currentPage === item
                        ? t("pagination:current-page-link", {
                            page: item,
                          })
                        : t("pagination:go-to-page-link", {
                            page: item,
                          })
                    }
                    aria-current={currentPage === item}
                  >
                    {item}
                  </a>
                </li>
              );
            })}
            <li
              className={`c-pagination__item ${
                currentPage === numOfButtons.length ? "disabled" : ""
              }`}
            >
              <button
                aria-label={t("pagination:button-next-page-label")}
                className="c-pagination__next-page-btn"
                onClick={nextPageClick}
                disabled={currentPage === numOfButtons.length}
                aria-disabled={currentPage === numOfButtons.length}
              >
                <img
                  src={
                    width > 991
                      ? RightPaginationArrow
                      : RightMobilePaginationArrow
                  }
                  alt=""
                />
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default React.forwardRef(Pagination);
