import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, withRouter } from "react-router-dom";
import parse from "html-react-parser";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingEvacuationDetails({ item, mode }) {
  const { id } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  const [image, setImage] = useState(null);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__evacuation:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (item.general.evacuation_map) {
      fetch(
        `${process.env.REACT_APP_API_URL}/images/?id=${item.general.evacuation_map}`,
        {
          signal,
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setImage(data);
        });

      return () => {
        controller.abort();
      };
    }
  }, []);

  return (
    <>
      <section aria-label={t("building__evacuation:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__evacuation:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name">
          {t("building__evacuation:category_header")}
        </h3>
        {item.general.translations[cookies.i18next].evacuation_description &&
          parse(
            String(
              item.general.translations[cookies.i18next].evacuation_description
            )
          )}
        {image && (
          <>
            <p
              role="heading"
              aria-level="4"
              className="object-category-subheader-name"
            >
              {t("building__evacuation:evacuation_map_subheader")}:
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={image && image[0].image_960w}
                className="object-img"
                alt=""
              />
            </div>
          </>
        )}
        {item.entrances && item.entrances.length > 0 ? (
          <>
            <p
              role="heading"
              aria-level="4"
              className="object-category-subheader-name"
            >
              {t("building__evacuation:emergency_exits_subheader")}:
            </p>
            <ul className="c-clickable-list">
              {item.entrances &&
                item.entrances.length > 0 &&
                item.entrances
                  .sort((a, b) => a.order_number - b.order_number)
                  .map((entrance) => (
                    <li key={entrance.id} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={{
                          pathname: `${t(
                            "routes:building_evacuation_entrance_details",
                            {
                              id: id,
                              entrance_id: entrance.id,
                            }
                          )}`,
                          state: {
                            category: "evacuation",
                            building_name:
                              item.general.translations[cookies.i18next].name,
                          },
                        }}
                      >
                        <span>
                          {entrance.translations[cookies.i18next].name}
                        </span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))}
            </ul>
          </>
        ) : null}
      </section>
    </>
  );
}

export default withRouter(BuildingEvacuationDetails);
