import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Footer.css";

import PNSSign from "../../assets/footer/logo_pns.svg";
import TNMSign from "../../assets/footer/logo_tnm.svg";

function Nav() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  return (
    <>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-3">
              <p className="text text--uppercase text--gray text--w-medium text--small">
                {t("footer:footer_university_name")}
              </p>
              <p className="text text--w-medium text--white text--huge">
                {t("footer:footer_app_name")}
              </p>
            </div>
            <div className="col-12 col-sm-7 col-md-5 col-lg-3 col-xl-3 address-info">
              <p className="text text--gray">
                {t("footer:footer_address_info_1")}
                <br></br>
                {t("footer:footer_address_info_2")}
                <br></br>
                {t("footer:footer_address_info_3")}
              </p>
            </div>
            <div className="col-12 col-sm-5 col-md-4 col-lg-3 contact-info">
              <p className="text text--gray">
                tel. <a href="tel:+48 71 320 36 49">+48 71 320 36 49</a>
                <br></br>
                e-mail:{" "}
                <a href="mailto:przewodnik@pwr.edu.pl">przewodnik@pwr.edu.pl</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <nav>
                <ul className="footer-nav-menu-list">
                  <li>
                    <Link to={t("routes:micronavigation")}>
                      {t("footer:footer_nav_micronavigation")}
                    </Link>
                  </li>
                  <li>
                    <Link to={t("routes:about")}>
                      {t("footer:footer_nav_about")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname:
                          "https://dostepnosc.pwr.edu.pl/dostepnosc_cyfrowa/deklaracje-dostepnosci/dd-przewodnikpwredupl",
                      }}
                      target="_blank"
                    >
                      {t("footer:footer_nav_accessibility_statement")}{" "}
                      <span className="sr-only">
                        {t("general:sr-only_link_description")}
                      </span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="row">
            <p className="text text--small text--w-medium text--gray text--uppercase">
              {t("footer:footer_university_name")} © {new Date().getFullYear()}
            </p>
          </div>
        </div>
        <div className="footer-signs">
          <img src={PNSSign} alt="" />
          <img src={TNMSign} alt="" />
        </div>
        <div className="footer-decoration"></div>
      </footer>
    </>
  );
}

export default Nav;
