import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import "./EmailConfirmationAlertMessage.css";

import AlertError from "../../assets/alert-error.svg";
import AlertSuccess from "../../assets/alert-success.svg";

function EmailConfirmationAlertMessage({ type, closeModal }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [alert, setAlert] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      closeModal();
    }, 3000);
  }, []);

  return ReactDOM.createPortal(
    alert && (
      <div className="c-alert-container">
        <div
          className={`alert ${
            type === "success" ? "alert-success" : "alert-danger"
          } m-3 d-flex row`}
          role="alert"
        >
          <div className="col-2 d-flex justify-content-center align-items-center">
            {type === "success" ? (
              <img src={AlertSuccess} alt="" />
            ) : (
              <img src={AlertError} alt="" />
            )}
          </div>
          <div className="col-10">
            {type === "success" ? (
              <p className="alert-heading">{t("modal:alert-success-header")}</p>
            ) : (
              <>
                <p className="alert-heading">{t("modal:alert-error-header")}</p>
                <p>{t("modal:alert-error-description")}</p>
              </>
            )}
          </div>
        </div>
      </div>
    ),
    document.getElementById("popup")
  );
}

export default EmailConfirmationAlertMessage;
