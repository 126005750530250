import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import AccessibilityInfoContainer from "../AccessibilityInfoContainer/AccessibilityInfoContainer";

import Gallery from "../Gallery/Gallery";

import WhiteDownDoubleArrow from "../../assets/white-down-double-arrows.svg";

function BuildingGeneralDetails({ item, mode }) {
  /* A hook that allows you to use i18next in React. */
  const { t } = useTranslation();

  const [image, setImage] = useState(null);

  /* A list of all the campuses of the university. */
  const campusList = [
    t("building__general_information:campus_1"),
    t("building__general_information:campus_2"),
    t("building__general_information:campus_3"),
    t("building__general_information:campus_4"),
    t("building__general_information:campus_5"),
    t("building__general_information:campus_6"),
    t("building__general_information:campus_7"),
    t("building__general_information:campus_8"),
    t("building__general_information:campus_9"),
    t("building__general_information:campus_10"),
    t("building__general_information:campus_11"),
  ];

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  /* Setting the title of the page to the name of the building. */
  useEffect(() => {
    document.title = t("building__general_information:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (item.general.location_map) {
      fetch(
        `${process.env.REACT_APP_API_URL}/images/?id=${item.general.location_map}`,
        {
          signal,
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setImage(data);
        });

      return () => {
        controller.abort();
      };
    }
  }, []);

  return (
    <>
      <section
        aria-label={t("building__general_information:section_description")}
      >
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__general_information:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        {item.general ? (
          <AccessibilityInfoContainer
            item={item.general}
            type={1}
            category={"building"}
          />
        ) : null}
        {item.images && item.images.length > 0 ? (
          <Gallery item={item.images} />
        ) : null}
        <div className="object-general-information">
          <p className="object-category-subheader-name">
            {t("building__general_information:name_subheader")}:
          </p>
          <p className="text">
            {item.general.translations[cookies.i18next].name}
          </p>
          {item.general.translations[cookies.i18next].extended_name ===
          "" ? null : (
            <>
              <p className="object-category-subheader-name">
                {t("building__general_information:extended_name_subheader")}:
              </p>
              <p className="text">
                {item.general.translations[cookies.i18next].extended_name}
              </p>
            </>
          )}
          <p className="object-category-subheader-name">
            {t("building__general_information:campus_subheader")}:
          </p>
          <p className="text">
            {campusList[parseInt(item.general.campus) - 1]}
          </p>
          <p className="object-category-subheader-name">
            {t("building__general_information:address_subheader")}:
          </p>
          <p className="text">
            {item.general.translations[cookies.i18next].address}
          </p>
          {item.general.telephone_number === "" ? null : (
            <>
              <p className="object-category-subheader-name">
                {t("building__general_information:contact_number_subheader")}:
              </p>
              <div className="text">
                {parse(String(item.general.telephone_number))}
              </div>
            </>
          )}
          {item.general.number_of_storeys && (
            <>
              <p className="object-category-subheader-name">
                {t("building__general_information:number_of_floors_subheader")}:
              </p>
              <p className="text">{item.general.number_of_storeys}</p>
            </>
          )}
          {image && (
            <>
              <p className="object-category-subheader-name">
                {t("building__general_information:location_transcription")}:
              </p>
              <div className="d-flex justify-content-center">
                <img
                  src={image && image[0].image_960w}
                  className="object-img"
                  alt={
                    item.general.translations[cookies.i18next]
                      .location_transcription
                  }
                />
              </div>
            </>
          )}
        </div>
        {item.general.link_to_building_location && (
          <div className="c-map">
            <div className="c-map__btn-container">
              <button
                type="button"
                className="c-map__btn--campus-map"
                onClick={() =>
                  window.open(item.general.link_to_building_location, "_blank")
                }
                aria-label={t(
                  "building__general_information:location-button-label",
                  {
                    building_name:
                      item.general.translations[cookies.i18next].name,
                  }
                )}
              >
                {t("building__general_information:location-button", {
                  building_name:
                    item.general.translations[cookies.i18next].name,
                })}
                <span className="sr-only">
                  {t("general:sr-only_link_description")}
                </span>
                <img src={WhiteDownDoubleArrow} alt="" />
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default BuildingGeneralDetails;
