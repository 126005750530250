import types from "./types.js";

const addObjectMatch = (item) => ({
  type: types.ADD_OBJECT_MATCH,
  item,
});

const setLoadingState = () => ({
  type: types.SET_LOADING_STATE,
});

const resetLoadingState = () => ({
  type: types.RESET_LOADING_STATE,
});

const addObjectDetails = (item) => ({
  type: types.ADD_OBJECT_DETAILS,
  item,
});

const resetObjectDetails = (item) => ({
  type: types.RESET_OBJECT_DETAILS,
  item,
});

const addObjectImages = (item) => ({
  type: types.ADD_OBJECT_IMAGES,
  item,
});

const resetObjectImages = (item) => ({
  type: types.RESET_OBJECT_IMAGES,
  item,
});

const addSubsidebarItem = (item) => ({
  type: types.ADD_SUBSIDEBAR_ITEM,
  item,
});

const resetSubsidebarItem = (item) => ({
  type: types.RESET_SUBSIDEBAR_ITEM,
  item,
});

const addCategoryItem = (item) => ({
  type: types.ADD_OBJECT_CATEGORY_ITEM,
  item,
});

const resetCategoryItem = (item) => ({
  type: types.RESET_OBJECT_CATEGORY_ITEM,
  item,
});

export default {
  addObjectMatch,
  setLoadingState,
  resetLoadingState,
  addObjectDetails,
  resetObjectDetails,
  addObjectImages,
  resetObjectImages,
  addSubsidebarItem,
  resetSubsidebarItem,
  addCategoryItem,
  resetCategoryItem,
};
