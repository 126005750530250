import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import "./ObjectsDisplayContainer.css";

import RightMoreButton from "../../assets/left-more-button.svg";

function ObjectsDisplayContainer({
  name,
  items,
  searchTerm,
  setShowSearchResults,
  showSearchResults,
  setObjectsObjectsLength,
}) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* Creating a state variable called objectsObjects and setting it to an empty array. */
  const [objectsObjects, setObjectsObjects] = useState([]);

  /* Creating a state variable called isActive and setting it to true. */
  const [isActive, setActive] = useState(true);

  /* Fetching data from the API and setting it to the state. */
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (!showSearchResults || searchTerm === "") {
      items.forEach((item) => {
        /* Checking if the item has an image. */
        if (typeof item.images[0] !== "undefined") {
          /* Fetching data from the API. */
          fetch(`${process.env.REACT_APP_API_URL}/images/${item.images[0]}`, {
            signal,
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              /* Creating a new object with the properties id, item and data. */
              const item1 = {};
              item1.id = item.id;
              item1.item = item;
              item1.data = data;
              /* Adding the new object to the array. */
              setObjectsObjects((objectsObjects) => [...objectsObjects, item1]);
            });
        }
      });
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (showSearchResults && items) {
      setObjectsObjects([]);

      const searchItems =
        items.length > 0
          ? items.filter((object) => {
              if (searchTerm === "") {
                return object;
              } else if (
                object.translations[cookies.i18next].name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return object;
              }
            })
          : null;

      searchItems.forEach((item) => {
        /* Checking if the item has an image. */
        if (typeof item.images[0] !== "undefined") {
          /* Fetching data from the API. */
          fetch(`${process.env.REACT_APP_API_URL}/images/${item.images[0]}`, {
            signal,
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              /* Creating a new object with the properties id, item and data. */
              const item1 = {};
              item1.id = item.id;
              item1.item = item;
              item1.data = data;
              /* Adding the new object to the array. */
              setObjectsObjects((objectsObjects) => [...objectsObjects, item1]);
            });
        }
      });
      setObjectsObjectsLength((prevValue) => prevValue + searchItems.length);

      setShowSearchResults(false);
    }
    return () => {
      controller.abort();
    };
  }, [showSearchResults]);

  /*
   * When the button is clicked, toggle the value of the isActive state variable.
   */
  const toggleActive = () => {
    setActive(!isActive);
  };

  return (
    <>
      {objectsObjects && objectsObjects.length > 0 ? (
        <>
          <div className="row">
            <div
              className="col-4 col-sm-4 col-md-3 col-lg-1 col-xl-1 c-objects-header"
              onClick={toggleActive}
              aria-expanded={isActive ? "true" : "false"}
            >
              <p
                role="heading"
                aria-level="3"
                className="c-objects-header__label"
              >
                {name}
              </p>
              <div className="c-objects-header__objects-number">
                {objectsObjects.length}
              </div>
            </div>
          </div>
          <div
            className={
              isActive
                ? "row justify-content-start c-objects-list-container--open"
                : "row justify-content-start c-objects-list-container"
            }
          >
            {objectsObjects
              .sort((a, b) => a.id - b.id)
              .map((object) => (
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4"
                  key={object.id}
                >
                  <Link
                    to={t("routes:object_details", {
                      id: object.id,
                    })}
                    aria-label={`${t("general:read_more_button")} ${
                      object.item.translations[cookies.i18next].name
                    }`}
                  >
                    <div className="c-card">
                      <img
                        className="c-card__img"
                        sizes="(max-width: 991px) 960px, (min-width: 991px) and (max-width: 1199px) 320px, (min-width: 1199px) 960px"
                        srcSet={`${object.data.image_320w} 320w,
                              ${object.data.image_960w} 960w`}
                        src={object.data.image_960w}
                        alt=""
                      />
                      <div className="c-card__body">
                        <h4 className="c-card__title" id={`card-${object.id}`}>
                          {object.item.translations[cookies.i18next].name}
                        </h4>
                        <p>
                          {object.item.translations[cookies.i18next].address}
                        </p>
                        <div className="c-card__btn-more">
                          <img src={RightMoreButton} alt="" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </>
      ) : null}
    </>
  );
}

export default ObjectsDisplayContainer;
