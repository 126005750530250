import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import AccordionRings from "./Accordion/AccordionRings";

import CategoryGallery from "../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../assets/profiles/profile-pictogram-white.svg";

function BuildingLodgeDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__lodge:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      <section aria-label={t("building__lodge:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__lodge:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        {Object.keys(item.lodges).length !== 0 ? (
          <>
            <h3 className="object-category-header-name">
              {t("building__lodge:category_header")}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.lodges[0].translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.lodges[0].translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.lodges[0].translations[cookies.i18next].location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__lodge:location", {
                          location:
                            item.lodges[0].translations[cookies.i18next]
                              .location,
                        })}
                      </li>
                    )}
                    {item.lodges[0].is_marked === "Unknown" ? null : (
                      <li className="text">
                        {item.lodges[0].is_marked === "True"
                          ? t("building__lodge:is_marked__true", {
                              is_marked_comment:
                                item.lodges[0].translations[cookies.i18next]
                                  .is_marked_comment,
                            })
                          : t("building__lodge:is_marked__false", {
                              is_marked_comment:
                                item.lodges[0].translations[cookies.i18next]
                                  .is_marked_comment,
                            })}
                      </li>
                    )}
                    {item.lodges[0].are_seats_available === "Unknown" ? null : (
                      <li className="text">
                        {item.lodges[0].are_seats_available === "True"
                          ? t("building__lodge:are_seats_available__true", {
                              are_seats_available_comment:
                                item.lodges[0].translations[cookies.i18next]
                                  .are_seats_available_comment,
                            })
                          : t("building__lodge:are_seats_available__false", {
                              are_seats_available_comment:
                                item.lodges[0].translations[cookies.i18next]
                                  .are_seats_available_comment,
                            })}
                      </li>
                    )}
                    {item.lodges[0].is_space_on_counter === "Unknown" ? null : (
                      <li className="text">
                        {item.lodges[0].is_space_on_counter === "True"
                          ? t("building__lodge:is_space_on_counter__true", {
                              is_space_on_counter_comment:
                                item.lodges[0].translations[cookies.i18next]
                                  .is_space_on_counter_comment,
                            })
                          : t("building__lodge:is_space_on_counter__false", {
                              is_space_on_counter_comment:
                                item.lodges[0].translations[cookies.i18next]
                                  .is_space_on_counter_comment,
                            })}
                      </li>
                    )}
                    {item.lodges[0].translations[cookies.i18next]
                      .working_days_and_hours === "" ? null : (
                      <li className="text">
                        {t("building__lodge:working_days_and_hours", {
                          working_days_and_hours:
                            item.lodges[0].translations[cookies.i18next]
                              .working_days_and_hours,
                        })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [3]) ? (
                                item.lodges[0].is_visible_from_afar ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.lodges[0].is_visible_from_afar ===
                                    "True"
                                      ? t(
                                          "building__lodge:is_visible_from_afar__true",
                                          {
                                            is_visible_from_afar_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_visible_from_afar_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:is_visible_from_afar__false",
                                          {
                                            is_visible_from_afar_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_visible_from_afar_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.lodges[0].are_access_barriers ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.lodges[0].are_access_barriers ===
                                    "True"
                                      ? t(
                                          "building__lodge:are_access_barriers__true",
                                          {
                                            are_access_barriers_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].are_access_barriers_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:are_access_barriers__false",
                                          {
                                            are_access_barriers_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].are_access_barriers_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.lodges[0].is_movement_space ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.lodges[0].is_movement_space === "True"
                                      ? t(
                                          "building__lodge:is_movement_space__true",
                                          {
                                            is_movement_space_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_movement_space_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:is_movement_space__false",
                                          {
                                            is_movement_space_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_movement_space_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.lodges[0].is_good_lit ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.lodges[0].is_good_lit === "True"
                                      ? t("building__lodge:is_good_lit__true", {
                                          is_good_lit_comment:
                                            item.lodges[0].translations[
                                              cookies.i18next
                                            ].is_good_lit_comment,
                                        })
                                      : t(
                                          "building__lodge:is_good_lit__false",
                                          {
                                            is_good_lit_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_good_lit_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.lodges[0].counter_height ? (
                                  <li className="text">
                                    {t("building__lodge:counter_height", {
                                      counter_height:
                                        item.lodges[0].counter_height,
                                    })}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.lodges[0].is_space_under_counter ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.lodges[0].is_space_under_counter ===
                                    "True"
                                      ? t(
                                          "building__lodge:is_space_under_counter__true",
                                          {
                                            is_space_under_counter_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_space_under_counter_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:is_space_under_counter__false",
                                          {
                                            is_space_under_counter_comment:
                                              item.lodges[0].translations[
                                                cookies.i18next
                                              ].is_space_under_counter_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            {item.lodges[0].rings && item.lodges[0].rings.length > 0
              ? item.lodges[0].rings.map((ring, index) => (
                  <AccordionRings
                    id={ring}
                    key={ring}
                    parentObjectName={null}
                    parentObjectIndex={null}
                    lodgeIndex={index}
                  />
                ))
              : null}
            {item.lodges[0].images && item.lodges[0].images.length > 0 ? (
              <CategoryGallery item={item.lodges[0].images} />
            ) : null}
          </>
        ) : null}
      </section>
    </>
  );
}

export default BuildingLodgeDetails;
