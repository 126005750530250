const ADD_STUDENTS_HOUSES_LIST = "ADD_STUDENTS_HOUSES_LIST";
const RESET_STUDENTS_HOUSES_LIST = "RESET_STUDENTS_HOUSES_LIST";
const ADD_STUDENTS_HOUSES_TO_SORTED_CATEGORY =
  "ADD_STUDENTS_HOUSES_TO_SORTED_CATEGORY";
const RESET_STUDENTS_HOUSES_SORTED_CATEGORY =
  "RESET_STUDENTS_HOUSES_SORTED_CATEGORY";

export default {
  ADD_STUDENTS_HOUSES_LIST,
  RESET_STUDENTS_HOUSES_LIST,
  ADD_STUDENTS_HOUSES_TO_SORTED_CATEGORY,
  RESET_STUDENTS_HOUSES_SORTED_CATEGORY,
};
