import actions from "./actions.js";

const fetchLibraries = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/libraries/`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();

  return json;
};

export const getAllLibraries = () => async (dispatch) => {
  const libraries = await fetchLibraries();

  dispatch(actions.reset());
  dispatch(actions.add(libraries));
};
