import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";
import RightArrow from "../../../assets/arrow-forward.svg";

function ClickableItemLinkParkingDetails({ mode }) {
  const { id, levelId, regionId, parkingId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const parkingTypesList = [
    t("building__parking:parking_type_1"),
    t("building__parking:parking_type_2"),
    t("building__parking:parking_type_3"),
    t("building__parking:parking_type_4"),
  ];

  /* The above code is using the useCookies hook to set a cookie. */
  const [cookies] = useCookies(["value"]);

  /* The above code is using the useState hook to create a state variable called item and a function
called setItem. */
  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(
          `object-category-header-${parkingId}`
        );
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${parkingId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  /* Fetching data from an API and setting the state of the component. */
  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__parking:single_title", {
      building_name: buildingName,
    });

    const controller = new AbortController();
    const signal = controller.signal;

    /* Making a GET request to the API endpoint. */
    fetch(`${process.env.REACT_APP_API_URL}/parkings/?id=${parkingId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        /* Iterating over the data array and calling the setItem function for each item in the array. */
        data.forEach(async (data) => {
          /* Setting the data to the local storage. */
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [parkingId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section aria-label={t("building__parking:label")}>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {t("building__parking:label")}
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${parkingId}`
                  : `object-category-mobile-header-${parkingId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {t("building__parking:label")}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.translations[cookies.i18next].entry_location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__parking:entry_location", {
                          entry_location:
                            item.translations[cookies.i18next].entry_location,
                        })}
                      </li>
                    )}
                    {item.is_entry_from_ground_level === "Unknown" ? null : (
                      <li className="text">
                        {item.is_entry_from_ground_level === "True"
                          ? t(
                              "building__parking:is_entry_from_ground_level__true",
                              {
                                is_entry_from_ground_level_comment:
                                  item.translations[cookies.i18next]
                                    .is_entry_from_ground_level_comment,
                              }
                            )
                          : t(
                              "building__parking:is_entry_from_ground_level__false",
                              {
                                is_entry_from_ground_level_comment:
                                  item.translations[cookies.i18next]
                                    .is_entry_from_ground_level_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_set_maximum_vehicle_height === "Unknown" ? null : (
                      <li className="text">
                        {item.is_set_maximum_vehicle_height === "True"
                          ? t(
                              "building__parking:is_set_maximum_vehicle_height__true",
                              {
                                is_set_maximum_vehicle_height_comment:
                                  item.translations[cookies.i18next]
                                    .is_set_maximum_vehicle_height_comment,
                              }
                            )
                          : t(
                              "building__parking:is_set_maximum_vehicle_height__false",
                              {
                                is_set_maximum_vehicle_height_comment:
                                  item.translations[cookies.i18next]
                                    .is_set_maximum_vehicle_height_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.permissions_types ? (
                      <li className="text">
                        {t("building__parking:permissions_types", {
                          permissions_types:
                            parkingTypesList[
                              parseInt(item.permissions_types) - 1
                            ],
                        })}
                      </li>
                    ) : null}
                    {item.is_multistorey_parking === "Unknown" ? null : (
                      <li className="text">
                        {item.is_multistorey_parking === "True"
                          ? t(
                              "building__parking:is_multistorey_parking__true",
                              {
                                is_multistorey_parking_comment:
                                  item.translations[cookies.i18next]
                                    .is_multistorey_parking_comment,
                              }
                            )
                          : t(
                              "building__parking:is_multistorey_parking__false",
                              {
                                is_multistorey_parking_comment:
                                  item.translations[cookies.i18next]
                                    .is_multistorey_parking_comment,
                              }
                            )}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [0, 1, 2, 3, 4, 5, 6]) ? (
                                item.are_spaces_for_pwd === "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_spaces_for_pwd === "True"
                                      ? t(
                                          "building__parking:are_spaces_for_pwd__true",
                                          {
                                            are_spaces_for_pwd_comment:
                                              item.translations[cookies.i18next]
                                                .are_spaces_for_pwd_comment,
                                          }
                                        )
                                      : t(
                                          "building__parking:are_spaces_for_pwd__false",
                                          {
                                            are_spaces_for_pwd_comment:
                                              item.translations[cookies.i18next]
                                                .are_spaces_for_pwd_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 3, 4]) ? (
                                item.are_spaces_for_pwd_vertical_marked ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_spaces_for_pwd_vertical_marked ===
                                    "True"
                                      ? t(
                                          "building__parking:are_spaces_for_pwd_vertical_marked__true",
                                          {
                                            are_spaces_for_pwd_vertical_marked_comment:
                                              item.translations[cookies.i18next]
                                                .are_spaces_for_pwd_vertical_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__parking:are_spaces_for_pwd_vertical_marked__false",
                                          {
                                            are_spaces_for_pwd_vertical_marked_comment:
                                              item.translations[cookies.i18next]
                                                .are_spaces_for_pwd_vertical_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_road_accessible_for_people_in_wheelchairs ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_road_accessible_for_people_in_wheelchairs ===
                                    "True"
                                      ? t(
                                          "building__parking:is_road_accessible_for_people_in_wheelchairs__true",
                                          {
                                            is_road_accessible_for_people_in_wheelchairs_comment:
                                              item.translations[cookies.i18next]
                                                .is_road_accessible_for_people_in_wheelchairs_comment,
                                          }
                                        )
                                      : t(
                                          "building__parking:is_road_accessible_for_people_in_wheelchairs__false",
                                          {
                                            is_road_accessible_for_people_in_wheelchairs_comment:
                                              item.translations[cookies.i18next]
                                                .is_road_accessible_for_people_in_wheelchairs_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 1, 2, 3, 4, 5, 6]) ? (
                                item.shortest_length_to_nearest_spaces_for_pwd ? (
                                  <li className="text">
                                    {t(
                                      "building__parking:shortest_length_to_nearest_spaces_for_pwd",
                                      {
                                        shortest_length_to_nearest_spaces_for_pwd:
                                          item.shortest_length_to_nearest_spaces_for_pwd,
                                      }
                                    )}
                                  </li>
                                ) : null
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            <ul className="c-clickable-list">
              {item.doors && item.doors.length > 0
                ? item.doors.map((door) => (
                    <li key={door} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={{
                          pathname: `${t(
                            "routes:building_region_parking_door_details",
                            {
                              id: id,
                              level_id: levelId,
                              region_id: regionId,
                              parking_id: parkingId,
                              door_id: door,
                            }
                          )}`,
                        }}
                      >
                        <span>{t("building__door:label")}</span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkParkingDetails;
