import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, withRouter } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingLiftsDetails({ item, mode }) {
  const { id } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__lift:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      <section aria-label={t("building__lift:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__lift:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name">
          {t("building__lift:category_header")}
        </h3>
        <ul className="c-clickable-list">
          {item.lifts &&
            item.lifts.length > 0 &&
            item.lifts
              .sort((a, b) => a.order_number - b.order_number)
              .map((lift) =>
                lift.display_in_lifts_category === "True" ? (
                  <li key={lift.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t("routes:building_lift_details", {
                          id: id,
                          lift_id: lift.id,
                        })}`,
                        state: {
                          category: "lifts",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>
                        {lift.translations[cookies.i18next].is_standard_lift ===
                        ""
                          ? t("building__lift:label")
                          : lift.translations[cookies.i18next].is_standard_lift}
                      </span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ) : null
              )}
        </ul>
      </section>
    </>
  );
}

export default withRouter(BuildingLiftsDetails);
