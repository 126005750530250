import actions from "./actions.js";
import i18n from "../../../i18n";

import { fetchObjectDetails } from "../../ObjectDetails/duck/operations.js";

import { Cookies } from "react-cookie";

import generalInformationIcon from "../../../assets/sidebar/general-information-icon.svg";
import newsIcon from "../../../assets/sidebar/news-icon.svg";
import accessibilityAmenitiesIcon from "../../../assets/sidebar/accessibility-amenities-icon.svg";
import usefulInformationIcon from "../../../assets/sidebar/useful-information-icon.svg";
import surroundingIcon from "../../../assets/sidebar/surrounding-icon.svg";
import accessIcon from "../../../assets/sidebar/access-icon.svg";
import entrancesIcon from "../../../assets/sidebar/entrances-icon.svg";
import liftsIcon from "../../../assets/sidebar/lifts-icon.svg";
import lodgeIcon from "../../../assets/sidebar/lodge-icon.svg";
import dressingRoomIcon from "../../../assets/sidebar/dressing-room-icon.svg";
import informationPointIcon from "../../../assets/sidebar/information-point-icon.svg";
import adaptedToiletIcon from "../../../assets/sidebar/adapted-toilet-icon.svg";
import typhlomapsIcon from "../../../assets/sidebar/typhlomaps-icon.svg";
import micronavigationIcon from "../../../assets/sidebar/micronavigation-icon.svg";
import buildingStructureIcon from "../../../assets/sidebar/building-structure-icon.svg";
import roomsIcon from "../../../assets/sidebar/rooms-icon.svg";
import evacuationIcon from "../../../assets/sidebar/evacuation-icon.svg";

import generalInformationActiveIcon from "../../../assets/sidebar/general-information-active-icon.svg";
import newsActiveIcon from "../../../assets/sidebar/news-active-icon.svg";
import accessibilityAmenitiesActiveIcon from "../../../assets/sidebar/accessibility-amenities-active-icon.svg";
import usefulInformationActiveIcon from "../../../assets/sidebar/useful-information-active-icon.svg";
import surroundingActiveIcon from "../../../assets/sidebar/surrounding-active-icon.svg";
import accessActiveIcon from "../../../assets/sidebar/access-active-icon.svg";
import entrancesActiveIcon from "../../../assets/sidebar/entrances-active-icon.svg";
import liftsActiveIcon from "../../../assets/sidebar/lifts-active-icon.svg";
import lodgeActiveIcon from "../../../assets/sidebar/lodge-active-icon.svg";
import dressingRoomActiveIcon from "../../../assets/sidebar/dressing-room-active-icon.svg";
import informationPointActiveIcon from "../../../assets/sidebar/information-point-active-icon.svg";
import adaptedToiletActiveIcon from "../../../assets/sidebar/adapted-toilet-active-icon.svg";
import typhlomapsActiveIcon from "../../../assets/sidebar/typhlomaps-active-icon.svg";
import micronavigationActiveIcon from "../../../assets/sidebar/micronavigation-active-icon.svg";
import buildingStructureActiveIcon from "../../../assets/sidebar/building-structure-active-icon.svg";
import roomsActiveIcon from "../../../assets/sidebar/rooms-active-icon.svg";
import evacuationActiveIcon from "../../../assets/sidebar/evacuation-active-icon.svg";

export const fetchBuildingDetails = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/buildings/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();

  if (!response.ok) {
    return;
  }
  return json;
};

const fetchBuildingImages = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/images/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

export const getAllBuildingDetails = () => async (dispatch, getState) => {
  dispatch(actions.setLoadingState());

  const state = getState();

  const cookies = new Cookies();

  const buildingDetails = await fetchBuildingDetails(
    state.buildingDetails.match
  );

  if (buildingDetails) {
    dispatch(actions.resetSurroundingObjects());

    buildingDetails.buildings_relations.forEach(async (building) => {
      const surroundingBuildingDetails = await fetchBuildingDetails(building);
      dispatch(
        actions.addSurroundingObjects({
          ...surroundingBuildingDetails,
          type: "building",
        })
      );
    });

    buildingDetails.objects_relations.forEach(async (object) => {
      const surroundingObjectDetails = await fetchObjectDetails(object);
      dispatch(
        actions.addSurroundingObjects({
          ...surroundingObjectDetails,
          type: "object",
        })
      );
    });

    dispatch(actions.resetBuildingImages());

    dispatch(actions.addBuildingDetails(buildingDetails));

    dispatch(actions.resetSubsidebarItem());

    const item = {};
    item.name = i18n.t("sidebar:sidebar_general_information");
    item.icon = generalInformationIcon;
    item.activeIcon = generalInformationActiveIcon;
    item.to = i18n.t("routes:building_general_information", {
      id: state.buildingDetails.match,
    });
    item.subSidebar = [];
    dispatch(actions.addSubsidebarItem(item));

    buildingDetails.images.forEach(async (image) => {
      const buildingImages = await fetchBuildingImages(image);
      dispatch(actions.addBuildingImages(buildingImages));
    });

    const item3 = {};
    item3.name = i18n.t("sidebar:sidebar_news");
    item3.icon = newsIcon;
    item3.activeIcon = newsActiveIcon;
    item3.to = i18n.t("routes:building_news", {
      id: state.buildingDetails.match,
    });
    item3.subSidebar = [];
    dispatch(actions.addSubsidebarItem(item3));

    if (
      buildingDetails.translations[cookies.get("i18next")]
        .useful_information !== ""
    ) {
      const item = {};
      item.name = i18n.t("sidebar:sidebar_useful_information");
      item.icon = usefulInformationIcon;
      item.activeIcon = usefulInformationActiveIcon;
      item.to = i18n.t("routes:building_general_information", {
        id: state.buildingDetails.match,
      });
      item.subSidebar = [];
      dispatch(actions.addSubsidebarItem(item));
    }

    const item1 = {};
    item1.name = i18n.t("sidebar:sidebar_accessibility_amenities");
    item1.icon = accessibilityAmenitiesIcon;
    item1.activeIcon = accessibilityAmenitiesActiveIcon;
    item1.to = i18n.t("routes:building_accessible_information", {
      id: state.buildingDetails.match,
    });
    item1.subSidebar = [];
    dispatch(actions.addSubsidebarItem(item1));

    if (buildingDetails.surrounding) {
      await fetch(
        `${process.env.REACT_APP_API_URL}/surroundings/${buildingDetails.surrounding}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch(actions.addBuildingSurrounding(data));

          const item = {};
          item.name = i18n.t("sidebar:sidebar_surroundings");
          item.icon = surroundingIcon;
          item.activeIcon = surroundingActiveIcon;
          item.to = i18n.t("routes:building_surrounding", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        });
    }

    if (buildingDetails.access) {
      await fetch(
        `${process.env.REACT_APP_API_URL}/access/${buildingDetails.access}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch(actions.addBuildingAccess(data));

          const item = {};
          item.name = i18n.t("sidebar:sidebar_access");
          item.icon = accessIcon;
          item.activeIcon = accessActiveIcon;
          item.to = i18n.t("routes:building_access", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        });
    }

    await fetch(
      `${process.env.REACT_APP_API_URL}/entrances/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.order_number - b.order_number);

        dispatch(actions.addBuildingEntrances(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_entrances");
          item.icon = entrancesIcon;
          item.activeIcon = entrancesActiveIcon;
          item.to = i18n.t("routes:building_entrances", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/lifts/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.order_number - b.order_number);

        dispatch(actions.addBuildingLifts(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_lifts");
          item.icon = liftsIcon;
          item.activeIcon = liftsActiveIcon;
          item.to = i18n.t("routes:building_lifts", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/lodges/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(actions.addBuildingLodges(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_lodge");
          item.icon = lodgeIcon;
          item.activeIcon = lodgeActiveIcon;
          item.to = i18n.t("routes:building_lodge", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/dressing_rooms/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(actions.addBuildingDressingRooms(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_dressing_room");
          item.icon = dressingRoomIcon;
          item.activeIcon = dressingRoomActiveIcon;
          item.to = i18n.t("routes:building_dressing_room", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/information_points/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(actions.addBuildingInformationPoints(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_information_point");
          item.icon = informationPointIcon;
          item.activeIcon = informationPointActiveIcon;
          item.to = i18n.t("routes:building_information_point", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/adapted_toilets/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.sort((a, b) => a.order_number - b.order_number);

          dispatch(actions.addBuildingAdaptedToilets(data));

          const item = {};
          item.name = i18n.t("sidebar:sidebar_adapted_toilets");
          item.icon = adaptedToiletIcon;
          item.activeIcon = adaptedToiletActiveIcon;
          item.to = i18n.t("routes:building_adapted_toilets", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/typhlomaps/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(actions.addBuildingTyphlomaps(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_typhlomaps");
          item.icon = typhlomapsIcon;
          item.activeIcon = typhlomapsActiveIcon;
          item.to = i18n.t("routes:building_typhlomaps_no_id", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];

          data.forEach(async (data) => {
            const item1 = {};
            item1.name = data.translations[cookies.get("i18next")].name;
            item1.to = i18n.t("routes:building_typhlomaps", {
              id: state.buildingDetails.match,
              typhlo_id: data.id,
            });
            item.subSidebar.push(item1);
          });

          dispatch(actions.addSubsidebarItem(item));
        }
      });

    if (buildingDetails.external_id) {
      await fetch(
        `${process.env.REACT_APP_URL}/api2/beaconplus/?location=${buildingDetails.external_id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          data.sort((a, b) => a.id - b.id);

          dispatch(actions.addBuildingMarkers(data));

          if (data.length) {
            const item = {};
            item.name = i18n.t("sidebar:sidebar_micronavigation");
            item.icon = micronavigationIcon;
            item.activeIcon = micronavigationActiveIcon;
            item.to = i18n.t("routes:building_micronavigation_no_id", {
              id: state.buildingDetails.match,
            });
            item.subSidebar = [];

            dispatch(actions.addSubsidebarItem(item));
          }
        });
    }

    await fetch(
      `${process.env.REACT_APP_API_URL}/levels/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.floor_number - b.floor_number);

        dispatch(actions.addBuildingLevels(data));

        if (data.length) {
          const item = {};
          item.name = i18n.t("sidebar:sidebar_building_structure");
          item.icon = buildingStructureIcon;
          item.activeIcon = buildingStructureActiveIcon;
          item.to = i18n.t("routes:building_structure", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];

          data.forEach(async (data) => {
            const item1 = {};
            item1.name = data.translations[cookies.get("i18next")].name;
            item1.to = i18n.t("routes:building_level", {
              id: state.buildingDetails.match,
              level_id: data.id,
            });
            item.subSidebar.push(item1);
          });

          dispatch(actions.addSubsidebarItem(item));
        }
      });

    await fetch(
      `${process.env.REACT_APP_API_URL}/rooms/?building=${state.buildingDetails.match}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.sort((a, b) => a.order_number - b.order_number);

          dispatch(actions.addBuildingRooms(data));

          const item = {};
          item.name = i18n.t("sidebar:sidebar_rooms");
          item.icon = roomsIcon;
          item.activeIcon = roomsActiveIcon;
          item.to = i18n.t("routes:building_rooms", {
            id: state.buildingDetails.match,
          });
          item.subSidebar = [];
          dispatch(actions.addSubsidebarItem(item));
        }
      });

    const item2 = {};
    item2.name = i18n.t("sidebar:sidebar_evacuation");
    item2.icon = evacuationIcon;
    item2.activeIcon = evacuationActiveIcon;
    item2.to = i18n.t("routes:building_evacuation", {
      id: state.buildingDetails.match,
    });
    item2.subSidebar = [];
    dispatch(actions.addSubsidebarItem(item2));

    dispatch(actions.resetLoadingState());

    return true;
  } else {
    return false;
  }
};

export const setMatchParams = (match) => async (dispatch) => {
  dispatch(actions.addBuildingMatch(match));
};
