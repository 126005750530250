import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";

function ClickableItemLinkMarkerDetails({ item, mode }) {
  const { id, markerId } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const data = useLocation();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(
          `object-category-header-${markerId}`
        );
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${markerId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__marker:title", {
      marker_name: data && data.state.item.name_override[cookies.i18next],
      building_name: buildingName,
    });
  }, [markerId]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 1:
        return VisuallyImpairedProfileWhite;
      case 2:
        return MovementDysfunctionProfileWhite;
      case 3:
        return HearingDysfunctionProfileWhite;
      case 4:
        return CognitiveDifficultiesProfileWhite;
    }
  };

  return (
    <>
      {data ? (
        <>
          <section
            aria-label={`${data.state.item.name_override[cookies.i18next]}`}
          >
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: `${t("routes:building_micronavigation_no_id", {
                        id: id,
                      })}`,
                    }}
                  >
                    {t("building__micronavigation:category_header")}
                  </Link>
                  <img src={RightNavigationArrow} alt="" />
                </li>
                <li>{data.state.item.name_override[cookies.i18next]}</li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${markerId}`
                  : `object-category-mobile-header-${markerId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {data.state.item.name_override[cookies.i18next]}
            </h3>
            <p
              role="heading"
              aria-level="4"
              className="object-category-subheader-name"
            >
              {t("building__marker:marker_message_header")}
            </p>
            <div className="object-category-content">
              {parse(String(data.state.item.web_content[cookies.i18next]))}
            </div>
            {checker(cookies.value, [1, 2, 3]) &&
              data.state.item.profiles
                .filter((item) => item.profile === 1)
                .map((item) => (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t(
                        `building__marker:marker_message_profile1_header`
                      )}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={getProfilePictogram(1)}
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t(`building__marker:marker_message_profile1_header`)}
                        </span>
                      </div>
                      {parse(String(item.web_content[cookies.i18next]))}
                    </article>
                  </>
                ))}
            {checker(cookies.value, [0]) &&
              data.state.item.profiles
                .filter((item) => item.profile === 2)
                .map((item) => (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t(
                        `building__marker:marker_message_profile2_header`
                      )}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={getProfilePictogram(2)}
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t(`building__marker:marker_message_profile2_header`)}
                        </span>
                      </div>
                      {parse(String(item.web_content[cookies.i18next]))}
                    </article>
                  </>
                ))}
            {checker(cookies.value, [4]) &&
              data.state.item.profiles
                .filter((item) => item.profile === 3)
                .map((item) => (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t(
                        `building__marker:marker_message_profile3_header`
                      )}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={getProfilePictogram(3)}
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t(`building__marker:marker_message_profile3_header`)}
                        </span>
                      </div>
                      {parse(String(item.web_content[cookies.i18next]))}
                    </article>
                  </>
                ))}
            {checker(cookies.value, [6]) &&
              data.state.item.profiles
                .filter((item) => item.profile === 4)
                .map((item) => (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t(
                        `building__marker:marker_message_profile4_header`
                      )}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={getProfilePictogram(4)}
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t(`building__marker:marker_message_profile4_header`)}
                        </span>
                      </div>
                      {parse(String(item.web_content[cookies.i18next]))}
                    </article>
                  </>
                ))}
            <p
              role="heading"
              aria-level="4"
              className="object-category-subheader-name"
            >
              {t("building__marker:marker_voice_message_header")}
            </p>
            <p className="text">
              {t("building__marker:marker_voice_message_description")}
            </p>
            {cookies.i18next === "pl"
              ? data.state.item.languages
                  .filter((element) => element.lang_code === "pl")
                  .map((element) => {
                    return (
                      <audio key={element.id} controls>
                        <source
                          src={element.sound}
                          type={
                            element.sound.slice(-3) === "mp3"
                              ? "audio/mp3"
                              : "audio/wav"
                          }
                        />
                      </audio>
                    );
                  })
              : null}
            {cookies.i18next === "en"
              ? data.state.item.languages
                  .filter((element) => element.lang_code === "en")
                  .map((element) => {
                    return (
                      <audio key={element.id} controls>
                        <source
                          src={element.sound}
                          type={
                            element.sound.slice(-3) === "mp3"
                              ? "audio/mp3"
                              : "audio/wav"
                          }
                        />
                      </audio>
                    );
                  })
              : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkMarkerDetails;
