import actions from "./actions.js";

import { Cookies } from "react-cookie";

const fetchObjects = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/objects/`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();

  return json;
};

export const getAllObjects = () => async (dispatch) => {
  const objects = await fetchObjects();

  let sorted_objects = {};
  let objects_list = [];

  const cookies = new Cookies();

  dispatch(actions.resetSortedCategory());
  dispatch(actions.resetObject());

  objects.sort((a, b) => {
    const nameA = a.translations[cookies.get("i18next")].name.toLowerCase();
    const nameB = b.translations[cookies.get("i18next")].name.toLowerCase();
    return nameA.localeCompare(nameB);
  });

  objects.forEach((data) => {
    objects_list.push(data);

    const firstLetter =
      data.translations[cookies.get("i18next")].name.charAt(0);

    if (!sorted_objects.hasOwnProperty(firstLetter)) {
      sorted_objects[firstLetter] = [];
    }

    sorted_objects[firstLetter].push(data);
  });

  dispatch(actions.addObject(objects_list));
  dispatch(actions.addObjectToSortedCategory(sorted_objects));
};
