import actions from "./actions.js";

import { Cookies } from "react-cookie";

const fetchBuildings = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/buildings/?translations__type=dom studencki`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

let sorted_students_houses = {};
let students_houses_list = [];

export const getAllStudentsHouses = () => async (dispatch) => {
  const studentHouses = await fetchBuildings();

  const cookies = new Cookies();

  dispatch(actions.resetSortedCategory());
  dispatch(actions.resetStudentHouse());

  studentHouses.forEach(async (data) => {
    students_houses_list.push(data);

    if (
      !sorted_students_houses.hasOwnProperty(
        data.translations[cookies.get("i18next")].name.charAt(0)
      )
    ) {
      sorted_students_houses[
        data.translations[cookies.get("i18next")].name.charAt(0)
      ] = [];
    }
    sorted_students_houses[
      data.translations[cookies.get("i18next")].name.charAt(0)
    ].push(data);
  });

  dispatch(actions.addStudentHouse(students_houses_list));
  dispatch(actions.addStudentsHousesToSortedCategory(sorted_students_houses));
};
