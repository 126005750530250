import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingTyphlomapsDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  const [typhlomaps, setTyphlomaps] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Setting the title of the page. */
    document.title = t("building__typhlomaps:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    /* Fetching data from the API. */
    fetch(
      `${process.env.REACT_APP_API_URL}/typhlomaps/?id=&building=${item.general.id}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setTyphlomaps((typhlomaps) => [...typhlomaps, ...data]);
      });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <section aria-label={t("building__typhlomaps:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__typhlomaps:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name mt-3">
          {t("building__typhlomaps:category_header")}
        </h3>
        <ul className="c-clickable-list">
          {typhlomaps &&
            typhlomaps.length > 0 &&
            typhlomaps.map((typhlomap) => (
              <li key={typhlomap.id} className="c-clickable-list__item">
                <Link
                  className="c-clickable-list__link"
                  to={`${t("routes:building_typhlomaps", {
                    id: item.general.id,
                    typhlo_id: typhlomap.id,
                  })}`}
                >
                  <span>{typhlomap.translations[cookies.i18next].name}</span>
                  <img src={RightArrow} alt="" />
                </Link>
              </li>
            ))}
        </ul>
      </section>
    </>
  );
}

export default BuildingTyphlomapsDetails;
