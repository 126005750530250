import {
  Redirect,
  Switch,
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import "bootstrap/dist/css/bootstrap.css";

import Home from "./components/Home/Home";

import Skiplinks from "./components/Skiplinks/Skiplinks";
import Nav from "./components/Navbar/Nav";

import Parkings from "./components/Parkings/Parkings";
import Libraries from "./components/Libraries/Libraries";
import DeansOffices from "./components/DeansOffices/DeansOffices";
import StudentsHouses from "./components/StudentsHouses/StudentsHouses";
import Services from "./components/Services/Services";

import Buildings from "./components/Buildings/Buildings";
import BuildingDetails from "./components/BuildingDetails/BuildingDetails";

import Objects from "./components/Objects/Objects";
import ObjectDetails from "./components/ObjectDetails/ObjectDetails";

import Micronavigation from "./components/Micronavigation/Micronavigation";
import About from "./components/About/About";

import Footer from "./components/Footer/Footer";

import InternalServerError from "./components/InternalServerError/InternalServerError";
import NotFound from "./components/NotFound/NotFound";
import ForbiddenError from "./components/ForbiddenError/ForbiddenError";
import BadRequest from "./components/BadRequest/BadRequest";
import CookiesInfoContainer from "./components/CookiesInfo/CookiesInfoContainer";
import CallToActionButton from "./components/CallToActionButton/CallToActionButton";

import TyphlomapAreas from "./components/TyphlomapAreas/TyphlomapAreas";
import RoomsPlates from "./components/RoomsPlates/RoomsPlates";

import News from "./components/News/News";
import Article from "./components/Article/Article";

import "./App.css";

function App() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  return (
    <Router>
      <CookiesInfoContainer />
      <Skiplinks />
      <Nav />
      <Switch>
        <Route path={`/:locale(pl|en)`} exact component={Home}></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:about")}`}
          exact
          component={About}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:micronavigation")}`}
          exact
          component={Micronavigation}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:news")}`}
          exact
          component={News}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:parkings")}`}
          exact
          component={Parkings}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:libraries")}`}
          exact
          component={Libraries}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:deans_offices")}`}
          exact
          component={DeansOffices}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:services")}`}
          exact
          component={Services}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:students_houses")}`}
          exact
          component={StudentsHouses}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:buildings")}`}
          exact
          component={Buildings}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:objects")}`}
          exact
          component={Objects}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:building_details_no_id")}:id`}
          component={BuildingDetails}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:object_details_no_id")}:id`}
          component={ObjectDetails}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:area_no_id")}:item`}
          component={TyphlomapAreas}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:room")}:item`}
          component={RoomsPlates}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:article_no_id")}:item`}
          component={Article}
        ></Route>
        <Route
          path={`/:locale(pl|en)?${t("routes:500")}`}
          component={InternalServerError}
        />
        <Route
          path={`/:locale(pl|en)?${t("routes:404")}`}
          component={NotFound}
        />
        <Route
          path={`/:locale(pl|en)?${t("routes:403")}`}
          component={ForbiddenError}
        />
        <Route
          path={`/:locale(pl|en)?${t("routes:400")}`}
          component={BadRequest}
        />
        <Redirect from="*" to={`/:locale(pl|en)?${t("routes:404")}`} />
      </Switch>
      <Footer />
      <CallToActionButton />
    </Router>
  );
}

export default App;
