import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AccordionRings from "../Accordion/AccordionRings";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";

function ClickableItemLinkLodgeDetails({ mode }) {
  const { id, levelId, regionId, lodgeId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* The above code is using the useCookies hook to set a cookie. */
  const [cookies] = useCookies(["value"]);

  /* The above code is using the useState hook to create a state variable called item and a function
called setItem. */
  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(`object-category-header-${lodgeId}`);
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${lodgeId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  /* Fetching data from an API and setting the state of the component. */
  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__lodge:title", {
      building_name: buildingName,
    });

    const controller = new AbortController();
    const signal = controller.signal;

    /* Making a GET request to the API endpoint. */
    fetch(`${process.env.REACT_APP_API_URL}/lodges/?id=${lodgeId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        /* Iterating over the data array and calling the setItem function for each item in the array. */
        data.forEach(async (data) => {
          /* Setting the data to the local storage. */
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [lodgeId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section aria-label={t("building__lodge:label")}>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                <li>{t("building__lodge:label")}</li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${lodgeId}`
                  : `object-category-mobile-header-${lodgeId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {t("building__lodge:label")}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.translations[cookies.i18next].location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__lodge:location", {
                          location: item.translations[cookies.i18next].location,
                        })}
                      </li>
                    )}
                    {item.is_marked === "Unknown" ? null : (
                      <li className="text">
                        {item.is_marked === "True"
                          ? t("building__lodge:is_marked__true", {
                              is_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_marked_comment,
                            })
                          : t("building__lodge:is_marked__false", {
                              is_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_marked_comment,
                            })}
                      </li>
                    )}
                    {item.are_seats_available === "Unknown" ? null : (
                      <li className="text">
                        {item.are_seats_available === "True"
                          ? t("building__lodge:are_seats_available__true", {
                              are_seats_available_comment:
                                item.translations[cookies.i18next]
                                  .are_seats_available_comment,
                            })
                          : t("building__lodge:are_seats_available__false", {
                              are_seats_available_comment:
                                item.translations[cookies.i18next]
                                  .are_seats_available_comment,
                            })}
                      </li>
                    )}
                    {item.is_space_on_counter === "Unknown" ? null : (
                      <li className="text">
                        {item.is_space_on_counter === "True"
                          ? t("building__lodge:is_space_on_counter__true", {
                              is_space_on_counter_comment:
                                item.translations[cookies.i18next]
                                  .is_space_on_counter_comment,
                            })
                          : t("building__lodge:is_space_on_counter__false", {
                              is_space_on_counter_comment:
                                item.translations[cookies.i18next]
                                  .is_space_on_counter_comment,
                            })}
                      </li>
                    )}
                    {item.translations[cookies.i18next]
                      .working_days_and_hours === "" ? null : (
                      <li className="text">
                        {t("building__lodge:working_days_and_hours", {
                          working_days_and_hours:
                            item.translations[cookies.i18next]
                              .working_days_and_hours,
                        })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [3]) ? (
                                item.is_visible_from_afar ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_visible_from_afar === "True"
                                      ? t(
                                          "building__lodge:is_visible_from_afar__true",
                                          {
                                            is_visible_from_afar_comment:
                                              item.translations[cookies.i18next]
                                                .is_visible_from_afar_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:is_visible_from_afar__false",
                                          {
                                            is_visible_from_afar_comment:
                                              item.translations[cookies.i18next]
                                                .is_visible_from_afar_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.are_access_barriers ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_access_barriers === "True"
                                      ? t(
                                          "building__lodge:are_access_barriers__true",
                                          {
                                            are_access_barriers_comment:
                                              item.translations[cookies.i18next]
                                                .are_access_barriers_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:are_access_barriers__false",
                                          {
                                            are_access_barriers_comment:
                                              item.translations[cookies.i18next]
                                                .are_access_barriers_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_movement_space === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_movement_space === "True"
                                      ? t(
                                          "building__lodge:is_movement_space__true",
                                          {
                                            is_movement_space_comment:
                                              item.translations[cookies.i18next]
                                                .is_movement_space_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:is_movement_space__false",
                                          {
                                            is_movement_space_comment:
                                              item.translations[cookies.i18next]
                                                .is_movement_space_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_lit === "True"
                                      ? t("building__lodge:is_good_lit__true", {
                                          is_good_lit_comment:
                                            item.translations[cookies.i18next]
                                              .is_good_lit_comment,
                                        })
                                      : t(
                                          "building__lodge:is_good_lit__false",
                                          {
                                            is_good_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_lit_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.counter_height ? (
                                  <li className="text">
                                    {t("building__lodge:counter_height", {
                                      counter_height: item.counter_height,
                                    })}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_space_under_counter ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_space_under_counter === "True"
                                      ? t(
                                          "building__lodge:is_space_under_counter__true",
                                          {
                                            is_space_under_counter_comment:
                                              item.translations[cookies.i18next]
                                                .is_space_under_counter_comment,
                                          }
                                        )
                                      : t(
                                          "building__lodge:is_space_under_counter__false",
                                          {
                                            is_space_under_counter_comment:
                                              item.translations[cookies.i18next]
                                                .is_space_under_counter_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            {item.rings && item.rings.length > 0
              ? item.rings.map((ring, index) => (
                  <AccordionRings
                    id={ring}
                    key={ring}
                    parentObjectName={"lodge"}
                    parentObjectIndex={lodgeId}
                    ringIndex={index}
                    mode={mode}
                  />
                ))
              : null}
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkLodgeDetails;
