import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./ArticleCard.css";

import DefaultImage960 from "../../assets/default-image-960w.png";

import RightMoreButton from "../../assets/left-more-button.svg";

function ArticleCard({ item }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [image, setImage] = useState(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* Fetching the image from the API. */
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Checking if the item has an image. */
    if (typeof item.images[0] !== "undefined") {
      fetch(`${process.env.REACT_APP_API_URL}/images/${item.images[0]}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setImage(data);
        });
    }

    return () => {
      controller.abort();
    };
  }, []);

  /**
   * The `catDescription` function takes in a text and a count, and returns a truncated version of the
   * text with an ellipsis (...) if the text is longer than the count.
   * @param text - The `text` parameter is a string that represents the description of a cat.
   * @param count - The `count` parameter in the `catDescription` function represents the maximum
   * number of characters that the `text` should be limited to. If the length of the `text` is greater
   * than `count`, the function will truncate the `text` and append "..." to indicate that it has been
   * @returns The function `catDescription` returns a modified version of the `text` parameter. If the
   * length of the `text` is greater than `count`, it returns the first `count` characters of `text`
   * followed by "...". Otherwise, it returns the entire `text` unchanged.
   */
  const catDescription = (text, count) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  };

  return (
    <>
      {item ? (
        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
          <Link
            to={`${t("routes:article", { id: item.id })}`}
            aria-label={`${t("general:read_more_button")} ${
              item.translations[cookies.i18next].name
            }`}
          >
            <div className="c-card">
              <img
                className="c-card__img"
                src={image ? image.image_960w : DefaultImage960}
                alt=""
              />
              <div className="c-card__body">
                <h3 className="c-card__title c-card__title--article">
                  {catDescription(item.translations[cookies.i18next].name, 18)}
                </h3>
                <span className="c-card__date">
                  {item.date.replaceAll("-", ".")}
                </span>
                <p>
                  {catDescription(
                    item.translations[cookies.i18next].description,
                    130
                  )}
                </p>
                <div className="c-card__btn-more">
                  <img src={RightMoreButton} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
}

export default ArticleCard;
