import actions from "./actions.js";

const fetchDeansOffices = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/deans_offices/`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

export const getAllDeansOffices = () => async (dispatch) => {
  const deansOffices = await fetchDeansOffices();

  dispatch(actions.reset());

  dispatch(actions.add(deansOffices));
};
