import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import "./ExtendedDescriptionContainer.css";

import ImageDescription from "../../assets/image-description.svg";
import LeftExtendArrow from "../../assets/left-arrow-extend.svg";

function ExtendedDescriptionContainer({
  item,
  popupExtendedDescriptionRef,
  popupExtendedDescriptionContentRef,
}) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [isActive, setActive] = useState(false);

  const toggleContainer = () => {
    setActive(!isActive);
  };

  return (
    <>
      {item ? (
        <>
          <button
            type="button"
            aria-label={t("gallery:button-extended-description-open-label")}
            className="btn--open-detailed-description"
            onClick={toggleContainer}
            ref={popupExtendedDescriptionRef}
          >
            <img src={ImageDescription} alt="" />
          </button>
          <div
            className={
              isActive
                ? "c-detailed-description-container--open"
                : "c-detailed-description-container"
            }
            ref={popupExtendedDescriptionContentRef}
          >
            <button
              type="button"
              aria-label={t("gallery:button-extended-description-close-label")}
              className="btn--close-detailed-description"
              onClick={toggleContainer}
            >
              <img src={LeftExtendArrow} alt="" />
            </button>
            <h2>{t("gallery:detailed-description-header")}</h2>
            <span></span>
            <div className="c-detailed-description__content">
              {parse(
                String(item.translations[cookies.i18next].extended_description)
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ExtendedDescriptionContainer;
