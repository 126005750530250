import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import AccordionPublicTransport from "./Accordion/AccordionPublicTransport";
import AccordionPrivateTransport from "./Accordion/AccordionPrivateTransport";

function BuildingAccessDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__access:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      <section aria-label={t("building__access:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__access:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name">
          {t("building__access:category_header")}
        </h3>
        {item.access ? (
          <AccordionPublicTransport item={item.access} index={0} mode={mode} />
        ) : null}
        {item.access ? (
          <AccordionPrivateTransport item={item.access} index={1} mode={mode} />
        ) : null}
      </section>
    </>
  );
}

export default BuildingAccessDetails;
