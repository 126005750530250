import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import "./RoomsPlates.css";

function RoomsPlates() {
  /* A hook that is used to get the value of the parameter from the URL. */
  const { item } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* Used to redirect the user to the 404 page. */
  const history = useHistory();

  /* Setting the state of the component. */
  const [room, setRoom] = useState(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Fetching data from the API. */
    fetch(`${process.env.REACT_APP_API_URL}/rooms_plates/?id=${item}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.forEach((data) => {
            /* Setting the state of the component. */
            setRoom(data);

            /* Setting the title of the page. */
            document.title = t("room_plates:title", {
              room_number: data.room_number,
              room_name:
                cookies.i18next === "pl"
                  ? data.predefined_room_purpose !== ""
                    ? data.predefined_room_purpose
                    : data.room_name
                  : data.predefined_room_purpose_en !== ""
                  ? data.predefined_room_purpose_en
                  : data.room_name_en,
              building_name: data.building_name,
            });
          });
        } else {
          /* Redirecting the user to the 404 page. */
          history.push("/404");
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {room ? (
        <>
          <main id="content">
            <div className="container d-flex justify-content-center">
              <div className="c-room-plate">
                <p className="c-room-plate__building-name">
                  {t("room_plates:building_label")} {room.building_name}
                </p>
                <h2 className="c-room-plate__number">{room.room_number}</h2>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h2 className="c-room-plate__name">
                    {room.predefined_room_purpose
                      ? room.predefined_room_purpose
                      : room.room_name}
                  </h2>
                  <h2 className="c-room-plate__subname" lang="en">
                    {room.predefined_room_purpose_en
                      ? room.predefined_room_purpose_en
                      : room.room_name_en}
                  </h2>
                  {(room.translations.pl &&
                    room.translations.pl.faculty_name) ||
                  (room.translations.en &&
                    room.translations.en.faculty_name) ? (
                    <p className="c-room-plate__subsubname text--uppercase">
                      {cookies.i18next === "pl"
                        ? room.translations.pl.faculty_name
                        : room.translations.en.faculty_name}
                    </p>
                  ) : null}
                  {(room.translations.pl &&
                    room.translations.pl.department_name) ||
                  (room.translations.en &&
                    room.translations.en.department_name) ? (
                    <p className="c-room-plate__subsubname">
                      {cookies.i18next === "pl"
                        ? room.translations.pl.department_name
                        : room.translations.en.department_name}
                    </p>
                  ) : null}
                </div>
                <div className="c-room-plate__level">
                  <p>{t("room_plates:level")}</p>
                  <p>
                    {cookies.i18next === "pl"
                      ? room.predefined_level
                        ? room.predefined_level
                        : room.translations.pl.level
                      : room.predefined_level_en
                      ? room.predefined_level_en
                      : room.translations.en.level}
                  </p>
                </div>
                {(room.translations.pl &&
                  room.translations.pl.additional_information) ||
                (room.translations.en &&
                  room.translations.en.additional_information) ? (
                  <>
                    <h3 className="c-room-plate__additional-info-header">
                      {t("room_plates:additional_information")}
                    </h3>
                    {cookies.i18next === "pl"
                      ? parse(
                          String(room.translations.pl.additional_information)
                        )
                      : parse(
                          String(room.translations.en.additional_information)
                        )}
                  </>
                ) : null}
                {(room.translations.pl &&
                  room.translations.pl.link_to_description) ||
                (room.translations.en &&
                  room.translations.en.link_to_description) ? (
                  <p className="c-room-plate-link-to-description">
                    <a
                      href={
                        cookies.i18next === "pl"
                          ? room.translations.pl.link_to_description
                          : room.translations.en.link_to_description
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("room_plates:link_to_description_title")}
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          </main>
        </>
      ) : null}
    </>
  );
}

export default RoomsPlates;
