const ADD_BUILDINGS_LIST = "ADD_BUILDINGS_LIST";
const RESET_BUILDINGS_LIST = "RESET_BUILDINGS_LIST";
const ADD_BUILDINGS_TO_SORTED_CATEGORY = "ADD_BUILDINGS_TO_SORTED_CATEGORY";
const RESET_BUILDINGS_SORTED_CATEGORY = "RESET_BUILDINGS_SORTED_CATEGORY";

export default {
  ADD_BUILDINGS_LIST,
  RESET_BUILDINGS_LIST,
  ADD_BUILDINGS_TO_SORTED_CATEGORY,
  RESET_BUILDINGS_SORTED_CATEGORY,
};
