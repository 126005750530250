import i18n from "../../../i18n";
import actions from "./actions.js";

import { Cookies } from "react-cookie";

const fetchTyphlomapAreaDetails = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/typhlomap_areas/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  if (!response.ok) {
    return;
  }
  return json;
};

const fetchTyphlomapDetails = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/typhlomaps/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

const fetchBuildingName = async (match) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/buildings/${match}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

export const getAllTyphlomapAreasDetails = () => async (dispatch, getState) => {
  const state = getState();

  const cookies = new Cookies();

  const typhlomapAreaDetails = await fetchTyphlomapAreaDetails(
    state.typhlomapAreaDetails.match
  );
  if (typhlomapAreaDetails) {
    const typhlomapDetails = await fetchTyphlomapDetails(
      typhlomapAreaDetails.typhlomap
    );

    dispatch(actions.addTyphlomap(typhlomapDetails.id));

    const buildingName = await fetchBuildingName(typhlomapDetails.building);

    dispatch(actions.addTyphlomapBuilding(typhlomapDetails.building));
    dispatch(
      actions.addTyphlomapBuildingName(
        buildingName.translations[cookies.get("i18next")].name
      )
    );
    dispatch(actions.addTyphlomapAreaDetails(typhlomapAreaDetails));
    dispatch(actions.addTyphlomapAreas(typhlomapDetails.typhlomap_areas));
    dispatch(
      actions.addTyphlomapAreaIndex(
        typhlomapDetails.typhlomap_areas.indexOf(
          parseInt(state.typhlomapAreaDetails.match)
        )
      )
    );

    document.title = i18n.t("typhlomap_areas:title", {
      typhlomap_name:
        typhlomapAreaDetails.translations[cookies.get("i18next")].name,
      building_name: buildingName.translations[cookies.get("i18next")].name,
    });

    return true;
  } else {
    return false;
  }
};

export const setTyphlomapAreaMatchParams = (match) => async (dispatch) => {
  dispatch(actions.addTyphlomapAreaMatch(match));
};
