import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, withRouter } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingLevelsRoomsDetails({ item, building_name }) {
  const { id } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setTimeout(() => {
      item.data.forEach((element) => {
        fetch(`${process.env.REACT_APP_API_URL}/rooms/?id=${element}`, {
          signal,
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            data.forEach((data) => {
              setRooms((rooms) => [
                ...rooms,
                {
                  id: data.id,
                  name: data.translations[cookies.i18next].name,
                },
              ]);
            });
          });
      });
    }, 300);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {rooms && rooms.length > 0 ? (
        <div>
          <p
            role="heading"
            aria-level="4"
            className="object-category-subheader-name"
          >
            {item.name}
          </p>
          <ul className="c-clickable-list">
            {rooms && rooms.length > 0
              ? rooms.map((room) => (
                  <li key={room.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t("routes:building_room_details", {
                          id: id,
                          room_id: room.id,
                        })}`,
                        state: {
                          category: "rooms",
                          building_name: building_name,
                        },
                      }}
                    >
                      <span>{room.name}</span> <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </div>
      ) : null}
    </>
  );
}

export default withRouter(BuildingLevelsRoomsDetails);
