import { combineReducers } from "redux";
import buildingsReducer from "./components/Buildings/duck";
import objectsReducer from "./components/Objects/duck";
import buildingDetailsReducer from "./components/BuildingDetails/duck";
import objectDetailsReducer from "./components/ObjectDetails/duck";
import parkingsReducer from "./components/Parkings/duck";
import librariesReducer from "./components/Libraries/duck";
import deansOfficesReducer from "./components/DeansOffices/duck";
import servicesReducer from "./components/Services/duck";
import studentsHousesReducer from "./components/StudentsHouses/duck";
import newsReducer from "./components/News/duck";
import typhlomapAreasDetailsReducer from "./components/TyphlomapAreas/duck";

const rootReducer = combineReducers({
  buildings: buildingsReducer,
  buildingDetails: buildingDetailsReducer,
  objects: objectsReducer,
  objectDetails: objectDetailsReducer,
  parkings: parkingsReducer,
  libraries: librariesReducer,
  deansOffices: deansOfficesReducer,
  services: servicesReducer,
  studentsHouses: studentsHousesReducer,
  news: newsReducer,
  typhlomapAreaDetails: typhlomapAreasDetailsReducer,
});

export default rootReducer;
