import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

import "../NotFound/NotFound.css";

function InternalServerError() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access the history object. */
  const history = useHistory();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("internal_server_error:title");
  }, []);

  return (
    <>
      <main id="content">
        <div className="container">
          <div className="c-no-found-container">
            <h2>{t("internal_server_error:page_header")}</h2>
            <p>{t("internal_server_error:page_header_annotation")}</p>
            <button
              className="c-bnt--go-to-home-page"
              type="button"
              onClick={() => history.push(`/${cookies.i18next}`)}
            >
              {t("internal_server_error:go_to_home_page_button")}
            </button>
          </div>
        </div>
      </main>
    </>
  );
}

export default InternalServerError;
