import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingStructureDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Setting the title of the page. */
    document.title = t("building__structure:title", {
      building_name:
        item.general.translation &&
        item.general.translation[cookies.i18next].name,
    });

    /* Fetching data from the API. */
    fetch(
      `${process.env.REACT_APP_API_URL}/levels/?building=${item.general.id}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.floor_number - b.floor_number);
        setLevels((levels) => [...levels, ...data]);
      });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <section aria-label={t("building__structure:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__structure:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name mt-3">
          {t("building__structure:category_header")}
        </h3>
        <ul className="c-clickable-list">
          {levels &&
            levels.length > 0 &&
            levels.map((level) => (
              <li key={level.id} className="c-clickable-list__item">
                <Link
                  className="c-clickable-list__link"
                  to={`${t("routes:building_level", {
                    id: item.general.id,
                    level_id: level.id,
                  })}`}
                >
                  <span>{level.translations[cookies.i18next].name}</span>
                  <img src={RightArrow} alt="" />
                </Link>
              </li>
            ))}
        </ul>
      </section>
    </>
  );
}

export default BuildingStructureDetails;
