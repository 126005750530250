import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import "./Micronavigation.css";

import AppView from "../../assets/micronavigation/app-view.png";
import AppStoreDownloadAppPL from "../../assets/micronavigation/download-in-app-store-pl.svg";
import GooglePlayDownloadAppPL from "../../assets/micronavigation/download-in-google-play-pl.svg";
import AppStoreDownloadAppEN from "../../assets/micronavigation/download-in-app-store-en.svg";
import GooglePlayDownloadAppEN from "../../assets/micronavigation/download-in-google-play-en.svg";

function Micronavigation() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const headingRef = useRef(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [item, setItem] = useState([]);

  /* Setting the width of the window to the width of the window. */
  const [width, setWidth] = useState(window.innerWidth);

  /**
   * When the window is resized, update the width variable to the new window width.
   */
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    /* Adding an event listener to the window object that will call the updateDimensions function when
    the window is resized. */
    window.addEventListener("resize", updateDimensions);

    /* Removing the event listeners. */
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/content/?id=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      headingRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("micronavigation:title");
  }, []);

  return (
    <>
      {item.translations && (
        <>
          <main id="content">
            <div className="container">
              <h2
                id="js-category-name"
                className="text--header text--center"
                tabIndex={-1}
                ref={headingRef}
              >
                {t("micronavigation:page_header_micronavigation")}
              </h2>
              <div className="l-description-container">
                <div className="l-description-box">
                  {parse(
                    String(
                      item.translations[cookies.i18next]
                        .micronavigation_page_content
                    )
                  )}
                </div>
              </div>
              <div className="container">
                <div className="c-micronavigation-box">
                  <div className="c-micronavigation-box__bg"></div>
                  <div className="c-micronavigation-box__info">
                    <div className="c-micronavigation-box__header">
                      <h3>{t("micronavigation:download_app_header")}</h3>
                    </div>
                    <div className="c-micronavigation-box__content">
                      {parse(
                        String(
                          item.translations[cookies.i18next]
                            .micronavigation_app_content
                        )
                      )}
                    </div>
                    <div className="c-micronavigation-box__apps">
                      <Link
                        to={{
                          pathname: `https://apps.apple.com/us/app/yourway/id1473003545?l=${cookies.i18next}&ls=1`,
                        }}
                        target="_parent"
                      >
                        <img
                          src={
                            cookies.i18next === "pl"
                              ? AppStoreDownloadAppPL
                              : AppStoreDownloadAppEN
                          }
                          alt={t(
                            "micronavigation:download_app_from_app_store_alt"
                          )}
                        />
                      </Link>
                      <Link
                        to={{
                          pathname: `https://play.google.com/store/apps/details?id=org.szansadlaniewidomych.yourway&hl=${cookies.i18next}`,
                        }}
                        target="_parent"
                      >
                        <img
                          src={
                            cookies.i18next === "pl"
                              ? GooglePlayDownloadAppPL
                              : GooglePlayDownloadAppEN
                          }
                          alt={t(
                            "micronavigation:download_app_from_google_play_alt"
                          )}
                        />
                      </Link>
                    </div>
                  </div>
                  {width > 1400 ? (
                    <div className="c-micronavigation-box__app-view">
                      <img src={AppView} alt="" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default Micronavigation;
