import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import plGeneral from "./assets/locales/pl/general.json";
import enGeneral from "./assets/locales/en/general.json";

import plCookieInfo from "./assets/locales/pl/cookie.json";
import enCookieInfo from "./assets/locales/en/cookie.json";

import plRoutes from "./assets/locales/pl/routes.json";
import enRoutes from "./assets/locales/en/routes.json";

import plInternalServerError from "./assets/locales/pl/internal_server_error.json";
import enInternalServerError from "./assets/locales/en/internal_server_error.json";
import plForbiddenError from "./assets/locales/pl/forbidden_error.json";
import enForbiddenError from "./assets/locales/en/forbidden_error.json";
import plNotFound from "./assets/locales/pl/notfound.json";
import enNotFound from "./assets/locales/en/notfound.json";
import plBadRequest from "./assets/locales/pl/bad_request.json";
import enBadRequest from "./assets/locales/en/bad_request.json";

import plAbout from "./assets/locales/pl/about.json";
import enAbout from "./assets/locales/en/about.json";
import plNews from "./assets/locales/pl/news.json";
import enNews from "./assets/locales/en/news.json";
import plPagination from "./assets/locales/pl/pagination.json";
import enPagination from "./assets/locales/en/pagination.json";
import plArticle from "./assets/locales/pl/article.json";
import enArticle from "./assets/locales/en/article.json";
import plMicronavigation from "./assets/locales/pl/micronavigation.json";
import enMicronavigation from "./assets/locales/en/micronavigation.json";

import plParkings from "./assets/locales/pl/parkings.json";
import enParkings from "./assets/locales/en/parkings.json";
import plDeansOffices from "./assets/locales/pl/deans_offices.json";
import enDeansOffices from "./assets/locales/en/deans_offices.json";
import plLibraries from "./assets/locales/pl/libraries.json";
import enLibraries from "./assets/locales/en/libraries.json";
import plStudentsHouses from "./assets/locales/pl/students_houses.json";
import enStudentsHouses from "./assets/locales/en/students_houses.json";
import plServices from "./assets/locales/pl/services.json";
import enServices from "./assets/locales/en/services.json";

import plSkiplink from "./assets/locales/pl/skiplink.json";
import enSkiplink from "./assets/locales/en/skiplink.json";
import plNav from "./assets/locales/pl/nav.json";
import enNav from "./assets/locales/en/nav.json";
import plHome from "./assets/locales/pl/home.json";
import enHome from "./assets/locales/en/home.json";
import plFooter from "./assets/locales/pl/footer.json";
import enFooter from "./assets/locales/en/footer.json";

import plBuildings from "./assets/locales/pl/buildings.json";
import enBuildings from "./assets/locales/en/buildings.json";

import plSidebar from "./assets/locales/pl/sidebar.json";
import enSidebar from "./assets/locales/en/sidebar.json";

import plDropdownProfile from "./assets/locales/pl/dropdown_profile.json";
import enDropdownProfile from "./assets/locales/en/dropdown_profile.json";

import plModal from "./assets/locales/pl/modal.json";
import enModal from "./assets/locales/en/modal.json";

import plBuildingAccessibilityInfo from "./assets/locales/pl/building__accessibility_info.json";
import enBuildingAccessibilityInfo from "./assets/locales/en/building__accessibility_info.json";
import plObjectAccessibilityInfo from "./assets/locales/pl/object__accessibility_info.json";
import enObjectAccessibilityInfo from "./assets/locales/en/object__accessibility_info.json";

import plGallery from "./assets/locales/pl/gallery.json";
import enGallery from "./assets/locales/en/gallery.json";

import plBuildingGeneralInformation from "./assets/locales/pl/building__general_information.json";
import enBuildingGeneralInformation from "./assets/locales/en/building__general_information.json";
import plBuildingNews from "./assets/locales/pl/building__news.json";
import enBuildingNews from "./assets/locales/en/building__news.json";
import plBuildingUsefulInformation from "./assets/locales/pl/building__useful_information.json";
import enBuildingUsefulInformation from "./assets/locales/en/building__useful_information.json";
import plBuildingAccessibilityAmenities from "./assets/locales/pl/building__accessibility_amenities.json";
import enBuildingAccessibilityAmenities from "./assets/locales/en/building__accessibility_amenities.json";
import plBuildingSurrounding from "./assets/locales/pl/building__surrounding.json";
import enBuildingSurrounding from "./assets/locales/en/building__surrounding.json";
import plBuildingAccess from "./assets/locales/pl/building__access.json";
import enBuildingAccess from "./assets/locales/en/building__access.json";
import plBuildingEntrance from "./assets/locales/pl/building__entrance.json";
import enBuildingEntrance from "./assets/locales/en/building__entrance.json";
import plBuildingDoor from "./assets/locales/pl/building__door.json";
import enBuildingDoor from "./assets/locales/en/building__door.json";
import plBuildingStairs from "./assets/locales/pl/building__stairs.json";
import enBuildingStairs from "./assets/locales/en/building__stairs.json";
import plBuildingStairway from "./assets/locales/pl/building__stairway.json";
import enBuildingStairway from "./assets/locales/en/building__stairway.json";
import plBuildingRailing from "./assets/locales/pl/building__railing.json";
import enBuildingRailing from "./assets/locales/en/building__railing.json";
import plBuildingRamp from "./assets/locales/pl/building__ramp.json";
import enBuildingRamp from "./assets/locales/en/building__ramp.json";
import plBuildingCorridor from "./assets/locales/pl/building__corridor.json";
import enBuildingCorridor from "./assets/locales/en/building__corridor.json";
import plBuildingIntercom from "./assets/locales/pl/building__intercom.json";
import enBuildingIntercom from "./assets/locales/en/building__intercom.json";
import plBuildingCardReader from "./assets/locales/pl/building__card_reader.json";
import enBuildingCardReader from "./assets/locales/en/building__card_reader.json";
import plBuildingDressingRoom from "./assets/locales/pl/building__dressing_room.json";
import enBuildingDressingRoom from "./assets/locales/en/building__dressing_room.json";
import plBuildingRing from "./assets/locales/pl/building__ring.json";
import enBuildingRing from "./assets/locales/en/building__ring.json";
import plBuildingLodge from "./assets/locales/pl/building__lodge.json";
import enBuildingLodge from "./assets/locales/en/building__lodge.json";
import plBuildingLift from "./assets/locales/pl/building__lift.json";
import enBuildingLift from "./assets/locales/en/building__lift.json";
import plBuildingRoomPlatform from "./assets/locales/pl/building__room_platform.json";
import enBuildingRoomPlatform from "./assets/locales/en/building__room_platform.json";
import plBuildingRoomStairs from "./assets/locales/pl/building__room_stairs.json";
import enBuildingRoomStairs from "./assets/locales/en/building__room_stairs.json";
import plBuildingRoom from "./assets/locales/pl/building__room.json";
import enBuildingRoom from "./assets/locales/en/building__room.json";
import plBuildingInformationPoint from "./assets/locales/pl/building__information_point.json";
import enBuildingInformationPoint from "./assets/locales/en/building__information_point.json";
import plBuildingToilet from "./assets/locales/pl/building__toilet.json";
import enBuildingToilet from "./assets/locales/en/building__toilet.json";
import plBuildingAdaptedToilet from "./assets/locales/pl/building__adapted_toilet.json";
import enBuildingAdaptedToilet from "./assets/locales/en/building__adapted_toilet.json";
import plBuildingParking from "./assets/locales/pl/building__parking.json";
import enBuildingParking from "./assets/locales/en/building__parking.json";

import plBuildingTyphlomaps from "./assets/locales/pl/building__typhlomaps.json";
import enBuildingTyphlomaps from "./assets/locales/en/building__typhlomaps.json";
import plBuildingTyphlomap from "./assets/locales/pl/building__typhlomap.json";
import enBuildingTyphlomap from "./assets/locales/en/building__typhlomap.json";
import plBuildingMicronavigation from "./assets/locales/pl/building__micronavigation.json";
import enBuildingMicronavigation from "./assets/locales/en/building__micronavigation.json";
import plBuildingMarker from "./assets/locales/pl/building__marker.json";
import enBuildingMarker from "./assets/locales/en/building__marker.json";

import plBuildingStructure from "./assets/locales/pl/building__structure.json";
import enBuildingStructure from "./assets/locales/en/building__structure.json";
import plBuildingLevel from "./assets/locales/pl/building__level.json";
import enBuildingLevel from "./assets/locales/en/building__level.json";
import plBuildingRegion from "./assets/locales/pl/building__region.json";
import enBuildingRegion from "./assets/locales/en/building__region.json";

import plBuildingEvacuation from "./assets/locales/pl/building__evacuation.json";
import enBuildingEvacuation from "./assets/locales/en/building__evacuation.json";

import plObjects from "./assets/locales/pl/objects.json";
import enObjects from "./assets/locales/en/objects.json";
import plObjectDetails from "./assets/locales/pl/object_details.json";
import enObjectDetails from "./assets/locales/en/object_details.json";

import plObjectGeneralInformation from "./assets/locales/pl/object__general_information.json";
import enObjectGeneralInformation from "./assets/locales/en/object__general_information.json";
import plObjectAccessibilityAmenities from "./assets/locales/pl/object__accessibility_amenities.json";
import enObjectAccessibilityAmenities from "./assets/locales/en/object__accessibility_amenities.json";

import plRoomPlates from "./assets/locales/pl/room_plates.json";
import enRoomPlates from "./assets/locales/en/room_plates.json";
import plTyphlomapAreas from "./assets/locales/pl/typhlomap_areas.json";
import enTyphlomapAreas from "./assets/locales/en/typhlomap_areas.json";

const resources = {
  pl: {
    general: plGeneral,
    cookie: plCookieInfo,
    routes: plRoutes,
    internal_server_error: plInternalServerError,
    notfound: plNotFound,
    forbidden_error: plForbiddenError,
    bad_request: plBadRequest,
    about: plAbout,
    news: plNews,
    pagination: plPagination,
    article: plArticle,
    micronavigation: plMicronavigation,
    parkings: plParkings,
    deans_offices: plDeansOffices,
    libraries: plLibraries,
    services: plServices,
    students_houses: plStudentsHouses,
    skiplink: plSkiplink,
    nav: plNav,
    home: plHome,
    footer: plFooter,
    buildings: plBuildings,
    sidebar: plSidebar,
    dropdown_profile: plDropdownProfile,
    modal: plModal,
    building__accessibility_info: plBuildingAccessibilityInfo,
    building__general_information: plBuildingGeneralInformation,
    building__news: plBuildingNews,
    building__useful_information: plBuildingUsefulInformation,
    building__accessibility_amenities: plBuildingAccessibilityAmenities,
    building__surrounding: plBuildingSurrounding,
    building__access: plBuildingAccess,
    building__entrance: plBuildingEntrance,
    building__door: plBuildingDoor,
    building__stairs: plBuildingStairs,
    building__stairway: plBuildingStairway,
    building__railing: plBuildingRailing,
    building__ramp: plBuildingRamp,
    building__corridor: plBuildingCorridor,
    building__intercom: plBuildingIntercom,
    building__card_reader: plBuildingCardReader,
    building__dressing_room: plBuildingDressingRoom,
    building__ring: plBuildingRing,
    building__lodge: plBuildingLodge,
    building__lift: plBuildingLift,
    building__room: plBuildingRoom,
    building__room_platform: plBuildingRoomPlatform,
    building__room_stairs: plBuildingRoomStairs,
    building__information_point: plBuildingInformationPoint,
    building__toilet: plBuildingToilet,
    building__adapted_toilet: plBuildingAdaptedToilet,
    building__parking: plBuildingParking,
    building__typhlomaps: plBuildingTyphlomaps,
    building__typhlomap: plBuildingTyphlomap,
    building__micronavigation: plBuildingMicronavigation,
    building__marker: plBuildingMarker,
    building__structure: plBuildingStructure,
    building__level: plBuildingLevel,
    building__region: plBuildingRegion,
    building__evacuation: plBuildingEvacuation,
    objects: plObjects,
    object__accessibility_info: plObjectAccessibilityInfo,
    object_details: plObjectDetails,
    object__general_information: plObjectGeneralInformation,
    object__accessibility_amenities: plObjectAccessibilityAmenities,
    gallery: plGallery,
    room_plates: plRoomPlates,
    typhlomap_areas: plTyphlomapAreas,
  },
  en: {
    general: enGeneral,
    cookie: enCookieInfo,
    routes: enRoutes,
    internal_server_error: enInternalServerError,
    notfound: enNotFound,
    forbidden_error: enForbiddenError,
    bad_request: enBadRequest,
    about: enAbout,
    news: enNews,
    pagination: enPagination,
    article: enArticle,
    micronavigation: enMicronavigation,
    parkings: enParkings,
    deans_offices: enDeansOffices,
    libraries: enLibraries,
    services: enServices,
    students_houses: enStudentsHouses,
    skiplink: enSkiplink,
    nav: enNav,
    home: enHome,
    footer: enFooter,
    buildings: enBuildings,
    sidebar: enSidebar,
    dropdown_profile: enDropdownProfile,
    modal: enModal,
    building__accessibility_info: enBuildingAccessibilityInfo,
    building__general_information: enBuildingGeneralInformation,
    building__news: enBuildingNews,
    building__useful_information: enBuildingUsefulInformation,
    building__accessibility_amenities: enBuildingAccessibilityAmenities,
    building__surrounding: enBuildingSurrounding,
    building__access: enBuildingAccess,
    building__entrance: enBuildingEntrance,
    building__door: enBuildingDoor,
    building__stairs: enBuildingStairs,
    building__stairway: enBuildingStairway,
    building__railing: enBuildingRailing,
    building__ramp: enBuildingRamp,
    building__corridor: enBuildingCorridor,
    building__intercom: enBuildingIntercom,
    building__card_reader: enBuildingCardReader,
    building__dressing_room: enBuildingDressingRoom,
    building__ring: enBuildingRing,
    building__lodge: enBuildingLodge,
    building__lift: enBuildingLift,
    building__room: enBuildingRoom,
    building__room_platform: enBuildingRoomPlatform,
    building__room_stairs: enBuildingRoomStairs,
    building__information_point: enBuildingInformationPoint,
    building__toilet: enBuildingToilet,
    building__adapted_toilet: enBuildingAdaptedToilet,
    building__parking: enBuildingParking,
    building__typhlomaps: enBuildingTyphlomaps,
    building__typhlomap: enBuildingTyphlomap,
    building__micronavigation: enBuildingMicronavigation,
    building__marker: enBuildingMarker,
    building__structure: enBuildingStructure,
    building__level: enBuildingLevel,
    building__region: enBuildingRegion,
    building__evacuation: enBuildingEvacuation,
    objects: enObjects,
    object__accessibility_info: enObjectAccessibilityInfo,
    object_details: enObjectDetails,
    object__general_information: enObjectGeneralInformation,
    object__accessibility_amenities: enObjectAccessibilityAmenities,
    gallery: enGallery,
    room_plates: enRoomPlates,
    typhlomap_areas: enTyphlomapAreas,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    resources,
    supportedLngs: ["pl", "en"],
    fallbackLng: "pl",
    defaultNS: "common",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
  });

document.documentElement.lang = i18n.language;

export default i18n;
