import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import NewsContainer from "../NewsContainer/NewsContainer";
import FAQAccordion from "../FAQAccordion/FAQAccordion";

import "./Home.css";

import PolishFlagSign from "../../assets/home/poland_sign.svg";
import EuropeanFundsSign from "../../assets/home/fe_sign.svg";
import EuropeanUnionSign from "../../assets/home/ue_sign.svg";

import BuildingsCardImage from "../../assets/home/buildings.svg";
import ObjectsCardImage from "../../assets/home/objects.svg";
import ParkingsCardImage from "../../assets/home/parkings.svg";
import LibrariesCardImage from "../../assets/home/libraries.svg";
import DeansOfficesCardImage from "../../assets/home/deans-offices.svg";
import StudentsHousesCardImage from "../../assets/home/students-houses.svg";
import ServicesCardImage from "../../assets/home/services.svg";
import VirtualWalkCardImage from "../../assets/home/virtual-walk.svg";
import CampusMapCardImage from "../../assets/home/campus-map.svg";

import HomeDecorationImage from "../../assets/home/home.png";

function Home() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [item, setItem] = useState([]);

  const [buildings, setBuildings] = useState([]);
  const [objects, setObjects] = useState([]);
  const [parkings, setParkings] = useState([]);

  const [questions, setQuestions] = useState([]);

  /* Setting the state of the number of buildings, objects, and parkings to 0. */
  const [numberOfBuildings, setNumberOfBuildings] = useState(0);
  const [numberOfObjects, setNumberOfObjects] = useState(0);
  const [numberOfParkings, setNumberOfParkings] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);

  /**
   * The handleClickOutside function checks if a click event occurred outside of a specific dropdown
   * element and updates the state accordingly.
   * @param e - The parameter "e" is an event object that represents the event that triggered the
   * function. In this case, it is likely a mouse click event.
   */
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
      setIsSearchInputOpen(false);
    }
  };

  /* The above code is using the useEffect hook in React to add an event listener to the document for
  the "mousedown" event. It then calls the handleClickOutside function when a mousedown event occurs
  outside of the component. */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/content/?id=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    /* Fetching the number of buildings from the API and setting the state of the number of buildings. */
    fetch(`${process.env.REACT_APP_API_URL}/buildings/`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBuildings(data);
        /* Setting the number of buildings to the length of the data array. */
        setNumberOfBuildings(data.length);
      });

    /* Fetching the number of objects from the API and setting the state of the numberOfObjects
    variable. */
    fetch(`${process.env.REACT_APP_API_URL}/objects/`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setObjects(data);
        /* Setting the number of objects to the length of the data array. */
        setNumberOfObjects(data.length);
      });

    /* Fetching the data from the API and setting the state of the number of parkings. */
    fetch(`${process.env.REACT_APP_API_URL}/outside_parkings/`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setParkings(data);
        /* Setting the number of parkings to the length of the data array. */
        setNumberOfParkings(data.length);
      });

    /* Fetching the data from the API and setting the state of FAQ questions. */
    fetch(`${process.env.REACT_APP_API_URL}/faq/?content=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
      });

    return () => {
      controller.abort();
    };
  }, []);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("home:title");
  }, []);

  const searchBuildingsResultsList =
    buildings.length > 0
      ? buildings
          .filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
          .slice(0, 3)
          .map((item) => {
            return (
              <li key={item.id} className="c-dropdown-search-results__item">
                <Link
                  to={t("routes:building_details_no_id") + item.id}
                  tabIndex={1}
                  className="c-dropdown-search-results___link"
                >
                  {item.translations[cookies.i18next].name}
                </Link>
              </li>
            );
          })
      : [];

  const searchObjectsResultsList =
    objects.length > 0
      ? objects
          .filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
          .slice(0, 3)
          .map((item) => {
            return (
              <li key={item.id} className="c-dropdown-search-results__item">
                <Link
                  to={t("routes:object_details_no_id") + item.id}
                  tabIndex={1}
                  className="c-dropdown-search-results___link"
                >
                  {item.translations[cookies.i18next].name}
                </Link>
              </li>
            );
          })
      : [];

  const searchParkingsResultsList =
    parkings.length > 0
      ? parkings
          .filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
          .slice(0, 3)
          .map((item) => {
            return (
              <li key={item.id} className="c-dropdown-search-results__item">
                <Link
                  to={t("routes:parkings") + `#${item.id}`}
                  tabIndex={1}
                  className="c-dropdown-search-results___link"
                >
                  {item.translations[cookies.i18next].name}
                </Link>
              </li>
            );
          })
      : [];

  return (
    <>
      {item.translations && (
        <>
          <main id="content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <div className="l-description-container">
                    <div className="l-description-box">
                      <p className="text--header">{t("home:hello")}</p>
                      <p className="text--header">{t("home:app_name")}!</p>
                      {parse(
                        String(
                          item.translations[cookies.i18next].main_page_content
                        )
                      )}
                      <section
                        className="l-search-container l-search-container--home"
                        aria-label={t("home:search_section_label")}
                      >
                        <form role="search" className="c-search">
                          <label
                            htmlFor="search-input"
                            className="c-search__label"
                            role="heading"
                            aria-level="2"
                          >
                            <span className="sr-only">
                              {t("home:search_input_placeholder")}
                            </span>
                          </label>
                          <input
                            type="text"
                            id="search-input"
                            className="c-search__input"
                            tabIndex="1"
                            placeholder={t("home:search_input_placeholder")}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsSearchInputOpen(true);
                            }}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                              setIsSearchInputOpen(true);
                            }}
                          />
                        </form>
                        <div
                          className={`c-dropdown-search-results ${
                            searchTerm && isSearchInputOpen ? "open" : ""
                          }`}
                          ref={dropdownRef}
                        >
                          {searchBuildingsResultsList &&
                          searchBuildingsResultsList.length > 0 ? (
                            <>
                              <p className="c-dropdown-search-results__category">
                                {t("general:search_buildings_category")}
                              </p>
                              <ul>{searchBuildingsResultsList}</ul>
                            </>
                          ) : null}
                          {searchObjectsResultsList &&
                          searchObjectsResultsList.length > 0 ? (
                            <>
                              <p className="c-dropdown-search-results__category">
                                {t("general:search_objects_category")}
                              </p>
                              <ul>{searchObjectsResultsList}</ul>
                            </>
                          ) : null}
                          {searchParkingsResultsList &&
                          searchParkingsResultsList.length > 0 ? (
                            <>
                              <p className="c-dropdown-search-results__category">
                                {t("general:search_parkings_category")}
                              </p>
                              <ul>{searchParkingsResultsList}</ul>
                            </>
                          ) : null}
                          <ul>
                            {searchBuildingsResultsList &&
                            searchBuildingsResultsList.length === 0 &&
                            searchObjectsResultsList &&
                            searchObjectsResultsList.length === 0 &&
                            searchParkingsResultsList &&
                            searchParkingsResultsList.length === 0 ? (
                              <li className="c-dropdown-search-no-data__item">
                                {t("general:search_dropdown_no_data_result")}
                              </li>
                            ) : null}
                          </ul>
                        </div>
                        {searchTerm && isSearchInputOpen ? (
                          <div role="alert" className="sr-only">
                            {t("general:search_input_result_suggestion_label", {
                              search_results_number:
                                searchBuildingsResultsList &&
                                searchBuildingsResultsList.length >= 0 &&
                                searchObjectsResultsList &&
                                searchObjectsResultsList.length >= 0 &&
                                searchParkingsResultsList &&
                                searchParkingsResultsList.length >= 0
                                  ? searchBuildingsResultsList.length +
                                    searchObjectsResultsList.length +
                                    searchParkingsResultsList.length
                                  : 0,
                            })}
                          </div>
                        ) : null}
                      </section>
                      <div className="l-info-container">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 l-info-container-box">
                          <p>{numberOfBuildings}</p>
                          <p>{t("home:number_of_buildings_label")}</p>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 l-info-container-box">
                          <p>{numberOfObjects}</p>
                          <p>{t("home:number_of_objects_label")}</p>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 l-info-container-box">
                          <p>{numberOfParkings}</p>
                          <p>{t("home:number_of_parkings_label")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 l-home-decorative-container">
                  <img src={HomeDecorationImage} alt="" />
                </div>
              </div>
              <div className="l-sign-container">
                <img src={EuropeanFundsSign} alt="" />
                <img src={PolishFlagSign} alt="" />
                <img src={EuropeanUnionSign} alt="" />
              </div>
              <nav>
                <div className="row l-cards-list" role="list">
                  <h2 className="text--home-header">
                    {t("home:all_categories_header")}
                  </h2>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:buildings")}
                      >
                        <img
                          className="c-card__img--home"
                          src={BuildingsCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_buildings")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:objects")}
                      >
                        <img
                          className="c-card__img--home"
                          src={ObjectsCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_objects")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:parkings")}
                      >
                        <img
                          className="c-card__img--home"
                          src={ParkingsCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_parkings")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:libraries")}
                      >
                        <img
                          className="c-card__img--home"
                          src={LibrariesCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_libraries")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:deans_offices")}
                      >
                        <img
                          className="c-card__img--home"
                          src={DeansOfficesCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_deans_offices")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:students_houses")}
                      >
                        <img
                          className="c-card__img--home"
                          src={StudentsHousesCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_students_houses")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={t("routes:services")}
                      >
                        <img
                          className="c-card__img--home"
                          src={ServicesCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_services")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  {/* <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={{ pathname: "http://pwr.rtprog.pl/" }}
                        target="_blank"
                      >
                        <img
                          className="c-card__img--home"
                          src={VirtualWalkCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_virtual_tour")}
                        </p>{" "}
                        <span className="sr-only">
                          {t("general:sr-only_link_description")}
                        </span>
                      </Link>
                    </div>
                  </div> */}
                  <div
                    className="col-sm-11 col-md-6 col-lg-6 col-xl-4"
                    role="listitem"
                  >
                    <div className="c-card c-card--home">
                      <Link
                        className="c-card__link--home"
                        to={{
                          pathname:
                            "https://experience.arcgis.com/experience/a709b3a0bd9342f2bdf7423382e6e7ba/",
                        }}
                        target="_blank"
                      >
                        <img
                          className="c-card__img--home"
                          src={CampusMapCardImage}
                          alt=""
                        />
                        <p className="c-card__title--home">
                          {t("home:home_card_campus_map")}
                        </p>{" "}
                        <span className="sr-only">
                          {t("general:sr-only_link_description")}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="row justify-content-center l-articles-container">
                <NewsContainer />
              </div>
              <div className="container">
                <div className="row l-faq-container">
                  <h2 className="text--home-header text--center">
                    {t("home:faq_questions_header")}
                  </h2>
                  <div
                    className="col-10 col-sm-11 col-md-10 col-lg-10 col-xl-10"
                    role="list"
                  >
                    {questions.map(function (data) {
                      return <FAQAccordion key={data.id} item={data} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default Home;
