import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import "./AccessibilityInfoContainer.css";

import MovementDysfunctionInaccessiblePictogram from "../../assets/accessibility-info/movement dysfunction inaccessible level pictogram.svg";
import MovementDysfunctionSemiAccessPictogram from "../../assets/accessibility-info/movement dysfunction semi access level pictogram.svg";
import MovementDysfunctionFullAccessPictogram from "../../assets/accessibility-info/movement dysfunction full access level pictogram.svg";

import BlindInaccessiblePictogram from "../../assets/accessibility-info/blind inaccessible level pictogram.svg";
import BlindSemiAccessPictogram from "../../assets/accessibility-info/blind semi access level pictogram.svg";
import BlindFullAccessPictogram from "../../assets/accessibility-info/blind full access level pictogram.svg";

import VisuallyImpairedInaccessiblePictogram from "../../assets/accessibility-info/visually impaired inaccessible level pictogram.svg";
import VisuallyImpairedSemiAccessPictogram from "../../assets/accessibility-info/visually impaired semi access level pictogram.svg";
import VisuallyImpairedFullAccessPictogram from "../../assets/accessibility-info/visually impaired full access level pictogram.svg";

import HearingDysfunctionInaccessiblePictogram from "../../assets/accessibility-info/hearing dysfunction inaccessible level pictogram.svg";
import HearingDysfunctionSemiAccessPictogram from "../../assets/accessibility-info/hearing dysfunction semi access level pictogram.svg";
import HearingDysfunctionFullAccessPictogram from "../../assets/accessibility-info/hearing dysfunction full access level pictogram.svg";

import CognitiveDifficultiesInaccessiblePictogram from "../../assets/accessibility-info/cognitive difficulties inaccessible level pictogram.svg";
import CognitiveDifficultiesSemiAccessPictogram from "../../assets/accessibility-info/cognitive difficulties semi access level pictogram.svg";
import CognitiveDifficultiesFullAccessPictogram from "../../assets/accessibility-info/cognitive difficulties full access level pictogram.svg";

import SensorySensitivityInaccessiblePictogram from "../../assets/accessibility-info/sensory sensitivity inaccessible level pictogram.svg";
import SensorySensitivitySemiAccessPictogram from "../../assets/accessibility-info/sensory sensitivity semi access level pictogram.svg";
import SensorySensitivityFullAccessPictogram from "../../assets/accessibility-info/sensory sensitivity full access level pictogram.svg";

function AccessibilityInfoContainer({ item, type, category }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["value"]);

  /**
   * The function checks if any element in the target array is included in the given array.
   * @param arr - The first parameter `arr` is an array that we want to check for the presence of
   * certain elements.
   * @param target - The `target` parameter is an array of values that we want to check if they exist
   * in another array (`arr`). The `checker` function returns `true` if at least one value from
   * `target` is found in `arr`, and `false` otherwise.
   */
  const checker = (arr, target) => target.some((r) => arr.includes(r));

  return (
    <>
      {(() => {
        if (cookies.value && cookies.value.length > 0) {
          return (
            <>
              {(() => {
                return (
                  <>
                    {checker(cookies.value, [0]) ? (
                      parseInt(
                        item.accessibility_level_for_motor_disability
                      ) === 1 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--inaccessible`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={MovementDysfunctionInaccessiblePictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:inaccessible_movement_dysfunction_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:inaccessible_movement_dysfunction_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:inaccessible_movement_dysfunction_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:inaccessible_movement_dysfunction_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_motor_disability_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translation[cookies.i18next]
                                      .accessibility_level_for_motor_disability_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_motor_disability
                        ) === 2 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--semi-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={MovementDysfunctionSemiAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:semi_access_movement_dysfunction_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:semi_access_movement_dysfunction_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:semi_access_movement_dysfunction_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:semi_access_movement_dysfunction_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_motor_disability_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_motor_disability_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_motor_disability
                        ) === 3 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--full-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={MovementDysfunctionFullAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:full_access_movement_dysfunction_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:full_access_movement_dysfunction_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:full_access_movement_dysfunction_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:full_access_movement_dysfunction_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_motor_disability_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_motor_disability_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {checker(cookies.value, [2]) ? (
                      parseInt(item.accessibility_level_for_blind) === 1 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--inaccessible`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img src={BlindInaccessiblePictogram} alt="" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:inaccessible_blind_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:inaccessible_blind_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:inaccessible_blind_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:inaccessible_blind_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_blind_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_blind_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(item.accessibility_level_for_blind) === 2 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--semi-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img src={BlindSemiAccessPictogram} alt="" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:semi_access_blind_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:semi_access_blind_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:semi_access_blind_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:semi_access_blind_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_blind_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_blind_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(item.accessibility_level_for_blind) === 3 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--full-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img src={BlindFullAccessPictogram} alt="" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:full_access_blind_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:full_access_blind_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:full_access_blind_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:full_access_blind_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_blind_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_blind_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {checker(cookies.value, [3]) ? (
                      parseInt(
                        item.accessibility_level_for_visually_impaired
                      ) === 1 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--inaccessible`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={VisuallyImpairedInaccessiblePictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:inaccessible_visually_impaired_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:inaccessible_visually_impaired_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:inaccessible_visually_impaired_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:inaccessible_visually_impaired_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_visually_impaired_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_visually_impaired_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_visually_impaired
                        ) === 2 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--semi-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={VisuallyImpairedSemiAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:semi_access_visually_impaired_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:semi_access_visually_impaired_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:semi_access_visually_impaired_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:semi_access_visually_impaired_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_visually_impaired_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_visually_impaired_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_visually_impaired
                        ) === 3 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--full-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={VisuallyImpairedFullAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:full_access_visually_impaired_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:full_access_visually_impaired_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:full_access_visually_impaired_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:full_access_visually_impaired_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_visually_impaired_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_visually_impaired_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {checker(cookies.value, [4]) ? (
                      parseInt(item.accessibility_level_for_hard_of_hearing) ===
                      1 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--inaccessible`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={HearingDysfunctionInaccessiblePictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:inaccessible_hard_of_hearing_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:inaccessible_hard_of_hearing_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:inaccessible_hard_of_hearing_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:inaccessible_hard_of_hearing_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_hard_of_hearing_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_hard_of_hearing_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_hard_of_hearing
                        ) === 2 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--semi-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={HearingDysfunctionSemiAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:semi_access_hard_of_hearing_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:semi_access_hard_of_hearing_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:semi_access_hard_of_hearing_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:semi_access_hard_of_hearing_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_hard_of_hearing_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_hard_of_hearing_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_hard_of_hearing
                        ) === 3 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--full-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={HearingDysfunctionFullAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:full_access_hard_of_hearing_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:full_access_hard_of_hearing_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:full_access_hard_of_hearing_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:full_access_hard_of_hearing_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_hard_of_hearing_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_hard_of_hearing_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {checker(cookies.value, [5]) ? (
                      parseInt(
                        item.accessibility_level_for_high_sensory_sensitivity
                      ) === 1 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--inaccessible`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={SensorySensitivityInaccessiblePictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:inaccessible_high_sensory_sensitivity_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:inaccessible_high_sensory_sensitivity_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:inaccessible_high_sensory_sensitivity_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:inaccessible_high_sensory_sensitivity_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_high_sensory_sensitivity_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_high_sensory_sensitivity_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_high_sensory_sensitivity
                        ) === 2 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--semi-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={SensorySensitivitySemiAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:semi_access_high_sensory_sensitivity_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:semi_access_high_sensory_sensitivity_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:semi_access_high_sensory_sensitivity_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:semi_access_high_sensory_sensitivity_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_high_sensory_sensitivity_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_high_sensory_sensitivity_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_high_sensory_sensitivity
                        ) === 3 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--full-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={SensorySensitivityFullAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:full_access_high_sensory_sensitivity_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:full_access_high_sensory_sensitivity_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:full_access_high_sensory_sensitivity_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:full_access_high_sensory_sensitivity_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_high_sensory_sensitivity_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_high_sensory_sensitivity_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {checker(cookies.value, [6]) ? (
                      parseInt(
                        item.accessibility_level_for_cognitive_difficulties
                      ) === 1 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--inaccessible`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={CognitiveDifficultiesInaccessiblePictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:inaccessible_cognitive_difficulties_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:inaccessible_cognitive_difficulties_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:inaccessible_cognitive_difficulties_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:inaccessible_cognitive_difficulties_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_cognitive_difficulties_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_cognitive_difficulties_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_cognitive_difficulties
                        ) === 2 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--semi-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={CognitiveDifficultiesSemiAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:semi_access_cognitive_difficulties_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:semi_access_cognitive_difficulties_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:semi_access_cognitive_difficulties_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:semi_access_cognitive_difficulties_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_cognitive_difficulties_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_cognitive_difficulties_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : parseInt(
                          item.accessibility_level_for_cognitive_difficulties
                        ) === 3 ? (
                        <div
                          className={`row m-3 c-accessibility-info__container c-accessibility-info__container--full-access`}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
                            <img
                              src={CognitiveDifficultiesFullAccessPictogram}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <p className="c-accessibility-info__header">
                              {type === 1
                                ? category === "building"
                                  ? t(
                                      "building__accessibility_info:full_access_cognitive_difficulties_building_info"
                                    )
                                  : t(
                                      "object__accessibility_info:full_access_cognitive_difficulties_object_info"
                                    )
                                : type === 2
                                ? t(
                                    "building__accessibility_info:full_access_cognitive_difficulties_surrounding_info"
                                  )
                                : t(
                                    "building__accessibility_info:full_access_cognitive_difficulties_region_info"
                                  )}
                            </p>
                            {item.translations[cookies.i18next]
                              .accessibility_level_for_cognitive_difficulties_comment && (
                              <div className="c-accessibility-info__content">
                                {parse(
                                  String(
                                    item.translations[cookies.i18next]
                                      .accessibility_level_for_cognitive_difficulties_comment
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </>
                );
              })()}
            </>
          );
        }
      })()}
    </>
  );
}

export default AccessibilityInfoContainer;
