const ADD_OBJECTS_LIST = "ADD_OBJECTS_LIST";
const RESET_OBJECTS_LIST = "RESET_OBJECTS_LIST";
const ADD_OBJECTS_TO_SORTED_CATEGORY = "ADD_OBJECTS_TO_SORTED_CATEGORY";
const RESET_OBJECTS_SORTED_CATEGORY = "RESET_OBJECTS_SORTED_CATEGORY";

export default {
  ADD_OBJECTS_LIST,
  RESET_OBJECTS_LIST,
  ADD_OBJECTS_TO_SORTED_CATEGORY,
  RESET_OBJECTS_SORTED_CATEGORY,
};
