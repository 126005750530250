import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import AccessibilityInfoContainer from "../AccessibilityInfoContainer/AccessibilityInfoContainer";

import CategoryGallery from "../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../assets/profiles/profile-pictogram-white.svg";

function BuildingSurroundingDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__surrounding:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      <section aria-label={t("building__surrounding:section_description")}>
        <button
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
        >
          {t("building__surrounding:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </button>
        {Object.keys(item.surrounding).length !== 0 ? (
          <>
            {item.surrounding ? (
              <AccessibilityInfoContainer item={item.surrounding} type={2} />
            ) : null}
            <h3 className="object-category-header-name">
              {t("building__surrounding:category_header")}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.surrounding.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.surrounding.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.surrounding.are_parking_spaces ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.surrounding.are_parking_spaces === "True"
                          ? t(
                              "building__surrounding:are_parking_spaces__true",
                              {
                                are_parking_spaces_comment:
                                  item.surrounding.translations[cookies.i18next]
                                    .are_parking_spaces_comment,
                              }
                            )
                          : t(
                              "building__surrounding:are_parking_spaces__false",
                              {
                                are_parking_spaces_comment:
                                  item.surrounding.translations[cookies.i18next]
                                    .are_parking_spaces_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.surrounding.translations[cookies.i18next]
                      .closest_buildings === "" ? null : (
                      <li className="text">
                        {t("building__surrounding:closest_buildings", {
                          closest_buildings:
                            item.surrounding.translations[cookies.i18next]
                              .closest_buildings,
                        })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [0, 2]) ? (
                                item.surrounding.are_no_barriers ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding.are_no_barriers === "True"
                                      ? t(
                                          "building__surrounding:are_no_barriers__true",
                                          {
                                            are_no_barriers_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].are_no_barriers_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:are_no_barriers__false",
                                          {
                                            are_no_barriers_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].are_no_barriers_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.surrounding.are_lowered_curbs ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding.are_lowered_curbs ===
                                    "True"
                                      ? t(
                                          "building__surrounding:are_lowered_curbs__true",
                                          {
                                            are_lowered_curbs_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].are_lowered_curbs_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:are_lowered_curbs__false",
                                          {
                                            are_lowered_curbs_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].are_lowered_curbs_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.surrounding.is_pavement_rough ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding.is_pavement_rough ===
                                    "True"
                                      ? t(
                                          "building__surrounding:is_pavement_rough__true",
                                          {
                                            is_pavement_rough_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].is_pavement_rough_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:is_pavement_rough__false",
                                          {
                                            is_pavement_rough_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].is_pavement_rough_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 1, 2, 3, 4, 5, 6]) ? (
                                item.surrounding.are_parking_spaces_for_pwd ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding
                                      .are_parking_spaces_for_pwd === "True"
                                      ? t(
                                          "building__surrounding:are_parking_spaces_for_pwd__true",
                                          {
                                            are_parking_spaces_for_pwd_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .are_parking_spaces_for_pwd_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:are_parking_spaces_for_pwd__false",
                                          {
                                            are_parking_spaces_for_pwd_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .are_parking_spaces_for_pwd_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.surrounding
                                  .are_high_curbs_at_parking_space_for_pwd ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding
                                      .are_high_curbs_at_parking_space_for_pwd ===
                                    "True"
                                      ? t(
                                          "building__surrounding:are_high_curbs_at_parking_space_for_pwd__true",
                                          {
                                            are_high_curbs_at_parking_space_for_pwd_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .are_high_curbs_at_parking_space_for_pwd_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:are_high_curbs_at_parking_space_for_pwd__false",
                                          {
                                            are_high_curbs_at_parking_space_for_pwd_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .are_high_curbs_at_parking_space_for_pwd_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.surrounding
                                  .is_path_to_accessible_entrance_marked ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding
                                      .is_path_to_accessible_entrance_marked ===
                                    "True"
                                      ? t(
                                          "building__surrounding:is_path_to_accessible_entrance_marked__true",
                                          {
                                            is_path_to_accessible_entrance_marked_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .is_path_to_accessible_entrance_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:is_path_to_accessible_entrance_marked__false",
                                          {
                                            is_path_to_accessible_entrance_marked_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .is_path_to_accessible_entrance_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.surrounding.are_dangerous_elements ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding.are_dangerous_elements ===
                                    "True"
                                      ? t(
                                          "building__surrounding:are_dangerous_elements__true",
                                          {
                                            are_dangerous_elements_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].are_dangerous_elements_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:are_dangerous_elements__false",
                                          {
                                            are_dangerous_elements_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].are_dangerous_elements_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.surrounding.is_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding.is_lit === "True"
                                      ? t(
                                          "building__surrounding:is_lit__true",
                                          {
                                            is_lit_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].is_lit_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:is_lit__false",
                                          {
                                            is_lit_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ].is_lit_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [5]) ? (
                                item.surrounding
                                  .is_noisy_street_near_building ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding
                                      .is_noisy_street_near_building === "True"
                                      ? t(
                                          "building__surrounding:is_noisy_street_near_building__true",
                                          {
                                            is_noisy_street_near_building_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .is_noisy_street_near_building_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:is_noisy_street_near_building__false",
                                          {
                                            is_noisy_street_near_building_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .is_noisy_street_near_building_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.surrounding
                                  .is_sidewalk_separated_from_the_road ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.surrounding
                                      .is_sidewalk_separated_from_the_road ===
                                    "True"
                                      ? t(
                                          "building__surrounding:is_sidewalk_separated_from_the_road__true",
                                          {
                                            is_sidewalk_separated_from_the_road_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .is_sidewalk_separated_from_the_road_comment,
                                          }
                                        )
                                      : t(
                                          "building__surrounding:is_sidewalk_separated_from_the_road__false",
                                          {
                                            is_sidewalk_separated_from_the_road_comment:
                                              item.surrounding.translations[
                                                cookies.i18next
                                              ]
                                                .is_sidewalk_separated_from_the_road_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            {item.surrounding.images && item.surrounding.images.length > 0 ? (
              <CategoryGallery item={item.surrounding.images} />
            ) : null}
          </>
        ) : null}
      </section>
    </>
  );
}

export default BuildingSurroundingDetails;
