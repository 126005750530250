import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

function About() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const headingRef = useRef(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* State variable called `item` and a function
  to update it called `setItem`. The initial value of `item` is an empty array `[]`. This hook is
  used to manage the state of the data fetched from the API and update the component when the state
  changes. */
  const [item, setItem] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/content/?id=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        /* Iterating over the `data` array fetched  from the API and setting the state of `item` to each element of the array. The `async`
        keyword is not necessary here since there is no asynchronous operation being performed
        inside the loop. It could be simplified to `data.forEach((data) => {setItem(data);});`. */
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, []);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("about:title");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      headingRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      {item.translations && (
        <>
          <main id="content">
            <div className="container">
              <h2
                id="js-category-name"
                className="text--header text--category-header text--center"
                tabIndex={-1}
                ref={headingRef}
              >
                {t("about:page_header_about")}
              </h2>
              <div className="l-description-container">
                <div className="l-description-box">
                  {parse(
                    String(
                      item.translations[cookies.i18next].about_page_content
                    )
                  )}
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default About;
