import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import $ from "jquery";

import "./Accordion.css";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightArrow from "../../../assets/right-arrow-forward.svg";
import DownArrow from "../../../assets/down-arrow-forward.svg";

function AccordionPublicTransport({ item, index, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [isActive, setActive] = useState(false);

  const toggleAccordion = () => {
    setActive(!isActive);

    if (!isActive) {
      setTimeout(() => {
        let targetElement;
        if (mode) {
          $("#accordion-public-transport ul > :first-child").attr(
            "id",
            `accordion-first-list-public-transport-element-${index}`
          );
          $("#accordion-public-transport ul > :first-child").attr(
            "tabindex",
            "-1"
          );

          targetElement = document.getElementById(
            `accordion-first-list-public-transport-element-${index}`
          );
        } else {
          $("#accordion-mobile-public-transport ul > :first-child").attr(
            "id",
            `accordion-mobile-first-list-public-transport-element-${index}`
          );
          $("#accordion-mobile-public-transport ul > :first-child").attr(
            "tabindex",
            "-1"
          );

          targetElement = document.getElementById(
            `accordion-mobile-first-list-public-transport-element-${index}`
          );
        }

        targetElement.focus();
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item.translations ? (
        <>
          <div className="c-accordion">
            <div className="c-accordion__title">
              <button
                type="button"
                onClick={toggleAccordion}
                aria-expanded={isActive ? "true" : "false"}
              >
                <span>{t("building__access:label__public_transport")}</span>{" "}
                <img src={isActive ? DownArrow : RightArrow} alt="" />
              </button>
            </div>
            <div
              id={
                mode
                  ? "accordion-public-transport"
                  : "accordion-mobile-public-transport"
              }
              className={
                isActive ? "c-accordion__content--open" : "c-accordion__content"
              }
            >
              <ul>
                {(() => {
                  return (
                    <>
                      {item.translations[cookies.i18next]
                        .nearest_public_transport_stop === "" ? null : (
                        <li className="text">
                          {t("building__access:nearest_public_transport_stop", {
                            nearest_public_transport_stop:
                              item.translations[cookies.i18next]
                                .nearest_public_transport_stop,
                          })}
                        </li>
                      )}
                      {item.nearest_public_transport_stop_distance ? (
                        <li className="text">
                          {t(
                            "building__access:nearest_public_transport_stop_distance",
                            {
                              nearest_public_transport_stop_distance:
                                item.nearest_public_transport_stop_distance,
                              nearest_public_transport_stop_distance_comment:
                                item.translations[cookies.i18next]
                                  .nearest_public_transport_stop_distance_comment,
                            }
                          )}
                        </li>
                      ) : null}
                      {item.daily_tram_bus_lines === "" ? null : (
                        <li className="text">
                          {t("building__access:daily_tram_bus_lines", {
                            daily_tram_bus_lines: item.daily_tram_bus_lines,
                          })}
                        </li>
                      )}
                      {item.translations[cookies.i18next]
                        .alternative_public_transport_stop === "" ? null : (
                        <li className="text">
                          {t(
                            "building__access:alternative_public_transport_stop",
                            {
                              alternative_public_transport_stop:
                                item.translations[cookies.i18next]
                                  .alternative_public_transport_stop,
                            }
                          )}
                        </li>
                      )}
                      {item.alternative_public_transport_stop_distance ? (
                        <li className="text">
                          {t(
                            "building__access:alternative_public_transport_stop_distance",
                            {
                              alternative_public_transport_stop_distance:
                                item.alternative_public_transport_stop_distance,
                              alternative_public_transport_stop_distance_comment:
                                item.translations[cookies.i18next]
                                  .alternative_public_transport_stop_distance_comment,
                            }
                          )}
                        </li>
                      ) : null}
                      {item.alternative_daily_tram_bus_lines_stop ===
                      "" ? null : (
                        <li className="text">
                          {t(
                            "building__access:alternative_daily_tram_bus_lines_stop",
                            {
                              alternative_daily_tram_bus_lines_stop:
                                item.alternative_daily_tram_bus_lines_stop,
                            }
                          )}
                        </li>
                      )}
                    </>
                  );
                })()}
              </ul>
              {(() => {
                if (cookies.value.length > 0) {
                  return (
                    <>
                      <article
                        className="object-category-profile-description"
                        aria-label={t("general:profile_description_label")}
                      >
                        <div className="object-category-profile-sign">
                          <img
                            className="object-category-profile-sign__icon"
                            src={
                              cookies.value.length > 1
                                ? ProfileWhite
                                : getProfilePictogram(cookies.value[0])
                            }
                            alt=""
                          />
                          <span className="object-category-profile-sign__label">
                            {t("general:profile_description_title")}
                          </span>
                        </div>
                        <ul>
                          {(() => {
                            return (
                              <>
                                {checker(cookies.value, [0, 6]) ? (
                                  item.are_pass_traffic_lights_from_stop_to_entry ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_pass_traffic_lights_from_stop_to_entry ===
                                      "True"
                                        ? t(
                                            "building__access:are_pass_traffic_lights_from_stop_to_entry__true",
                                            {
                                              are_pass_traffic_lights_from_stop_to_entry_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_pass_traffic_lights_from_stop_to_entry_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_pass_traffic_lights_from_stop_to_entry__false",
                                            {
                                              are_pass_traffic_lights_from_stop_to_entry_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_pass_traffic_lights_from_stop_to_entry_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0, 6]) ? (
                                  item.are_not_pass_traffic_lights_from_stop_to_entry ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_not_pass_traffic_lights_from_stop_to_entry ===
                                      "True"
                                        ? t(
                                            "building__access:are_not_pass_traffic_lights_from_stop_to_entry__true",
                                            {
                                              are_not_pass_traffic_lights_from_stop_to_entry_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_not_pass_traffic_lights_from_stop_to_entry_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_not_pass_traffic_lights_from_stop_to_entry__false",
                                            {
                                              are_not_pass_traffic_lights_from_stop_to_entry_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_not_pass_traffic_lights_from_stop_to_entry_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0]) ? (
                                  item.are_obstacles_for_wheelchair_user ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_obstacles_for_wheelchair_user ===
                                      "True"
                                        ? t(
                                            "building__access:are_obstacles_for_wheelchair_user__true",
                                            {
                                              are_obstacles_for_wheelchair_user_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_wheelchair_user_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_obstacles_for_wheelchair_user__false",
                                            {
                                              are_obstacles_for_wheelchair_user_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_wheelchair_user_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2]) ? (
                                  item.are_obstacles_for_blind ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_obstacles_for_blind === "True"
                                        ? t(
                                            "building__access:are_obstacles_for_blind__true",
                                            {
                                              are_obstacles_for_blind_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_blind_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_obstacles_for_blind__false",
                                            {
                                              are_obstacles_for_blind_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_blind_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2]) ? (
                                  item.are_facilities_for_blind_from_stop_to_entry ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_facilities_for_blind_from_stop_to_entry ===
                                      "True"
                                        ? t(
                                            "building__access:are_facilities_for_blind_from_stop_to_entry__true",
                                            {
                                              are_facilities_for_blind_from_stop_to_entry_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_facilities_for_blind_from_stop_to_entry_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_facilities_for_blind_from_stop_to_entry__false",
                                            {
                                              are_facilities_for_blind_from_stop_to_entry_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_facilities_for_blind_from_stop_to_entry_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0, 6]) ? (
                                  item.are_pass_traffic_lights_from_stop_to_entry_alt_road ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_pass_traffic_lights_from_stop_to_entry_alt_road ===
                                      "True"
                                        ? t(
                                            "building__access:are_pass_traffic_lights_from_stop_to_entry_alt_road__true",
                                            {
                                              are_pass_traffic_lights_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_pass_traffic_lights_from_stop_to_entry_alt_road_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_pass_traffic_lights_from_stop_to_entry_alt_road__false",
                                            {
                                              are_pass_traffic_lights_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_pass_traffic_lights_from_stop_to_entry_alt_road_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0, 6]) ? (
                                  item.are_not_pass_traffic_lights_from_stop_to_entry_alt_road ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_not_pass_traffic_lights_from_stop_to_entry_alt_road ===
                                      "True"
                                        ? t(
                                            "building__access:are_not_pass_traffic_lights_from_stop_to_entry_alt_road__true",
                                            {
                                              are_not_pass_traffic_lights_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_not_pass_traffic_lights_from_stop_to_entry_alt_road_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_not_pass_traffic_lights_from_stop_to_entry_alt_road__false",
                                            {
                                              are_not_pass_traffic_lights_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_not_pass_traffic_lights_from_stop_to_entry_alt_road_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [0]) ? (
                                  item.are_obstacles_for_wheelchair_user_alt_road ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_obstacles_for_wheelchair_user_alt_road ===
                                      "True"
                                        ? t(
                                            "building__access:are_obstacles_for_wheelchair_user_alt_road__true",
                                            {
                                              are_obstacles_for_wheelchair_user_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_wheelchair_user_alt_road_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_obstacles_for_wheelchair_user_alt_road__false",
                                            {
                                              are_obstacles_for_wheelchair_user_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_wheelchair_user_alt_road_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2]) ? (
                                  item.are_obstacles_for_blind_from_stop_to_entry_alt_road ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_obstacles_for_blind_from_stop_to_entry_alt_road ===
                                      "True"
                                        ? t(
                                            "building__access:are_obstacles_for_blind_from_stop_to_entry_alt_road__true",
                                            {
                                              are_obstacles_for_blind_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_blind_from_stop_to_entry_alt_road_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_obstacles_for_blind_from_stop_to_entry_alt_road__false",
                                            {
                                              are_obstacles_for_blind_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_obstacles_for_blind_from_stop_to_entry_alt_road_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2]) ? (
                                  item.are_facilities_for_blind_from_stop_to_entry_alt_road ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.are_facilities_for_blind_from_stop_to_entry_alt_road ===
                                      "True"
                                        ? t(
                                            "building__access:are_facilities_for_blind_from_stop_to_entry_alt_road__true",
                                            {
                                              are_facilities_for_blind_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_facilities_for_blind_from_stop_to_entry_alt_road_comment,
                                            }
                                          )
                                        : t(
                                            "building__access:are_facilities_for_blind_from_stop_to_entry_alt_road__false",
                                            {
                                              are_facilities_for_blind_from_stop_to_entry_alt_road_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .are_facilities_for_blind_from_stop_to_entry_alt_road_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                              </>
                            );
                          })()}
                        </ul>
                      </article>
                    </>
                  );
                }
              })()}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AccordionPublicTransport;
