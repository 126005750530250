import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import $ from "jquery";

import "./Accordion.css";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightArrow from "../../../assets/right-arrow-forward.svg";
import DownArrow from "../../../assets/down-arrow-forward.svg";

function AccordionPrivateTransport({ item, index, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [isActive, setActive] = useState(false);

  const toggleAccordion = () => {
    setActive(!isActive);

    if (!isActive) {
      setTimeout(() => {
        let targetElement;

        if (mode) {
          $("#accordion-private-transport ul > :first-child").attr(
            "id",
            `accordion-first-list-private-transport-element-${index}`
          );
          $("#accordion-private-transport ul > :first-child").attr(
            "tabindex",
            "-1"
          );

          targetElement = document.getElementById(
            `accordion-first-list-private-transport-element-${index}`
          );
        } else {
          $("#accordion-mobile-private-transport ul > :first-child").attr(
            "id",
            `accordion-mobile-first-list-private-transport-element-${index}`
          );
          $("#accordion-mobile-private-transport ul > :first-child").attr(
            "tabindex",
            "-1"
          );

          targetElement = document.getElementById(
            `accordion-mobile-first-list-private-transport-element-${index}`
          );
        }

        targetElement.focus();
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item.translations ? (
        <>
          <div className="c-accordion">
            <div className="c-accordion__title">
              <button
                type="button"
                onClick={toggleAccordion}
                aria-expanded={isActive ? "true" : "false"}
              >
                <span>{t("building__access:label__private_transport")}</span>{" "}
                <img src={isActive ? DownArrow : RightArrow} alt="" />
              </button>
            </div>
            <div
              id={
                mode
                  ? "accordion-private-transport"
                  : "accordion-mobile-private-transport"
              }
              className={
                isActive ? "c-accordion__content--open" : "c-accordion__content"
              }
            >
              <ul>
                {(() => {
                  return (
                    <>
                      {item.translations[cookies.i18next]
                        .nearest_public_parking_location === "" ? null : (
                        <li className="text">
                          {t(
                            "building__access:nearest_public_parking_location",
                            {
                              nearest_public_parking_location:
                                item.translations[cookies.i18next]
                                  .nearest_public_parking_location,
                            }
                          )}
                        </li>
                      )}
                      {item.nearest_public_parking_location_distance ? (
                        <li className="text">
                          {t(
                            "building__access:nearest_public_parking_location_distance",
                            {
                              nearest_public_parking_location_distance:
                                item.nearest_public_parking_location_distance,
                            }
                          )}
                        </li>
                      ) : null}
                      {item.is_paid_parking === "Unknown" ? null : (
                        <li className="text">
                          {item.is_paid_parking === "True"
                            ? t("building__access:is_paid_parking__true", {
                                is_paid_parking_comment:
                                  item.translations[cookies.i18next]
                                    .is_paid_parking_comment,
                              })
                            : t("building__access:is_paid_parking__false", {
                                is_paid_parking_comment:
                                  item.translations[cookies.i18next]
                                    .is_paid_parking_comment,
                              })}
                        </li>
                      )}
                      {item.translations[cookies.i18next]
                        .nearest_university_parking_location === "" ? null : (
                        <li className="text">
                          {t(
                            "building__access:nearest_university_parking_location",
                            {
                              nearest_university_parking_location:
                                item.translations[cookies.i18next]
                                  .nearest_university_parking_location,
                            }
                          )}
                        </li>
                      )}
                      {item.nearest_university_parking_location_distance ? (
                        <li className="text">
                          {t(
                            "building__access:nearest_university_parking_location_distance",
                            {
                              nearest_university_parking_location_distance:
                                item.nearest_university_parking_location_distance,
                            }
                          )}
                        </li>
                      ) : null}
                      {item.are_bicycle_stands === "Unknown" ? null : (
                        <li className="text">
                          {item.are_bicycle_stands === "True"
                            ? t("building__access:are_bicycle_stands__true", {
                                are_bicycle_stands_comment:
                                  item.translations[cookies.i18next]
                                    .are_bicycle_stands_comment,
                              })
                            : t("building__access:are_bicycle_stands__false", {
                                are_bicycle_stands_comment:
                                  item.translations[cookies.i18next]
                                    .are_bicycle_stands_comment,
                              })}
                        </li>
                      )}
                      {item.is_bicycle_path_lead_to_building ===
                      "Unknown" ? null : (
                        <li className="text">
                          {item.is_bicycle_path_lead_to_building === "True"
                            ? t(
                                "building__access:is_bicycle_path_lead_to_building__true",
                                {
                                  is_bicycle_path_lead_to_building_comment:
                                    item.translations[cookies.i18next]
                                      .is_bicycle_path_lead_to_building_comment,
                                }
                              )
                            : t(
                                "building__access:is_bicycle_path_lead_to_building__false",
                                {
                                  is_bicycle_path_lead_to_building_comment:
                                    item.translations[cookies.i18next]
                                      .is_bicycle_path_lead_to_building_comment,
                                }
                              )}
                        </li>
                      )}
                      {item.is_bicycle_path_lead_to_building ===
                      "False" ? null : (
                        <li className="text">
                          {t("building__access:distance_to_bicycle_path", {
                            distance_to_bicycle_path:
                              item.distance_to_bicycle_path,
                          })}
                        </li>
                      )}
                      {item.is_bicycle_path_lead_clearly_separated ===
                      "Unknown" ? null : (
                        <li className="text">
                          {item.is_bicycle_path_lead_clearly_separated ===
                          "True"
                            ? t(
                                "building__access:is_bicycle_path_lead_clearly_separated__true",
                                {
                                  is_bicycle_path_lead_clearly_separated_comment:
                                    item.translations[cookies.i18next]
                                      .is_bicycle_path_lead_clearly_separated_comment,
                                }
                              )
                            : t(
                                "building__access:is_bicycle_path_lead_clearly_separated__false",
                                {
                                  is_bicycle_path_lead_clearly_separated_comment:
                                    item.translations[cookies.i18next]
                                      .is_bicycle_path_lead_clearly_separated_comment,
                                }
                              )}
                        </li>
                      )}
                      {item.is_city_bike_station === "Unknown" ? null : (
                        <li className="text">
                          {item.is_city_bike_station === "True"
                            ? t("building__access:is_city_bike_station__true", {
                                is_city_bike_station_comment:
                                  item.translations[cookies.i18next]
                                    .is_city_bike_station_comment,
                              })
                            : t(
                                "building__access:is_city_bike_station__false",
                                {
                                  is_city_bike_station_comment:
                                    item.translations[cookies.i18next]
                                      .is_city_bike_station_comment,
                                }
                              )}
                        </li>
                      )}
                      {item.is_city_bike_station === "False" ? null : (
                        <li className="text">
                          {t("building__access:city_bike_station_distance", {
                            city_bike_station_distance:
                              item.city_bike_station_distance,
                          })}
                        </li>
                      )}
                    </>
                  );
                })()}
              </ul>
              {(() => {
                if (cookies.value.length > 0) {
                  return (
                    <>
                      <article
                        className="object-category-profile-description"
                        aria-label={t("general:profile_description_label")}
                      >
                        <div className="object-category-profile-sign">
                          <img
                            className="object-category-profile-sign__icon"
                            src={
                              cookies.value.length > 1
                                ? ProfileWhite
                                : getProfilePictogram(cookies.value[0])
                            }
                            alt=""
                          />
                          <span className="object-category-profile-sign__label">
                            {t("general:profile_description_title")}
                          </span>
                        </div>
                        <ul>
                          {(() => {
                            return (
                              <>
                                {checker(
                                  cookies.value,
                                  [0, 1, 2, 3, 4, 5, 6]
                                ) ? (
                                  item.translations[cookies.i18next]
                                    .nearest_disabled_parking_spaces ===
                                  "" ? null : (
                                    <li className="text">
                                      {t(
                                        "building__access:nearest_disabled_parking_spaces",
                                        {
                                          nearest_disabled_parking_spaces:
                                            item.translations[cookies.i18next]
                                              .nearest_disabled_parking_spaces,
                                        }
                                      )}
                                    </li>
                                  )
                                ) : null}
                                {checker(
                                  cookies.value,
                                  [0, 1, 2, 3, 4, 5, 6]
                                ) ? (
                                  item.nearest_disabled_parking_spaces_distance ? (
                                    <li className="text">
                                      {t(
                                        "building__access:nearest_disabled_parking_spaces_distance",
                                        {
                                          nearest_disabled_parking_spaces_distance:
                                            item.nearest_disabled_parking_spaces_distance,
                                        }
                                      )}
                                    </li>
                                  ) : null
                                ) : null}
                              </>
                            );
                          })()}
                        </ul>
                      </article>
                    </>
                  );
                }
              })()}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AccordionPrivateTransport;
