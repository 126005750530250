import React from "react";
import { useTranslation } from "react-i18next";

import "./Loader.css";

function Loader({ type }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  return (
    <div
      role="alert"
      className="container d-flex flex-column align-items-center"
    >
      <div className="c-loader">
        <div className="c-progress">
          <div className="c-progress__circle"></div>
          <div className="c-progress__circle"></div>
          <div className="c-progress__circle"></div>
        </div>
        <div className="c-loader__content">
          <p>
            {type === "building"
              ? t("general:building_loader_label")
              : t("general:object_loader_label")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Loader;
