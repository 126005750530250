import types from "./types.js";

const INITIAL_OBJECT_DETAILS = {
  match: 0,
  loading: false,
  general: {},
  images: [],
  categories: [],
  subsidebar: [],
};

const objectDetailsReducer = (state = INITIAL_OBJECT_DETAILS, action) => {
  switch (action.type) {
    case types.ADD_OBJECT_MATCH:
      return {
        ...state,
        match: action.item,
      };
    case types.SET_LOADING_STATE:
      return {
        ...state,
        loading: true,
      };
    case types.RESET_LOADING_STATE:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_OBJECT_DETAILS:
      return {
        ...state,
        general: action.item,
      };
    case types.RESET_OBJECT_DETAILS:
      return {
        ...state,
        general: {},
      };
    case types.ADD_OBJECT_IMAGES:
      return {
        ...state,
        images: [...state.images, action.item],
      };
    case types.RESET_OBJECT_IMAGES:
      return {
        ...state,
        images: [],
      };
    case types.ADD_SUBSIDEBAR_ITEM:
      return {
        ...state,
        subsidebar: [...state.subsidebar, action.item],
      };
    case types.RESET_SUBSIDEBAR_ITEM:
      return {
        ...state,
        subsidebar: [],
      };
    case types.ADD_OBJECT_CATEGORY_ITEM:
      return {
        ...state,
        categories: [...state.categories, action.item],
      };
    case types.RESET_OBJECT_CATEGORY_ITEM:
      return {
        ...state,
        categories: [],
      };
    default:
      return state;
  }
};

export default objectDetailsReducer;
