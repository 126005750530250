import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import "./Article.css";

function Article() {
  /* A hook that is used to get the value of the parameter from the URL. */
  const { item } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* Used to redirect the user to the 404 page. */
  const history = useHistory();

  const [article, setArticle] = useState(null);

  const articleHeadingRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Fetching data from the API. */
    fetch(`${process.env.REACT_APP_API_URL}/news/?id=${item}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.forEach((data) => {
            /* Setting the state of the component. */
            setArticle(data);

            /* Setting the title of the page. */
            document.title = t("article:title", {
              article_name: data.translations[cookies.i18next].name,
            });
          });
        } else {
          /* Redirecting the user to the 404 page. */
          history.push("/404");
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      articleHeadingRef.current?.focus();
      articleHeadingRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  const getDayOfWeek = (date) => {
    /* Getting the day of the week. */
    const dayOfWeek = new Date(date).getDay();
    /* Getting the day of the month. */
    const day = new Date(date).getDate();
    /* Getting the month of the date. */
    const month = new Date(date).getMonth();
    /* Getting the year from the date. */
    const year = new Date(date).getFullYear();
    /* Returning the date in the format of "Monday, 1 January 2020". */
    return isNaN(dayOfWeek)
      ? null
      : [
          t("article:sunday"),
          t("article:monday"),
          t("article:tuesday"),
          t("article:wednesday"),
          t("article:thursday"),
          t("article:friday"),
          t("article:saturday"),
        ][dayOfWeek] +
          ", " +
          day +
          " " +
          [
            t("article:january"),
            t("article:february"),
            t("article:march"),
            t("article:april"),
            t("article:may"),
            t("article:june"),
            t("article:july"),
            t("article:august"),
            t("article:september"),
            t("article:october"),
            t("article:november"),
            t("article:december"),
          ][month] +
          " " +
          year;
  };

  return (
    <>
      {article ? (
        <>
          <main id="content">
            <div className="container d-flex justify-content-center">
              <div className="c-article">
                <p className="c-article__date">{getDayOfWeek(article.date)}</p>
                <h2 ref={articleHeadingRef} className="c-article__name">
                  {article.translations[cookies.i18next].name}
                </h2>
                <div className="c-article__content">
                  {parse(String(article.translations[cookies.i18next].content))}
                </div>
                <Link to={t("routes:news")} className="c-article__return-link">
                  {t("article:go_to_news_page_button")}
                </Link>
              </div>
            </div>
          </main>
        </>
      ) : null}
    </>
  );
}

export default Article;
