import types from "./types.js";

const add = (item) => ({
  type: types.ADD_NEWS,
  item,
});

const reset = (item) => ({
  type: types.RESET_NEWS,
  item,
});

export default {
  add,
  reset,
};
