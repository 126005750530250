import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import { connect } from "react-redux";
import { getAllNews } from "./duck/operations.js";

import "./News.css";

import NewsArticleContainer from "../NewsArticleContainer/NewsArticleContainer";
import RadioButtons from "../RadioButtons/RadioButtons";
import Select from "../Select/Select";
import Pagination from "../Pagination/Pagination";

import SearchBtn from "../../assets/search-btn.svg";
import FilterBtn from "../../assets/filter-btn.svg";
import NoData from "../../assets/no-data.svg";

const News = ({ news, getAllNews }) => {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const headingRef = useRef(null);
  const currentPageRef = useRef(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [item, setItem] = useState([]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [category, setCategory] = useState("");
  const [buildings, setBuildings] = useState(new Set());
  const [building, setBuilding] = useState("");

  const [postPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [showSearchResultsCount, setShowSearchResultsCount] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/content/?id=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    fetch(`${process.env.REACT_APP_API_URL}/news/`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setBuildings((oldArray) =>
            Array.from(new Set([...oldArray, data.building_name]))
          );
        });
      });
    //setBuildings((prevArray) => [...prevArray, ""]);

    getAllNews();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      headingRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  const filterResults = () => {
    getAllNews(category, building);
  };

  const resetFilterResults = () => {
    getAllNews("", "");
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
      setIsSearchInputOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("news:title");
  }, []);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);

    setTimeout(() => {
      let jumpLink = document.getElementById("filter-heading");

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  const handleClickSearch = () => {
    const searchItems =
      Array.from(news.list).length > 0
        ? news.list.filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
        : null;
    setSearchResults(searchItems);
    setShowSearchResults(true);
    setShowSearchResultsCount(true);
    setCurrentPage(1);

    let jumpLink = document.getElementById("filter-result-info");

    jumpLink.focus();
    jumpLink.scrollIntoView({ behavior: "smooth" });
  };

  const handleValueChange = (e) => {
    setCategory(e.target.value);
  };

  const [pageItem, setPageItem] = useState({
    start: 0,
    end: postPerPage,
  });

  const onPageChangeEvent = (start, end) => {
    setPageItem({
      start: start,
      end: end,
    });
  };

  const numOfPages = Math.ceil(
    (showSearchResults ? searchResults.length : news.list.length) / postPerPage
  );

  const numOfButtons = [];
  for (let i = 1; i <= numOfPages; i++) {
    numOfButtons.push(i);
  }

  const prevPageClick = () => {
    setTimeout(() => {
      currentPageRef.current?.focus();
      currentPageRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);

    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageClick = () => {
    setTimeout(() => {
      currentPageRef.current?.focus();
      currentPageRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);

    if (currentPage === numOfButtons.length) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    let tempNumberOfButtons = [...arrOfCurrButtons];

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons;
    } else if (currentPage >= 1 && currentPage <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dotsInitial, numOfButtons.length];
    } else if (currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, numOfButtons.length];
    } else if (currentPage > 4 && currentPage < numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numOfButtons.slice(currentPage - 2, currentPage);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numOfButtons.slice(currentPage, currentPage + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numOfButtons.length,
      ];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (currentPage > numOfButtons.length - 3) {
      // > 7
      const sliced = numOfButtons.slice(numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    } else if (currentPage === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      setCurrentPage(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentPage === dotsRight) {
      setCurrentPage(arrOfCurrButtons[3] + 2);
    } else if (currentPage === dotsLeft) {
      setCurrentPage(arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfButtons);
    const value = currentPage * postPerPage;

    onPageChangeEvent(value - postPerPage, value);
  }, [currentPage, postPerPage, numOfPages]);

  const searchResultsList =
    Array.from(news.list).length > 0
      ? news.list
          .filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
          .slice(0, 8)
          .map((item) => {
            return (
              <li key={item.id} className="c-dropdown-search-results__item">
                <Link
                  to={`${t("routes:article", { id: item.id })}`}
                  onClick={() => {
                    setSearchTerm(item.translations[cookies.i18next].name);

                    const targetElement =
                      document.getElementById(`search-input`);
                    targetElement.value =
                      item.translations[cookies.i18next].name;

                    resetFilterResults();
                    setIsSearchInputOpen(false);
                  }}
                  className="c-dropdown-search-results___link"
                >
                  {item.translations[cookies.i18next].name}
                </Link>
              </li>
            );
          })
      : null;

  return (
    <>
      {item.translations && (
        <>
          <main id="content">
            <div className="container">
              <h2
                id="js-category-name"
                className="text--header text--category-header text--center"
                tabIndex={-1}
                ref={headingRef}
              >
                {t("news:page_header_news")}
              </h2>
              <div className="l-description-container">
                <div className="l-description-box">
                  {parse(
                    String(item.translations[cookies.i18next].news_page_content)
                  )}
                  <div className="l-search-container">
                    <form role="search" className="c-search">
                      <label
                        htmlFor="search-input"
                        className="c-search__label"
                        role="heading"
                        aria-level="2"
                      >
                        <span className="sr-only" tabIndex={-1}>
                          {t("news:search_input_placeholder")}
                        </span>
                      </label>
                      <input
                        type="text"
                        id="search-input"
                        className="c-search__input"
                        placeholder={t("news:search_input_placeholder")}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSearchInputOpen(true);
                        }}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setIsSearchInputOpen(true);
                          setShowSearchResultsCount(false);
                        }}
                      />
                      <div className="c-search__btn-container">
                        <button
                          type="button"
                          className="search__btn"
                          aria-expanded={isFilterOpen}
                          onClick={toggleFilter}
                          aria-label={t("general:filter_button")}
                        >
                          <img src={FilterBtn} alt="" />
                        </button>
                        <button
                          type="button"
                          className="search__btn"
                          onClick={handleClickSearch}
                          aria-label={t("general:search_button")}
                        >
                          <img src={SearchBtn} alt="" />
                        </button>
                      </div>
                    </form>
                    <div
                      className={`c-dropdown-search-results ${
                        searchTerm && isSearchInputOpen ? "open" : ""
                      }`}
                      ref={dropdownRef}
                    >
                      <ul>
                        {searchResultsList && searchResultsList.length > 0 ? (
                          searchResultsList
                        ) : (
                          <li className="c-dropdown-search-no-data__item">
                            {t("general:search_dropdown_no_data_result")}
                          </li>
                        )}
                      </ul>
                    </div>
                    {searchTerm && isSearchInputOpen ? (
                      <div
                        aria-live="assertive"
                        aria-atomic="true"
                        className="sr-only"
                      >
                        {t("general:search_input_result_suggestion_label", {
                          search_results_number:
                            searchResultsList && searchResultsList.length > 0
                              ? searchResultsList?.length
                              : 0,
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`l-filter-container ${
                      isFilterOpen ? "open" : ""
                    }`}
                    role="navigation"
                    aria-label={t("news:filter_container_label")}
                  >
                    <div className="d-flex w-100 justify-content-around">
                      <span
                        id="filter-heading"
                        className="sr-only"
                        aria-level="3"
                        tabIndex={0}
                      >
                        {t("news:sr_only_filter_heading")}
                      </span>
                      <div className="d-flex align-items-center">
                        <RadioButtons
                          valueChange={handleValueChange}
                          category={category}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        {buildings.length > 0 && (
                          <>
                            <label
                              className="p-2 c-select-building__label"
                              htmlFor="building-select"
                            >
                              {t("news:filter_buildings_select_label")}
                            </label>
                            <Select
                              buildings={buildings}
                              change={setBuilding}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between c-filter-buttons">
                      <div className="d-flex align-items-center">
                        <button
                          className="btn--reset"
                          onClick={() => {
                            setCategory("");
                            setBuilding("");
                            resetFilterResults();
                          }}
                        >
                          {t("general:filter_reset_button")}
                        </button>
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn--close"
                          onClick={() => {
                            setIsFilterOpen(false);
                          }}
                        >
                          {t("general:filter_close_button")}
                        </button>
                        <button className="btn--filter" onClick={filterResults}>
                          {t("general:filter_button")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    id="filter-result-info"
                    className="c-filter-result-info"
                    aria-live={showSearchResultsCount ? "assertive" : null}
                    aria-atomic={showSearchResultsCount ? "true" : null}
                  >
                    <>
                      {t("general:filter_result_info_part_1")}{" "}
                      <span>
                        {t("general:filter_result_info_part_2", {
                          results:
                            showSearchResults && searchTerm !== ""
                              ? Array.from(searchResults).length
                              : Array.from(news.list).length,
                        })}
                      </span>
                      <span className="sr-only" ref={currentPageRef}>
                        {t("general:page_label", {
                          page: currentPage,
                        })}
                      </span>
                    </>
                  </div>
                </div>
              </div>
              <div className="container c-news-list-container">
                <div className="row justify-content-center">
                  {searchTerm !== "" &&
                  showSearchResults &&
                  Array.from(searchResults).length > 0 ? (
                    searchResults
                      .slice(pageItem.start, pageItem.end)
                      .map((item) => {
                        return (
                          <NewsArticleContainer key={item.id} item={item} />
                        );
                      })
                  ) : (!showSearchResults || searchTerm === "") &&
                    Array.from(news.list).length > 0 ? (
                    news.list
                      .slice(pageItem.start, pageItem.end)
                      .map((item) => {
                        return (
                          <NewsArticleContainer key={item.id} item={item} />
                        );
                      })
                  ) : Array.from(news.list).length === 0 ||
                    (showSearchResults &&
                      Array.from(searchResults).length === 0) ? (
                    <>
                      <div className="no-data-info">
                        <img src={NoData} alt="" />
                        <span>{t("news:no-data-info")}</span>
                        <span>{t("home:no-data-sub-info")}</span>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="row justify-content-center pt-3">
                  {numOfPages > 1 ? (
                    <Pagination
                      prevPageClick={prevPageClick}
                      nextPageClick={nextPageClick}
                      currentPage={currentPage}
                      arrOfCurrButtons={arrOfCurrButtons}
                      numOfButtons={numOfButtons}
                      setCurrentPage={setCurrentPage}
                      ref={currentPageRef}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  news: state.news,
});

const mapDispatchToProps = (dispatch) => ({
  getAllNews: (category, building) => {
    dispatch(getAllNews(category, building));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
