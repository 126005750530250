import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import CategoryGallery from "../CategoryGallery/CategoryGallery";

function ObjectCategoryDetails({ item, id, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  const [categoryDetails, setCategoryDetails] = useState({});

  useEffect(() => {
    /* Setting the title of the page.*/
    setTimeout(() => {
      document.title = t("object_details:title", {
        category_name:
          categoryDetails[0] &&
          categoryDetails[0].translations[cookies.i18next].name,
        object_name: item.general.translations[cookies.i18next].name,
      });
    }, 300);

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(
      `${process.env.REACT_APP_API_URL}/objects_category/?id=${id}&object=${item.match}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCategoryDetails(data);
      });

    return () => {
      controller.abort();
    };
  }, [id, item.match]);

  return (
    <>
      {categoryDetails[0] && (
        <section
          aria-label={categoryDetails[0].translations[cookies.i18next].name}
        >
          <span
            id={mode ? "js-jump-link" : "js-jump-link-mobile"}
            className="sr-only"
            tabIndex={0}
          >
            {t("object_details:startup_message", {
              category_name:
                categoryDetails[0].translations[cookies.i18next].name,
              object_name: item.general.translations[cookies.i18next].name,
            })}
          </span>
          <h3 className="object-category-header-name">
            {categoryDetails[0].translations[cookies.i18next].name}
          </h3>
          {parse(
            String(categoryDetails[0].translations[cookies.i18next].content)
          )}
          {categoryDetails[0].images && categoryDetails[0].images.length > 0 ? (
            <CategoryGallery item={categoryDetails[0].images} />
          ) : null}
        </section>
      )}
    </>
  );
}

export default ObjectCategoryDetails;
