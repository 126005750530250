import types from "./types.js";

const addStudentHouse = (item) => ({
  type: types.ADD_STUDENTS_HOUSES_LIST,
  item,
});

const resetStudentHouse = (item) => ({
  type: types.RESET_STUDENTS_HOUSES_LIST,
  item,
});

const addStudentsHousesToSortedCategory = (item) => ({
  type: types.ADD_STUDENTS_HOUSES_TO_SORTED_CATEGORY,
  item,
});

const resetSortedCategory = (item) => ({
  type: types.RESET_STUDENTS_HOUSES_SORTED_CATEGORY,
  item,
});

export default {
  addStudentHouse,
  resetStudentHouse,
  addStudentsHousesToSortedCategory,
  resetSortedCategory,
};
