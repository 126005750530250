import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import DefaultImage960 from "../../assets/default-image-960w.png";

function ParkingsDisplayContainer({ item }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    item.images?.forEach((data) => {
      fetch(`${process.env.REACT_APP_API_URL}/images/?id=${data}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setImages((images) => [...images, ...data]);
        });
    });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {images.length > 0 ? (
        <>
          <div className="row justify-content-center my-2">
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="c-card c-card--direction">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <img
                    className="c-card__img--direction"
                    sizes="(max-width: 991px) 960px, (min-width: 991px) and (max-width: 1199px) 320px, (min-width: 1199px) 960px"
                    srcSet={`${images[0].image_320w} 320w,
                              ${images[0].image_960w} 960w`}
                    src={images[0] ? images[0].image_960w : DefaultImage960}
                    alt=""
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                  <div className="c-card__body c-card__body--direction">
                    <h3 id={item.id} className="c-card__title">
                      {item.translations[cookies.i18next].name}
                    </h3>
                    <p>
                      <span className="text--w-medium">
                        {t("parkings:address_header")}
                      </span>{" "}
                      {item.translations[cookies.i18next].address}
                    </p>
                    <p>
                      <span className="text--w-medium">
                        {t("parkings:type_header")}
                      </span>{" "}
                      {item.translations[cookies.i18next].type}
                    </p>
                    {item.translations[cookies.i18next].opening_hours && (
                      <>
                        <span className="text--w-medium">
                          {t("parkings:opening_hours_header")}
                        </span>
                        <div className="c-card__opening_hours">
                          {parse(
                            String(
                              item.translations[cookies.i18next].opening_hours
                            )
                          )}
                        </div>
                      </>
                    )}
                    <p>
                      <span className="text--w-medium">
                        {t("parkings:num_of_places_header")}
                      </span>{" "}
                      {item.num_of_places}
                    </p>
                    <p>
                      <span className="text--w-medium">
                        {t("parkings:num_of_places_for_disabled_people_header")}
                      </span>{" "}
                      {item.num_of_places_for_disabled_people}
                    </p>
                    {item.location && (
                      <p>
                        <span className="text--w-medium">
                          {t("parkings:location_header")}
                        </span>{" "}
                        <a
                          href={`${item.location}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("parkings:location_link_title")}{" "}
                          <span className="sr-only">
                            {t("general:sr-only_link_description")}
                          </span>
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ParkingsDisplayContainer;
