import actions from "./actions.js";

const fetchParkings = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/outside_parkings/`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

export const getAllParkings = () => async (dispatch) => {
  const parkings = await fetchParkings();

  dispatch(actions.reset());

  dispatch(actions.add(parkings));
};
