import types from "./types.js";

const addObject = (item) => ({
  type: types.ADD_OBJECTS_LIST,
  item,
});

const resetObject = (item) => ({
  type: types.RESET_OBJECTS_LIST,
  item,
});

const addObjectToSortedCategory = (item) => ({
  type: types.ADD_OBJECTS_TO_SORTED_CATEGORY,
  item,
});

const resetSortedCategory = (item) => ({
  type: types.RESET_OBJECTS_SORTED_CATEGORY,
  item,
});

export default {
  addObject,
  resetObject,
  addObjectToSortedCategory,
  resetSortedCategory,
};
