import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

function BuildingUsefulLinks({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__useful_information:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      <section
        aria-label={t("building__useful_information:section_description")}
      >
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__useful_information:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name">
          {t("building__useful_information:category_header")}
        </h3>
        {item.general.translations[cookies.i18next].useful_information &&
          parse(
            String(
              item.general.translations[cookies.i18next].useful_information
            )
          )}
      </section>
    </>
  );
}

export default BuildingUsefulLinks;
