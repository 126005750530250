import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useParams, useHistory } from "react-router-dom";
import parse from "html-react-parser";

import AccordionTyphlomapAreas from "./Accordion/AccordionTyphlomapAreas";

import Gallery from "../Gallery/Gallery";

function BuildingTyphlomapDetails({ item, mode }) {
  /* Getting the value of the parameter from the URL. */
  const { typhloId } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* Used to redirect the user to the 404 page if the data is not found. */
  const history = useHistory();

  /* Used to set the cookie. */
  const [cookies] = useCookies(["value"]);

  /* Setting the initial state of the component. */
  const [typhlomap, setTyphlomap] = useState(null);
  /* Setting the initial state of the component. */
  const [level, setLevel] = useState(null);
  /* Setting the initial state of the component. */
  const [images, setImages] = useState([]);
  /* Setting the initial state of the component. */
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Fetching data from API. */
    fetch(`${process.env.REACT_APP_API_URL}/typhlomaps/?id=${typhloId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.forEach(async (data) => {
            setTyphlomap(data);

            fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${data.level}`, {
              signal,
              method: "GET",
              credentials: "include",
              headers: {
                Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                "Content-Type": "application/json",
              },
            })
              .then((response) => response.json())
              .then((data) => {
                data.forEach(async (data) => {
                  setLevel(data.translations[cookies.i18next].name);
                });
              });

            setImages([]);

            data.images.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/images/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setImages((images) => [...images, ...data]);
                });
            });

            setAreas([]);
            data.typhlomap_areas.forEach((data) => {
              fetch(
                `${process.env.REACT_APP_API_URL}/typhlomap_areas/?id=${data}`,
                {
                  signal,
                  method: "GET",
                  credentials: "include",
                  headers: {
                    Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setAreas((areas) => [...areas, ...data]);
                });
            });
          });
        } else {
          history.push("/404");
        }
      });

    return () => {
      controller.abort();
    };
  }, [typhloId]);

  useEffect(() => {
    setTimeout(() => {
      /* Setting the title of the page. */
      document.title = t("building__typhlomap:title", {
        typhlomap_name:
          typhlomap && typhlomap.translations[cookies.i18next].name,
        building_name: item && item.general.translations[cookies.i18next].name,
      });

      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, [typhlomap]);

  return (
    <>
      {typhlomap && (
        <section aria-label={`${typhlomap.translations[cookies.i18next].name}`}>
          <span
            id={mode ? "js-jump-link" : "js-jump-link-mobile"}
            className="sr-only"
            tabIndex={0}
          >
            {t("building__typhlomap:startup_message", {
              typhlomap_name: typhlomap.translations[cookies.i18next].name,
              building_name: item.general.translations[cookies.i18next].name,
            })}
          </span>
          <h3 className="object-category-header-name">
            {typhlomap.translations[cookies.i18next].name}
          </h3>
          {images && images.length > 0 ? <Gallery item={images} /> : null}
          <p
            role="heading"
            aria-level="4"
            className="object-category-subheader-name"
          >
            {t("building__typhlomap:level_header")}
          </p>
          <p className="text">{level}</p>
          {typhlomap.translations[cookies.i18next].access_description && (
            <>
              <p
                role="heading"
                aria-level="4"
                className="object-category-subheader-name"
              >
                {t("building__typhlomap:access_description_header")}
              </p>
              <div className="object-category-content">
                {typhlomap &&
                  parse(
                    String(
                      typhlomap.translations[cookies.i18next].access_description
                    )
                  )}
              </div>
            </>
          )}
          {typhlomap.translations[cookies.i18next].extended_description && (
            <>
              <p
                role="heading"
                aria-level="4"
                className="object-category-subheader-name"
              >
                {t("building__typhlomap:extended_description_header")}
              </p>
              <div className="object-category-content">
                {typhlomap &&
                  parse(
                    String(
                      typhlomap.translations[cookies.i18next]
                        .extended_description
                    )
                  )}
              </div>
            </>
          )}
          {areas &&
            areas.length > 0 &&
            areas.map((area, index) => (
              <AccordionTyphlomapAreas
                key={area.id}
                item={area}
                typhlomapAreaIndex={index}
              />
            ))}
        </section>
      )}
    </>
  );
}

export default BuildingTyphlomapDetails;
