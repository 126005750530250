import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import GuideDogPictogram from "../../assets/accessibility-amenities/guide-dog-pictogram.svg";
import MicronavigationSystemPictogram from "../../assets/accessibility-amenities/micronavigation-system-pictogram.svg";
import OrientationPathPictogram from "../../assets/accessibility-amenities/orientation-path-pictogram.svg";
import BraillePictogram from "../../assets/accessibility-amenities/braille-pictogram.svg";
import LargeFontPictogram from "../../assets/accessibility-amenities/large-font-pictogram.svg";

function ObjectAccessibleInfoDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("object__accessibility_amenities:title", {
      object_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      <section
        aria-label={t("object__accessibility_amenities:section_description")}
      >
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("object__accessibility_amenities:startup_message", {
            object_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name">
          {t("object__accessibility_amenities:category_header")}
        </h3>
        <ul className="c-accessible-info-list">
          {item.general.is_possibility_to_enter_with_assistance_dog ===
          "True" ? (
            <li className="c-accessible-info-list__item">
              <span className="c-accessible-info-list__pictogram">
                <img src={GuideDogPictogram} alt="" />
              </span>
              {t(
                "object__accessibility_amenities:is_possibility_to_enter_with_assistance_dog__true"
              )}
            </li>
          ) : null}
          {item.general.is_micronavigation_system === "True" ? (
            <li className="c-accessible-info-list__item">
              <span className="c-accessible-info-list__pictogram">
                <img src={MicronavigationSystemPictogram} alt="" />
              </span>
              {t(
                "object__accessibility_amenities:is_micronavigation_system__true"
              )}
            </li>
          ) : null}
          {item.general.are_guidance_paths === "True" ? (
            <li className="c-accessible-info-list__item">
              <span className="c-accessible-info-list__pictogram">
                <img src={OrientationPathPictogram} alt="" />
              </span>
              {t("object__accessibility_amenities:are_guidance_paths__true")}
            </li>
          ) : null}
          {item.general.are_information_boards_with_braille_description ===
          "True" ? (
            <li className="c-accessible-info-list__item">
              <span className="c-accessible-info-list__pictogram">
                <img src={BraillePictogram} alt="" />
              </span>
              {t(
                "object__accessibility_amenities:are_information_boards_with_braille_description__true"
              )}
            </li>
          ) : null}
          {item.general.are_information_boards_with_large_font === "True" ? (
            <li className="c-accessible-info-list__item">
              <span className="c-accessible-info-list__pictogram">
                <img src={LargeFontPictogram} alt="" />
              </span>
              {t(
                "object__accessibility_amenities:are_information_boards_with_large_font__true"
              )}
            </li>
          ) : null}
        </ul>
      </section>
    </>
  );
}

export default ObjectAccessibleInfoDetails;
