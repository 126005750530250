import types from "./types.js";

const INITIAL_STUDENTS_HOUSES = {
  list: [],
  sorted_students_houses_categories: {},
};

const studentHousesReducer = (state = INITIAL_STUDENTS_HOUSES, action) => {
  switch (action.type) {
    case types.ADD_STUDENTS_HOUSES_LIST:
      return {
        ...state,
        list: action.item,
      };
    case types.RESET_STUDENTS_HOUSES_LIST:
      return {
        ...state,
        list: [],
      };
    case types.ADD_STUDENTS_HOUSES_TO_SORTED_CATEGORY:
      return {
        ...state,
        sorted_students_houses_categories: action.item,
      };
    case types.RESET_STUDENTS_HOUSES_SORTED_CATEGORY:
      return {
        ...state,
        sorted_students_houses_categories: {},
      };
    default:
      return state;
  }
};

export default studentHousesReducer;
