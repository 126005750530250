import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import "./DropdownProfile.css";

import HelpIcon from "../../assets/help-circled-icon.svg";

import Profile from "../../assets/profile-white.svg";

function DropdownProfile() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [hoverTargetElement, setHoverTargetElement] = useState(null);

  const [cookies, setCookie] = useCookies(["value"]);

  const profiles = [
    {
      id: 0,
      name: t("dropdown_profile:profile_1"),
    },
    {
      id: 1,
      name: t("dropdown_profile:profile_2"),
    },
    {
      id: 4,
      name: t("dropdown_profile:profile_3"),
    },
    {
      id: 5,
      name: t("dropdown_profile:profile_4"),
      help_text: t("dropdown_profile:profile_4_help_text"),
    },
    {
      id: 6,
      name: t("dropdown_profile:profile_5"),
      help_text: t("dropdown_profile:profile_5_help_text"),
    },
  ];

  const [subProfiles] = useState([
    { id: 2, name: t("dropdown_profile:profile_2_sub_1") },
    { id: 3, name: t("dropdown_profile:profile_2_sub_2") },
  ]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);

    setTimeout(() => {
      const targetElement = document.getElementById(
        "dropdown-profile-list-item-0"
      );
      targetElement.focus();
    }, 300);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
      setIsOptionsOpen(false);
    }
  };

  const handleListKeyDown = (e) => {
    const active = document.activeElement;

    switch (e.key) {
      case "Escape":
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        if (active.previousSibling) {
          active.previousSibling.focus();
        }
        break;
      case "ArrowDown":
        if (active.nextSibling) {
          active.nextSibling.focus();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (typeof cookies.value === "undefined" && cookies.value == null) {
      setCookie("value", [], { path: "/" });
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleListKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleListKeyDown);
    };
  }, []);

  const profilesChecked = (index) => {
    let isElementIndex = cookies.value.findIndex((x) => x === index);

    if (isElementIndex === -1) {
      if (index === 1) {
        if (cookies.value.includes(2))
          setCookie("value", [...cookies.value, ...[1, 3]], { path: "/" });
        else if (cookies.value.includes(3))
          setCookie("value", [...cookies.value, ...[1, 2]], { path: "/" });
        else
          setCookie("value", [...cookies.value, ...[1, 2, 3]], {
            path: "/",
          });
      } else if (index === 2 && cookies.value.includes(3)) {
        setCookie("value", [...cookies.value, ...[1, 2]], { path: "/" });
      } else if (index === 3 && cookies.value.includes(2)) {
        setCookie("value", [...cookies.value, ...[1, 3]], { path: "/" });
      } else {
        setCookie("value", [...cookies.value, index], { path: "/" });
      }
    } else {
      if (index === 1) {
        cookies.value = cookies.value.filter((data) => {
          return ![1, 2, 3].includes(data);
        });
        setCookie("value", cookies.value, { path: "/" });
      } else if (index === 2) {
        cookies.value = cookies.value.filter((data) => {
          return ![1, 2].includes(data);
        });
        setCookie("value", cookies.value, { path: "/" });
      } else if (index === 3) {
        cookies.value = cookies.value.filter((data) => {
          return ![1, 3].includes(data);
        });
        setCookie("value", cookies.value, { path: "/" });
      } else {
        cookies.value.splice(cookies.value.indexOf(index), 1);
        setCookie("value", cookies.value, { path: "/" });
      }
    }
  };

  return (
    <>
      {cookies.value ? (
        <>
          <div className="l-dropdown-profile-container" ref={dropdownRef}>
            <button
              type="button"
              className="c-dropdown-profile__btn"
              aria-expanded={isOptionsOpen}
              onClick={toggleOptions}
              title={t("dropdown_profile:profile_btn_title")}
              aria-label={t("dropdown_profile:profile_btn_label")}
            >
              <img src={Profile} alt="" />
            </button>
            <div
              className={`c-dropdown-profile ${isOptionsOpen ? "open" : ""}`}
            >
              <p className="c-dropdown-profile__header">
                {t("dropdown_profile:profile_header")}
              </p>
              <form action="">
                <ul className="c-dropdown-profile__list">
                  {profiles.map((option) => (
                    <li
                      id={`dropdown-profile-list-item-` + option.id}
                      key={option.id}
                      className="c-dropdown-profile-list__item"
                      tabIndex={-1}
                    >
                      <label className="c-dropdown-profile__checkbox bounce">
                        <div className="l-dropdown-sub-profile__checkbox">
                          <input
                            onChange={() => profilesChecked(option.id)}
                            checked={
                              cookies.value.findIndex(
                                (x) => x === option.id
                              ) !== -1
                                ? true
                                : false
                            }
                            type="checkbox"
                            aria-label={option.name}
                          />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          {option.name}
                          <div
                            className="c-help-text__container"
                            aria-describedby={`c-help-text-` + option.id}
                          >
                            {option.help_text ? (
                              <img
                                onMouseEnter={(e) => {
                                  setHoverTargetElement(e.target.id.slice(-1));
                                  setIsShown(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverTargetElement(null);
                                  setIsShown(false);
                                }}
                                id={`c-help-text-icon-` + option.id}
                                src={HelpIcon}
                                tabIndex={0}
                                alt={`${t(
                                  "dropdown_profile:profile_tooltip_label"
                                )} ${option.help_text}`}
                              />
                            ) : null}
                            <div
                              role="tooltip"
                              id={`c-help-text-` + option.id}
                              className={`c-help-text ${
                                isShown &&
                                parseInt(hoverTargetElement) === option.id
                                  ? "open"
                                  : ""
                              }`}
                            >
                              {option.help_text}
                            </div>
                          </div>
                        </div>
                        {option.id === 1 ? (
                          <ul className="c-dropdown-sub-profile__list">
                            {subProfiles.map((data) => (
                              <li
                                id={`dropdown-sub-profile-list-item-` + data.id}
                                key={data.id}
                                className="c-dropdown-sub-profile-list__item"
                                tabIndex={-1}
                              >
                                <label className="c-dropdown-sub-profile__checkbox l-dropdown-sub-profile__checkbox bounce">
                                  <input
                                    onChange={() => profilesChecked(data.id)}
                                    checked={
                                      cookies.value.findIndex(
                                        (x) => x === data.id
                                      ) !== -1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    aria-label={data.name}
                                  />
                                  <svg viewBox="0 0 21 21">
                                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                  </svg>
                                  {data.name}
                                </label>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </label>
                    </li>
                  ))}
                </ul>
              </form>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default DropdownProfile;
