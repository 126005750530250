import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";
import RightArrow from "../../../assets/arrow-forward.svg";

function ClickableItemLinkCorridorDetails({ mode }) {
  const { id, levelId, regionId, corridorId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(
          `object-category-header-${corridorId}`
        );
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${corridorId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/corridors/?id=${corridorId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [corridorId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  useEffect(() => {
    /* Setting the title of the page. */
    if (item) {
      document.title = t("building__corridor:single_title", {
        corridor_name: item.translations[cookies.i18next].name,
        building_name: buildingName,
      });
    }
  }, [item]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section aria-label={t("building__corridor:label")}>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                <li>{item.translations[cookies.i18next].name}</li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${corridorId}`
                  : `object-category-mobile-header-${corridorId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {item.translations[cookies.i18next].name}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.is_simple_corridor_layout === "Unknown" ? null : (
                      <li className="text">
                        {item.is_simple_corridor_layout === "True"
                          ? t(
                              "building__corridor:is_simple_corridor_layout__true",
                              {
                                is_simple_corridor_layout_comment:
                                  item.translations[cookies.i18next]
                                    .is_simple_corridor_layout_comment,
                              }
                            )
                          : t(
                              "building__corridor:is_simple_corridor_layout__false",
                              {
                                is_simple_corridor_layout_comment:
                                  item.translations[cookies.i18next]
                                    .is_simple_corridor_layout_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_floor_marked === "Unknown" ? null : (
                      <li className="text">
                        {item.is_floor_marked === "True"
                          ? t("building__corridor:is_floor_marked__true", {
                              is_floor_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_floor_marked_comment,
                            })
                          : t("building__corridor:is_floor_marked__false", {
                              is_floor_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_floor_marked_comment,
                            })}
                      </li>
                    )}
                    {item.are_rooms_entrances === "Unknown" ? null : (
                      <li className="text">
                        {item.are_rooms_entrances === "True"
                          ? t("building__corridor:are_rooms_entrances__true", {
                              are_rooms_entrances_comment:
                                item.translations[cookies.i18next]
                                  .are_rooms_entrances_comment,
                            })
                          : t("building__corridor:are_rooms_entrances__false", {
                              are_rooms_entrances_comment:
                                item.translations[cookies.i18next]
                                  .are_rooms_entrances_comment,
                            })}
                      </li>
                    )}
                    {item.is_information_board === "Unknown" ? null : (
                      <li className="text">
                        {item.is_information_board === "True"
                          ? t("building__corridor:is_information_board__true", {
                              is_information_board_comment:
                                item.translations[cookies.i18next]
                                  .is_information_board_comment,
                            })
                          : t(
                              "building__corridor:is_information_board__false",
                              {
                                is_information_board_comment:
                                  item.translations[cookies.i18next]
                                    .is_information_board_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.are_room_purpose_described_in_en ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.are_room_purpose_described_in_en === "True"
                          ? t(
                              "building__corridor:are_room_purpose_described_in_en__true",
                              {
                                are_room_purpose_described_in_en_comment:
                                  item.translations[cookies.i18next]
                                    .are_room_purpose_described_in_en_comment,
                              }
                            )
                          : t(
                              "building__corridor:are_room_purpose_described_in_en__false",
                              {
                                are_room_purpose_described_in_en_comment:
                                  item.translations[cookies.i18next]
                                    .are_room_purpose_described_in_en_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_consistent_level_color_pattern ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.is_consistent_level_color_pattern === "True"
                          ? t(
                              "building__corridor:is_consistent_level_color_pattern__true",
                              {
                                is_consistent_level_color_pattern_comment:
                                  item.translations[cookies.i18next]
                                    .is_consistent_level_color_pattern_comment,
                              }
                            )
                          : t(
                              "building__corridor:is_consistent_level_color_pattern__false",
                              {
                                is_consistent_level_color_pattern_comment:
                                  item.translations[cookies.i18next]
                                    .is_consistent_level_color_pattern_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.are_pictorial_directional_signs ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.are_pictorial_directional_signs === "True"
                          ? t(
                              "building__corridor:are_pictorial_directional_signs__true",
                              {
                                are_pictorial_directional_signs_comment:
                                  item.translations[cookies.i18next]
                                    .are_pictorial_directional_signs_comment,
                              }
                            )
                          : t(
                              "building__corridor:are_pictorial_directional_signs__false",
                              {
                                are_pictorial_directional_signs_comment:
                                  item.translations[cookies.i18next]
                                    .are_pictorial_directional_signs_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.are_directional_signs_consistent_for_level ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.are_directional_signs_consistent_for_level ===
                        "True"
                          ? t(
                              "building__corridor:are_directional_signs_consistent_for_level__true",
                              {
                                are_directional_signs_consistent_for_level_comment:
                                  item.translations[cookies.i18next]
                                    .are_directional_signs_consistent_for_level_comment,
                              }
                            )
                          : t(
                              "building__corridor:are_directional_signs_consistent_for_level__false",
                              {
                                are_directional_signs_consistent_for_level_comment:
                                  item.translations[cookies.i18next]
                                    .are_directional_signs_consistent_for_level_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.are_seats === "Unknown" ? null : (
                      <li className="text">
                        {item.are_seats === "True"
                          ? t("building__corridor:are_seats__true", {
                              are_seats_comment:
                                item.translations[cookies.i18next]
                                  .are_seats_comment,
                            })
                          : t("building__corridor:are_seats__false")}
                      </li>
                    )}
                    {item.are_vending_machines === "Unknown" ? null : (
                      <li className="text">
                        {item.are_vending_machines === "True"
                          ? t("building__corridor:are_vending_machines__true", {
                              are_vending_machines_comment:
                                item.translations[cookies.i18next]
                                  .are_vending_machines_comment,
                            })
                          : t(
                              "building__corridor:are_vending_machines__false",
                              {
                                are_vending_machines_comment:
                                  item.translations[cookies.i18next]
                                    .are_vending_machines_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.translations[cookies.i18next]
                      .vending_machines_products === "" ? null : (
                      <li className="text">
                        {t("building__corridor:vending_machines_products", {
                          vending_machines_products:
                            item.translations[cookies.i18next]
                              .vending_machines_products,
                        })}
                      </li>
                    )}
                    {item.is_emergency_plan === "Unknown" ? null : (
                      <li className="text">
                        {item.is_emergency_plan === "True"
                          ? t("building__corridor:is_emergency_plan__true", {
                              is_emergency_plan_comment:
                                item.translations[cookies.i18next]
                                  .is_emergency_plan_comment,
                            })
                          : t("building__corridor:is_emergency_plan__false", {
                              is_emergency_plan_comment:
                                item.translations[cookies.i18next]
                                  .is_emergency_plan_comment,
                            })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [2, 3]) ? (
                                item.is_typhlomap === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_typhlomap === "True"
                                      ? t(
                                          "building__corridor:is_typhlomap__true",
                                          {
                                            is_typhlomap_comment:
                                              item.translations[cookies.i18next]
                                                .is_typhlomap_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:is_typhlomap__false",
                                          {
                                            is_typhlomap_comment:
                                              item.translations[cookies.i18next]
                                                .is_typhlomap_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3, 6]) ? (
                                item.are_plates_with_rooms_list_have_readable_font ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_plates_with_rooms_list_have_readable_font ===
                                    "True"
                                      ? t(
                                          "building__corridor:are_plates_with_rooms_list_have_readable_font__true",
                                          {
                                            are_plates_with_rooms_list_have_readable_font_comment:
                                              item.translations[cookies.i18next]
                                                .are_plates_with_rooms_list_have_readable_font_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_plates_with_rooms_list_have_readable_font__false",
                                          {
                                            are_plates_with_rooms_list_have_readable_font_comment:
                                              item.translations[cookies.i18next]
                                                .are_plates_with_rooms_list_have_readable_font_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3, 6]) ? (
                                item.are_rooms_marked === "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_rooms_marked === "True"
                                      ? t(
                                          "building__corridor:are_rooms_marked__true",
                                          {
                                            are_rooms_marked_comment:
                                              item.translations[cookies.i18next]
                                                .are_rooms_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_rooms_marked__false",
                                          {
                                            are_rooms_marked_comment:
                                              item.translations[cookies.i18next]
                                                .are_rooms_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3, 6]) ? (
                                item.are_plates_info_accessible ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_plates_info_accessible === "True"
                                      ? t(
                                          "building__corridor:are_plates_info_accessible__true",
                                          {
                                            are_plates_info_accessible_comment:
                                              item.translations[cookies.i18next]
                                                .are_plates_info_accessible_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_plates_info_accessible__false",
                                          {
                                            are_plates_info_accessible_comment:
                                              item.translations[cookies.i18next]
                                                .are_plates_info_accessible_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3, 6]) ? (
                                item.are_pictograms_used ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_pictograms_used === "True"
                                      ? t(
                                          "building__corridor:are_pictograms_used__true",
                                          {
                                            are_pictograms_used_comment:
                                              item.translations[cookies.i18next]
                                                .are_pictograms_used_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_pictograms_used__false",
                                          {
                                            are_pictograms_used_comment:
                                              item.translations[cookies.i18next]
                                                .are_pictograms_used_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_enough_min_width_length ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_enough_min_width_length === "True"
                                      ? t(
                                          "building__corridor:is_enough_min_width_length__true"
                                        )
                                      : t(
                                          "building__corridor:is_enough_min_width_length__false",
                                          {
                                            is_enough_min_width_length_comment:
                                              item.translations[cookies.i18next]
                                                .is_enough_min_width_length_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3, 6]) ? (
                                item.is_glare === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_glare === "True"
                                      ? t("building__corridor:is_glare__true", {
                                          is_glare_comment:
                                            item.translations[cookies.i18next]
                                              .is_glare_comment,
                                        })
                                      : t(
                                          "building__corridor:is_glare__false",
                                          {
                                            is_glare_comment:
                                              item.translations[cookies.i18next]
                                                .is_glare_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_walls_color_contrast ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_walls_color_contrast ===
                                    "True"
                                      ? t(
                                          "building__corridor:is_good_walls_color_contrast__true",
                                          {
                                            is_good_walls_color_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_walls_color_contrast_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:is_good_walls_color_contrast__false",
                                          {
                                            is_good_walls_color_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_walls_color_contrast_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_lit === "True"
                                      ? t(
                                          "building__corridor:is_good_lit__true",
                                          {
                                            is_good_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_lit_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:is_good_lit__false",
                                          {
                                            is_good_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_lit_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.are_horizontal_markings_for_blind ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_horizontal_markings_for_blind ===
                                    "True"
                                      ? t(
                                          "building__corridor:are_horizontal_markings_for_blind__true",
                                          {
                                            are_horizontal_markings_for_blind_comment:
                                              item.translations[cookies.i18next]
                                                .are_horizontal_markings_for_blind_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_horizontal_markings_for_blind__false",
                                          {
                                            are_horizontal_markings_for_blind_comment:
                                              item.translations[cookies.i18next]
                                                .are_horizontal_markings_for_blind_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.are_horizontal_markings_for_visually_impaired ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_horizontal_markings_for_visually_impaired ===
                                    "True"
                                      ? t(
                                          "building__corridor:are_horizontal_markings_for_visually_impaired__true",
                                          {
                                            are_horizontal_markings_for_visually_impaired_comment:
                                              item.translations[cookies.i18next]
                                                .are_horizontal_markings_for_visually_impaired_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_horizontal_markings_for_visually_impaired__false",
                                          {
                                            are_horizontal_markings_for_visually_impaired_comment:
                                              item.translations[cookies.i18next]
                                                .are_horizontal_markings_for_visually_impaired_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.are_structural_elements_marked ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_structural_elements_marked ===
                                    "True"
                                      ? t(
                                          "building__corridor:are_structural_elements_marked__true",
                                          {
                                            are_structural_elements_marked_comment:
                                              item.translations[cookies.i18next]
                                                .are_structural_elements_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_structural_elements_marked__false",
                                          {
                                            are_structural_elements_marked_comment:
                                              item.translations[cookies.i18next]
                                                .are_structural_elements_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.are_railings === "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_railings === "True"
                                      ? t(
                                          "building__corridor:are_railings__true",
                                          {
                                            are_railings_comment:
                                              item.translations[cookies.i18next]
                                                .are_railings_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_railings__false",
                                          {
                                            are_railings_comment:
                                              item.translations[cookies.i18next]
                                                .are_railings_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_correct_light_switches_sockets_height ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_correct_light_switches_sockets_height ===
                                    "True"
                                      ? t(
                                          "building__corridor:is_correct_light_switches_sockets_height__true",
                                          {
                                            is_correct_light_switches_sockets_height_comment:
                                              item.translations[cookies.i18next]
                                                .is_correct_light_switches_sockets_height_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:is_correct_light_switches_sockets_height__false",
                                          {
                                            is_correct_light_switches_sockets_height_comment:
                                              item.translations[cookies.i18next]
                                                .is_correct_light_switches_sockets_height_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.are_dangerous_elements ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_dangerous_elements === "True"
                                      ? t(
                                          "building__corridor:are_dangerous_elements__true",
                                          {
                                            are_dangerous_elements_comment:
                                              item.translations[cookies.i18next]
                                                .are_dangerous_elements_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_dangerous_elements__false",
                                          {
                                            are_dangerous_elements_comment:
                                              item.translations[cookies.i18next]
                                                .are_dangerous_elements_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [4, 6]) ? (
                                item.is_corridor_causes_echo ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_corridor_causes_echo === "True"
                                      ? t(
                                          "building__corridor:is_corridor_causes_echo__true",
                                          {
                                            is_corridor_causes_echo_comment:
                                              item.translations[cookies.i18next]
                                                .is_corridor_causes_echo_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:is_corridor_causes_echo__false",
                                          {
                                            is_corridor_causes_echo_comment:
                                              item.translations[cookies.i18next]
                                                .is_corridor_causes_echo_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [6]) ? (
                                item.are_any_flashing_devices ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_any_flashing_devices === "True"
                                      ? t(
                                          "building__corridor:are_any_flashing_devices__true",
                                          {
                                            are_any_flashing_devices_comment:
                                              item.translations[cookies.i18next]
                                                .are_any_flashing_devices_comment,
                                          }
                                        )
                                      : t(
                                          "building__corridor:are_any_flashing_devices__false",
                                          {
                                            are_any_flashing_devices_comment:
                                              item.translations[cookies.i18next]
                                                .are_any_flashing_devices_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            <ul className="c-clickable-list">
              {item.doors && item.doors.length > 0
                ? item.doors.map((door) => (
                    <li key={door} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={{
                          pathname: `${t(
                            "routes:building_region_corridor_door_details",
                            {
                              id: id,
                              level_id: levelId,
                              region_id: regionId,
                              corridor_id: corridorId,
                              door_id: door,
                            }
                          )}`,
                        }}
                      >
                        <span>{t("building__door:label")}</span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkCorridorDetails;
