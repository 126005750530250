import actions from "./actions.js";

const fetchNews = async (category, building) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/news/?id=&category=${
      category || ""
    }&building_name=${building || ""}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();

  return json;
};

export const getAllNews = (category, building) => async (dispatch) => {
  const news = await fetchNews(category, building);

  dispatch(actions.reset());

  dispatch(actions.add(news));
};
