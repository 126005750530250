import types from "./types.js";

const addBuildingMatch = (item) => ({
  type: types.ADD_BUILDING_MATCH,
  item,
});

const setLoadingState = () => ({
  type: types.SET_LOADING_STATE,
});

const resetLoadingState = () => ({
  type: types.RESET_LOADING_STATE,
});

const addBuildingDetails = (item) => ({
  type: types.ADD_BUILDING_DETAILS,
  item,
});

const resetBuildingDetails = (item) => ({
  type: types.RESET_BUILDING_DETAILS,
  item,
});

const addSurroundingObjects = (item) => ({
  type: types.ADD_SURROUNDING_OBJECTS,
  item,
});

const resetSurroundingObjects = (item) => ({
  type: types.RESET_SURROUNDING_OBJECTS,
  item,
});

const addBuildingImages = (item) => ({
  type: types.ADD_BUILDING_IMAGES,
  item,
});

const resetBuildingImages = (item) => ({
  type: types.RESET_BUILDING_IMAGES,
  item,
});

const addBuildingSurrounding = (item) => ({
  type: types.ADD_BUILDING_SURROUNDING,
  item,
});

const resetBuildingSurrounding = (item) => ({
  type: types.RESET_BUILDING_SURROUNDING,
  item,
});

const addBuildingLodges = (item) => ({
  type: types.ADD_BUILDING_LODGES,
  item,
});

const resetBuildingLodges = (item) => ({
  type: types.RESET_BUILDING_LODGES,
  item,
});

const addBuildingDressingRooms = (item) => ({
  type: types.ADD_BUILDING_DRESSING_ROOMS,
  item,
});

const resetBuildingDressingRooms = (item) => ({
  type: types.RESET_BUILDING_DRESSING_ROOMS,
  item,
});

const addBuildingInformationPoints = (item) => ({
  type: types.ADD_BUILDING_INFORMATION_POINTS,
  item,
});

const resetBuildingInformationPoints = (item) => ({
  type: types.RESET_BUILDING_INFORMATION_POINTS,
  item,
});

const addBuildingAccess = (item) => ({
  type: types.ADD_BUILDING_ACCESS,
  item,
});

const resetBuildingAccess = (item) => ({
  type: types.RESET_BUILDING_ACCESS,
  item,
});

const addBuildingEntrances = (item) => ({
  type: types.ADD_BUILDING_ENTRANCES,
  item,
});

const resetBuildingEntrances = (item) => ({
  type: types.RESET_BUILDING_ENTRANCES,
  item,
});

const addBuildingLifts = (item) => ({
  type: types.ADD_BUILDING_LIFTS,
  item,
});

const resetBuildingLifts = (item) => ({
  type: types.RESET_BUILDING_LIFTS,
  item,
});

const addBuildingToilets = (item) => ({
  type: types.ADD_BUILDING_TOILETS,
  item,
});

const resetBuildingToilets = (item) => ({
  type: types.RESET_BUILDING_TOILETS,
  item,
});

const addBuildingAdaptedToilets = (item) => ({
  type: types.ADD_BUILDING_ADAPTED_TOILETS,
  item,
});

const resetBuildingAdaptedToilets = (item) => ({
  type: types.RESET_BUILDING_ADAPTED_TOILETS,
  item,
});

const addBuildingTyphlomaps = (item) => ({
  type: types.ADD_BUILDING_TYPHLOMAPS,
  item,
});

const resetBuildingTyphlomaps = (item) => ({
  type: types.RESET_BUILDING_TYPHLOMAPS,
  item,
});

const addBuildingMarkers = (item) => ({
  type: types.ADD_BUILDING_MARKERS,
  item,
});

const resetBuildingMarkers = (item) => ({
  type: types.RESET_BUILDING_MARKERS,
  item,
});

const addBuildingLevels = (item) => ({
  type: types.ADD_BUILDING_LEVELS,
  item,
});

const resetBuildingLevels = (item) => ({
  type: types.RESET_BUILDING_LEVELS,
  item,
});

const addBuildingRooms = (item) => ({
  type: types.ADD_BUILDING_ROOMS,
  item,
});

const resetBuildingRooms = (item) => ({
  type: types.RESET_BUILDING_ROOMS,
  item,
});

const addSubsidebarItem = (item) => ({
  type: types.ADD_SUBSIDEBAR_ITEM,
  item,
});

const resetSubsidebarItem = (item) => ({
  type: types.RESET_SUBSIDEBAR_ITEM,
  item,
});

export default {
  addBuildingMatch,
  setLoadingState,
  resetLoadingState,
  addBuildingDetails,
  resetBuildingDetails,
  addSurroundingObjects,
  resetSurroundingObjects,
  addBuildingImages,
  resetBuildingImages,
  addBuildingSurrounding,
  resetBuildingSurrounding,
  addBuildingAccess,
  resetBuildingAccess,
  addBuildingLodges,
  resetBuildingLodges,
  addBuildingDressingRooms,
  resetBuildingDressingRooms,
  addBuildingInformationPoints,
  resetBuildingInformationPoints,
  addBuildingEntrances,
  resetBuildingEntrances,
  addBuildingLifts,
  resetBuildingLifts,
  addBuildingToilets,
  resetBuildingToilets,
  addBuildingAdaptedToilets,
  resetBuildingAdaptedToilets,
  addBuildingTyphlomaps,
  resetBuildingTyphlomaps,
  addBuildingMarkers,
  resetBuildingMarkers,
  addBuildingLevels,
  resetBuildingLevels,
  addBuildingRooms,
  resetBuildingRooms,
  addSubsidebarItem,
  resetSubsidebarItem,
};
