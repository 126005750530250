import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";

function ClickableItemLinkLiftDetails({ mode }) {
  const { id, levelId, regionId, entranceId, liftId, stairwayId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* The above code is using the useCookies hook to set a cookie. */
  const [cookies] = useCookies(["value"]);

  /* The above code is using the useState hook to create a state variable called item and a function
called setItem. */
  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);
  const [entrance, setEntrance] = useState(null);
  const [stairway, setStairway] = useState(null);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(`object-category-header-${liftId}`);
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${liftId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  /* Fetching data from an API and setting the state of the component. */
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Making a GET request to the API endpoint. */
    fetch(`${process.env.REACT_APP_API_URL}/lifts/?id=${liftId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        /* Iterating over the data array and calling the setItem function for each item in the array. */
        data.forEach(async (data) => {
          /* Setting the data to the local storage. */
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [liftId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/entrances/?id=${entranceId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEntrance(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [entranceId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/stairways/?id=${stairwayId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStairway(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [stairwayId]);

  useEffect(() => {
    /* Setting the title of the page. */
    if (item) {
      document.title = t("building__lift:single_title", {
        lift_type:
          item.translations[cookies.i18next].is_standard_lift === ""
            ? t("building__lift:label")
            : item.translations[cookies.i18next].is_standard_lift,
        building_name: buildingName,
      });
    }
  }, [item]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section
            aria-label={
              item.translations[cookies.i18next].is_standard_lift === ""
                ? t("building__lift:label")
                : item.translations[cookies.i18next].is_standard_lift
            }
          >
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {liftId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_lifts", {
                          id: id,
                        })}`}
                      >
                        {t("building__lift:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {entranceId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_entrances", {
                          id: id,
                        })}`}
                      >
                        {t("building__entrance:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `${t("routes:building_entrance_details", {
                            id: id,
                            entrance_id: entranceId,
                          })}`,
                          state: { category: "entrances" },
                        }}
                      >
                        {entrance?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {(window.location.href.indexOf("ewakuacja") > -1 ||
                  window.location.href.indexOf("evacuation") > -1) && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_evacuation", {
                          id: id,
                        })}`}
                      >
                        {t("building__evacuation:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `${t(
                            "routes:building_evacuation_entrance_details",
                            {
                              id: id,
                              entrance_id: entranceId,
                            }
                          )}`,
                          state: { category: "evacuation" },
                        }}
                      >
                        {entrance?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    {stairwayId && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_stairway_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  stairway_id: stairwayId,
                                }
                              )}`,
                            }}
                          >
                            {stairway?.translations[cookies.i18next]?.name}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                  </>
                )}
                <li>
                  {item.translations[cookies.i18next].is_standard_lift === ""
                    ? t("building__lift:label")
                    : item.translations[cookies.i18next].is_standard_lift}
                </li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${liftId}`
                  : `object-category-mobile-header-${liftId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {item.translations[cookies.i18next].is_standard_lift === ""
                ? t("building__lift:label")
                : item.translations[cookies.i18next].is_standard_lift}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.translations[cookies.i18next].location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__lift:location", {
                          location: item.translations[cookies.i18next].location,
                        })}
                      </li>
                    )}
                    {item.is_marked_from_entrance === "Unknown" ? null : (
                      <li className="text">
                        {item.is_marked_from_entrance === "True"
                          ? t("building__lift:is_marked_from_entrance__true", {
                              is_marked_from_entrance_comment:
                                item.translations[cookies.i18next]
                                  .is_marked_from_entrance_comment,
                            })
                          : t("building__lift:is_marked_from_entrance__false", {
                              is_marked_from_entrance_comment:
                                item.translations[cookies.i18next]
                                  .is_marked_from_entrance_comment,
                            })}
                      </li>
                    )}
                    {item.is_marked_from_entrance_in_en === "Unknown" ? null : (
                      <li className="text">
                        {item.is_marked_from_entrance_in_en === "True"
                          ? t(
                              "building__lift:is_marked_from_entrance_in_en__true",
                              {
                                is_marked_from_entrance_in_en_comment:
                                  item.translations[cookies.i18next]
                                    .is_marked_from_entrance_in_en_comment,
                              }
                            )
                          : t(
                              "building__lift:is_marked_from_entrance_in_en__false",
                              {
                                is_marked_from_entrance_in_en_comment:
                                  item.translations[cookies.i18next]
                                    .is_marked_from_entrance_in_en_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_used_by ? null : (
                      <li className="text">
                        {t("building__lift:is_used_by", {
                          is_used_by: item.is_used_by,
                        })}
                      </li>
                    )}
                    {item.is_signed_in_en === "Unknown" ? null : (
                      <li className="text">
                        {item.is_signed_in_en === "True"
                          ? t("building__lift:is_signed_in_en__true", {
                              is_signed_in_en_comment:
                                item.translations[cookies.i18next]
                                  .is_signed_in_en_comment,
                            })
                          : t("building__lift:is_signed_in_en__false", {
                              is_signed_in_en_comment:
                                item.translations[cookies.i18next]
                                  .is_signed_in_en_comment,
                            })}
                      </li>
                    )}
                    {item.translations[cookies.i18next].floors_list ===
                    "" ? null : (
                      <li className="text">
                        {t("building__lift:floors_list", {
                          floors_list:
                            item.translations[cookies.i18next].floors_list,
                        })}
                      </li>
                    )}
                    {item.is_glazed === "Unknown" ? null : (
                      <li className="text">
                        {item.is_glazed === "True"
                          ? t("building__lift:is_glazed__true", {
                              is_glazed_comment:
                                item.translations[cookies.i18next]
                                  .is_glazed_comment,
                            })
                          : t("building__lift:is_glazed__false", {
                              is_glazed_comment:
                                item.translations[cookies.i18next]
                                  .is_glazed_comment,
                            })}
                      </li>
                    )}
                    {item.translations[cookies.i18next]
                      .maximum_lift_capacity === "" ? null : (
                      <li className="text">
                        {t("building__lift:maximum_lift_capacity", {
                          maximum_lift_capacity:
                            item.translations[cookies.i18next]
                              .maximum_lift_capacity,
                        })}
                      </li>
                    )}
                    {item.is_open_automatically === "Unknown" ? null : (
                      <li className="text">
                        {item.is_open_automatically === "True"
                          ? t("building__lift:is_open_automatically__true", {
                              is_open_automatically_comment:
                                item.translations[cookies.i18next]
                                  .is_open_automatically_comment,
                            })
                          : t("building__lift:is_open_automatically__false", {
                              is_open_automatically_comment:
                                item.translations[cookies.i18next]
                                  .is_open_automatically_comment,
                            })}
                      </li>
                    )}
                    {item.door_type ? null : (
                      <li className="text">
                        {t("building__lift:door_type", {
                          door_type: item.door_type,
                        })}
                      </li>
                    )}
                    {item.is_increased_force_required === "Unknown" ? null : (
                      <li className="text">
                        {item.is_increased_force_required === "True"
                          ? t("building__lift:is_floor_marked__true", {
                              is_floor_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_floor_marked_comment,
                            })
                          : t("building__lift:is_floor_marked__false", {
                              is_floor_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_floor_marked_comment,
                            })}
                      </li>
                    )}
                    {item.is_two_sided === "Unknown" ? null : (
                      <li className="text">
                        {item.is_two_sided === "True"
                          ? t("building__lift:is_two_sided__true", {
                              is_two_sided_comment:
                                item.translations[cookies.i18next]
                                  .is_two_sided_comment,
                            })
                          : t("building__lift:is_two_sided__false", {
                              is_two_sided_comment:
                                item.translations[cookies.i18next]
                                  .is_two_sided_comment,
                            })}
                      </li>
                    )}
                    {item.is_voice_prompts_in_en === "Unknown" ? null : (
                      <li className="text">
                        {item.is_voice_prompts_in_en === "True"
                          ? t("building__lift:is_voice_prompts_in_en__true", {
                              is_voice_prompts_in_en_comment:
                                item.translations[cookies.i18next]
                                  .is_voice_prompts_in_en_comment,
                            })
                          : t("building__lift:is_voice_prompts_in_en__false", {
                              is_voice_prompts_in_en_comment:
                                item.translations[cookies.i18next]
                                  .is_voice_prompts_in_en_comment,
                            })}
                      </li>
                    )}
                    {item.is_control_panel_touchscreen === "Unknown" ? null : (
                      <li className="text">
                        {item.is_control_panel_touchscreen === "True"
                          ? t(
                              "building__lift:is_control_panel_touchscreen__true",
                              {
                                is_control_panel_touchscreen_comment:
                                  item.translations[cookies.i18next]
                                    .is_control_panel_touchscreen_comment,
                              }
                            )
                          : t(
                              "building__lift:is_control_panel_touchscreen__false",
                              {
                                is_control_panel_touchscreen_comment:
                                  item.translations[cookies.i18next]
                                    .is_control_panel_touchscreen_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_alarm_button === "Unknown" ? null : (
                      <li className="text">
                        {item.is_alarm_button === "True"
                          ? t("building__lift:is_alarm_button__true", {
                              is_alarm_button_comment:
                                item.translations[cookies.i18next]
                                  .is_alarm_button_comment,
                            })
                          : t("building__lift:is_alarm_button__false", {
                              is_alarm_button_comment:
                                item.translations[cookies.i18next]
                                  .is_alarm_button_comment,
                            })}
                      </li>
                    )}
                    {item.is_display_showing_floor === "Unknown" ? null : (
                      <li className="text">
                        {item.is_display_showing_floor === "True"
                          ? t("building__lift:is_display_showing_floor__true", {
                              is_display_showing_floor_comment:
                                item.translations[cookies.i18next]
                                  .is_display_showing_floor_comment,
                            })
                          : t(
                              "building__lift:is_display_showing_floor__false",
                              {
                                is_display_showing_floor_comment:
                                  item.translations[cookies.i18next]
                                    .is_display_showing_floor_comment,
                              }
                            )}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [2, 6]) ? (
                                item.is_signed === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_signed === "True"
                                      ? t("building__lift:is_signed__true", {
                                          is_signed_comment:
                                            item.translations[cookies.i18next]
                                              .is_signed_comment,
                                        })
                                      : t("building__lift:is_signed__false", {
                                          is_signed_comment:
                                            item.translations[cookies.i18next]
                                              .is_signed_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [6]) ? (
                                item.is_floor_marked === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_floor_marked === "True"
                                      ? t(
                                          "building__lift:is_floor_marked__true",
                                          {
                                            is_floor_marked_comment:
                                              item.translations[cookies.i18next]
                                                .is_floor_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_floor_marked__false",
                                          {
                                            is_floor_marked_comment:
                                              item.translations[cookies.i18next]
                                                .is_floor_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.door_width ? (
                                  <li className="text">
                                    {t("building__lift:door_width", {
                                      door_width: item.door_width,
                                    })}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.translations[cookies.i18next]
                                  .lift_dimensions === "" ? null : (
                                  <li className="text">
                                    {t("building__lift:lift_dimensions", {
                                      lift_dimensions:
                                        item.translations[cookies.i18next]
                                          .lift_dimensions,
                                    })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_mirror === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_mirror === "True"
                                      ? t("building__lift:is_mirror__true", {
                                          is_mirror_comment:
                                            item.translations[cookies.i18next]
                                              .is_mirror_comment,
                                        })
                                      : t("building__lift:is_mirror__false", {
                                          is_mirror_comment:
                                            item.translations[cookies.i18next]
                                              .is_mirror_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [5]) ? (
                                item.is_display_floor_information ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_display_floor_information ===
                                    "True"
                                      ? t(
                                          "building__lift:is_display_floor_information__true",
                                          {
                                            is_display_floor_information_comment:
                                              item.translations[cookies.i18next]
                                                .is_display_floor_information_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_display_floor_information__false",
                                          {
                                            is_display_floor_information_comment:
                                              item.translations[cookies.i18next]
                                                .is_display_floor_information_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3]) ? (
                                item.is_voice_prompts === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_voice_prompts === "True"
                                      ? t(
                                          "building__lift:is_voice_prompts__true",
                                          {
                                            is_voice_prompts_comment:
                                              item.translations[cookies.i18next]
                                                .is_voice_prompts_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_voice_prompts__false",
                                          {
                                            is_voice_prompts_comment:
                                              item.translations[cookies.i18next]
                                                .is_voice_prompts_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_inside_button_braille ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_inside_button_braille === "True"
                                      ? t(
                                          "building__lift:is_inside_button_braille__true",
                                          {
                                            is_inside_button_braille_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_button_braille_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_inside_button_braille__false",
                                          {
                                            is_inside_button_braille_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_button_braille_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_inside_button_braille_marked ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_inside_button_braille_marked ===
                                    "True"
                                      ? t(
                                          "building__lift:is_inside_button_braille_marked__true",
                                          {
                                            is_inside_button_braille_marked_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_button_braille_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_inside_button_braille_marked__false",
                                          {
                                            is_inside_button_braille_marked_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_button_braille_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3]) ? (
                                item.is_inside_convex_button ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_inside_convex_button === "True"
                                      ? t(
                                          "building__lift:is_inside_convex_button__true",
                                          {
                                            is_inside_convex_button_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_convex_button_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_inside_convex_button__false",
                                          {
                                            is_inside_convex_button_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_convex_button_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_outside_button_braille ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_outside_button_braille === "True"
                                      ? t(
                                          "building__lift:is_outside_button_braille__true",
                                          {
                                            is_outside_button_braille_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_button_braille_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_outside_button_braille__false",
                                          {
                                            is_outside_button_braille_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_button_braille_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_outside_button_braille_marked ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_outside_button_braille_marked ===
                                    "True"
                                      ? t(
                                          "building__lift:is_outside_button_braille_marked__true",
                                          {
                                            is_outside_button_braille_marked_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_button_braille_marked_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_outside_button_braille_marked__false",
                                          {
                                            is_outside_button_braille_marked_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_button_braille_marked_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3]) ? (
                                item.is_outside_convex_button ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_outside_convex_button === "True"
                                      ? t(
                                          "building__lift:is_outside_convex_button__true",
                                          {
                                            is_outside_convex_button_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_convex_button_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_outside_convex_button__false",
                                          {
                                            is_outside_convex_button_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_convex_button_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.inside_control_panel_height ? (
                                  <li className="text">
                                    {t(
                                      "building__lift:inside_control_panel_height",
                                      {
                                        inside_control_panel_height:
                                          item.inside_control_panel_height,
                                      }
                                    )}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.outside_control_panel_height ? (
                                  <li className="text">
                                    {t(
                                      "building__lift:outside_control_panel_height",
                                      {
                                        outside_control_panel_height:
                                          item.outside_control_panel_height,
                                      }
                                    )}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [3, 6]) ? (
                                item.is_inside_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_inside_lit === "True"
                                      ? t(
                                          "building__lift:is_inside_lit__true",
                                          {
                                            is_inside_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_lit_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_inside_lit__false",
                                          {
                                            is_inside_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_lit_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_railing === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_railing === "True"
                                      ? t("building__lift:is_railing__true", {
                                          is_railing_comment:
                                            item.translations[cookies.i18next]
                                              .is_railing_comment,
                                        })
                                      : t("building__lift:is_railing__false", {
                                          is_railing_comment:
                                            item.translations[cookies.i18next]
                                              .is_railing_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3]) ? (
                                item.is_outside_arrival_notification_sound ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_outside_arrival_notification_sound ===
                                    "True"
                                      ? t(
                                          "building__lift:is_outside_arrival_notification_sound__true",
                                          {
                                            is_outside_arrival_notification_sound_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_arrival_notification_sound_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_outside_arrival_notification_sound__false",
                                          {
                                            is_outside_arrival_notification_sound_comment:
                                              item.translations[cookies.i18next]
                                                .is_outside_arrival_notification_sound_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3]) ? (
                                item.is_inside_arrival_notification_sound ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_inside_arrival_notification_sound ===
                                    "True"
                                      ? t(
                                          "building__lift:is_inside_arrival_notification_sound__true",
                                          {
                                            is_inside_arrival_notification_sound_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_arrival_notification_sound_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_inside_arrival_notification_sound__false",
                                          {
                                            is_inside_arrival_notification_sound_comment:
                                              item.translations[cookies.i18next]
                                                .is_inside_arrival_notification_sound_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_door_wall_contrast ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_door_wall_contrast === "True"
                                      ? t(
                                          "building__lift:is_good_door_wall_contrast__true",
                                          {
                                            is_good_door_wall_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_door_wall_contrast_comment,
                                          }
                                        )
                                      : t(
                                          "building__lift:is_good_door_wall_contrast__false",
                                          {
                                            is_good_door_wall_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_door_wall_contrast_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkLiftDetails;
