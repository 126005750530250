import { useTranslation } from "react-i18next";

import "./RadioButtons.css";

function RadioButtons({ valueChange, category }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  return (
    <>
      <div className="c-radio__container" role="radiogroup">
        <div className="c-radio">
          <input
            type="radio"
            id="radio1"
            className="c-radio__item"
            value=""
            checked={category === ""}
            aria-checked={category === ""}
            onChange={valueChange}
          />
          <label htmlFor="radio1" className="c-radio__label">
            {t("news:filter_category_all")}
          </label>
        </div>
        <div className="c-radio">
          <input
            type="radio"
            id="radio2"
            className="c-radio__item"
            value={t("news:filter_category_accessibility_amenities")}
            checked={
              category === t("news:filter_category_accessibility_amenities")
            }
            aria-checked={
              category === t("news:filter_category_accessibility_amenities")
            }
            onChange={valueChange}
          />
          <label htmlFor="radio2" className="c-radio__label">
            {t("news:filter_category_accessibility_amenities_label")}
          </label>
        </div>
        <div className="c-radio">
          <input
            type="radio"
            id="radio3"
            className="c-radio__item"
            value={t("news:filter_category_barriers")}
            checked={category === t("news:filter_category_barriers")}
            aria-checked={category === t("news:filter_category_barriers")}
            onChange={valueChange}
          />
          <label htmlFor="radio3" className="c-radio__label">
            {t("news:filter_category_barriers_label")}
          </label>
        </div>
      </div>
    </>
  );
}
export default RadioButtons;
