import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, withRouter } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingLevelAdaptedToiletsDetails({ item, building_name }) {
  const { id } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const adaptedToiletsTypes = [
    t("building__adapted_toilet:adapted_toilet_type_1"),
    t("building__adapted_toilet:adapted_toilet_type_2"),
    t("building__adapted_toilet:adapted_toilet_type_3"),
  ];

  const [adaptedToilets, setAdaptedToilets] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setTimeout(() => {
      item &&
        item.data.forEach((element) => {
          fetch(
            `${process.env.REACT_APP_API_URL}/adapted_toilets/?id=${element}`,
            {
              signal,

              method: "GET",
              credentials: "include",
              headers: {
                Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              data.forEach((data) => {
                setAdaptedToilets((adaptedToilets) => [
                  ...adaptedToilets,
                  {
                    id: data.id,
                    name: adaptedToiletsTypes[
                      parseInt(data.has_additional_purpose) - 1
                    ],
                  },
                ]);
              });
            });
        });
    }, 300);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {adaptedToilets && adaptedToilets.length > 0 ? (
        <div>
          <p
            role="heading"
            aria-level="4"
            className="object-category-subheader-name"
          >
            {item.name}
          </p>
          <ul className="c-clickable-list">
            {adaptedToilets && adaptedToilets.length > 0
              ? adaptedToilets.map((toilet) => (
                  <li key={toilet.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_adapted_toilet_details",
                          {
                            id: id,
                            adapted_toilet_id: toilet.id,
                          }
                        )}`,
                        state: {
                          category: "adapted_toilets",
                          building_name: building_name,
                        },
                      }}
                    >
                      <span>{toilet.name}</span> <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </div>
      ) : null}
    </>
  );
}

export default withRouter(BuildingLevelAdaptedToiletsDetails);
