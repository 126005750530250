import types from "./types.js";

const INITIAL_BUILDING_DETAILS = {
  match: 0,
  loading: false,
  general: {},
  surrounding_objects: [],
  images: [],
  surrounding: {},
  access: {},
  lodges: {},
  dressing_rooms: {},
  information_points: {},
  entrances: {},
  lifts: {},
  toilets: {},
  adapted_toilets: {},
  typhlomaps: [],
  markers: [],
  levels: [],
  rooms: {},
  subsidebar: [],
};

const buildingDetailsReducer = (state = INITIAL_BUILDING_DETAILS, action) => {
  switch (action.type) {
    case types.ADD_BUILDING_MATCH:
      return {
        ...state,
        match: action.item,
      };
    case types.SET_LOADING_STATE:
      return {
        ...state,
        loading: true,
      };
    case types.RESET_LOADING_STATE:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_BUILDING_DETAILS:
      return {
        ...state,
        general: action.item,
      };
    case types.RESET_BUILDING_DETAILS:
      return {
        ...state,
        general: {},
      };
    case types.ADD_SURROUNDING_OBJECTS:
      return {
        ...state,
        surrounding_objects: [...state.surrounding_objects, action.item],
      };
    case types.RESET_SURROUNDING_OBJECTS:
      return {
        ...state,
        surrounding_objects: [],
      };
    case types.ADD_BUILDING_IMAGES:
      return {
        ...state,
        images: [...state.images, action.item],
      };
    case types.RESET_BUILDING_IMAGES:
      return {
        ...state,
        images: [],
      };
    case types.ADD_BUILDING_SURROUNDING:
      return {
        ...state,
        surrounding: action.item,
      };
    case types.RESET_BUILDING_SURROUNDING:
      return {
        ...state,
        surrounding: {},
      };
    case types.ADD_BUILDING_ACCESS:
      return {
        ...state,
        access: action.item,
      };
    case types.RESET_BUILDING_ACCESS:
      return {
        ...state,
        access: {},
      };
    case types.ADD_BUILDING_LODGES:
      return {
        ...state,
        lodges: action.item,
      };
    case types.RESET_BUILDING_LODGES:
      return {
        ...state,
        lodges: {},
      };
    case types.ADD_BUILDING_DRESSING_ROOMS:
      return {
        ...state,
        dressing_rooms: action.item,
      };
    case types.RESET_BUILDING_DRESSING_ROOMS:
      return {
        ...state,
        dressing_rooms: {},
      };
    case types.ADD_BUILDING_INFORMATION_POINTS:
      return {
        ...state,
        information_points: action.item,
      };
    case types.RESET_BUILDING_INFORMATION_POINTS:
      return {
        ...state,
        information_points: {},
      };
    case types.ADD_BUILDING_ENTRANCES:
      return {
        ...state,
        entrances: action.item,
      };
    case types.RESET_BUILDING_ENTRANCES:
      return {
        ...state,
        entrances: {},
      };
    case types.ADD_BUILDING_LIFTS:
      return {
        ...state,
        lifts: action.item,
      };
    case types.RESET_BUILDING_LIFTS:
      return {
        ...state,
        lifts: {},
      };
    case types.ADD_BUILDING_TOILETS:
      return {
        ...state,
        toilets: action.item,
      };
    case types.RESET_BUILDING_TOILETS:
      return {
        ...state,
        toilets: {},
      };
    case types.ADD_BUILDING_ADAPTED_TOILETS:
      return {
        ...state,
        adapted_toilets: action.item,
      };
    case types.RESET_BUILDING_ADAPTED_TOILETS:
      return {
        ...state,
        adapted_toilets: {},
      };
    case types.ADD_BUILDING_TYPHLOMAPS:
      return {
        ...state,
        typhlomaps: action.item,
      };
    case types.RESET_BUILDING_TYPHLOMAPS:
      return {
        ...state,
        typhlomaps: {},
      };
    case types.ADD_BUILDING_MARKERS:
      return {
        ...state,
        markers: action.item,
      };
    case types.RESET_BUILDING_MARKERS:
      return {
        ...state,
        markers: {},
      };
    case types.ADD_BUILDING_LEVELS:
      return {
        ...state,
        levels: action.item,
      };
    case types.RESET_BUILDING_LEVELS:
      return {
        ...state,
        levels: {},
      };
    case types.ADD_BUILDING_ROOMS:
      return {
        ...state,
        rooms: action.item,
      };
    case types.RESET_BUILDING_ROOMS:
      return {
        ...state,
        rooms: {},
      };
    case types.ADD_SUBSIDEBAR_ITEM:
      return {
        ...state,
        subsidebar: [...state.subsidebar, action.item],
      };
    case types.RESET_SUBSIDEBAR_ITEM:
      return {
        ...state,
        subsidebar: [],
      };
    default:
      return state;
  }
};

export default buildingDetailsReducer;
