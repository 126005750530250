import actions from "./actions.js";

import { Cookies } from "react-cookie";

const fetchBuildings = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/buildings/`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();

  return json;
};

export const getAllBuildings = () => async (dispatch) => {
  const buildings = await fetchBuildings();

  let sorted_buildings = {};
  let buildings_list = [];

  const cookies = new Cookies();

  dispatch(actions.resetSortedCategory());
  dispatch(actions.resetBuilding());

  buildings.sort((a, b) => {
    const nameA = a.translations[cookies.get("i18next")].name.toLowerCase();
    const nameB = b.translations[cookies.get("i18next")].name.toLowerCase();
    return nameA.localeCompare(nameB);
  });

  buildings.forEach((data) => {
    buildings_list.push(data);

    const firstLetter =
      data.translations[cookies.get("i18next")].name.charAt(0);

    if (!sorted_buildings.hasOwnProperty(firstLetter)) {
      sorted_buildings[firstLetter] = [];
    }

    sorted_buildings[firstLetter].push(data);
  });

  dispatch(actions.addBuilding(buildings_list));
  dispatch(actions.addBuildingToSortedCategory(sorted_buildings));
};
