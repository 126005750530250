import React, { useEffect, useRef } from "react";

import BuildingSidebarItems from "./BuildingSidebarItems";

import "./Sidebar.css";

import LeftArrow from "../../assets/left-button-arrow-forward.svg";
import RightArrow from "../../assets/right-button-arrow-forward.svg";

function BuildingSidebar(props, ref) {
  const { buildingDetails, subBuildingSidebarItems, setActive, isActive } =
    props;

  const sidebarRef = useRef(null);

  useEffect(() => {
    const moveFocus = (e) => {
      const active = document.activeElement;

      if (e.keyCode === 39 && active.parentElement.nextSibling) {
        active.parentElement.nextSibling.firstChild.setAttribute(
          "tabindex",
          "0"
        );
        active.parentElement.firstChild.setAttribute("tabindex", "-1");
        active.parentElement.nextSibling.firstChild.focus();
      }

      if (e.keyCode === 37 && active.parentElement.previousSibling) {
        active.parentElement.previousSibling.firstChild.setAttribute(
          "tabindex",
          "0"
        );
        active.parentElement.firstChild.setAttribute("tabindex", "-1");
        active.parentElement.previousSibling.firstChild.focus();
      }
    };

    const node = sidebarRef.current;

    node?.addEventListener("keydown", moveFocus);

    return () => {
      node?.removeEventListener("keydown", moveFocus);
    };
  }, []);

  return (
    <>
      <div className="c-sidebar-menu">
        <ul role="tablist" className="c-sidebar-menu-list" ref={sidebarRef}>
          {subBuildingSidebarItems &&
            subBuildingSidebarItems.map((subBuildingSidebarItem, index) => (
              <BuildingSidebarItems
                key={index}
                name={subBuildingSidebarItem.name}
                iconIndex={index}
                activeIcon={subBuildingSidebarItem.activeIcon}
                icon={subBuildingSidebarItem.icon}
                to={subBuildingSidebarItem.to}
                subBuildingSidebar={subBuildingSidebarItem.subSidebar || []}
                buildingDetails={buildingDetails}
                setActive={setActive}
                isActive={isActive}
                ref={ref}
              />
            ))}
        </ul>
        <button
          type="button"
          className="btn--toggle-sidebar"
          onClick={() => setActive(!isActive)}
          tabIndex={-1}
        >
          <img src={isActive ? LeftArrow : RightArrow} alt="" />
        </button>
      </div>
    </>
  );
}

export default React.forwardRef(BuildingSidebar);
