import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";

// import LanguagesContainer from "../LanguagesContainer/LanguagesContainer";

import "./Nav.css";

import LogoPL from "../../assets/logo/pwr-logo-pl.svg";
import LogoMobilePL from "../../assets/logo/pwr-logo-mobile-pl.svg";
import LogoEN from "../../assets/logo/pwr-logo-en.svg";
import LogoMobileEN from "../../assets/logo/pwr-logo-mobile-en.svg";

import NavbarArrow from "../../assets/navbar/navbar-arrow.svg";

import BuildingsImage from "../../assets/navbar/buildings.svg";
import ObjectsImage from "../../assets/navbar/objects.svg";
import LibrariesImage from "../../assets/navbar/libraries.svg";
import DeansOfficesImage from "../../assets/navbar/deans-offices.svg";
import StudentsHousesImage from "../../assets/navbar/students-houses.svg";
import ParkingsImage from "../../assets/navbar/parkings.svg";
import ServicesImage from "../../assets/navbar/services.svg";

function Nav() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const firstDropdownSubmenuLink = useRef(null);

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* A React hook that allows you to use state in functional components. */
  const [isActive, setActive] = useState(false);

  /* The above code is using the useState hook to create a state variable called isShown and a function
  called setIsShown. */
  const [isShown, setIsShown] = useState(false);

  /* Setting the width of the window to the width of the window. */
  const [width, setWidth] = useState(window.innerWidth);

  /**
   * When the window is resized, update the width variable to the new window width.
   */
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  /**
   * If the dropdownRef.current is true and if it is not, it is setting the isShown to false.
   * @param e - This is the event that is being passed in.
   */
  const handleClickOutside = (e) => {
    /* Checking if the dropdownRef.current is true and if it is not, it is setting the isShown to
      false. */
    if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
      setIsShown(false);
    }
  };

  /**
   * The function `handleListKeyDown` handles keyboard events for a dropdown list, including handling
   * the Escape key to close the dropdown, and the ArrowUp and ArrowDown keys to navigate through the
   * list.
   * @param e - The parameter `e` is an event object that represents the keyboard event. It contains
   * information about the key that was pressed, such as the key code and key name.
   */
  const handleListKeyDown = (e) => {
    const active = document.activeElement;

    switch (e.key) {
      case "Escape":
        setIsShown(false);

        dropdownRef.current?.children[0].focus();
        dropdownRef.current?.children[0].scrollIntoView({
          behavior: "smooth",
        });

        break;
      case "ArrowUp":
        if (e.key === "ArrowUp" && active.parentElement.previousSibling) {
          active.parentElement.previousSibling.firstChild.focus();
        }
        break;
      case "ArrowDown":
        if (e.key === "ArrowDown" && active.parentElement.nextSibling) {
          active.parentElement.nextSibling.firstChild.focus();
        }
        break;
      default:
        break;
    }
  };

  /* The above code is using the useEffect hook in React to add event listeners for window resize,
  document mousedown, and document keydown events. These event listeners are added when the
  component is mounted. */
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleListKeyDown);

    /* Removing the event listeners. */
    return () => {
      window.removeEventListener("resize", updateDimensions);

      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleListKeyDown);
    };
  }, []);

  /**
   * When the user clicks on the button, the dropdown menu will toggle between being shown and hidden.
   */
  const toggleDropdownMenu = () => {
    setIsShown(!isShown);

    if (!isShown) {
      setTimeout(() => {
        firstDropdownSubmenuLink.current?.focus();
      }, 300);
    }
  };

  /**
   * When the menu button is clicked, toggle the menu, the main content, and the footer.
   */
  const toggleMenu = () => {
    setActive(!isActive);

    $(".l-language-controls-nav").toggle();
    $("main").toggle();
    $("#footer").toggle();
  };

  return (
    <>
      <header>
        <div className="container d-flex flex-row justify-content-end">
          {/* <LanguagesContainer /> */}
        </div>
        <div></div>
        <nav
          className={
            isShown ? "navbar navbar-expand-lg show" : "navbar navbar-expand-lg"
          }
        >
          <div className="container">
            <figure>
              <h1>
                <Link
                  to={`/${cookies.i18next}`}
                  aria-label={t("nav:nav_logo_label")}
                  className="navbar-brand"
                  tabIndex="1"
                >
                  <img
                    src={
                      cookies.i18next === "pl"
                        ? width > 991
                          ? LogoPL
                          : LogoMobilePL
                        : width > 991
                        ? LogoEN
                        : LogoMobileEN
                    }
                    alt={t("nav:nav_logo_alt")}
                  />
                </Link>
              </h1>
            </figure>
            <button
              className={isActive ? "mobile-menu-btn open" : "mobile-menu-btn"}
              tabIndex="0"
              type="button"
              aria-label={
                isActive
                  ? t("nav:mobile_menu_close_label")
                  : t("nav:mobile_menu_open_label")
              }
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div
              className={
                isActive
                  ? "mobile-menu-container open"
                  : "mobile-menu-container"
              }
            >
              <ul className="mobile-menu-container__list" onClick={toggleMenu}>
                <li className="mobile-menu-container-list__item">
                  <NavLink
                    className="mobile-menu-container-list-item__link"
                    to="#"
                  >
                    {t("nav:nav_home")}
                  </NavLink>
                  <ul className="mobile-menu-container-dropdown-submenu-list">
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:buildings")}
                      >
                        {t("nav:nav_buildings")}
                      </NavLink>
                    </li>
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:objects")}
                      >
                        {t("nav:nav_objects")}
                      </NavLink>
                    </li>
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:parkings")}
                      >
                        {t("nav:nav_parkings")}
                      </NavLink>
                    </li>
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:libraries")}
                      >
                        {t("nav:nav_libraries")}
                      </NavLink>
                    </li>
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:deans_offices")}
                      >
                        {t("nav:nav_deans_offices")}
                      </NavLink>
                    </li>
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:students_houses")}
                      >
                        {t("nav:nav_students_houses")}
                      </NavLink>
                    </li>
                    <li className="mobile-menu-container-dropdown-submenu__item">
                      <NavLink
                        className="mobile-menu-container-dropdown-submenu__link"
                        to={t("routes:services")}
                      >
                        {t("nav:nav_services")}
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="mobile-menu-container-list__item">
                  <NavLink
                    className="mobile-menu-container-list-item__link"
                    to={t("routes:news")}
                  >
                    {t("nav:nav_news")}
                  </NavLink>
                </li>
                <li className="mobile-menu-container-list__item">
                  <Link
                    className="mobile-menu-container-list-item__link"
                    to={{
                      pathname:
                        "https://experience.arcgis.com/experience/a709b3a0bd9342f2bdf7423382e6e7ba/",
                    }}
                    target="_blank"
                  >
                    {t("nav:nav_campus_map")}{" "}
                    <span className="sr-only">
                      {t("general:sr-only_link_description")}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="collapse navbar-collapse menu-container">
              <ul role="menubar" className="menu-container__list">
                <li
                  role="none"
                  className="menu-container-list__item"
                  ref={dropdownRef}
                >
                  <button
                    role="menuitem"
                    className="menu-container-list-item__link"
                    id="navbar-dropdown"
                    aria-expanded={isShown ? "true" : "false"}
                    aria-haspopup="true"
                    aria-controls="menu-container-dropdown-submenu-list"
                    onClick={toggleDropdownMenu}
                  >
                    {t("nav:nav_home")}
                    <span>
                      <img src={NavbarArrow} alt="" />
                    </span>
                  </button>
                  <div
                    className={
                      isShown
                        ? "menu-container-dropdown-submenu show"
                        : "menu-container-dropdown-submenu"
                    }
                    aria-labelledby="navbar-dropdown"
                  >
                    <ul
                      role="menubar"
                      id="menu-container-dropdown-submenu-list"
                      onClick={toggleDropdownMenu}
                    >
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:buildings")}
                          className="menu-container-dropdown-submenu__link"
                          ref={firstDropdownSubmenuLink}
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={BuildingsImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_buildings")}</div>
                            <div>{t("nav:nav_buildings_description")}</div>
                          </div>
                        </Link>
                      </li>
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:objects")}
                          className="menu-container-dropdown-submenu__link"
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={ObjectsImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_objects")}</div>
                            <div>{t("nav:nav_objects_description")}</div>
                          </div>
                        </Link>
                      </li>
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:parkings")}
                          className="menu-container-dropdown-submenu__link"
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={ParkingsImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_parkings")}</div>
                            <div>{t("nav:nav_parkings_description")}</div>
                          </div>
                        </Link>
                      </li>
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:libraries")}
                          className="menu-container-dropdown-submenu__link"
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={LibrariesImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_libraries")}</div>
                            <div>{t("nav:nav_libraries_description")}</div>
                          </div>
                        </Link>
                      </li>
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:deans_offices")}
                          className="menu-container-dropdown-submenu__link"
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={DeansOfficesImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_deans_offices")}</div>
                            <div>{t("nav:nav_deans_offices_description")}</div>
                          </div>
                        </Link>
                      </li>
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:students_houses")}
                          className="menu-container-dropdown-submenu__link"
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={StudentsHousesImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_students_houses")}</div>
                            <div>
                              {t("nav:nav_students_houses_description")}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li role="none">
                        <Link
                          role="menuitem"
                          to={t("routes:services")}
                          className="menu-container-dropdown-submenu__link"
                        >
                          <span className="menu-container-dropdown-submenu-link__pictogram">
                            <img src={ServicesImage} alt="" />
                          </span>
                          <div className="menu-container-dropdown-submenu-link__content">
                            <div>{t("nav:nav_services")}</div>
                            <div>{t("nav:nav_services_description")}</div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li role="none" className="menu-container-list__item">
                  <NavLink
                    role="menuitem"
                    to={t("routes:news")}
                    className="menu-container-list-item__link"
                  >
                    {t("nav:nav_news")}
                  </NavLink>
                </li>
                <li role="none" className="menu-container-list__item">
                  <Link
                    role="menuitem"
                    to={{
                      pathname:
                        "https://experience.arcgis.com/experience/a709b3a0bd9342f2bdf7423382e6e7ba/",
                    }}
                    target="_blank"
                    className="menu-container-list-item__link"
                  >
                    {t("nav:nav_campus_map")}{" "}
                    <span className="sr-only">
                      {t("general:sr-only_link_description")}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Nav;
