import React, { useEffect } from "react";

import ObjectSidebarItems from "./ObjectSidebarItems";

import "../BuildingSidebar/Sidebar.css";

import LeftArrow from "../../assets/left-button-arrow-forward.svg";
import RightArrow from "../../assets/right-button-arrow-forward.svg";

function ObjectSidebar(props, ref) {
  const { objectDetails, subObjectSidebarItems, setActive, isActive } = props;

  useEffect(() => {
    const moveFocus = (e) => {
      const active = document.activeElement;

      if (
        (e.keyCode === 40 && active.parentElement.nextSibling) ||
        (e.keyCode === 39 && active.parentElement.nextSibling)
      ) {
        active.parentElement.nextSibling.firstChild.setAttribute(
          "tabindex",
          "0"
        );
        active.parentElement.firstChild.setAttribute("tabindex", "-1");
        active.parentElement.nextSibling.firstChild.focus();
      }

      if (
        (e.keyCode === 38 && active.parentElement.previousSibling) ||
        (e.keyCode === 37 && active.parentElement.previousSibling)
      ) {
        active.parentElement.previousSibling.firstChild.setAttribute(
          "tabindex",
          "0"
        );
        active.parentElement.firstChild.setAttribute("tabindex", "-1");
        active.parentElement.previousSibling.firstChild.focus();
      }
    };
    const node = ref.current;

    node.addEventListener("keydown", moveFocus);

    return () => {
      node.removeEventListener("keydown", moveFocus);
    };
  }, []);

  return (
    <>
      <div className="c-sidebar-menu">
        <ul
          role="tablist"
          className="c-sidebar-menu-list"
          ref={ref}
          tabIndex={-1}
        >
          {subObjectSidebarItems &&
            subObjectSidebarItems.map((subObjectSidebarItem, index) => (
              <ObjectSidebarItems
                key={index}
                name={subObjectSidebarItem.name}
                iconIndex={index}
                activeIcon={subObjectSidebarItem.activeIcon}
                icon={subObjectSidebarItem.icon}
                to={subObjectSidebarItem.to}
                subObjectSidebar={subObjectSidebarItem.subSidebar || []}
                objectDetails={objectDetails}
                setActive={setActive}
                isActive={isActive}
              />
            ))}
        </ul>
        <button
          type="button"
          className="btn--toggle-sidebar"
          onClick={() => setActive(!isActive)}
          tabIndex={-1}
        >
          <img src={isActive ? LeftArrow : RightArrow} alt="" />
        </button>
      </div>
    </>
  );
}

export default React.forwardRef(ObjectSidebar);
