import { useState, useEffect } from "react";

import "./ImageContainer.css";

function ImageContainer({ item }) {
  const [image, setImage] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setImage({});
    fetch(`${process.env.REACT_APP_API_URL}/images/?id=${item}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((data) => {
          setImage(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [item]);

  return (
    <>
      {image ? (
        <div className="c-img-container">
          <img
            className="c-img"
            sizes="(max-width: 991px) 320px, (min-width: 991px) and (max-width: 1199px) 960px, (min-width: 1199px) 960px"
            srcSet={`${image.image_320w} 320w, ${image.image_960w} 960w`}
            src={image.image_960w}
            alt=""
          />
        </div>
      ) : null}
    </>
  );
}

export default ImageContainer;
