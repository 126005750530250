import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";
import RightArrow from "../../../assets/arrow-forward.svg";

function ClickableItemLinkEntranceDetails({ mode }) {
  const { id, entranceId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [liftName, setLiftName] = useState(null);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(
          `object-category-header-${entranceId}`
        );
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${entranceId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/entrances/?id=${entranceId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [entranceId]);

  useEffect(() => {
    /* Setting the title of the page. */
    if (item) {
      document.title = t("building__entrance:single_title", {
        entrance_name: item.translations[cookies.i18next].name,
        building_name: buildingName,
      });
    }
  }, [item]);

  const getLiftName = (liftId) => {
    fetch(`${process.env.REACT_APP_API_URL}/lifts/?id=${liftId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((element) => {
          setLiftName(
            element.translations[cookies.i18next].is_standard_lift === ""
              ? t("building__lift:label")
              : element.translations[cookies.i18next].is_standard_lift
          );
        });
      });

    return liftName;
  };

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section aria-label={item.translations[cookies.i18next].name}>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {entranceId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_entrances", {
                          id: id,
                        })}`}
                      >
                        {t("building__entrance:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {(window.location.href.indexOf("ewakuacja") > -1 ||
                  window.location.href.indexOf("evacuation") > -1) && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_evacuation", {
                          id: id,
                        })}`}
                      >
                        {t("building__evacuation:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                <li>{item.translations[cookies.i18next].name}</li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${entranceId}`
                  : `object-category-mobile-header-${entranceId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {item.translations[cookies.i18next].name}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.is_main === "Unknown" ? null : (
                      <li className="text">
                        {item.is_main === "True"
                          ? t("building__entrance:is_main__true", {
                              is_main_comment:
                                item.translations[cookies.i18next]
                                  .is_main_comment,
                            })
                          : t("building__entrance:is_main__false", {
                              is_main_comment:
                                item.translations[cookies.i18next]
                                  .is_main_comment,
                            })}
                      </li>
                    )}
                    {item.is_accessible === "Unknown" ? null : (
                      <li className="text">
                        {item.is_main === "True"
                          ? t("building__entrance:is_accessible__true")
                          : t("building__entrance:is_accessible__false")}
                      </li>
                    )}
                    {item.is_for_personel === "Unknown" ? null : (
                      <li className="text">
                        {item.is_for_personel === "True"
                          ? t("building__entrance:is_for_personel__true", {
                              is_for_personel_comment:
                                item.translations[cookies.i18next]
                                  .is_for_personel_comment,
                            })
                          : t("building__entrance:is_for_personel__false", {
                              is_for_personel_comment:
                                item.translations[cookies.i18next]
                                  .is_for_personel_comment,
                            })}
                      </li>
                    )}
                    {item.translations[cookies.i18next].location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__entrance:location", {
                          location: item.translations[cookies.i18next].location,
                        })}
                      </li>
                    )}
                    {item.is_emergency_exit === "Unknown" ? null : (
                      <li className="text">
                        {item.is_emergency_exit === "True"
                          ? t("building__entrance:is_emergency_exit__true", {
                              is_emergency_exit_comment:
                                item.translations[cookies.i18next]
                                  .is_emergency_exit_comment,
                            })
                          : t("building__entrance:is_emergency_exit__false", {
                              is_emergency_exit_comment:
                                item.translations[cookies.i18next]
                                  .is_emergency_exit_comment,
                            })}
                      </li>
                    )}
                    {item.is_building_marked_in_en === "Unknown" ? null : (
                      <li className="text">
                        {item.is_building_marked_in_en === "True"
                          ? t(
                              "building__entrance:is_building_marked_in_en__true",
                              {
                                is_building_marked_in_en_comment:
                                  item.translations[cookies.i18next]
                                    .is_building_marked_in_en_comment,
                              }
                            )
                          : t(
                              "building__entrance:is_building_marked_in_en__false",
                              {
                                is_building_marked_in_en_comment:
                                  item.translations[cookies.i18next]
                                    .is_building_marked_in_en_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_protection_from_weather === "Unknown" ? null : (
                      <li className="text">
                        {item.is_protection_from_weather === "True"
                          ? t(
                              "building__entrance:is_protection_from_weather__true",
                              {
                                is_protection_from_weather_comment:
                                  item.translations[cookies.i18next]
                                    .is_protection_from_weather_comment,
                              }
                            )
                          : t(
                              "building__entrance:is_protection_from_weather__false",
                              {
                                is_protection_from_weather_comment:
                                  item.translations[cookies.i18next]
                                    .is_protection_from_weather_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.are_benches === "Unknown" ? null : (
                      <li className="text">
                        {item.are_benches === "True"
                          ? t("building__entrance:are_benches__true", {
                              are_benches_comment:
                                item.translations[cookies.i18next]
                                  .are_benches_comment,
                            })
                          : t("building__entrance:are_benches__false", {
                              are_benches_comment:
                                item.translations[cookies.i18next]
                                  .are_benches_comment,
                            })}
                      </li>
                    )}
                    {item.number_of_doors ? (
                      <li className="text">
                        {t("building__entrance:number_of_doors", {
                          number_of_doors: item.number_of_doors,
                        })}
                      </li>
                    ) : null}
                    {item.doors_distance === "" ? null : (
                      <li className="text">
                        {t("building__entrance:doors_distance", {
                          doors_distance: item.doors_distance,
                        })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [3, 6]) ? (
                                item.is_building_marked_from_entrance ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_building_marked_from_entrance ===
                                    "True"
                                      ? t(
                                          "building__entrance:is_building_marked_from_entrance__true",
                                          {
                                            is_building_marked_from_entrance_comment:
                                              item.translations[cookies.i18next]
                                                .is_building_marked_from_entrance_comment,
                                          }
                                        )
                                      : t(
                                          "building__entrance:is_building_marked_from_entrance__false",
                                          {
                                            is_building_marked_from_entrance_comment:
                                              item.translations[cookies.i18next]
                                                .is_building_marked_from_entrance_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_lit === "True"
                                      ? t("building__entrance:is_lit__true", {
                                          is_lit_comment:
                                            item.translations[cookies.i18next]
                                              .is_lit_comment,
                                        })
                                      : t("building__entrance:is_lit__false", {
                                          is_lit_comment:
                                            item.translations[cookies.i18next]
                                              .is_lit_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_solid_surface === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_solid_surface === "True"
                                      ? t(
                                          "building__entrance:is_solid_surface__true",
                                          {
                                            is_solid_surface_comment:
                                              item.translations[cookies.i18next]
                                                .is_solid_surface_comment,
                                          }
                                        )
                                      : t(
                                          "building__entrance:is_solid_surface__false",
                                          {
                                            is_solid_surface_comment:
                                              item.translations[cookies.i18next]
                                                .is_solid_surface_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.are_different_types_of_surface ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_different_types_of_surface ===
                                    "True"
                                      ? t(
                                          "building__entrance:are_different_types_of_surface__true",
                                          {
                                            are_different_types_of_surface_comment:
                                              item.translations[cookies.i18next]
                                                .are_different_types_of_surface_comment,
                                          }
                                        )
                                      : t(
                                          "building__entrance:are_different_types_of_surface__false",
                                          {
                                            are_different_types_of_surface_comment:
                                              item.translations[cookies.i18next]
                                                .are_different_types_of_surface_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.translations[cookies.i18next]
                                  .entrance_threats === "" ? null : (
                                  <li className="text">
                                    {t("building__entrance:entrance_threats", {
                                      entrance_threats:
                                        item.translations[cookies.i18next]
                                          .entrance_threats,
                                    })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [1, 2]) ? (
                                item.has_sound_transmitter ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.has_sound_transmitter === "True"
                                      ? t(
                                          "building__entrance:has_sound_transmitter__true",
                                          {
                                            has_sound_transmitter_comment:
                                              item.translations[cookies.i18next]
                                                .has_sound_transmitter_comment,
                                          }
                                        )
                                      : t(
                                          "building__entrance:has_sound_transmitter__false",
                                          {
                                            has_sound_transmitter_comment:
                                              item.translations[cookies.i18next]
                                                .has_sound_transmitter_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [1, 2]) ? (
                                item.has_tactile_paving === "Unknown" ? null : (
                                  <li className="text">
                                    {item.has_tactile_paving === "True"
                                      ? t(
                                          "building__entrance:has_tactile_paving__true",
                                          {
                                            has_tactile_paving_comment:
                                              item.translations[cookies.i18next]
                                                .has_tactile_paving_comment,
                                          }
                                        )
                                      : t(
                                          "building__entrance:has_tactile_paving__false",
                                          {
                                            has_tactile_paving_comment:
                                              item.translations[cookies.i18next]
                                                .has_tactile_paving_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            <ul className="c-clickable-list">
              {item.stairs && item.stairs.length > 0
                ? item.stairs.map((stairs) => (
                    <li key={stairs} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={
                          entranceId
                            ? {
                                pathname: `${t(
                                  "routes:building_entrance_stairs_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    stairs_id: stairs,
                                  }
                                )}`,
                              }
                            : {
                                pathname: `${t(
                                  "routes:building_evacuation_entrance_stairs_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    stairs_id: stairs,
                                  }
                                )}`,
                              }
                        }
                      >
                        <span>{t("building__stairs:label")}</span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
              {item.lifts && item.lifts.length > 0
                ? item.lifts.map((lift) => (
                    <li key={lift} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={
                          entranceId
                            ? {
                                pathname: `${t(
                                  "routes:building_entrance_lift_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    lift_id: lift,
                                  }
                                )}`,
                              }
                            : {
                                pathname: `${t(
                                  "routes:building_evacuation_entrance_lift_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    lift_id: lift,
                                  }
                                )}`,
                              }
                        }
                      >
                        <span>{getLiftName(lift)}</span>{" "}
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
              {item.doors && item.doors.length > 0
                ? item.doors.map((door) => (
                    <li key={door} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={
                          entranceId
                            ? {
                                pathname: `${t(
                                  "routes:building_entrance_door_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    door_id: door,
                                  }
                                )}`,
                              }
                            : {
                                pathname: `${t(
                                  "routes:building_evacuation_entrance_door_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    door_id: door,
                                  }
                                )}`,
                              }
                        }
                      >
                        <span>{t("building__door:label")}</span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
              {item.ramps && item.ramps.length > 0
                ? item.ramps.map((ramp) => (
                    <li key={ramp} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={
                          entranceId
                            ? {
                                pathname: `${t(
                                  "routes:building_entrance_ramp_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    ramp_id: ramp,
                                  }
                                )}`,
                              }
                            : {
                                pathname: `${t(
                                  "routes:building_evacuation_entrance_ramp_details",
                                  {
                                    id: id,
                                    entrance_id: entranceId,
                                    ramp_id: ramp,
                                  }
                                )}`,
                              }
                        }
                      >
                        <span>{t("building__ramp:label")}</span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkEntranceDetails;
