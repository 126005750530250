const ADD_OBJECT_MATCH = "ADD_OBJECT_MATCH";
const SET_LOADING_STATE = "SET_LOADING_STATE";
const RESET_LOADING_STATE = "RESET_LOADING_STATE";
const ADD_OBJECT_DETAILS = "ADD_OBJECT_DETAILS";
const RESET_OBJECT_DETAILS = "RESET_OBJECT_DETAILS";
const ADD_OBJECT_IMAGES = "ADD_OBJECT_IMAGES";
const RESET_OBJECT_IMAGES = "RESET_OBJECT_IMAGES";
const ADD_SUBSIDEBAR_ITEM = "ADD_SUBSIDEBAR_ITEM";
const RESET_SUBSIDEBAR_ITEM = "RESET_SUBSIDEBAR_ITEM";
const ADD_OBJECT_CATEGORY_ITEM = "ADD_OBJECT_CATEGORY_ITEM";
const RESET_OBJECT_CATEGORY_ITEM = "RESET_OBJECT_CATEGORY_ITEM";

export default {
  ADD_OBJECT_MATCH,
  SET_LOADING_STATE,
  RESET_LOADING_STATE,
  ADD_OBJECT_DETAILS,
  RESET_OBJECT_DETAILS,
  ADD_OBJECT_IMAGES,
  RESET_OBJECT_IMAGES,
  ADD_SUBSIDEBAR_ITEM,
  RESET_SUBSIDEBAR_ITEM,
  ADD_OBJECT_CATEGORY_ITEM,
  RESET_OBJECT_CATEGORY_ITEM,
};
