import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import $ from "jquery";

import "./Accordion.css";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightArrow from "../../../assets/right-arrow-forward.svg";
import DownArrow from "../../../assets/down-arrow-forward.svg";

function AccordionCardReaders({
  id,
  parentObjectName,
  parentObjectIndex,
  cardReaderIndex,
  mode,
}) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${process.env.REACT_APP_API_URL}/card_readers/?id=${id}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });
    return () => {
      controller.abort();
    };
  }, [id]);

  const toggleAccordion = () => {
    setActive(!isActive);

    if (!isActive) {
      let parentObject =
        parentObjectName && parentObjectIndex
          ? "-" + parentObjectName + "-" + parentObjectIndex
          : "";
      setTimeout(() => {
        let targetElement;

        if (mode) {
          $(
            `#accordion${parentObject}-card-reader-${cardReaderIndex} ul > :first-child`
          ).attr(
            "id",
            `accordion${parentObject}-first-list-card-reader-element-${cardReaderIndex}`
          );
          $(
            `#accordion${parentObject}-card-reader-${cardReaderIndex} ul > :first-child`
          ).attr("tabindex", "-1");

          targetElement = document.getElementById(
            `accordion${parentObject}-first-list-card-reader-element-${cardReaderIndex}`
          );
        } else {
          $(
            `#accordion-mobile${parentObject}-card-reader-${cardReaderIndex} ul > :first-child`
          ).attr(
            "id",
            `accordion-mobile${parentObject}-first-list-card-reader-element-${cardReaderIndex}`
          );
          $(
            `#accordion-mobile${parentObject}-card-reader-${cardReaderIndex} ul > :first-child`
          ).attr("tabindex", "-1");

          targetElement = document.getElementById(
            `accordion-mobile${parentObject}-first-list-card-reader-element-${cardReaderIndex}`
          );
        }

        targetElement.focus();
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <div className="c-accordion">
            <div className="c-accordion__title">
              <button
                type="button"
                onClick={toggleAccordion}
                aria-expanded={isActive ? "true" : "false"}
              >
                <span>{t("building__card_reader:label")}</span>{" "}
                <img src={isActive ? DownArrow : RightArrow} alt="" />
              </button>
            </div>
            <div
              id={
                mode
                  ? `accordion${
                      parentObjectName && parentObjectIndex
                        ? "-" + parentObjectName + "-" + parentObjectIndex
                        : ""
                    }-card-reader-${cardReaderIndex}`
                  : `accordion-mobile${
                      parentObjectName && parentObjectIndex
                        ? "-" + parentObjectName + "-" + parentObjectIndex
                        : ""
                    }-card-reader-${cardReaderIndex}`
              }
              className={
                isActive ? "c-accordion__content--open" : "c-accordion__content"
              }
            >
              <ul>
                {(() => {
                  return (
                    <>
                      {item.translations[cookies.i18next].comment ===
                      "" ? null : (
                        <li className="text">
                          {item.translations[cookies.i18next].comment}
                        </li>
                      )}
                      {item.is_card_required === "Unknown" ? null : (
                        <li className="text">
                          {item.is_card_required === "True"
                            ? t(
                                "building__card_reader:is_card_required__true",
                                {
                                  is_card_required_comment:
                                    item.translations[cookies.i18next]
                                      .is_card_required_comment,
                                }
                              )
                            : t(
                                "building__card_reader:is_card_required__false",
                                {
                                  is_card_required_comment:
                                    item.translations[cookies.i18next]
                                      .is_card_required_comment,
                                }
                              )}
                        </li>
                      )}
                      {item.translations[cookies.i18next].location ===
                      "" ? null : (
                        <li className="text">
                          {t("building__card_reader:location", {
                            location:
                              item.translations[cookies.i18next].location,
                          })}
                        </li>
                      )}
                    </>
                  );
                })()}
              </ul>
              {(() => {
                if (cookies.value.length > 0) {
                  return (
                    <>
                      <article
                        className="object-category-profile-description"
                        aria-label={t("general:profile_description_label")}
                      >
                        <div className="object-category-profile-sign">
                          <img
                            className="object-category-profile-sign__icon"
                            src={
                              cookies.value.length > 1
                                ? ProfileWhite
                                : getProfilePictogram(cookies.value[0])
                            }
                            alt=""
                          />
                          <span className="object-category-profile-sign__label">
                            {t("general:profile_description_title")}
                          </span>
                        </div>
                        <ul>
                          {(() => {
                            return (
                              <>
                                {checker(cookies.value, [2]) ? (
                                  item.card_reader_height ? (
                                    <li className="text">
                                      {t(
                                        "building__card_reader:card_reader_height",
                                        {
                                          card_reader_height:
                                            item.card_reader_height,
                                        }
                                      )}
                                    </li>
                                  ) : null
                                ) : null}
                                {checker(cookies.value, [3]) ? (
                                  item.is_visible === "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_visible === "True"
                                        ? t(
                                            "building__card_reader:is_visible__true",
                                            {
                                              is_visible_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_visible_comment,
                                            }
                                          )
                                        : t(
                                            "building__card_reader:is_visible__false",
                                            {
                                              is_visible_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ].is_visible_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [2, 3]) ? (
                                  item.is_voice_confirmation_message ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_voice_confirmation_message ===
                                      "True"
                                        ? t(
                                            "building__card_reader:is_voice_confirmation_message__true",
                                            {
                                              is_voice_confirmation_message_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_voice_confirmation_message_comment,
                                            }
                                          )
                                        : t(
                                            "building__card_reader:is_voice_confirmation_message__false",
                                            {
                                              is_voice_confirmation_message_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_voice_confirmation_message_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                                {checker(cookies.value, [4]) ? (
                                  item.is_visual_confirmation_message ===
                                  "Unknown" ? null : (
                                    <li className="text">
                                      {item.is_visual_confirmation_message ===
                                      "True"
                                        ? t(
                                            "building__card_reader:is_visual_confirmation_message__true",
                                            {
                                              is_visual_confirmation_message_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_visual_confirmation_message_comment,
                                            }
                                          )
                                        : t(
                                            "building__card_reader:is_visual_confirmation_message__false",
                                            {
                                              is_visual_confirmation_message_comment:
                                                item.translations[
                                                  cookies.i18next
                                                ]
                                                  .is_visual_confirmation_message_comment,
                                            }
                                          )}
                                    </li>
                                  )
                                ) : null}
                              </>
                            );
                          })()}
                        </ul>
                      </article>
                    </>
                  );
                }
              })()}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AccordionCardReaders;
