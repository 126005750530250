import types from "./types.js";

const addBuilding = (item) => ({
  type: types.ADD_BUILDINGS_LIST,
  item,
});

const resetBuilding = (item) => ({
  type: types.RESET_BUILDINGS_LIST,
  item,
});

const addBuildingToSortedCategory = (item) => ({
  type: types.ADD_BUILDINGS_TO_SORTED_CATEGORY,
  item,
});

const resetSortedCategory = (item) => ({
  type: types.RESET_BUILDINGS_SORTED_CATEGORY,
  item,
});

export default {
  addBuilding,
  resetBuilding,
  addBuildingToSortedCategory,
  resetSortedCategory,
};
