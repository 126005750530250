const ADD_AREA_MATCH = "ADD_AREA_MATCH";
const ADD_TYPHLOMAP = "ADD_TYPHLOMAP";
const ADD_TYPHLOMAP_BUILDING = "ADD_TYPHLOMAP_BUILDING";
const ADD_TYPHLOMAP_BUILDING_NAME = "ADD_TYPHLOMAP_BUILDING_NAME";
const ADD_TYPHLOMAP_AREA_DETAILS = "ADD_TYPHLOMAP_AREA_DETAILS";
const RESET_TYPHLOMAP_AREA_DETAILS = "RESET_TYPHLOMAP_AREA_DETAILS";
const ADD_TYPHLOMAP_AREAS = "ADD_TYPHLOMAP_AREAS";
const RESET_TYPHLOMAP_AREAS = "RESET_TYPHLOMAP_AREAS";
const ADD_TYPHLOMAP_AREA_INDEX = "ADD_TYPHLOMAP_AREA_INDEX";

export default {
  ADD_AREA_MATCH,
  ADD_TYPHLOMAP,
  ADD_TYPHLOMAP_BUILDING,
  ADD_TYPHLOMAP_BUILDING_NAME,
  ADD_TYPHLOMAP_AREA_DETAILS,
  RESET_TYPHLOMAP_AREA_DETAILS,
  ADD_TYPHLOMAP_AREAS,
  RESET_TYPHLOMAP_AREAS,
  ADD_TYPHLOMAP_AREA_INDEX,
};
