import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";
import RightArrow from "../../../assets/arrow-forward.svg";

function ClickableItemLinkAdaptedToiletDetails({ mode }) {
  const { id, levelId, regionId, adaptedToiletId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);

  const adaptedToiletsTypes = [
    t("building__adapted_toilet:adapted_toilet_type_1"),
    t("building__adapted_toilet:adapted_toilet_type_2"),
    t("building__adapted_toilet:adapted_toilet_type_3"),
  ];

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(
          `object-category-header-${adaptedToiletId}`
        );
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${adaptedToiletId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(
      `${process.env.REACT_APP_API_URL}/adapted_toilets/?id=${adaptedToiletId}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [adaptedToiletId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  useEffect(() => {
    /* Setting the title of the page. */
    if (item) {
      document.title = t("building__adapted_toilet:single_title", {
        adapted_toilet_type:
          adaptedToiletsTypes[parseInt(item.has_additional_purpose) - 1],
        building_name: buildingName,
      });
    }
  }, [item]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section
            aria-label={
              adaptedToiletsTypes[parseInt(item.has_additional_purpose) - 1]
            }
          >
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {adaptedToiletId && (
                  <>
                    <li>
                      <Link
                        to={{
                          pathname: `${t("routes:building_adapted_toilets", {
                            id: id,
                          })}`,
                        }}
                      >
                        {t("building__adapted_toilet:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                <li>
                  {
                    adaptedToiletsTypes[
                      parseInt(item.has_additional_purpose) - 1
                    ]
                  }
                </li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${adaptedToiletId}`
                  : `object-category-mobile-header-${adaptedToiletId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {adaptedToiletsTypes[parseInt(item.has_additional_purpose) - 1]}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.translations[cookies.i18next].location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__adapted_toilet:location", {
                          location: item.translations[cookies.i18next].location,
                        })}
                      </li>
                    )}
                    {item.translations[cookies.i18next].number_of_cabins ===
                    "" ? null : (
                      <li className="text">
                        {t("building__adapted_toilet:number_of_cabins", {
                          number_of_cabins:
                            item.translations[cookies.i18next].number_of_cabins,
                        })}
                      </li>
                    )}
                    {item.is_need_authorization === "Unknown" ? null : (
                      <li className="text">
                        {item.is_need_authorization === "True"
                          ? t(
                              "building__adapted_toilet:is_need_authorization__true",
                              {
                                is_need_authorization_comment:
                                  item.translations[cookies.i18next]
                                    .is_need_authorization_comment,
                              }
                            )
                          : t(
                              "building__adapted_toilet:is_need_authorization__false",
                              {
                                is_need_authorization_comment:
                                  item.translations[cookies.i18next]
                                    .is_need_authorization_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_entrance_graphically_marked ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.is_entrance_graphically_marked === "True"
                          ? t(
                              "building__adapted_toilet:is_entrance_graphically_marked__true",
                              {
                                is_entrance_graphically_marked_comment:
                                  item.translations[cookies.i18next]
                                    .is_entrance_graphically_marked_comment,
                              }
                            )
                          : t(
                              "building__adapted_toilet:is_entrance_graphically_marked__false",
                              {
                                is_entrance_graphically_marked_comment:
                                  item.translations[cookies.i18next]
                                    .is_entrance_graphically_marked_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_marked === "Unknown" ? null : (
                      <li className="text">
                        {item.is_marked === "True"
                          ? t("building__adapted_toilet:is_marked__true", {
                              is_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_marked_comment,
                            })
                          : t("building__adapted_toilet:is_marked__false", {
                              is_marked_comment:
                                item.translations[cookies.i18next]
                                  .is_marked_comment,
                            })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [2]) ? (
                                item.translations[cookies.i18next]
                                  .toilet_description === "" ? null : (
                                  <li className="text">
                                    {t(
                                      "building__adapted_toilet:toilet_description",
                                      {
                                        toilet_description:
                                          item.translations[cookies.i18next]
                                            .toilet_description,
                                      }
                                    )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2, 3]) ? (
                                item.is_access_accessible_for_pwd ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_access_accessible_for_pwd ===
                                    "True"
                                      ? t(
                                          "building__adapted_toilet:is_access_accessible_for_pwd__true",
                                          {
                                            is_access_accessible_for_pwd_comment:
                                              item.translations[cookies.i18next]
                                                .is_access_accessible_for_pwd_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_access_accessible_for_pwd__false",
                                          {
                                            is_access_accessible_for_pwd_comment:
                                              item.translations[cookies.i18next]
                                                .is_access_accessible_for_pwd_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_area_allowing_movement_in_front_entranced ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_area_allowing_movement_in_front_entrance ===
                                    "True"
                                      ? t(
                                          "building__adapted_toilet:is_area_allowing_movement_in_front_entrance__true",
                                          {
                                            is_area_allowing_movement_in_front_entrance_comment:
                                              item.translations[cookies.i18next]
                                                .is_area_allowing_movement_in_front_entrance_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_area_allowing_movement_in_front_entrance__false",
                                          {
                                            is_area_allowing_movement_in_front_entrance_comment:
                                              item.translations[cookies.i18next]
                                                .is_area_allowing_movement_in_front_entrance_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.translations[cookies.i18next]
                                  .toilet_dimensions === "" ? null : (
                                  <li className="text">
                                    {t(
                                      "building__adapted_toilet:toilet_dimensions",
                                      {
                                        toilet_dimensions:
                                          item.translations[cookies.i18next]
                                            .toilet_dimensions,
                                      }
                                    )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_150x150_free_space ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_150x150_free_space === "True"
                                      ? t(
                                          "building__adapted_toilet:is_150x150_free_space__true",
                                          {
                                            is_150x150_free_space_comment:
                                              item.translations[cookies.i18next]
                                                .is_150x150_free_space_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_150x150_free_space__false",
                                          {
                                            is_150x150_free_space_comment:
                                              item.translations[cookies.i18next]
                                                .is_150x150_free_space_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_floor_wall_contrast ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_floor_wall_contrast === "True"
                                      ? t(
                                          "building__adapted_toilet:is_good_floor_wall_contrast__true",
                                          {
                                            is_good_floor_wall_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_floor_wall_contrast_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_good_floor_wall_contrast__false",
                                          {
                                            is_good_floor_wall_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_floor_wall_contrast_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_side_maneuvering_space ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_side_maneuvering_space === "True"
                                      ? t(
                                          "building__adapted_toilet:is_side_maneuvering_space__true",
                                          {
                                            is_side_maneuvering_space_comment:
                                              item.translations[cookies.i18next]
                                                .is_side_maneuvering_space_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_side_maneuvering_space__false",
                                          {
                                            is_side_maneuvering_space_comment:
                                              item.translations[cookies.i18next]
                                                .is_side_maneuvering_space_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2, 3]) ? (
                                item.is_pulldown_handle === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_pulldown_handle === "True"
                                      ? t(
                                          "building__adapted_toilet:is_pulldown_handle__true",
                                          {
                                            is_pulldown_handle_comment:
                                              item.translations[cookies.i18next]
                                                .is_pulldown_handle_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_pulldown_handle__false",
                                          {
                                            is_pulldown_handle_comment:
                                              item.translations[cookies.i18next]
                                                .is_pulldown_handle_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2, 3]) ? (
                                item.is_fixed_handle === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_fixed_handle === "True"
                                      ? t(
                                          "building__adapted_toilet:is_fixed_handle__true",
                                          {
                                            is_fixed_handle_comment:
                                              item.translations[cookies.i18next]
                                                .is_fixed_handle_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_fixed_handle__false",
                                          {
                                            is_fixed_handle_comment:
                                              item.translations[cookies.i18next]
                                                .is_fixed_handle_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_pulldown_handle_wall_contrast ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_pulldown_handle_wall_contrast ===
                                    "True"
                                      ? t(
                                          "building__adapted_toilet:is_good_pulldown_handle_wall_contrast__true",
                                          {
                                            is_good_pulldown_handle_wall_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_pulldown_handle_wall_contrast_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_good_pulldown_handle_wall_contrast__false",
                                          {
                                            is_good_pulldown_handle_wall_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_pulldown_handle_wall_contrast_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_mirror === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_mirror === "True"
                                      ? t(
                                          "building__adapted_toilet:is_mirror__true",
                                          {
                                            is_mirror_comment:
                                              item.translations[cookies.i18next]
                                                .is_mirror_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_mirror__false",
                                          {
                                            is_mirror_comment:
                                              item.translations[cookies.i18next]
                                                .is_mirror_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.are_clothes_hooks === "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_clothes_hooks === "True"
                                      ? t(
                                          "building__adapted_toilet:are_clothes_hooks__true",
                                          {
                                            are_clothes_hooks_comment:
                                              item.translations[cookies.i18next]
                                                .are_clothes_hooks_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:are_clothes_hooks__false",
                                          {
                                            are_clothes_hooks_comment:
                                              item.translations[cookies.i18next]
                                                .are_clothes_hooks_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.toilet_seat_height ? (
                                  <li className="text">
                                    {t(
                                      "building__adapted_toilet:toilet_seat_height",
                                      {
                                        toilet_seat_height:
                                          item.toilet_seat_height,
                                      }
                                    )}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_wastebasket === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_wastebasket === "True"
                                      ? t(
                                          "building__adapted_toilet:is_wastebasket__true",
                                          {
                                            is_wastebasket_comment:
                                              item.translations[cookies.i18next]
                                                .is_wastebasket_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_wastebasket__false",
                                          {
                                            is_wastebasket_comment:
                                              item.translations[cookies.i18next]
                                                .is_wastebasket_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_towel_dispenser === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_towel_dispenser === "True"
                                      ? t(
                                          "building__adapted_toilet:is_towel_dispenser__true",
                                          {
                                            is_towel_dispenser_comment:
                                              item.translations[cookies.i18next]
                                                .is_towel_dispenser_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_towel_dispenser__false",
                                          {
                                            is_towel_dispenser_comment:
                                              item.translations[cookies.i18next]
                                                .is_towel_dispenser_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_hand_dryer === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_hand_dryer === "True"
                                      ? t(
                                          "building__adapted_toilet:is_hand_dryer__true",
                                          {
                                            is_hand_dryer_comment:
                                              item.translations[cookies.i18next]
                                                .is_hand_dryer_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_hand_dryer__false",
                                          {
                                            is_hand_dryer_comment:
                                              item.translations[cookies.i18next]
                                                .is_hand_dryer_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.translations[cookies.i18next]
                                  .washbasin_location_height === "" ? null : (
                                  <li className="text">
                                    {t(
                                      "building__adapted_toilet:washbasin_location_height",
                                      {
                                        washbasin_location_height:
                                          item.translations[cookies.i18next]
                                            .washbasin_location_height,
                                      }
                                    )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_space_under_washbasin_for_wheelchair ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_space_under_washbasin_for_wheelchair ===
                                    "True"
                                      ? t(
                                          "building__adapted_toilet:is_space_under_washbasin_for_wheelchair__true",
                                          {
                                            is_space_under_washbasin_for_wheelchair_comment:
                                              item.translations[cookies.i18next]
                                                .is_space_under_washbasin_for_wheelchair_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_space_under_washbasin_for_wheelchair__false",
                                          {
                                            is_space_under_washbasin_for_wheelchair_comment:
                                              item.translations[cookies.i18next]
                                                .is_space_under_washbasin_for_wheelchair_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 3, 4, 6]) ? (
                                item.is_light_switch === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_light_switch === "True"
                                      ? t(
                                          "building__adapted_toilet:is_light_switch__true",
                                          {
                                            is_light_switch_comment:
                                              item.translations[cookies.i18next]
                                                .is_light_switch_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_light_switch__false",
                                          {
                                            is_light_switch_comment:
                                              item.translations[cookies.i18next]
                                                .is_light_switch_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_lit === "True"
                                      ? t(
                                          "building__adapted_toilet:is_good_lit__true",
                                          {
                                            is_good_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_lit_comment,
                                          }
                                        )
                                      : t(
                                          "building__adapted_toilet:is_good_lit__false",
                                          {
                                            is_good_lit_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_lit_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            <ul className="c-clickable-list">
              {item.doors && item.doors.length > 0
                ? item.doors.map((door) => (
                    <li key={door} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={
                          levelId && regionId && adaptedToiletId
                            ? {
                                pathname: `${t(
                                  "routes:building_region_adapted_toilet_door_details",
                                  {
                                    id: id,
                                    level_id: levelId,
                                    region_id: regionId,
                                    adapted_toilet_id: adaptedToiletId,
                                    door_id: door,
                                  }
                                )}`,
                              }
                            : {
                                pathname: `${t(
                                  "routes:building_adapted_toilet_door_details",
                                  {
                                    id: id,
                                    adapted_toilet_id: adaptedToiletId,
                                    door_id: door,
                                  }
                                )}`,
                              }
                        }
                      >
                        <span>{t("building__door:label")}</span>
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkAdaptedToiletDetails;
