import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AccordionRings from "../Accordion/AccordionRings";
import AccordionCardReaders from "../Accordion/AccordionCardReaders";
import AccordionIntercoms from "../Accordion/AccordionIntercoms";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";

function ClickableItemLinkDoorDetails({ mode }) {
  const {
    id,
    levelId,
    regionId,
    adaptedToiletId,
    toiletId,
    roomId,
    entranceId,
    parkingId,
    corridorId,
    stairwayId,
    doorId,
  } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);
  const [adaptedToilet, setAdaptedToilet] = useState(null);
  const [toilet, setToilet] = useState(null);
  const [room, setRoom] = useState(null);
  const [entrance, setEntrance] = useState(null);
  const [corridor, setCorridor] = useState(null);
  const [stairway, setStairway] = useState(null);

  const adaptedToiletsTypes = [
    t("building__adapted_toilet:adapted_toilet_type_1"),
    t("building__adapted_toilet:adapted_toilet_type_2"),
    t("building__adapted_toilet:adapted_toilet_type_3"),
  ];

  const doorTypesList = [
    t("building__door:door_type_1"),
    t("building__door:door_type_2"),
    t("building__door:door_type_3"),
    t("building__door:door_type_4"),
    t("building__door:door_type_5"),
    t("building__door:door_type_6"),
    t("building__door:door_type_7"),
    t("building__door:door_type_8"),
  ];

  const openingSystemTypesList = [
    t("building__door:opening_system_type_1"),
    t("building__door:opening_system_type_2"),
    t("building__door:opening_system_type_3"),
    t("building__door:opening_system_type_4"),
    t("building__door:opening_system_type_5"),
    t("building__door:opening_system_type_6"),
  ];

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(`object-category-header-${doorId}`);
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${doorId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__door:single_title", {
      building_name: buildingName,
    });

    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/doors/?id=${doorId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [doorId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(
      `${process.env.REACT_APP_API_URL}/adapted_toilets/?id=${adaptedToiletId}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setAdaptedToilet(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [adaptedToiletId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/toilets/?id=${toiletId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setToilet(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [toiletId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/rooms/?id=${roomId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRoom(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [roomId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/entrances/?id=${entranceId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEntrance(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [entranceId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/corridors/?id=${corridorId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCorridor(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [corridorId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/stairways/?id=${stairwayId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStairway(data[0]);
      });

    return () => {
      controller.abort();
    };
  }, [stairwayId]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section aria-label={t("building__door:label")}>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {entranceId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_entrances", {
                          id: id,
                        })}`}
                      >
                        {t("building__entrance:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `${t("routes:building_entrance_details", {
                            id: id,
                            entrance_id: entranceId,
                          })}`,
                        }}
                      >
                        {entrance?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {roomId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_rooms", {
                          id: id,
                        })}`}
                      >
                        {t("building__room:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `${t("routes:building_room_details", {
                            id: id,
                            room_id: roomId,
                          })}`,
                        }}
                      >
                        {room?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {adaptedToiletId && (
                  <>
                    <li>
                      <Link
                        to={{
                          pathname: `${t("routes:building_adapted_toilets", {
                            id: id,
                          })}`,
                        }}
                      >
                        {t("building__adapted_toilet:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `${t(
                            "routes:building_adapted_toilet_details",
                            {
                              id: id,
                              adapted_toilet_id: adaptedToiletId,
                            }
                          )}`,
                        }}
                      >
                        {
                          adaptedToiletsTypes[
                            parseInt(adaptedToilet?.has_additional_purpose) - 1
                          ]
                        }
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {(window.location.href.indexOf("ewakuacja") > -1 ||
                  window.location.href.indexOf("evacuation") > -1) && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_evacuation", {
                          id: id,
                        })}`}
                      >
                        {t("building__evacuation:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `${t(
                            "routes:building_evacuation_entrance_details",
                            {
                              id: id,
                              entrance_id: entranceId,
                            }
                          )}`,
                        }}
                      >
                        {entrance?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    {room?.translations[cookies.i18next]?.name && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_room_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  room_id: roomId,
                                }
                              )}`,
                            }}
                          >
                            {room?.translations[cookies.i18next]?.name}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                    {adaptedToilet?.translations[cookies.i18next]?.name && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_adapted_toilet_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  adapted_toilet_id: adaptedToiletId,
                                }
                              )}`,
                            }}
                          >
                            {adaptedToilet?.translations[cookies.i18next]?.name}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                    {toilet?.translations[cookies.i18next]?.name && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_toilet_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  toilet_id: toiletId,
                                }
                              )}`,
                            }}
                          >
                            {toilet?.translations[cookies.i18next]?.name}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                    {corridor?.translations[cookies.i18next]?.name && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_corridor_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  corridor_id: corridorId,
                                }
                              )}`,
                            }}
                          >
                            {corridor?.translations[cookies.i18next]?.name}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                    {stairwayId && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_stairway_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  stairway_id: stairwayId,
                                }
                              )}`,
                            }}
                          >
                            {stairway?.translations[cookies.i18next]?.name}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                    {parkingId && (
                      <>
                        <li>
                          <Link
                            to={{
                              pathname: `${t(
                                "routes:building_region_parking_details",
                                {
                                  id: id,
                                  level_id: levelId,
                                  region_id: regionId,
                                  parking_id: parkingId,
                                }
                              )}`,
                            }}
                          >
                            {t("building__parking:label")}
                          </Link>
                          <img src={RightNavigationArrow} alt="" />
                        </li>
                      </>
                    )}
                  </>
                )}
                <li>{t("building__door:label")}</li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${doorId}`
                  : `object-category-mobile-header-${doorId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {t("building__door:label")}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations[cookies.i18next].comment ===
                    "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.translations[cookies.i18next].from_to ===
                    "" ? null : (
                      <li className="text">
                        {t("building__door:from_to", {
                          from_to: item.translations[cookies.i18next].from_to,
                        })}
                      </li>
                    )}
                    {item.are_visible === "Unknown" ? null : (
                      <li className="text">
                        {item.are_visible === "True"
                          ? t("building__door:are_visible__true", {
                              are_visible_comment:
                                item.translations[cookies.i18next]
                                  .are_visible_comment,
                            })
                          : t("building__door:are_visible__false", {
                              are_visible_comment:
                                item.translations[cookies.i18next]
                                  .are_visible_comment,
                            })}
                      </li>
                    )}
                    {item.is_good_door_visible_from_outside ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.is_good_door_visible_from_outside === "True"
                          ? t(
                              "building__door:is_good_door_visible_from_outside__true",
                              {
                                is_good_door_visible_from_outside_comment:
                                  item.translations[cookies.i18next]
                                    .is_good_door_visible_from_outside_comment,
                              }
                            )
                          : t(
                              "building__door:is_good_door_visible_from_outside__false",
                              {
                                is_good_door_visible_from_outside_comment:
                                  item.translations[cookies.i18next]
                                    .is_good_door_visible_from_outside_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_good_door_visible_from_inside ===
                    "Unknown" ? null : (
                      <li className="text">
                        {item.is_good_door_visible_from_inside === "True"
                          ? t(
                              "building__door:is_good_door_visible_from_inside__true",
                              {
                                is_good_door_visible_from_inside_comment:
                                  item.translations[cookies.i18next]
                                    .is_good_door_visible_from_inside_comment,
                              }
                            )
                          : t(
                              "building__door:is_good_door_visible_from_inside__false",
                              {
                                is_good_door_visible_from_inside_comment:
                                  item.translations[cookies.i18next]
                                    .is_good_door_visible_from_inside_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.door_type ? (
                      <li className="text">
                        {t("building__door:door_type", {
                          door_type:
                            doorTypesList[parseInt(item.door_type) - 1],
                        })}
                      </li>
                    ) : null}
                    {item.is_main_wing_highlighted === "Unknown" ? null : (
                      <li className="text">
                        {item.is_main_wing_highlighted === "True"
                          ? t("building__door:is_main_wing_highlighted__true", {
                              is_main_wing_highlighted_comment:
                                item.translations[cookies.i18next]
                                  .is_main_wing_highlighted_comment,
                            })
                          : t(
                              "building__door:is_main_wing_highlighted__false",
                              {
                                is_main_wing_highlighted_comment:
                                  item.translations[cookies.i18next]
                                    .is_main_wing_highlighted_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_increased_force_required === "Unknown" ? null : (
                      <li className="text">
                        {item.is_increased_force_required === "True"
                          ? t(
                              "building__door:is_increased_force_required__true",
                              {
                                is_increased_force_required_comment:
                                  item.translations[cookies.i18next]
                                    .is_increased_force_required_comment,
                              }
                            )
                          : t(
                              "building__door:is_increased_force_required__false",
                              {
                                is_increased_force_required_comment:
                                  item.translations[cookies.i18next]
                                    .is_increased_force_required_comment,
                              }
                            )}
                      </li>
                    )}
                    {item.is_door_closer === "Unknown" ? null : (
                      <li className="text">
                        {item.is_door_closer === "True"
                          ? t("building__door:is_door_closer__true", {
                              is_door_closer_comment:
                                item.translations[cookies.i18next]
                                  .is_door_closer_comment,
                            })
                          : t("building__door:is_door_closer__false", {
                              is_door_closer_comment:
                                item.translations[cookies.i18next]
                                  .is_door_closer_comment,
                            })}
                      </li>
                    )}
                    {item.is_closure_from_inside === "Unknown" ? null : (
                      <li className="text">
                        {item.is_closure_from_inside === "True"
                          ? t("building__door:is_closure_from_inside__true")
                          : t("building__door:is_closure_from_inside__false")}
                      </li>
                    )}
                    {item.is_key_needed === "Unknown" ? null : (
                      <li className="text">
                        {item.is_key_needed === "True"
                          ? t("building__door:is_key_needed__true", {
                              is_key_needed_comment:
                                item.translations[cookies.i18next]
                                  .is_key_needed_comment,
                            })
                          : t("building__door:is_key_needed__false", {
                              is_key_needed_comment:
                                item.translations[cookies.i18next]
                                  .is_key_needed_comment,
                            })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [2]) ? (
                                item.is_graphic === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_graphic === "True"
                                      ? t("building__door:is_graphic__true", {
                                          is_graphic_comment:
                                            item.translations[cookies.i18next]
                                              .is_graphic_comment,
                                        })
                                      : t("building__door:is_graphic__false", {
                                          is_graphic_comment:
                                            item.translations[cookies.i18next]
                                              .is_graphic_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [1]) ? (
                                item.are_open_automatically ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_open_automatically === "True"
                                      ? t(
                                          "building__door:are_open_automatically__true",
                                          {
                                            are_open_automatically_comment:
                                              item.translations[cookies.i18next]
                                                .are_open_automatically_comment,
                                          }
                                        )
                                      : t(
                                          "building__door:are_open_automatically__false",
                                          {
                                            are_open_automatically_comment:
                                              item.translations[cookies.i18next]
                                                .are_open_automatically_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.door_width ? (
                                  <li className="text">
                                    {t("building__door:door_width", {
                                      door_width: item.door_width,
                                    })}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.are_glazed === "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_glazed === "True"
                                      ? t("building__door:are_glazed__true", {
                                          are_glazed_comment:
                                            item.translations[cookies.i18next]
                                              .are_glazed_comment,
                                        })
                                      : t("building__door:are_glazed__false", {
                                          are_glazed_comment:
                                            item.translations[cookies.i18next]
                                              .are_glazed_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.are_open_outwards === "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_open_outwards === "True"
                                      ? t(
                                          "building__door:are_open_outwards__true",
                                          {
                                            are_open_outwards_comment:
                                              item.translations[cookies.i18next]
                                                .are_open_outwards_comment,
                                          }
                                        )
                                      : t(
                                          "building__door:are_open_outwards__false",
                                          {
                                            are_open_outwards_comment:
                                              item.translations[cookies.i18next]
                                                .are_open_outwards_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.opening_system_type ? (
                                  <li className="text">
                                    {t("building__door:opening_system_type", {
                                      opening_system_type:
                                        openingSystemTypesList[
                                          parseInt(item.opening_system_type) - 1
                                        ],
                                    })}
                                  </li>
                                ) : null
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.translations[cookies.i18next]
                                  .handle_height === "" ? null : (
                                  <li className="text">
                                    {t("building__door:handle_height", {
                                      handle_height:
                                        item.translations[cookies.i18next]
                                          .handle_height,
                                    })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_handle_highlighted ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_handle_highlighted === "True"
                                      ? t(
                                          "building__door:is_handle_highlighted__true",
                                          {
                                            is_handle_highlighted_comment:
                                              item.translations[cookies.i18next]
                                                .is_handle_highlighted_comment,
                                          }
                                        )
                                      : t(
                                          "building__door:is_handle_highlighted__false",
                                          {
                                            is_handle_highlighted_comment:
                                              item.translations[cookies.i18next]
                                                .is_handle_highlighted_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2]) ? (
                                item.is_same_opening_system ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_same_opening_system === "True"
                                      ? t(
                                          "building__door:is_same_opening_system__true",
                                          {
                                            is_same_opening_system_comment:
                                              item.translations[cookies.i18next]
                                                .is_same_opening_system_comment,
                                          }
                                        )
                                      : t(
                                          "building__door:is_same_opening_system__false",
                                          {
                                            is_same_opening_system_comment:
                                              item.translations[cookies.i18next]
                                                .is_same_opening_system_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.is_elevated_threshold ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_elevated_threshold === "True"
                                      ? t(
                                          "building__door:is_elevated_threshold__true",
                                          {
                                            is_elevated_threshold_comment:
                                              item.translations[cookies.i18next]
                                                .is_elevated_threshold_comment,
                                          }
                                        )
                                      : t(
                                          "building__door:is_elevated_threshold__false",
                                          {
                                            is_elevated_threshold_comment:
                                              item.translations[cookies.i18next]
                                                .is_elevated_threshold_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.translations[cookies.i18next]
                                  .door_threats === "" ? null : (
                                  <li className="text">
                                    {t("building__door:door_threats", {
                                      door_threats:
                                        item.translations[cookies.i18next]
                                          .door_threats,
                                    })}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            {item.rings && item.rings.length > 0
              ? item.rings.map((ring, index) => (
                  <AccordionRings
                    id={ring}
                    key={ring}
                    parentObjectName={"door"}
                    parentObjectIndex={doorId}
                    ringIndex={index}
                    mode={mode}
                  />
                ))
              : null}
            {item.card_readers && item.card_readers.length > 0
              ? item.card_readers.map((card_reader, index) => (
                  <AccordionCardReaders
                    id={card_reader}
                    key={card_reader}
                    parentObjectName={"door"}
                    parentObjectIndex={doorId}
                    cardReaderIndex={index}
                    mode={mode}
                  />
                ))
              : null}
            {item.intercoms && item.intercoms.length > 0
              ? item.intercoms.map((intercom, index) => (
                  <AccordionIntercoms
                    id={intercom}
                    key={intercom}
                    parentObjectName={"door"}
                    parentObjectIndex={doorId}
                    intercomIndex={index}
                    mode={mode}
                  />
                ))
              : null}
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkDoorDetails;
