import { useState } from "react";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";
import $, { cssNumber } from "jquery";

import "./Accordion.css";

import RightArrow from "../../../assets/right-arrow-forward.svg";
import DownArrow from "../../../assets/down-arrow-forward.svg";

function AccordionTyphlomapAreas({ item, typhlomapAreaIndex }) {
  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  const [isActive, setActive] = useState(false);

  const toggleAccordion = () => {
    setActive(!isActive);

    if (!isActive) {
      setTimeout(() => {
        let targetElement = document.getElementById(
          `accordion-typhlomap-area-${typhlomapAreaIndex}`
        );

        targetElement.focus();
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  return (
    <>
      {item ? (
        <>
          <div className="c-accordion">
            <div className="c-accordion__title">
              <button
                type="button"
                onClick={toggleAccordion}
                aria-expanded={isActive ? "true" : "false"}
              >
                <span>{item.translations[cookies.i18next].name}</span>{" "}
                <img src={isActive ? DownArrow : RightArrow} alt="" />
              </button>
            </div>
            <div
              id={`accordion-typhlomap-area-${typhlomapAreaIndex}`}
              className={
                isActive ? "c-accordion__content--open" : "c-accordion__content"
              }
              tabIndex={-1}
            >
              {parse(
                String(item.translations[cookies.i18next].extended_description)
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AccordionTyphlomapAreas;
