import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import { connect } from "react-redux";
import { getAllObjects } from "./duck/operations.js";

import ObjectsDisplayContainer from "../ObjectsDisplayContainer/ObjectsDisplayContainer";

import SearchBtn from "../../assets/search-btn.svg";
import NoData from "../../assets/no-data.svg";

const Objects = ({ objects, getAllObjects }) => {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const dropdownRef = useRef(null);
  const headingRef = useRef(null);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);

  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showSearchResultsCount, setShowSearchResultsCount] = useState(false);
  const [objectsObjectsLength, setObjectsObjectsLength] = useState(null);

  const [searchObjects, setSearchObjects] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/content/?id=1`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    getAllObjects();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      headingRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        setIsSearchInputOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    /* Changing the title of the page. */
    document.title = t("objects:title");
  }, []);

  const searchResultsList =
    objects.list.length > 0
      ? objects.list
          .filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.translations[cookies.i18next].name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })
          .slice(0, 8)
          .map((item) => {
            return (
              <li key={item.id} className="c-dropdown-search-results__item">
                <Link
                  to={t("routes:object_details_no_id") + item.id}
                  className="c-dropdown-search-results___link"
                >
                  {item.translations[cookies.i18next].name}
                </Link>
              </li>
            );
          })
      : null;

  const getFilteredObjectsList = () => {
    let sorted_objects = {};

    const filteredObjectsList = objects.list.filter((item) => {
      if (searchTerm === "") {
        return item;
      } else if (
        item.translations[cookies.i18next].name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return item;
      }
    });

    filteredObjectsList.forEach((data) => {
      const firstLetter = data.translations[cookies.i18next].name.charAt(0);

      if (!sorted_objects.hasOwnProperty(firstLetter)) {
        sorted_objects[firstLetter] = [];
      }

      sorted_objects[firstLetter].push(data);
    });

    setSearchObjects(sorted_objects);
  };

  return (
    <>
      {item.translations && (
        <>
          <main id="content">
            <div className="container">
              <h2
                id="js-category-name"
                className="text--header text--category-header text--center"
                tabIndex={-1}
                ref={headingRef}
              >
                {t("objects:page_header_objects")}
              </h2>
              <div className="l-description-container">
                <div className="l-description-box">
                  {parse(
                    String(
                      item.translations[cookies.i18next].objects_page_content
                    )
                  )}
                  <div className="l-search-container">
                    <form
                      role="search"
                      className="c-search"
                      onSubmit={(e) => {
                        e.preventDefault();
                        setShowSearchResults(true);
                        setShowSearchResultsCount(true);
                        setObjectsObjectsLength(0);

                        getFilteredObjectsList();

                        let jumpLink =
                          document.getElementById("filter-result-info");

                        jumpLink.focus();
                        jumpLink.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      <label
                        htmlFor="search-input"
                        className="c-search__label"
                        role="heading"
                        aria-level="2"
                      >
                        <span className="sr-only">
                          {t("objects:search_input_placeholder")}
                        </span>
                      </label>
                      <input
                        type="text"
                        id="search-input"
                        className="c-search__input"
                        placeholder={t("objects:search_input_placeholder")}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSearchInputOpen(true);
                        }}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setIsSearchInputOpen(true);
                          setShowSearchResultsCount(false);
                        }}
                      />
                      <div className="c-search__btn-container">
                        <button
                          className="search__btn"
                          type="submit"
                          aria-label={t("general:search_button")}
                        >
                          <img src={SearchBtn} alt="" />
                        </button>
                      </div>
                    </form>
                    <div
                      className={`c-dropdown-search-results ${
                        searchTerm && isSearchInputOpen ? "open" : ""
                      }`}
                      ref={dropdownRef}
                    >
                      <ul>
                        {searchResultsList && searchResultsList.length > 0 ? (
                          searchResultsList
                        ) : (
                          <li className="c-dropdown-search-no-data__item">
                            {t("general:search_dropdown_no_data_result")}
                          </li>
                        )}
                      </ul>
                    </div>
                    {searchTerm && isSearchInputOpen ? (
                      <div
                        aria-live="assertive"
                        aria-atomic="true"
                        className="sr-only"
                      >
                        {t("general:search_input_result_suggestion_label", {
                          search_results_number:
                            searchResultsList && searchResultsList.length > 0
                              ? searchResultsList?.length
                              : 0,
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div
                    id="filter-result-info"
                    className="c-filter-result-info mb-3"
                    aria-live={showSearchResultsCount ? "assertive" : null}
                    aria-atomic={showSearchResultsCount ? "true" : null}
                  >
                    <>
                      {t("general:filter_result_info_part_1")}{" "}
                      <span>
                        {t("general:filter_result_info_part_2", {
                          results:
                            searchTerm === "" && objectsObjectsLength === null
                              ? objects.list.length
                              : objectsObjectsLength,
                        })}
                      </span>
                    </>
                  </div>
                </div>
              </div>
              <div className="container">
                {(objectsObjectsLength === 0 &&
                  Object.keys(objects.sorted_objects_categories).length > 0) ||
                objectsObjectsLength !== 0
                  ? Object.keys(objects.sorted_objects_categories).map(
                      (prop, index) => {
                        return (
                          <ObjectsDisplayContainer
                            key={index}
                            name={prop}
                            items={objects.sorted_objects_categories[prop]}
                            searchTerm={searchTerm}
                            setShowSearchResults={setShowSearchResults}
                            showSearchResults={showSearchResults}
                            setObjectsObjectsLength={setObjectsObjectsLength}
                          />
                        );
                      }
                    )
                  : null}
                {searchObjects !== null && objectsObjectsLength !== 0
                  ? Object.keys(searchObjects).map((prop, index) => {
                      return (
                        <ObjectsDisplayContainer
                          key={index}
                          name={prop}
                          items={searchObjects[prop]}
                          searchTerm={searchTerm}
                          setShowSearchResults={setShowSearchResults}
                          showSearchResults={showSearchResults}
                          setObjectsObjectsLength={setObjectsObjectsLength}
                        />
                      );
                    })
                  : null}
                {objectsObjectsLength === 0 ||
                (objectsObjectsLength === null &&
                  Object.keys(objects.sorted_objects_categories).length ===
                    0) ? (
                  <div className="no-data-info">
                    <img src={NoData} alt="" />
                    <span>{t("objects:no-data-info")}</span>
                    <span>{t("home:no-data-sub-info")}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  objects: state.objects,
});

const mapDispatchToProps = (dispatch) => ({
  getAllObjects: () => dispatch(getAllObjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Objects);
