import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AccordionPlatforms from "../Accordion/AccordionPlatforms";
import AccordionRoomStairs from "../Accordion/AccordionRoomStairs";

import CategoryGallery from "../../CategoryGallery/CategoryGallery";

import MovementDysfunctionProfileWhite from "../../../assets/profiles/movement-dysfunction-profile-pictogram-white.svg";
import BlindDysfunctionProfileWhite from "../../../assets/profiles/blind-profile-pictogram-white.svg";
import VisuallyImpairedProfileWhite from "../../../assets/profiles/visually-impaired-profile-pictogram-white.svg";
import HearingDysfunctionProfileWhite from "../../../assets/profiles/hearing-dysfunction-profile-pictogram-white.svg";
import CognitiveDifficultiesProfileWhite from "../../../assets/profiles/cognitive-difficulties-profile-pictogram-white.svg";
import SensorySensitivityProfileWhite from "../../../assets/profiles/sensory-sensitivity-profile-pictogram-white.svg";
import ProfileWhite from "../../../assets/profiles/profile-pictogram-white.svg";

import RightNavigationArrow from "../../../assets/right-navigation-arrow.svg";
import RightArrow from "../../../assets/arrow-forward.svg";

function ClickableItemLinkRoomDetails({ mode }) {
  const { id, levelId, regionId, roomId } = useParams();

  const buildingName = useSelector(
    (state) => state?.buildingDetails?.general?.translations?.pl?.name
  );

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const [item, setItem] = useState(null);

  const [level, setLevel] = useState(null);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    let jumpLink;

    setTimeout(() => {
      if (mode) {
        jumpLink = document.getElementById(`object-category-header-${roomId}`);
      } else {
        jumpLink = document.getElementById(
          `object-category-mobile-header-${roomId}`
        );
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/rooms/?id=${roomId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach(async (data) => {
          setItem(data);
        });
      });

    return () => {
      controller.abort();
    };
  }, [roomId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (levelId && regionId) {
      fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLevel(data[0]);
        });

      fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRegion(data[0]);
        });
    }

    return () => {
      controller.abort();
    };
  }, [levelId, regionId]);

  useEffect(() => {
    /* Setting the title of the page. */
    if (item) {
      document.title = t("building__room:single_title", {
        room_name: item.translations[cookies.i18next].name,
        building_name: buildingName,
      });
    }
  }, [item]);

  const checker = (arr, target) => target.some((r) => arr.includes(r));

  const getProfilePictogram = (element) => {
    switch (element) {
      case 0:
        return MovementDysfunctionProfileWhite;
      case 2:
        return BlindDysfunctionProfileWhite;
      case 3:
        return VisuallyImpairedProfileWhite;
      case 4:
        return HearingDysfunctionProfileWhite;
      case 5:
        return CognitiveDifficultiesProfileWhite;
      case 6:
        return SensorySensitivityProfileWhite;
      default:
        return ProfileWhite;
    }
  };

  return (
    <>
      {item ? (
        <>
          <section aria-label={t("building__room:category_header")}>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                {roomId && (
                  <>
                    <li>
                      <Link
                        to={{
                          pathname: `${t("routes:building_rooms", {
                            id: id,
                          })}`,
                        }}
                      >
                        {t("building__room:category_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                {regionId && (
                  <>
                    <li>
                      <Link
                        to={`${t("routes:building_structure", {
                          id: id,
                        })}`}
                      >
                        {t("building__region:building_structure_header")}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_level", {
                          id: id,
                          level_id: levelId,
                        })}`}
                      >
                        {level?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                    <li>
                      <Link
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                        })}`}
                      >
                        {region?.translations[cookies.i18next]?.name}
                      </Link>
                      <img src={RightNavigationArrow} alt="" />
                    </li>
                  </>
                )}
                <li>{item.translations[cookies.i18next].name}</li>
              </ul>
            </nav>
            <h3
              id={
                mode
                  ? `object-category-header-${roomId}`
                  : `object-category-mobile-header-${roomId}`
              }
              className="object-category-header-name mt-3"
              tabIndex={-1}
            >
              {item.translations[cookies.i18next].name}
            </h3>
            <ul>
              {(() => {
                return (
                  <>
                    {item.translations.pl.comment === "" ? null : (
                      <li className="text">
                        {item.translations[cookies.i18next].comment}
                      </li>
                    )}
                    {item.translations[cookies.i18next].location ===
                    "" ? null : (
                      <li className="text">
                        {t("building__room:location", {
                          location: item.translations[cookies.i18next].location,
                        })}
                      </li>
                    )}
                    {item.are_entrances === "Unknown" ? null : (
                      <li className="text">
                        {item.are_entrances === "True"
                          ? t("building__room:are_entrances__true", {
                              are_entrances_comment:
                                item.translations[cookies.i18next]
                                  .are_entrances_comment,
                            })
                          : t("building__room:are_entrances__false", {
                              are_entrances_comment:
                                item.translations[cookies.i18next]
                                  .are_entrances_comment,
                            })}
                      </li>
                    )}
                    {item.is_use_restricted === "Unknown" ? null : (
                      <li className="text">
                        {item.is_use_restricted === "True"
                          ? t("building__room:is_use_restricted__true", {
                              is_use_restricted_comment:
                                item.translations[cookies.i18next]
                                  .is_use_restricted_comment,
                            })
                          : t("building__room:is_use_restricted__false", {
                              is_use_restricted_comment:
                                item.translations[cookies.i18next]
                                  .is_use_restricted_comment,
                            })}
                      </li>
                    )}
                    {item.translations[cookies.i18next]
                      .working_days_and_hours === "" ? null : (
                      <li className="text">
                        {t("building__room:working_days_and_hours", {
                          working_days_and_hours:
                            item.translations[cookies.i18next]
                              .working_days_and_hours,
                        })}
                      </li>
                    )}
                    {item.are_seats === "Unknown" ? null : (
                      <li className="text">
                        {item.are_seats === "True"
                          ? t("building__room:are_seats__true", {
                              are_seats_comment:
                                item.translations[cookies.i18next]
                                  .are_seats_comment,
                            })
                          : t("building__room:are_seats__false", {
                              are_seats_comment:
                                item.translations[cookies.i18next]
                                  .are_seats_comment,
                            })}
                      </li>
                    )}
                    {item.is_room_causes_echo === "Unknown" ? null : (
                      <li className="text">
                        {item.is_room_causes_echo === "True"
                          ? t("building__room:is_room_causes_echo__true", {
                              is_room_causes_echo_comment:
                                item.translations[cookies.i18next]
                                  .is_room_causes_echo_comment,
                            })
                          : t("building__room:is_room_causes_echo__false", {
                              is_room_causes_echo_comment:
                                item.translations[cookies.i18next]
                                  .is_room_causes_echo_comment,
                            })}
                      </li>
                    )}
                    {item.is_noisy === "Unknown" ? null : (
                      <li className="text">
                        {item.is_noisy === "True"
                          ? t("building__room:is_noisy__true", {
                              is_noisy_comment:
                                item.translations[cookies.i18next]
                                  .is_noisy_comment,
                            })
                          : t("building__room:is_noisy__false", {
                              is_noisy_comment:
                                item.translations[cookies.i18next]
                                  .is_noisy_comment,
                            })}
                      </li>
                    )}
                  </>
                );
              })()}
            </ul>
            {(() => {
              if (cookies.value.length > 0) {
                return (
                  <>
                    <article
                      className="object-category-profile-description"
                      aria-label={t("general:profile_description_label")}
                    >
                      <div className="object-category-profile-sign">
                        <img
                          className="object-category-profile-sign__icon"
                          src={
                            cookies.value.length > 1
                              ? ProfileWhite
                              : getProfilePictogram(cookies.value[0])
                          }
                          alt=""
                        />
                        <span className="object-category-profile-sign__label">
                          {t("general:profile_description_title")}
                        </span>
                      </div>
                      <ul>
                        {(() => {
                          return (
                            <>
                              {checker(cookies.value, [0, 2, 3]) ? (
                                item.is_one_level_floor === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_one_level_floor === "True"
                                      ? t(
                                          "building__room:is_one_level_floor__true",
                                          {
                                            is_one_level_floor_comment:
                                              item.translations[cookies.i18next]
                                                .is_one_level_floor_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:is_one_level_floor__false",
                                          {
                                            is_one_level_floor_comment:
                                              item.translations[cookies.i18next]
                                                .is_one_level_floor_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.are_dimensions_allow_free_movement ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_dimensions_allow_free_movement ===
                                    "True"
                                      ? t(
                                          "building__room:are_dimensions_allow_free_movement__true",
                                          {
                                            are_dimensions_allow_free_movement_comment:
                                              item.translations[cookies.i18next]
                                                .are_dimensions_allow_free_movement_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_dimensions_allow_free_movement__false",
                                          {
                                            are_dimensions_allow_free_movement_comment:
                                              item.translations[cookies.i18next]
                                                .are_dimensions_allow_free_movement_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.are_places_for_wheelchairs ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_places_for_wheelchairs === "True"
                                      ? t(
                                          "building__room:are_places_for_wheelchairs__true",
                                          {
                                            are_places_for_wheelchairs_comment:
                                              item.translations[cookies.i18next]
                                                .are_places_for_wheelchairs_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_places_for_wheelchairs__false",
                                          {
                                            are_places_for_wheelchairs_comment:
                                              item.translations[cookies.i18next]
                                                .are_places_for_wheelchairs_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.are_places_accessible_from_main_entrance ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_places_accessible_from_main_entrance ===
                                    "True"
                                      ? t(
                                          "building__room:are_places_accessible_from_main_entrance__true",
                                          {
                                            are_places_accessible_from_main_entrance_comment:
                                              item.translations[cookies.i18next]
                                                .are_places_accessible_from_main_entrance_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_places_accessible_from_main_entrance__false",
                                          {
                                            are_places_accessible_from_main_entrance_comment:
                                              item.translations[cookies.i18next]
                                                .are_places_accessible_from_main_entrance_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [2, 3]) ? (
                                item.are_any_dangerous_elements ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_any_dangerous_elements === "True"
                                      ? t(
                                          "building__room:are_any_dangerous_elements__true",
                                          {
                                            are_any_dangerous_elements_comment:
                                              item.translations[cookies.i18next]
                                                .are_any_dangerous_elements_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_any_dangerous_elements__false",
                                          {
                                            are_any_dangerous_elements_comment:
                                              item.translations[cookies.i18next]
                                                .are_any_dangerous_elements_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.is_generally_available_desk ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_generally_available_desk === "True"
                                      ? t(
                                          "building__room:is_generally_available_desk__true",
                                          {
                                            is_generally_available_desk_comment:
                                              item.translations[cookies.i18next]
                                                .is_generally_available_desk_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:is_generally_available_desk__false",
                                          {
                                            is_generally_available_desk_comment:
                                              item.translations[cookies.i18next]
                                                .is_generally_available_desk_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.is_space_under_counter ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_space_under_counter === "True"
                                      ? t(
                                          "building__room:is_space_under_counter__true",
                                          {
                                            is_space_under_counter_comment:
                                              item.translations[cookies.i18next]
                                                .is_space_under_counter_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:is_space_under_counter__false",
                                          {
                                            is_space_under_counter_comment:
                                              item.translations[cookies.i18next]
                                                .is_space_under_counter_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0]) ? (
                                item.are_electrical_outlets ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_electrical_outlets === "True"
                                      ? t(
                                          "building__room:are_electrical_outlets__true",
                                          {
                                            are_electrical_outlets_comment:
                                              item.translations[cookies.i18next]
                                                .are_electrical_outlets_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_electrical_outlets__false",
                                          {
                                            are_electrical_outlets_comment:
                                              item.translations[cookies.i18next]
                                                .are_electrical_outlets_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_floor_room_contrast ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_floor_room_contrast === "True"
                                      ? t(
                                          "building__room:is_good_floor_room_contrast__true",
                                          {
                                            is_good_floor_room_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_floor_room_contrast_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:is_good_floor_room_contrast__false",
                                          {
                                            is_good_floor_room_contrast_comment:
                                              item.translations[cookies.i18next]
                                                .is_good_floor_room_contrast_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_good_lit === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_good_lit === "True"
                                      ? t("building__room:is_good_lit__true", {
                                          is_good_lit_comment:
                                            item.translations[cookies.i18next]
                                              .is_good_lit_comment,
                                        })
                                      : t("building__room:is_good_lit__false", {
                                          is_good_lit_comment:
                                            item.translations[cookies.i18next]
                                              .is_good_lit_comment,
                                        })}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3, 6]) ? (
                                item.is_floor_shiny === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_floor_shiny === "True"
                                      ? t(
                                          "building__room:is_floor_shiny__true",
                                          {
                                            is_floor_shiny_comment:
                                              item.translations[cookies.i18next]
                                                .is_floor_shiny_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:is_floor_shiny__false",
                                          {
                                            is_floor_shiny_comment:
                                              item.translations[cookies.i18next]
                                                .is_floor_shiny_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [3]) ? (
                                item.is_blackboard === "Unknown" ? null : (
                                  <li className="text">
                                    {item.is_blackboard === "True"
                                      ? t(
                                          "building__room:is_blackboard__true",
                                          {
                                            is_blackboard_comment:
                                              item.translations[cookies.i18next]
                                                .is_blackboard_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:is_blackboard__false",
                                          {
                                            is_blackboard_comment:
                                              item.translations[cookies.i18next]
                                                .is_blackboard_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [0, 2]) ? (
                                item.are_movable_elements ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_movable_elements === "True"
                                      ? t(
                                          "building__room:are_movable_elements__true",
                                          {
                                            are_movable_elements_comment:
                                              item.translations[cookies.i18next]
                                                .are_movable_elements_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_movable_elements__false",
                                          {
                                            are_movable_elements_comment:
                                              item.translations[cookies.i18next]
                                                .are_movable_elements_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [6]) ? (
                                item.are_flashing_devices ===
                                "Unknown" ? null : (
                                  <li className="text">
                                    {item.are_flashing_devices === "True"
                                      ? t(
                                          "building__room:are_flashing_devices__true",
                                          {
                                            are_flashing_devices_comment:
                                              item.translations[cookies.i18next]
                                                .are_flashing_devices_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:are_flashing_devices__false",
                                          {
                                            are_flashing_devices_comment:
                                              item.translations[cookies.i18next]
                                                .are_flashing_devices_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                              {checker(cookies.value, [5]) ? (
                                item.has_induction_loop === "Unknown" ? null : (
                                  <li className="text">
                                    {item.has_induction_loop === "True"
                                      ? t(
                                          "building__room:has_induction_loop__true",
                                          {
                                            has_induction_loop_comment:
                                              item.translations[cookies.i18next]
                                                .has_induction_loop_comment,
                                          }
                                        )
                                      : t(
                                          "building__room:has_induction_loop__false",
                                          {
                                            has_induction_loop_comment:
                                              item.translations[cookies.i18next]
                                                .has_induction_loop_comment,
                                          }
                                        )}
                                  </li>
                                )
                              ) : null}
                            </>
                          );
                        })()}
                      </ul>
                    </article>
                  </>
                );
              }
            })()}
            <ul className="c-clickable-list">
              {item.doors && item.doors.length > 0
                ? item.doors.map((door) => (
                    <li key={door} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={
                          roomId
                            ? {
                                pathname: `${t(
                                  "routes:building_room_door_details",
                                  {
                                    id: id,
                                    room_id: roomId,
                                    door_id: door,
                                  }
                                )}`,
                              }
                            : {
                                pathname: `${t(
                                  "routes:building_region_room_door_details",
                                  {
                                    id: id,
                                    level_id: levelId,
                                    region_id: regionId,
                                    room_id: roomId,
                                    door_id: door,
                                  }
                                )}`,
                              }
                        }
                      >
                        <span>{t("building__door:label")}</span>{" "}
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            {item.platforms && item.platforms.length > 0
              ? item.platforms.map((platform, index) => (
                  <AccordionPlatforms
                    id={platform}
                    parentObjectName={"room"}
                    parentObjectIndex={roomId}
                    platformIndex={index}
                    key={platform}
                    mode={mode}
                  />
                ))
              : null}
            {item.room_stairs && item.room_stairs.length > 0
              ? item.room_stairs.map((room_stairs, index) => (
                  <AccordionRoomStairs
                    id={room_stairs}
                    parentObjectName={"room"}
                    parentObjectIndex={roomId}
                    roomStairsIndex={index}
                    key={room_stairs}
                    mode={mode}
                  />
                ))
              : null}
            {item.images && item.images.length > 0 ? (
              <CategoryGallery item={item.images} />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
}

export default ClickableItemLinkRoomDetails;
