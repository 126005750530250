import { useState, useEffect, useRef } from "react";

import "./Select.css";

import SelectDownArrow from "../../assets/select-down-arrow.svg";
import SelectUpArrow from "../../assets/select-up-arrow.svg";

function Select({ buildings, change }) {
  const dropdownRef = useRef(null);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    change(buildings[index]);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : buildings.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption === buildings.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="l-select-container" ref={dropdownRef}>
      <button
        type="button"
        aria-expanded={isOptionsOpen}
        className={`c-select-box__btn ${isOptionsOpen ? "expanded" : ""}`}
        onClick={toggleOptions}
        onKeyDown={handleListKeyDown}
      >
        {buildings[selectedOption]}
        {isOptionsOpen ? (
          <img src={SelectUpArrow} alt="" />
        ) : (
          <img src={SelectDownArrow} alt="" />
        )}
      </button>
      <ul
        className={`c-select-box__listbox ${isOptionsOpen ? "show" : ""}`}
        onKeyDown={handleListKeyDown}
      >
        {buildings.map((option, index) => (
          <li
            id={option}
            key={option}
            className="c-select-box__option"
            aria-selected={selectedOption === index}
            onKeyDown={handleKeyDown(index)}
            onClick={() => {
              setSelectedThenCloseDropdown(index);
            }}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Select;
