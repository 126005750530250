import ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

import "./Modal.css";

import EmailConfirmationAlertMessage from "../EmailConfirmationAlertMessage/EmailConfirmationAlertMessage";

import Close from "../../assets/close.svg";

function Modal({ closeModal }) {
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const formRef = useRef(null);

  const [isNotificationInduced, setIsNotificationInduced] = useState(false);
  const [type, setType] = useState("success");
  const [isVerified, setIsVerified] = useState(false);

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  let activeIndex = -1;

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);

    inputRef.current?.focus();

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current?.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      const activeElement = document.activeElement;
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("mousedown", handleClickOutside);
      activeElement.focus();
    };
  }, []);

  const handleTab = (e) => {
    const focusableElements = modalRef.current?.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="email"], input[type="radio"], input[type="checkbox"], select'
    );
    let total = focusableElements.length;

    if (!e.shiftKey) {
      activeIndex + 1 === total ? (activeIndex = 0) : (activeIndex += 1);
      focusableElements[activeIndex].focus();
      return e.preventDefault();
    }

    if (e.shiftKey) {
      activeIndex - 1 < 0 ? (activeIndex = total - 1) : (activeIndex -= 1);
      focusableElements[activeIndex].focus();
      return e.preventDefault();
    }
  };

  const handleEscape = (e) => {
    if (e.key === "Escape") closeModal();
  };

  const keyListenersMap = new Map([
    [27, handleEscape],
    [9, handleTab],
  ]);

  const handleKeydown = (e) => {
    const listener = keyListenersMap.get(e.keyCode);
    return listener && listener(e);
  };

  const handleRecaptchaChange = (value) => {
    setIsVerified(true);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setIsNotificationInduced(true);
          setType("success");
        },
        (error) => {
          setIsNotificationInduced(true);
          setType("error");
        }
      );
  };

  return ReactDOM.createPortal(
    <div
      className="c-modal-container"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-header-label"
    >
      <div className="c-modal" ref={modalRef}>
        <div className="c-modal__header">
          <p id="modal-header-label">{t("modal:modal-header")}</p>
        </div>
        <div className="c-modal__description">
          <p>{t("modal:modal-description")}</p>
        </div>
        <div className="c-modal__form">
          <form ref={formRef} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="form-address-email">
                {t("modal:address-email-form-label")} <span>*</span>:
              </label>
              <input
                type="email"
                className="input-form"
                name="user_email"
                id="form-address-email"
                ref={inputRef}
                placeholder={t("modal:address-email-input-placeholder")}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="form-description-problem">
                {t("modal:description-problem-form-label")} <span>*</span>:
              </label>
              <textarea
                className="input-form"
                id="form-description-problem"
                name="message"
                rows="3"
                placeholder={t("modal:description-problem-input-placeholder")}
                required
              ></textarea>
            </div>
            {isNotificationInduced && (
              <EmailConfirmationAlertMessage
                type={type === "success" ? "success" : "error"}
                closeModal={closeModal}
              />
            )}
            <div className="form-group">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                onChange={handleRecaptchaChange}
              />
            </div>
            <div className="form-group">
              <p>
                <span>*</span> - {t("modal:required-field-label")}
              </p>
            </div>
            <div className="l-modal-btn-container">
              <button
                className="c-btn--modal-submit"
                type="submit"
                aria-label={t("modal:submit-button-label")}
                disabled={!isVerified}
              >
                {t("modal:submit-button-label")}
              </button>
            </div>
          </form>
        </div>
        <button
          className="c-btn--modal-close"
          type="button"
          aria-label={t("modal:close-button-label")}
          onClick={closeModal}
        >
          <img src={Close} alt="" />
        </button>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default Modal;
