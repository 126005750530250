import { useState, useEffect, useRef } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { setMatchParams, getAllObjectDetails } from "./duck/operations.js";

import DropdownProfile from "../DropdownProfile/DropdownProfile";

import ObjectGeneralDetails from "../ObjectDetailsComponents/ObjectGeneralDetails";
import ObjectAccessibleInfoDetails from "../ObjectDetailsComponents/ObjectAccessibleInfoDetails";
import ObjectCategoryDetails from "../ObjectDetailsComponents/ObjectCategoryDetails";

import NotFound from "../NotFound/NotFound";
import ObjectSidebar from "../ObjectSidebar/ObjectSidebar";
import Modal from "../Modal/Modal";
import Loader from "../Loader/Loader";

import "./ObjectDetails.css";

import RightArrow from "../../assets/right-button-arrow-forward.svg";

const ObjectDetails = ({
  match,
  objectDetails,
  setMatchParams,
  getAllObjectDetails,
}) => {
  /* The above code is using the useState hook to create a state variable called isModalVisible and a
  function called setIsModalVisible. */
  const [isModalVisible, setIsModalVisible] = useState(false);

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  /* Getting the history object from the react-router-dom library. */
  const history = useHistory();

  const [isActive, setActive] = useState(true);

  const sidebarRef = useRef(null);

  useEffect(() => {
    /* Setting the match parameters. */
    setMatchParams(match);

    const objectDetails = getAllObjectDetails();

    objectDetails.then((result) => {
      if (result) {
        setTimeout(() => {
          sidebarRef.current?.focus();
          sidebarRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 300);
      } else {
        history.push("/404");
      }
    });
  }, []);

  return (
    <>
      {Object.keys(objectDetails.general).length !== 0 ? (
        <main id="content">
          {objectDetails.loading ? (
            <Loader type={"object"} />
          ) : (
            <>
              <div className="container">
                <div className="d-flex justify-content-between">
                  <h2 className="object-name--hide">
                    {objectDetails.general.translations[cookies.i18next].name}
                  </h2>
                  <DropdownProfile />
                </div>
                <div className="row pt-2 d-flex justify-content-center mb-4">
                  <div
                    className={
                      isActive
                        ? "col-12 col-sm-12 col-md-12 col-lg-3 d-flex flex-column align-items-center c-object-sidebar-container"
                        : "col-12 col-sm-12 col-md-12 col-lg-2 d-flex flex-column align-items-center c-object-sidebar-container"
                    }
                  >
                    {objectDetails.subsidebar && (
                      <ObjectSidebar
                        objectDetails={objectDetails}
                        subObjectSidebarItems={objectDetails.subsidebar}
                        setActive={setActive}
                        isActive={isActive}
                        ref={sidebarRef}
                      />
                    )}
                    {(isActive || window.innerWidth < 991) && (
                      <button
                        className="btn--inform-us"
                        type="button"
                        onClick={() => setIsModalVisible(true)}
                      >
                        <span>
                          {t("general:inform_us_button_label_part_1")}
                          <br />
                          {t("general:inform_us_button_label_part_2")}
                        </span>
                        <img src={RightArrow} alt="" />
                      </button>
                    )}
                    {isModalVisible && (
                      <Modal closeModal={() => setIsModalVisible(false)} />
                    )}
                  </div>
                  <div
                    className={
                      isActive
                        ? "col-12 col-sm-12 col-md-12 col-lg-8 c-object-content-container"
                        : "col-12 col-sm-12 col-md-12 col-lg-9 c-object-content-container"
                    }
                  >
                    <Switch>
                      <Route
                        exact
                        path={`${t("routes:object_details_no_id")}:id/`}
                        render={() => (
                          <Redirect
                            to={`${t("routes:object_general_information", {
                              id: match,
                            })}`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`${t("routes:object_general_information", {
                          id: match,
                        })}`}
                      >
                        <ObjectGeneralDetails item={objectDetails} mode={1} />
                      </Route>
                      <Route
                        exact
                        path={`${t("routes:object_accessible_information", {
                          id: match,
                        })}`}
                      >
                        <ObjectAccessibleInfoDetails
                          item={objectDetails}
                          mode={1}
                        />
                      </Route>
                      {objectDetails.categories &&
                        objectDetails.categories.map((data) => (
                          <Route
                            key={data.id}
                            exact
                            path={`${t("routes:object_category_details", {
                              id: match,
                              category_name: data.name
                                .toLowerCase()
                                .replace(/[^\w ]+/g, "")
                                .replace(/ +/g, "-"),
                              category_id: data.id,
                            })}`}
                          >
                            <ObjectCategoryDetails
                              item={objectDetails}
                              id={data.id}
                              mode={1}
                            />
                          </Route>
                        ))}
                      <Route
                        path={`/:locale(pl|en)?${t("routes:404")}`}
                        component={NotFound}
                      />
                      <Redirect
                        from="*"
                        to={`/:locale(pl|en)?${t("routes:404")}`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </>
          )}
        </main>
      ) : null}
    </>
  );
};

/**
 * MapStateToProps is a function that takes in the state and the props of the component and returns an
 * object that will be merged with the component's props.
 * @param state - The state of the Redux store.
 * @param ownProps - The props passed to the component.
 */
const mapStateToProps = (state, ownProps) => ({
  objectDetails: state.objectDetails,
  match: ownProps.match.params.id,
});

const mapDispatchToProps = (dispatch) => ({
  setMatchParams: (match) => dispatch(setMatchParams(match)),
  getAllObjectDetails: () => dispatch(getAllObjectDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDetails);
