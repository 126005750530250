import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, useHistory } from "react-router-dom";

import ImageContainer from "../ImageContainer/ImageContainer";

import RightArrow from "../../assets/arrow-forward.svg";
import RightNavigationArrow from "../../assets/right-navigation-arrow.svg";

function BuildingLevelDetails({ item, mode }) {
  const { id, levelId } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const history = useHistory();

  const [level, setLevel] = useState(null);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Fetching data from the API. */
    fetch(
      `${process.env.REACT_APP_API_URL}/levels/?building=${id}&id=${levelId}`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.forEach(async (data) => {
            setLevel(data);

            setRegions([]);

            data.regions.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${data}`, {
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setRegions((regions) => [...regions, ...data]);
                });
            });
          });
        } else {
          history.push("/404");
        }
      });

    return () => {
      controller.abort();
    };
  }, [levelId]);

  useEffect(() => {
    setTimeout(() => {
      /* Setting the title of the page. */
      document.title = t("building__level:title", {
        level_name: level && level.translations[cookies.i18next].name,
        building_name: item.general.translations[cookies.i18next].name,
      });

      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, [level]);

  return (
    <>
      {level ? (
        <>
          <section aria-label={`${level.translations[cookies.i18next].name}`}>
            <span
              id={mode ? "js-jump-link" : "js-jump-link-mobile"}
              className="sr-only"
              tabIndex={0}
            >
              {t("building__level:startup_message", {
                level_name: level.translations[cookies.i18next].name,
                building_name: item.general.translations[cookies.i18next].name,
              })}
            </span>
            <nav
              className="navigation-area"
              aria-label={t("general:nav_area_label")}
            >
              <ul>
                <li>
                  <Link
                    to={`${t("routes:building_structure", {
                      id: id,
                    })}`}
                  >
                    {t("building__level:building_structure_header")}
                  </Link>
                  <img src={RightNavigationArrow} alt="" />
                </li>
                <li>
                  <Link
                    className="active"
                    to={`${t("routes:building_level", {
                      id: id,
                      level_id: levelId,
                    })}`}
                  >
                    {level.translations[cookies.i18next].name}
                  </Link>
                </li>
              </ul>
            </nav>
            <h3 className="object-category-header-name mt-3">
              {level.translations[cookies.i18next].name}
            </h3>
            {level.images.length > 0 ? (
              <ImageContainer item={level.images[0]} />
            ) : null}
            <p
              role="heading"
              aria-level="4"
              className="object-category-subheader-name"
            >
              {t("building__level:room_layout_header")}
            </p>
            <p className="text">
              {level.translations[cookies.i18next].room_numbers_range}
            </p>
            <ul className="c-clickable-list">
              {regions &&
                regions.length > 0 &&
                regions
                  .sort((a, b) => a.order_number - b.order_number)
                  .map((region) => (
                    <li key={region.id} className="c-clickable-list__item">
                      <Link
                        className="c-clickable-list__link"
                        to={`${t("routes:building_region", {
                          id: id,
                          level_id: levelId,
                          region_id: region.id,
                        })}`}
                      >
                        <span>{region.translations[cookies.i18next].name}</span>{" "}
                        <img src={RightArrow} alt="" />
                      </Link>
                    </li>
                  ))}
            </ul>
          </section>
        </>
      ) : null}
    </>
  );
}

export default BuildingLevelDetails;
