import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import "./SurroundingCard.css";

import RightMoreButton from "../../assets/left-more-button.svg";

function SurroundingCard(item) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* Setting the image state to null. */
  const [image, setImage] = useState(null);

  /* Fetching the image from the API. */
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Checking if the item has an image. */
    if (typeof item.images[0] !== "undefined") {
      fetch(`${process.env.REACT_APP_API_URL}/images/${item.images[0]}`, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          /* Setting the image state to the data that is returned from the API. */
          setImage(data);
        });
    }
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {item && image ? (
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 l-surrounding-cards-container">
          <Link
            to={`${t("routes:building_details", {
              id: item.id,
            })}`}
            aria-label={`${t("general:read_more_button")} ${
              item.translations[cookies.i18next].name
            }`}
          >
            <div className="c-card">
              <img
                className="c-card__img"
                sizes="(max-width: 991px) 960px, (min-width: 991px) and (max-width: 1199px) 320px, (min-width: 1199px) 960px"
                srcSet={`${image.image_320w} 320w,
                              ${image.image_960w} 960w`}
                src={image.image_960w}
                alt=""
              />
              <div className="c-card__body">
                <h3 className="c-card__title">
                  {item.translations[cookies.i18next].name}
                </h3>
                <p>{item.translations[cookies.i18next].address}</p>
                <div className="c-card__btn-more">
                  <img src={RightMoreButton} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
}

export default SurroundingCard;
