import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import { connect } from "react-redux";
import {
  setTyphlomapAreaMatchParams,
  getAllTyphlomapAreasDetails,
} from "./duck/operations.js";

import "./TyphlomapAreas.css";

import LeftArrow from "../../assets/left-arrow-forward.svg";
import RightArrow from "../../assets/right-arrow-forward.svg";

/**
 * It's a React component that renders a list of areas.
 */
const TyphlomapAreas = ({
  match,
  typhlomapAreaDetails,
  setTyphlomapAreaMatchParams,
  getAllTyphlomapAreasDetails,
}) => {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  /* Getting the history object from the react-router-dom library. */
  const history = useHistory();

  /* Setting the state of the component. */
  const [count, setCount] = useState(null);

  useEffect(() => {
    /* Setting the match params in the redux store. */
    setTyphlomapAreaMatchParams(match);
    /* Getting the details of the typhlomap areas. */
    const typhlomapsAreasDetails = getAllTyphlomapAreasDetails();
    /* Checking if the result is true, if it is, it is focusing on the element with the id of
    js-object-name. If it is not, it is pushing the user to the 404 page. */
    typhlomapsAreasDetails.then((result) => {
      if (result) {
        setTimeout(() => {
          const targetElement = document.getElementById("js-object-name");
          targetElement.focus();
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 300);
      } else {
        history.push("/404");
      }
    });
  }, [count]);

  const nextArea = () => {
    /* Getting the index of the current area in the array of all areas. */
    let index = typhlomapAreaDetails.list.indexOf(
      parseInt(typhlomapAreaDetails.match)
    );

    /* Checking if the index is less than the length of the array minus 1. If it is, it is pushing the
    next element of the array to the history. If it is not, it is pushing the current element of the
    array to the history. */
    if (index < typhlomapAreaDetails.list.length - 1) {
      history.push(
        `${t("routes:area", {
          id: typhlomapAreaDetails.list[index + 1],
        })}`
      );
      setCount(index + 1);
    } else if (index === typhlomapAreaDetails.list.length - 1) {
      history.push(
        `${t("routes:area", {
          id: typhlomapAreaDetails.list[index],
        })}`
      );
      setCount(index);
    }
  };

  const prevArea = () => {
    /* Getting the index of the current area in the array of all areas. */
    let index = typhlomapAreaDetails.list.indexOf(
      parseInt(typhlomapAreaDetails.match)
    );
    /* Setting the state of the component. */
    setCount(index);

    /* Checking if the index is 0, if it is, it is pushing the first element of the array to the
    history. If it is not, it is pushing the previous element of the array to the history. */
    if (index === 0) {
      history.push(
        `${t("routes:area", {
          id: typhlomapAreaDetails.list[0],
        })}`
      );
      setCount(0);
    } else {
      history.push(
        `${t("routes:area", {
          id: typhlomapAreaDetails.list[index - 1],
        })}`
      );
      setCount(count - 1);
    }
  };

  return (
    <>
      {Object.keys(typhlomapAreaDetails.area).length !== 0 ? (
        <main id="content">
          <div className="container l-typhlomaps-area-container">
            <div className="row p-2">
              <div className="text">
                <h2 id="js-object-name" className="text--header">
                  {typhlomapAreaDetails.area.translations[cookies.i18next].name}
                </h2>
                {parse(
                  String(
                    typhlomapAreaDetails.area.translations[cookies.i18next]
                      .extended_description
                  )
                )}
                <Link
                  to={`${t("routes:building_details", {
                    id: typhlomapAreaDetails.building,
                  })}`}
                >
                  {t("typhlomap_areas:go-to-building-description", {
                    building_name: typhlomapAreaDetails.building_name,
                  })}
                </Link>
              </div>
            </div>
            <div className="row justify-content-between p-3">
              <button
                className={
                  typhlomapAreaDetails.index === 0
                    ? "btn-area btn-area--disabled"
                    : "btn-area"
                }
                disabled={typhlomapAreaDetails.index === 0}
                onClick={prevArea}
                aria-label={t("typhlomap_areas:button-prev-area-label")}
              >
                <img src={LeftArrow} alt="" />{" "}
                <span>{t("typhlomap_areas:button-prev-area-label")}</span>
              </button>
              <button
                className={
                  typhlomapAreaDetails.index ===
                  typhlomapAreaDetails.list.length - 1
                    ? "btn-area btn-area--disabled"
                    : "btn-area"
                }
                disabled={
                  typhlomapAreaDetails.index ===
                  typhlomapAreaDetails.list.length - 1
                }
                onClick={nextArea}
                aria-label={t("typhlomap_areas:button-next-area-label")}
              >
                <span>{t("typhlomap_areas:button-next-area-label")}</span>{" "}
                <img src={RightArrow} alt="" />
              </button>
            </div>
          </div>
        </main>
      ) : null}
    </>
  );
};

/**
 * MapStateToProps is a function that takes in the state and the props of the component and returns an
 * object that will be merged with the component's props.
 * @param state - the state of the store
 * @param ownProps - The props passed to the component.
 */
const mapStateToProps = (state, ownProps) => ({
  typhlomapAreaDetails: state.typhlomapAreaDetails,
  match: ownProps.match.params.item,
});

/**
 * MapDispatchToProps is a function that returns an object with functions that dispatch actions to the
 * store.
 * @param dispatch - A function of dispatch method from the Redux store. You call store.dispatch to
 * dispatch an action. This is the only way to trigger a state change.
 */
const mapDispatchToProps = (dispatch) => ({
  setTyphlomapAreaMatchParams: (match) =>
    dispatch(setTyphlomapAreaMatchParams(match)),
  getAllTyphlomapAreasDetails: () => dispatch(getAllTyphlomapAreasDetails()),
});

/* Exporting the component with the router and the redux store. */
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TyphlomapAreas)
);
