const ADD_BUILDING_MATCH = "ADD_BUILDING_MATCH";
const SET_LOADING_STATE = "SET_LOADING_STATE";
const RESET_LOADING_STATE = "RESET_LOADING_STATE";
const ADD_BUILDING_DETAILS = "ADD_BUILDING_DETAILS";
const RESET_BUILDING_DETAILS = "RESET_BUILDING_DETAILS";
const ADD_SURROUNDING_OBJECTS = "ADD_SURROUNDING_OBJECTS";
const RESET_SURROUNDING_OBJECTS = "RESET_SURROUNDING_OBJECTS";
const ADD_BUILDING_IMAGES = "ADD_BUILDING_IMAGES";
const RESET_BUILDING_IMAGES = "RESET_BUILDING_IMAGES";
const ADD_BUILDING_SURROUNDING = "ADD_BUILDING_SURROUNDING";
const RESET_BUILDING_SURROUNDING = "RESET_BUILDING_SURROUNDING";
const ADD_BUILDING_ACCESS = "ADD_BUILDING_ACCESS";
const RESET_BUILDING_ACCESS = "RESET_BUILDING_ACCESS";
const ADD_BUILDING_LODGES = "ADD_BUILDING_LODGES";
const RESET_BUILDING_LODGES = "RESET_BUILDING_LODGES";
const ADD_BUILDING_DRESSING_ROOMS = "ADD_BUILDING_DRESSING_ROOMS";
const RESET_BUILDING_DRESSING_ROOMS = "RESET_BUILDING_DRESSING_ROOMS";
const ADD_BUILDING_INFORMATION_POINTS = "ADD_BUILDING_INFORMATION_POINTS";
const RESET_BUILDING_INFORMATION_POINTS = "RESET_BUILDING_INFORMATION_POINTS";
const ADD_BUILDING_ENTRANCES = "ADD_BUILDING_ENTRANCES";
const RESET_BUILDING_ENTRANCES = "RESET_BUILDING_ENTRANCES";
const ADD_BUILDING_LIFTS = "ADD_BUILDING_LIFTS";
const RESET_BUILDING_LIFTS = "RESET_BUILDING_LIFTS";
const ADD_BUILDING_TOILETS = "ADD_BUILDING_TOILETS";
const RESET_BUILDING_TOILETS = "RESET_BUILDING_TOILETS";
const ADD_BUILDING_ADAPTED_TOILETS = "ADD_BUILDING_ADAPTED_TOILETS";
const RESET_BUILDING_ADAPTED_TOILETS = "RESET_BUILDING_ADAPTED_TOILETS";
const ADD_BUILDING_TYPHLOMAPS = "ADD_BUILDING_TYPHLOMAPS";
const RESET_BUILDING_TYPHLOMAPS = "RESET_BUILDING_TYPHLOMAPS";
const ADD_BUILDING_MARKERS = "ADD_BUILDING_MARKERS";
const RESET_BUILDING_MARKERS = "RESET_BUILDING_MARKERS";
const ADD_BUILDING_LEVELS = "ADD_BUILDING_LEVELS";
const RESET_BUILDING_LEVELS = "RESET_BUILDING_LEVELS";
const ADD_BUILDING_ROOMS = "ADD_BUILDING_ROOMS";
const RESET_BUILDING_ROOMS = "RESET_BUILDING_ROOMS";
const ADD_SUBSIDEBAR_ITEM = "ADD_SUBSIDEBAR_ITEM";
const RESET_SUBSIDEBAR_ITEM = "RESET_SUBSIDEBAR_ITEM";

export default {
  ADD_BUILDING_MATCH,
  SET_LOADING_STATE,
  RESET_LOADING_STATE,
  ADD_BUILDING_DETAILS,
  RESET_BUILDING_DETAILS,
  ADD_SURROUNDING_OBJECTS,
  RESET_SURROUNDING_OBJECTS,
  ADD_BUILDING_IMAGES,
  RESET_BUILDING_IMAGES,
  ADD_BUILDING_SURROUNDING,
  RESET_BUILDING_SURROUNDING,
  ADD_BUILDING_ACCESS,
  RESET_BUILDING_ACCESS,
  ADD_BUILDING_LODGES,
  RESET_BUILDING_LODGES,
  ADD_BUILDING_DRESSING_ROOMS,
  RESET_BUILDING_DRESSING_ROOMS,
  ADD_BUILDING_INFORMATION_POINTS,
  RESET_BUILDING_INFORMATION_POINTS,
  ADD_BUILDING_ENTRANCES,
  RESET_BUILDING_ENTRANCES,
  ADD_BUILDING_LIFTS,
  RESET_BUILDING_LIFTS,
  ADD_BUILDING_TOILETS,
  RESET_BUILDING_TOILETS,
  ADD_BUILDING_ADAPTED_TOILETS,
  RESET_BUILDING_ADAPTED_TOILETS,
  ADD_BUILDING_TYPHLOMAPS,
  RESET_BUILDING_TYPHLOMAPS,
  ADD_BUILDING_MARKERS,
  RESET_BUILDING_MARKERS,
  ADD_BUILDING_LEVELS,
  RESET_BUILDING_LEVELS,
  ADD_BUILDING_ROOMS,
  RESET_BUILDING_ROOMS,
  ADD_SUBSIDEBAR_ITEM,
  RESET_SUBSIDEBAR_ITEM,
};
