import { useState, useEffect, useRef } from "react";
import { Switch, Redirect, Route, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { connect } from "react-redux";
import { setMatchParams, getAllBuildingDetails } from "./duck/operations.js";

import SurroundingCard from "../SurroundingCard/SurroundingCard";

import DropdownProfile from "../DropdownProfile/DropdownProfile";

import BuildingGeneralDetails from "../BuildingDetailsComponents/BuildingGeneralDetails";
import BuildingNewsDetails from "../BuildingDetailsComponents/BuildingNewsDetails";
import BuildingUsefulInformation from "../BuildingDetailsComponents/BuildingUsefulInformation";
import BuildingAccessibleInfoDetails from "../BuildingDetailsComponents/BuildingAccessibleInfoDetails";
import BuildingAccessDetails from "../BuildingDetailsComponents/BuildingAccessDetails";
import BuildingSurroundingDetails from "../BuildingDetailsComponents/BuildingSurroundingDetails";
import BuildingLodgeDetails from "../BuildingDetailsComponents/BuildingLodgeDetails";
import BuildingDressingRoomDetails from "../BuildingDetailsComponents/BuildingDressingRoomDetails";
import BuildingInformationPointDetails from "../BuildingDetailsComponents/BuildingInformationPointDetails";
import BuildingEntrancesDetails from "../BuildingDetailsComponents/BuildingEntrancesDetails";
import BuildingLiftsDetails from "../BuildingDetailsComponents/BuildingLiftsDetails";
import BuildingLevelsAdaptedToiletsDetails from "../BuildingDetailsComponents/BuildingLevelsAdaptedToiletsDetails";
import BuildingTyphlomapsDetails from "../BuildingDetailsComponents/BuildingTyphlomapsDetails";
import BuildingTyphlomapDetails from "../BuildingDetailsComponents/BuildingTyphlomapDetails";
import BuildingMarkersDetails from "../BuildingDetailsComponents/BuildingMarkersDetails";
import BuildingStructureDetails from "../BuildingDetailsComponents/BuildingStructureDetails";
import BuildingLevelDetails from "../BuildingDetailsComponents/BuildingLevelDetails";
import BuildingRegionDetails from "../BuildingDetailsComponents/BuildingRegionDetails";
import BuildingLevelsRoomsDetails from "../BuildingDetailsComponents/BuildingLevelsRoomsDetails";
import BuildingEvacuationDetails from "../BuildingDetailsComponents/BuildingEvacuationDetails";

import ClickableItemLinkLiftDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkLiftDetails";
import ClickableItemLinkEntranceDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkEntranceDetails";
import ClickableItemLinkAdaptedToiletDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkAdaptedToiletDetails.js";
import ClickableItemLinkToiletDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkToiletDetails.js";
import ClickableItemLinkDoorDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkDoorDetails";
import ClickableItemLinkStairsDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkStairsDetails";
import ClickableItemLinkRampDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkRampDetails";
import ClickableItemLinkRoomDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkRoomDetails";
import ClickableItemLinkParkingDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkParkingDetails";
import ClickableItemLinkInformationPointDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkInformationPointDetails";
import ClickableItemLinkLodgeDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkLodgeDetails";
import ClickableItemLinkDressingRoomDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkDressingRoomDetails";
import ClickableItemLinkCorridorDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkCorridorDetails";
import ClickableItemLinkStairwayDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkStairwayDetails";
import ClickableItemLinkMarkerDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkMarkerDetails";

import NotFound from "../NotFound/NotFound";
import BuildingSidebar from "../BuildingSidebar/BuildingSidebar";
import Modal from "../Modal/Modal";
import Loader from "../Loader/Loader";

import "./BuildingDetails.css";

import RightArrow from "../../assets/right-button-arrow-forward.svg";

/**
 * A React component that takes in props and returns a JSX element.
 */
const BuildingDetails = ({
  match,
  buildingDetails,
  setMatchParams,
  getAllBuildingDetails,
}) => {
  /* The above code is using the useState hook to create a state variable called isModalVisible and a
  function called setIsModalVisible. */
  const [isModalVisible, setIsModalVisible] = useState(false);

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  /* Getting the history object from the react-router-dom library. */
  const history = useHistory();

  const [isActive, setActive] = useState(true);

  const sidebarRef = useRef(null);

  useEffect(() => {
    /* Setting the match parameters. */
    setMatchParams(match);

    const buildingsDetails = getAllBuildingDetails();

    buildingsDetails.then((result) => {
      if (result) {
        setTimeout(() => {
          sidebarRef.current?.focus();
          sidebarRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 300);
      } else {
        history.push("/404");
      }
    });
  }, []);

  return (
    <>
      {Object.keys(buildingDetails.general).length !== 0 ? (
        <main id="content">
          {buildingDetails.loading ? (
            <Loader type={"building"} />
          ) : (
            <>
              <div className="container">
                <div className="d-flex justify-content-between">
                  <h2 className="text--header object-name">
                    {buildingDetails.general.translations[cookies.i18next].name}
                  </h2>
                  <DropdownProfile />
                </div>
                <div className="row pt-2 d-flex justify-content-center mb-4">
                  <div
                    className={
                      isActive
                        ? "col-12 col-sm-12 col-md-12 col-lg-3 d-flex flex-column align-items-center c-object-sidebar-container"
                        : "col-12 col-sm-12 col-md-12 col-lg-2 d-flex flex-column align-items-center c-object-sidebar-container"
                    }
                  >
                    {buildingDetails.subsidebar && (
                      <BuildingSidebar
                        buildingDetails={buildingDetails}
                        subBuildingSidebarItems={buildingDetails.subsidebar}
                        setActive={setActive}
                        isActive={isActive}
                        ref={sidebarRef}
                      />
                    )}
                    {(isActive || window.innerWidth < 991) && (
                      <button
                        className="btn--inform-us"
                        type="button"
                        onClick={() => setIsModalVisible(true)}
                      >
                        <span>
                          {t("general:inform_us_button_label_part_1")}
                          <br />
                          {t("general:inform_us_button_label_part_2")}
                        </span>
                        <img src={RightArrow} alt="" />
                      </button>
                    )}
                    {isModalVisible && (
                      <Modal closeModal={() => setIsModalVisible(false)} />
                    )}
                  </div>
                  <div
                    className={
                      isActive
                        ? "col-12 col-sm-12 col-md-12 col-lg-8 c-object-content-container"
                        : "col-12 col-sm-12 col-md-12 col-lg-9 c-object-content-container"
                    }
                  >
                    <Switch>
                      <Route
                        exact
                        path={`${t("routes:building_details_no_id")}:id/`}
                        render={() => (
                          <Redirect
                            to={`${t("routes:building_general_information", {
                              id: match,
                            })}`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`${t("routes:building_general_information", {
                          id: match,
                        })}`}
                      >
                        <BuildingGeneralDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/aktualnosci`,
                          `${t("routes:building_details_no_id")}:id/news`,
                        ]}
                      >
                        <BuildingNewsDetails item={buildingDetails} mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/przydatne-informacje`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/useful-information`,
                        ]}
                      >
                        <BuildingUsefulInformation
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/udogodnienia-dostepnosci`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/a11y-information`,
                        ]}
                      >
                        <BuildingAccessibleInfoDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/dojazd`,
                          `${t("routes:building_details_no_id")}:id/access`,
                        ]}
                      >
                        <BuildingAccessDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/otoczenie`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/surrounding`,
                        ]}
                      >
                        <BuildingSurroundingDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/portiernia`,
                          `${t("routes:building_details_no_id")}:id/lodge`,
                        ]}
                      >
                        <BuildingLodgeDetails item={buildingDetails} mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/szatnia`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/dressing-room`,
                        ]}
                      >
                        <BuildingDressingRoomDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/punkt-informacyjny`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/information-point`,
                        ]}
                      >
                        <BuildingInformationPointDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/wejscia`,
                          `${t("routes:building_details_no_id")}:id/entrances`,
                        ]}
                      >
                        <BuildingEntrancesDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/wejscia/:entranceId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/entrances/:entranceId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkEntranceDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/wejscia/:entranceId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/entrances/:entranceId/doors/:doorId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/wejscia/:entranceId/schody/:stairsId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/entrances/:entranceId/stairs/:stairsId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkStairsDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/wejscia/:entranceId/windy/:liftId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/entrances/:entranceId/lifts/:liftId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkLiftDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/wejscia/:entranceId/pochylnie/:rampId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/entrances/:entranceId/ramps/:rampId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkRampDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/windy`,
                          `${t("routes:building_details_no_id")}:id/lifts`,
                        ]}
                      >
                        <BuildingLiftsDetails item={buildingDetails} mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/windy/:liftId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/lifts/:liftId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkLiftDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/windy/:liftId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/lifts/:liftId`,
                        ]}
                      >
                        <ClickableItemLinkLiftDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/toalety-dostosowane`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/adapted-toilets`,
                        ]}
                      >
                        <BuildingLevelsAdaptedToiletsDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/toalety-dostosowane/:adaptedToiletId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/adapted-toilets/:adaptedToiletId`,
                        ]}
                      >
                        <ClickableItemLinkAdaptedToiletDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/toalety-dostosowane/:adaptedToiletId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/adapted-toilets/:adaptedToiletId/doors/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/tyflomapy`,
                          `${t("routes:building_details_no_id")}:id/typhlomaps`,
                        ]}
                      >
                        <BuildingTyphlomapsDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/tyflomapy/:typhloId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/typhlomaps/:typhloId`,
                        ]}
                      >
                        <BuildingTyphlomapDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/mikronawigacja`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/micronavigation`,
                        ]}
                      >
                        <BuildingMarkersDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/mikronawigacja/:markerId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/micronavigation/:markerId`,
                        ]}
                      >
                        <ClickableItemLinkMarkerDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure`,
                        ]}
                      >
                        <BuildingStructureDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId`,
                        ]}
                      >
                        <BuildingLevelDetails item={buildingDetails} mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId`,
                        ]}
                        mode={1}
                      >
                        <BuildingRegionDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/schody/:stairsId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/stairs/:stairsId`,
                        ]}
                      >
                        <ClickableItemLinkStairsDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/punkt-informacyjny/:informationPointId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/information-point/:informationPointId`,
                        ]}
                      >
                        <ClickableItemLinkInformationPointDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/portiernia/:lodgeId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/lodge/:lodgeId`,
                        ]}
                      >
                        <ClickableItemLinkLodgeDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/szatnia/:dressingRoomId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/dressing-room/:dressingRoomId`,
                        ]}
                      >
                        <ClickableItemLinkDressingRoomDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/parking/:parkingId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/parking/:parkingId`,
                        ]}
                      >
                        <ClickableItemLinkParkingDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/parking/:parkingId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/parking/:parkingId/doors/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/korytarz/:corridorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/corridor/:corridorId`,
                        ]}
                      >
                        <ClickableItemLinkCorridorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/korytarz/:corridorId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/corridor/:corridorId/doors/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId`,
                        ]}
                      >
                        <ClickableItemLinkStairwayDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId/doors/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId/winda/:liftId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId/lift/:liftId`,
                        ]}
                      >
                        <ClickableItemLinkLiftDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId/schody/:stairsId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId/stairs/:stairsId`,
                        ]}
                      >
                        <ClickableItemLinkStairsDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/pochylnie/:rampId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/ramp/:rampId`,
                        ]}
                      >
                        <ClickableItemLinkRampDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/toalety/:toiletId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/toilets/:toiletId`,
                        ]}
                      >
                        <ClickableItemLinkToiletDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/toalety/:toiletId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/toilets/:toiletId/door/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/toalety-dostosowane/:adaptedToiletId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/adapted-toilets/:adaptedToiletId`,
                        ]}
                      >
                        <ClickableItemLinkAdaptedToiletDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/toalety-dostosowane/:adaptedToiletId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/adapted-toilets/:adaptedToiletId/door/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/pomieszczenia`,
                          `${t("routes:building_details_no_id")}:id/rooms`,
                        ]}
                        mode={1}
                      >
                        <BuildingLevelsRoomsDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/pomieszczenia/:roomId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/rooms/:roomId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkRoomDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/pomieszczenia/:roomId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/rooms/:roomId`,
                        ]}
                      >
                        <ClickableItemLinkRoomDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/struktura-budynku/:levelId/region/:regionId/pomieszczenia/:roomId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/building-structure/:levelId/region/:regionId/rooms/:roomId/doors/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/pomieszczenia/:roomId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/rooms/:roomId/doors/:doorId`,
                        ]}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t("routes:building_details_no_id")}:id/ewakuacja`,
                          `${t("routes:building_details_no_id")}:id/evacuation`,
                        ]}
                        mode={1}
                      >
                        <BuildingEvacuationDetails
                          item={buildingDetails}
                          mode={1}
                        />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/ewakuacja/wejscia/:entranceId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/evacuation/entrances/:entranceId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkEntranceDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/ewakuacja/wejscia/:entranceId/drzwi/:doorId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/evacuation/entrances/:entranceId/doors/:doorId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkDoorDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/ewakuacja/wejscia/:entranceId/schody/:stairsId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/evacuation/entrances/:entranceId/stairs/:stairsId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkStairsDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/ewakuacja/wejscia/:entranceId/windy/:liftId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/evacuation/entrances/:entranceId/lifts/:liftId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkLiftDetails mode={1} />
                      </Route>
                      <Route
                        exact
                        path={[
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/ewakuacja/wejscia/:entranceId/pochylnie/:rampId`,
                          `${t(
                            "routes:building_details_no_id"
                          )}:id/evacuation/entrances/:entranceId/ramps/:rampId`,
                        ]}
                        mode={1}
                      >
                        <ClickableItemLinkRampDetails mode={1} />
                      </Route>
                      <Route
                        path={`/:locale(pl|en)?${t("routes:404")}`}
                        component={NotFound}
                      />
                      <Redirect
                        from="*"
                        to={`/:locale(pl|en)?${t("routes:404")}`}
                      />
                    </Switch>
                  </div>
                </div>
                {Object.keys(buildingDetails.surrounding_objects).length !==
                0 ? (
                  <>
                    <div className="row">
                      <h2 className="text--subheader surrounding-objects-name">
                        {t("general:check_also_header_label")}
                      </h2>
                    </div>
                    <div className="row pr-2 pl-2 justify-content-center">
                      {buildingDetails.surrounding_objects
                        .slice(0, 3)
                        .map((building) => (
                          <SurroundingCard key={building.id} item={building} />
                        ))}
                    </div>
                    <div className="row d-flex flex-row-reverse m-3">
                      <button className="btn--more" type="button">
                        <Link to={t("routes:buildings")}>
                          {t("general:more_button")}
                        </Link>
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          )}
        </main>
      ) : null}
    </>
  );
};

/**
 * This function takes the state from the Redux store and the props from the component and returns an
 * object that contains the data that the component needs.
 * @param state - The state of the Redux store.
 * @param ownProps - This is the props object that is passed to the component.
 */
const mapStateToProps = (state, ownProps) => ({
  buildingDetails: state.buildingDetails,
  match: ownProps.match.params.id,
});

const mapDispatchToProps = (dispatch) => ({
  setMatchParams: (match) => dispatch(setMatchParams(match)),
  getAllBuildingDetails: () => dispatch(getAllBuildingDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetails);
