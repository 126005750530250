import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, withRouter } from "react-router-dom";

import RightArrow from "../../assets/arrow-forward.svg";

function BuildingEntrancesDetails({ item, mode }) {
  const { id } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    /* Setting the title of the page. */
    document.title = t("building__entrance:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  return (
    <>
      <section aria-label={t("building__entrance:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__entrance:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name">
          {t("building__entrance:category_header")}
        </h3>
        <ul className="c-clickable-list">
          {item.entrances &&
            item.entrances.length > 0 &&
            item.entrances
              .sort((a, b) => a.order_number - b.order_number)
              .map((entrance) => (
                <li key={entrance.id} className="c-clickable-list__item">
                  <Link
                    className="c-clickable-list__link"
                    to={{
                      pathname: `${t("routes:building_entrance_details", {
                        id: id,
                        entrance_id: entrance.id,
                      })}`,
                      state: {
                        category: "entrances",
                        building_name:
                          item.general.translations[cookies.i18next].name,
                      },
                    }}
                  >
                    <span>{entrance.translations[cookies.i18next].name}</span>
                    <img src={RightArrow} alt="" />
                  </Link>
                </li>
              ))}
        </ul>
      </section>
    </>
  );
}

export default withRouter(BuildingEntrancesDetails);
