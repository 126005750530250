import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./CallToActionButton.css";

import UpArrow from "../../assets/up-arrow-forward.svg";

function CallToActionButton() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () =>
    window.scroll({
      top: 0,
      behavior: "auto",
    });

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <div id="call-to-action" className="l-call-to-action-btn-container">
        <button
          type="button"
          className={
            visible ? "btn--call-to-action visible" : "btn--call-to-action"
          }
          onClick={scrollToTop}
        >
          <span className="sr-only">{t("general:call_to_action_button")}</span>
          <img src={UpArrow} alt="" />
        </button>
      </div>
    </>
  );
}

export default CallToActionButton;
