import React, { useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import { useTranslation } from "react-i18next";

import ObjectGeneralDetails from "../ObjectDetailsComponents/ObjectGeneralDetails";
import ObjectAccessibleInfoDetails from "../ObjectDetailsComponents/ObjectAccessibleInfoDetails";
import ObjectCategoryDetails from "../ObjectDetailsComponents/ObjectCategoryDetails";

function ObjectSidebarItems({
  name,
  iconIndex,
  activeIcon,
  icon,
  to,
  subObjectSidebar,
  objectDetails,
  setActive,
  isActive,
}) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* Setting the width of the window to the width of the window. */
  const [width, setWidth] = useState(window.innerWidth);

  /**
   * When the window is resized, update the width variable to the new window width.
   */
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    /* Adding an event listener to the window object that will call the updateDimensions function when
    the window is resized. */
    window.addEventListener("resize", updateDimensions);

    /* Removing the event listeners. */
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const goToContent = () => {
    setTimeout(() => {
      let jumpLink;

      if (width > 991) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  const setSrcImage = () => {
    if (!window.location.pathname.includes(to)) {
      $(`#sidebar-menu-icon-${iconIndex}`).attr("src", icon);
    }
  };

  const setSrcActiveImage = () => {
    if (!window.location.pathname.includes(to)) {
      $(`#sidebar-menu-icon-${iconIndex}`).attr("src", activeIcon);
    }
  };

  return (
    <>
      <li>
        <NavLink
          to={to}
          className="c-sidebar-menu-list__item"
          role="tab"
          tabIndex={window.location.pathname.includes(to) ? 0 : -1}
          onClick={() => {
            goToContent();
            if (width > 991) setActive(true);
          }}
          onMouseEnter={setSrcActiveImage}
          onMouseLeave={setSrcImage}
          aria-expanded={
            width > 991
              ? subObjectSidebar.length > 0
                ? window.location.pathname.includes(to) &&
                  subObjectSidebar.length > 0
                  ? "true"
                  : "false"
                : null
              : window.location.pathname.includes(to)
              ? "true"
              : "false"
          }
          aria-label={(isActive && width > 991) || width < 991 ? null : name}
        >
          <div
            className={
              (isActive && width > 991) || width < 991
                ? "c-sidebar-menu-item__icon"
                : "c-sidebar-menu-item__icon--toggle-sidebar"
            }
          >
            <img
              id={`sidebar-menu-icon-${iconIndex}`}
              src={window.location.pathname.includes(to) ? activeIcon : icon}
              alt=""
            />
          </div>
          {(isActive && width > 991) || width < 991 ? (
            <span>{name}</span>
          ) : null}
        </NavLink>
        <div
          className={
            window.location.pathname.includes(to)
              ? "c-object-sidebar-mobile-container--open"
              : "c-object-sidebar-mobile-container"
          }
        >
          <div className="c-object-sidebar-mobile-container__content">
            <Switch>
              <Route
                exact
                path={`${t("routes:object_details_no_id")}:id/`}
                render={() => (
                  <Redirect
                    to={`${t("routes:object_general_information", {
                      id: objectDetails.general.id,
                    })}`}
                  />
                )}
              />
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={`${t("routes:object_general_information", {
                    id: objectDetails.general.id,
                  })}`}
                >
                  <ObjectGeneralDetails item={objectDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={`${t("routes:object_accessible_information", {
                    id: objectDetails.general.id,
                  })}`}
                >
                  <ObjectAccessibleInfoDetails item={objectDetails} mode={0} />
                </Route>
              ) : null}
              {objectDetails.categories &&
                objectDetails.categories.map((data) => (
                  <Route
                    key={data.id}
                    exact
                    path={`${t("routes:object_category_details", {
                      id: objectDetails.general.id,
                      category_name: data.name
                        .toLowerCase()
                        .replace(/[^\w ]+/g, "")
                        .replace(/ +/g, "-"),
                      category_id: data.id,
                    })}`}
                  >
                    <ObjectCategoryDetails
                      item={objectDetails}
                      id={data.id}
                      mode={0}
                    />
                  </Route>
                ))}
            </Switch>
          </div>
        </div>
        <div
          className={
            (window.location.pathname.includes(to) &&
              subObjectSidebar.length > 0 &&
              isActive &&
              width > 991) ||
            (!isActive && width < 991)
              ? "c-subsidebar-menu--open"
              : "c-subsidebar-menu"
          }
        >
          {subObjectSidebar && subObjectSidebar.length > 0 ? (
            <>
              <span></span>
              <ul className="c-subsidebar-menu-list" role="tablist">
                {subObjectSidebar.map((subObjectSidebarItem, subIndex) => (
                  <li key={subIndex}>
                    <NavLink
                      to={subObjectSidebarItem.to}
                      className="c-subsidebar-menu-list__item"
                      role="tab"
                      tabIndex={
                        subObjectSidebarItem.to === window.location.pathname
                          ? 0
                          : -1
                      }
                    >
                      {subObjectSidebarItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </li>
    </>
  );
}

export default ObjectSidebarItems;
