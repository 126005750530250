import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import ExtendedDescriptionContainer from "../ExtendedDescriptionContainer/ExtendedDescriptionContainer";

import "./Popup.css";

import Close from "../../assets/close.svg";

function Popup({ images, index, image, closePopup }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to access cookies. */
  const [cookies] = useCookies(["i18next"]);

  const popupRef = useRef(null);
  const popupImgRef = useRef(null);
  const popupPrevImgRef = useRef(null);
  const popupNextImgRef = useRef(null);
  const popupExtendedDescriptionRef = useRef(null);
  const popupExtendedDescriptionContentRef = useRef(null);

  const [popupImageIndex, setPopupImageIndex] = useState(index);
  const [popupImage, setPopupImage] = useState(image);

  let activeIndex = -1;

  const handleKeydown = (e) => {
    switch (e.keyCode) {
      case 9:
        const focusableElements =
          popupRef.current?.querySelectorAll("a[href], button");
        let total = focusableElements.length;

        if (!e.shiftKey) {
          activeIndex + 1 === total ? (activeIndex = 0) : (activeIndex += 1);
          focusableElements[activeIndex].focus();
          return e.preventDefault();
        }

        if (e.shiftKey) {
          activeIndex - 1 < 0 ? (activeIndex = total - 1) : (activeIndex -= 1);
          focusableElements[activeIndex].focus();
          return e.preventDefault();
        }
        break;
      case 27:
        closePopup();
        break;
      case 37:
        prevImage();
        break;
      case 39:
        nextImage();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const targetElement = document.getElementById("popup-img");
      targetElement.focus();
      targetElement.scrollIntoView({ behavior: "smooth" });
    }, 300);

    document.addEventListener("keydown", handleKeydown);

    const handleClickOutside = (e) => {
      if (
        popupImgRef.current &&
        !popupImgRef.current?.contains(e.target) &&
        popupNextImgRef.current &&
        !popupNextImgRef.current?.contains(e.target) &&
        popupPrevImgRef.current &&
        !popupPrevImgRef.current?.contains(e.target) &&
        popupExtendedDescriptionRef.current !== null &&
        popupExtendedDescriptionRef.current &&
        !popupExtendedDescriptionRef.current?.contains(e.target) &&
        popupExtendedDescriptionContentRef.current !== null &&
        popupExtendedDescriptionContentRef.current &&
        !popupExtendedDescriptionContentRef.current?.contains(e.target)
      ) {
        closePopup();
      }

      if (
        popupImgRef.current &&
        !popupImgRef.current?.contains(e.target) &&
        popupNextImgRef.current &&
        !popupNextImgRef.current?.contains(e.target) &&
        popupPrevImgRef.current &&
        !popupPrevImgRef.current?.contains(e.target) &&
        popupExtendedDescriptionRef.current === null &&
        popupExtendedDescriptionContentRef.current === null
      ) {
        closePopup();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      const activeElement = document.activeElement;
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("mousedown", handleClickOutside);
      activeElement.focus();
    };
  }, [handleKeydown]);

  const prevImage = () => {
    if (popupImageIndex > 0) {
      setPopupImageIndex(popupImageIndex - 1);
      setPopupImage(images[popupImageIndex - 1]);
    }
  };

  const nextImage = () => {
    if (popupImageIndex < parseInt(Object.keys(images).length) - 1) {
      setPopupImageIndex(popupImageIndex + 1);
      setPopupImage(images[popupImageIndex + 1]);
    }
  };

  return ReactDOM.createPortal(
    <div className="popup" ref={popupRef}>
      <button
        className="popup__btn-close"
        type="button"
        aria-label={t("gallery:button-popup-close-label")}
        onClick={closePopup}
      >
        <img src={Close} alt="" />
      </button>
      <div className="popup-img__container">
        {parseInt(Object.keys(images).length) > 0 ? (
          <button
            type="button"
            aria-label={t("gallery:button-prev-image-label")}
            className={"popup__btn popup__btn-prev-img"}
            disabled={parseInt(popupImageIndex) === 0}
            aria-disabled={parseInt(popupImageIndex) === 0}
            onClick={prevImage}
            ref={popupPrevImgRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.988"
              height="16.822"
              viewBox="0 0 9.988 16.822"
            >
              <path
                id="Icon_ionic-ios-arrow-forward"
                data-name="Icon ionic-ios-arrow-forward"
                d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
                transform="translate(9.988 16.822) rotate(180)"
              />
            </svg>
          </button>
        ) : null}
        <img
          id="popup-img"
          className="popup__img"
          sizes="(max-width: 991px) 960px, (min-width: 991px) and (max-width: 1199px) 960px, (min-width: 1199px) 1920px"
          srcSet={`${popupImage.image_960w} 960w, ${popupImage.image_1920w} 1920w`}
          src={popupImage.image_1920w}
          alt={
            popupImage.translations &&
            popupImage.translations[cookies.i18next].alternative_description
          }
          tabIndex={-1}
          ref={popupImgRef}
        />
        <div className="popup__counter">{`${popupImageIndex + 1} / ${
          images.length
        }`}</div>
        {parseInt(Object.keys(images).length) > 0 ? (
          <button
            type="button"
            aria-label={t("gallery:button-next-image-label")}
            className={"popup__btn popup__btn-next-img"}
            disabled={parseInt(popupImageIndex) === images.length - 1}
            aria-disabled={parseInt(popupImageIndex) === images.length - 1}
            onClick={nextImage}
            ref={popupNextImgRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.988"
              height="16.822"
              viewBox="0 0 9.988 16.822"
            >
              <path
                id="Icon_ionic-ios-arrow-forward"
                data-name="Icon ionic-ios-arrow-forward"
                d="M6.977,8.408.367,2.047a1.166,1.166,0,0,1,0-1.7,1.294,1.294,0,0,1,1.768,0l7.49,7.207a1.168,1.168,0,0,1,.036,1.658L2.14,16.472a1.273,1.273,0,0,1-.884.351,1.273,1.273,0,0,1-.884-.351,1.166,1.166,0,0,1,0-1.7Z"
              />
            </svg>
          </button>
        ) : null}
        {popupImage.translations &&
        popupImage.translations[cookies.i18next].extended_description ===
          "" ? null : (
          <ExtendedDescriptionContainer
            item={popupImage}
            popupExtendedDescriptionRef={popupExtendedDescriptionRef}
            popupExtendedDescriptionContentRef={
              popupExtendedDescriptionContentRef
            }
          />
        )}
      </div>
    </div>,
    document.getElementById("popup")
  );
}

export default Popup;
