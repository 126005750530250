import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import AccessibilityInfoContainer from "../AccessibilityInfoContainer/AccessibilityInfoContainer";

import Gallery from "../Gallery/Gallery";

import WhiteDownDoubleArrow from "../../assets/white-down-double-arrows.svg";

function ObjectGeneralDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  useEffect(() => {
    document.title = t("object__general_information:title", {
      object_name: item.general.translations[cookies.i18next].name,
    });
  }, []);

  return (
    <>
      <section
        aria-label={t("object__general_information:section_description")}
      >
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("object__general_information:startup_message", {
            object_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        {item.general ? (
          <AccessibilityInfoContainer
            item={item.general}
            type={1}
            category={"object"}
          />
        ) : null}
        {item.images && item.images.length > 0 ? (
          <Gallery item={item.images} />
        ) : null}
        <div className="object-general-information">
          <p className="object-category-subheader-name">
            {t("object__general_information:name_subheader")}:
          </p>
          <p className="text">
            {item.general.translations[cookies.i18next].name}
          </p>
          <p className="object-category-subheader-name">
            {t("object__general_information:address_subheader")}:
          </p>
          <p className="text">
            {item.general.translations[cookies.i18next].address}
          </p>
        </div>
        {item.general.link_to_object_location && (
          <div className="c-map">
            <div className="c-map__btn-container">
              <button
                type="button"
                className="c-map__btn--campus-map"
                onClick={() =>
                  (window.location.href = item.general.link_to_object_location)
                }
                aria-label={t(
                  "object__general_information:location-button-label",
                  {
                    object_name:
                      item.general.translations[cookies.i18next].name,
                  }
                )}
              >
                {t("object__general_information:location-button", {
                  object_name: item.general.translations[cookies.i18next].name,
                })}
                <span className="sr-only">
                  {t("general:sr-only_link_description")}
                </span>
                <img src={WhiteDownDoubleArrow} alt="" />
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default ObjectGeneralDetails;
