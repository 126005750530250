import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Redirect, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import BuildingGeneralDetails from "../BuildingDetailsComponents/BuildingGeneralDetails";
import BuildingNewsDetails from "../BuildingDetailsComponents/BuildingNewsDetails";
import BuildingUsefulInformation from "../BuildingDetailsComponents/BuildingUsefulInformation";
import BuildingAccessibleInfoDetails from "../BuildingDetailsComponents/BuildingAccessibleInfoDetails";
import BuildingAccessDetails from "../BuildingDetailsComponents/BuildingAccessDetails";
import BuildingSurroundingDetails from "../BuildingDetailsComponents/BuildingSurroundingDetails";
import BuildingLodgeDetails from "../BuildingDetailsComponents/BuildingLodgeDetails";
import BuildingDressingRoomDetails from "../BuildingDetailsComponents/BuildingDressingRoomDetails";
import BuildingInformationPointDetails from "../BuildingDetailsComponents/BuildingInformationPointDetails";
import BuildingEntrancesDetails from "../BuildingDetailsComponents/BuildingEntrancesDetails";
import BuildingLiftsDetails from "../BuildingDetailsComponents/BuildingLiftsDetails";
import BuildingLevelsAdaptedToiletsDetails from "../BuildingDetailsComponents/BuildingLevelsAdaptedToiletsDetails";
import BuildingTyphlomapsDetails from "../BuildingDetailsComponents/BuildingTyphlomapsDetails";
import BuildingTyphlomapDetails from "../BuildingDetailsComponents/BuildingTyphlomapDetails";
import BuildingMarkersDetails from "../BuildingDetailsComponents/BuildingMarkersDetails";
import BuildingStructureDetails from "../BuildingDetailsComponents/BuildingStructureDetails";
import BuildingLevelDetails from "../BuildingDetailsComponents/BuildingLevelDetails";
import BuildingRegionDetails from "../BuildingDetailsComponents/BuildingRegionDetails";
import BuildingLevelsRoomsDetails from "../BuildingDetailsComponents/BuildingLevelsRoomsDetails";
import BuildingEvacuationDetails from "../BuildingDetailsComponents/BuildingEvacuationDetails";

import ClickableItemLinkLiftDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkLiftDetails";
import ClickableItemLinkEntranceDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkEntranceDetails";
import ClickableItemLinkAdaptedToiletDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkAdaptedToiletDetails";
import ClickableItemLinkToiletDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkToiletDetails";
import ClickableItemLinkDoorDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkDoorDetails";
import ClickableItemLinkStairsDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkStairsDetails";
import ClickableItemLinkRampDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkRampDetails";
import ClickableItemLinkRoomDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkRoomDetails";
import ClickableItemLinkInformationPointDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkInformationPointDetails";
import ClickableItemLinkLodgeDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkLodgeDetails";
import ClickableItemLinkDressingRoomDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkDressingRoomDetails";
import ClickableItemLinkCorridorDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkCorridorDetails";
import ClickableItemLinkStairwayDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkStairwayDetails";
import ClickableItemLinkMarkerDetails from "../BuildingDetailsComponents/ClickableItemLink/ClickableItemLinkMarkerDetails";

function BuildingSidebarItems(props, ref) {
  const {
    name,
    iconIndex,
    activeIcon,
    icon,
    to,
    subBuildingSidebar,
    buildingDetails,
    setActive,
    isActive,
  } = props;

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* Setting the width of the window to the width of the window. */
  const [width, setWidth] = useState(window.innerWidth);

  /**
   * When the window is resized, update the width variable to the new window width.
   */
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    /* Adding an event listener to the window object that will call the updateDimensions function when
    the window is resized. */
    window.addEventListener("resize", updateDimensions);

    /* Removing the event listeners. */
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const goToContent = () => {
    setTimeout(() => {
      let jumpLink;

      if (width > 991) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  const setSrcImage = () => {
    if (!window.location.pathname.includes(to)) {
      $(`#sidebar-menu-icon-${iconIndex}`).attr("src", icon);
    }
  };

  const setSrcActiveImage = () => {
    if (!window.location.pathname.includes(to)) {
      $(`#sidebar-menu-icon-${iconIndex}`).attr("src", activeIcon);
    }
  };

  return (
    <>
      <li>
        <NavLink
          to={to}
          className="c-sidebar-menu-list__item"
          role="tab"
          tabIndex={window.location.pathname.includes(to) ? 0 : -1}
          ref={window.location.pathname.includes(to) ? ref : undefined}
          onClick={() => {
            goToContent();
            if (width > 991) setActive(true);
          }}
          onMouseEnter={setSrcActiveImage}
          onMouseLeave={setSrcImage}
          aria-label={(isActive && width > 991) || width < 991 ? null : name}
        >
          <div
            className={
              (isActive && width > 991) || width < 991
                ? "c-sidebar-menu-item__icon"
                : "c-sidebar-menu-item__icon--toggle-sidebar"
            }
          >
            <img
              id={`sidebar-menu-icon-${iconIndex}`}
              src={window.location.pathname.includes(to) ? activeIcon : icon}
              alt=""
            />
          </div>
          {(isActive && width > 991) || width < 991 ? (
            <span>{name}</span>
          ) : null}
        </NavLink>
        <div
          className={
            window.location.pathname.includes(to)
              ? "c-object-sidebar-mobile-container--open"
              : "c-object-sidebar-mobile-container"
          }
        >
          <div className="c-object-sidebar-mobile-container__content">
            <Switch>
              <Route
                exact
                path={`${t("routes:building_details_no_id")}:id/`}
                render={() => (
                  <Redirect
                    to={`${t("routes:building_general_information", {
                      id: buildingDetails.general.id,
                    })}`}
                  />
                )}
              />
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={`${t("routes:building_general_information", {
                    id: buildingDetails.general.id,
                  })}`}
                >
                  <BuildingGeneralDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/aktualnosci`,
                    `${t("routes:building_details_no_id")}:id/news`,
                  ]}
                >
                  <BuildingNewsDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/przydatne-informacje`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/useful-information`,
                  ]}
                >
                  <BuildingUsefulInformation item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/udogodnienia-dostepnosci`,
                    `${t("routes:building_details_no_id")}:id/a11y-information`,
                  ]}
                >
                  <BuildingAccessibleInfoDetails
                    item={buildingDetails}
                    mode={0}
                  />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/dojazd`,
                    `${t("routes:building_details_no_id")}:id/access`,
                  ]}
                >
                  <BuildingAccessDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/otoczenie`,
                    `${t("routes:building_details_no_id")}:id/surrounding`,
                  ]}
                >
                  <BuildingSurroundingDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/portiernia`,
                    `${t("routes:building_details_no_id")}:id/lodge`,
                  ]}
                >
                  <BuildingLodgeDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/szatnia`,
                    `${t("routes:building_details_no_id")}:id/dressing-room`,
                  ]}
                >
                  <BuildingDressingRoomDetails
                    item={buildingDetails}
                    mode={0}
                  />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/punkt-informacyjny`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/information-point`,
                  ]}
                >
                  <BuildingInformationPointDetails
                    item={buildingDetails}
                    mode={0}
                  />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/wejscia`,
                    `${t("routes:building_details_no_id")}:id/entrances`,
                  ]}
                >
                  <BuildingEntrancesDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/wejscia/:entranceId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/entrances/:entranceId`,
                  ]}
                >
                  <ClickableItemLinkEntranceDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/wejscia/:entranceId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/entrances/:entranceId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/wejscia/:entranceId/schody/:stairsId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/entrances/:entranceId/stairs/:stairsId`,
                  ]}
                >
                  <ClickableItemLinkStairsDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/wejscia/:entranceId/windy/:liftId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/entrances/:entranceId/lifts/:liftId`,
                  ]}
                >
                  <ClickableItemLinkLiftDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/wejscia/:entranceId/pochylnie/:rampId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/entrances/:entranceId/ramps/:rampId`,
                  ]}
                >
                  <ClickableItemLinkRampDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/windy`,
                    `${t("routes:building_details_no_id")}:id/lifts`,
                  ]}
                >
                  <BuildingLiftsDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/windy/:liftId`,
                    `${t("routes:building_details_no_id")}:id/lifts/:liftId`,
                  ]}
                >
                  <ClickableItemLinkLiftDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/windy/:liftId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/lifts/:liftId`,
                  ]}
                >
                  <ClickableItemLinkLiftDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/toalety-dostosowane`,
                    `${t("routes:building_details_no_id")}:id/adapted-toilets`,
                  ]}
                >
                  <BuildingLevelsAdaptedToiletsDetails
                    item={buildingDetails}
                    mode={0}
                  />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/toalety-dostosowane/:adaptedToiletId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/adapted-toilets/:adaptedToiletId`,
                  ]}
                >
                  <ClickableItemLinkAdaptedToiletDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/toalety-dostosowane/:adaptedToiletId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/adapted-toilets/:adaptedToiletId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/tyflomapy`,
                    `${t("routes:building_details_no_id")}:id/typhlomaps`,
                  ]}
                >
                  <BuildingTyphlomapsDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/tyflomapy/:typhloId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/typhlomaps/:typhloId`,
                  ]}
                >
                  <BuildingTyphlomapDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              <Route
                exact
                path={[
                  `${t("routes:building_details_no_id")}:id/mikronawigacja`,
                  `${t("routes:building_details_no_id")}:id/micronavigation`,
                ]}
              >
                <BuildingMarkersDetails item={buildingDetails} mode={0} />
              </Route>
              <Route
                exact
                path={[
                  `${t(
                    "routes:building_details_no_id"
                  )}:id/mikronawigacja/:markerId`,
                  `${t(
                    "routes:building_details_no_id"
                  )}:id/micronavigation/:markerId`,
                ]}
              >
                <ClickableItemLinkMarkerDetails
                  item={buildingDetails}
                  mode={0}
                />
              </Route>
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure`,
                  ]}
                >
                  <BuildingStructureDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId`,
                  ]}
                >
                  <BuildingLevelDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId`,
                  ]}
                >
                  <BuildingRegionDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/schody/:stairsId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/stairs/:stairsId`,
                  ]}
                >
                  <ClickableItemLinkStairsDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/punkt-informacyjny/:informationPointId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/information-point/:informationPointId`,
                  ]}
                >
                  <ClickableItemLinkInformationPointDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/portiernia/:lodgeId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/lodge/:lodgeId`,
                  ]}
                >
                  <ClickableItemLinkLodgeDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/szatnia/:dressingRoomId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/dressing-room/:dressingRoomId`,
                  ]}
                >
                  <ClickableItemLinkDressingRoomDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/pochylnie/:rampId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/ramps/:rampId`,
                  ]}
                >
                  <ClickableItemLinkRampDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/pomieszczenia`,
                    `${t("routes:building_details_no_id")}:id/rooms`,
                  ]}
                >
                  <BuildingLevelsRoomsDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/pomieszczenia/:roomId`,
                    `${t("routes:building_details_no_id")}:id/rooms/:roomId`,
                  ]}
                >
                  <ClickableItemLinkRoomDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/pomieszczenia/:roomId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/rooms/:roomId`,
                  ]}
                >
                  <ClickableItemLinkRoomDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/pomieszczenia/:roomId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/rooms/:roomId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/korytarz/:corridorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/corridor/:corridorId`,
                  ]}
                >
                  <ClickableItemLinkCorridorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/korytarz/:corridorId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/corridor/:corridorId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId`,
                  ]}
                >
                  <ClickableItemLinkStairwayDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId/winda/:liftId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId/lifts/:liftId`,
                  ]}
                >
                  <ClickableItemLinkLiftDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/klatka-schodowa/:stairwayId/schody/:stairsId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/stairway/:stairwayId/stairs/:stairsId`,
                  ]}
                >
                  <ClickableItemLinkStairsDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/toalety/:toiletId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/toilets/:toiletId`,
                  ]}
                >
                  <ClickableItemLinkToiletDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/toalety/:toiletId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/toilets/:toiletId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/toalety-dostosowane/:adaptedToiletId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/adapted-toilets/:adaptedToiletId`,
                  ]}
                >
                  <ClickableItemLinkAdaptedToiletDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/struktura-budynku/:levelId/region/:regionId/toalety-dostosowane/:adaptedToiletId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/building-structure/:levelId/region/:regionId/adapted-toilets/:adaptedToiletId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/pomieszczenia/:roomId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/rooms/:roomId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t("routes:building_details_no_id")}:id/ewakuacja`,
                    `${t("routes:building_details_no_id")}:id/evacuation`,
                  ]}
                >
                  <BuildingEvacuationDetails item={buildingDetails} mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/ewakuacja/wejscia/:entranceId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/evacuation/entrances/:entranceId`,
                  ]}
                >
                  <ClickableItemLinkEntranceDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/ewakuacja/wejscia/:entranceId/drzwi/:doorId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/evacuation/entrances/:entranceId/doors/:doorId`,
                  ]}
                >
                  <ClickableItemLinkDoorDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/ewakuacja/wejscia/:entranceId/schody/:stairsId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/evacuation/entrances/:entranceId/stairs/:stairsId`,
                  ]}
                >
                  <ClickableItemLinkStairsDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/ewakuacja/wejscia/:entranceId/windy/:liftId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/evacuation/entrances/:entranceId/lifts/:liftId`,
                  ]}
                >
                  <ClickableItemLinkLiftDetails mode={0} />
                </Route>
              ) : null}
              {window.location.pathname.includes(to) ? (
                <Route
                  exact
                  path={[
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/ewakuacja/wejscia/:entranceId/pochylnie/:rampId`,
                    `${t(
                      "routes:building_details_no_id"
                    )}:id/evacuation/entrances/:entranceId/ramps/:rampId`,
                  ]}
                >
                  <ClickableItemLinkRampDetails mode={0} />
                </Route>
              ) : null}
            </Switch>
          </div>
        </div>
        <div
          className={
            (window.location.pathname.includes(to) &&
              subBuildingSidebar.length > 0 &&
              isActive &&
              width > 991) ||
            (!isActive && width < 991)
              ? "c-subsidebar-menu--open"
              : "c-subsidebar-menu"
          }
        >
          {subBuildingSidebar && subBuildingSidebar.length > 0 ? (
            <>
              <span></span>
              <ul className="c-subsidebar-menu-list" role="tablist">
                {subBuildingSidebar.map((subBuildingSidebarItem, subIndex) => (
                  <li key={subIndex}>
                    <NavLink
                      to={subBuildingSidebarItem.to}
                      className="c-subsidebar-menu-list__item"
                      role="tab"
                      tabIndex={
                        subBuildingSidebarItem.to === window.location.pathname
                          ? 0
                          : -1
                      }
                    >
                      {subBuildingSidebarItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </li>
    </>
  );
}

export default React.forwardRef(BuildingSidebarItems);
