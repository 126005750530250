import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import "./CookiesInfoContainer.css";

import CookieIcon from "../../assets/cookie-icon.svg";
import CookieInformCloseBtn from "../../assets/cookie-info-close-btn.svg";

function CookiesInfoContainer() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* Setting the cookie_informed cookie to true. */
  const [cookies, setCookie] = useCookies(["cookie_informed"]);

  /**
   * It sets a cookie called cookie_informed with a value of 1 and a path of /
   */
  const informCookie = () => {
    setCookie("cookie_informed", "true", { path: "/" });
  };

  return (
    <>
      {
        <div
          className={
            cookies.cookie_informed !== "true"
              ? "l-cookies-info-container"
              : "l-cookies-info-container--informed"
          }
        >
          <div className="c-cookies-info-container">
            <div className="c-cookies-info-img">
              <img src={CookieIcon} alt="" />
            </div>
            <div className="c-cookies-info-content">
              <p>{t("cookie:cookie_info")}</p>
            </div>
            <div className="c-cookies-info-close-section-container">
              <button
                onClick={informCookie}
                className="c-cookies-info-close-btn"
              >
                <img
                  src={CookieInformCloseBtn}
                  alt={t("cookie:close_button")}
                />
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default CookiesInfoContainer;
