import types from "./types.js";

const addTyphlomapAreaMatch = (item) => ({
  type: types.ADD_AREA_MATCH,
  item,
});

const addTyphlomap = (item) => ({
  type: types.ADD_TYPHLOMAP,
  item,
});

const addTyphlomapBuilding = (item) => ({
  type: types.ADD_TYPHLOMAP_BUILDING,
  item,
});

const addTyphlomapBuildingName = (item) => ({
  type: types.ADD_TYPHLOMAP_BUILDING_NAME,
  item,
});

const addTyphlomapAreaDetails = (item) => ({
  type: types.ADD_TYPHLOMAP_AREA_DETAILS,
  item,
});

const resetTyphlomapAreaDetails = (item) => ({
  type: types.RESET_TYPHLOMAP_AREA_DETAILS,
  item,
});

const addTyphlomapAreas = (item) => ({
  type: types.ADD_TYPHLOMAP_AREAS,
  item,
});

const resetTyphlomapAreas = (item) => ({
  type: types.RESET_TYPHLOMAP_AREAS,
  item,
});

const addTyphlomapAreaIndex = (item) => ({
  type: types.ADD_TYPHLOMAP_AREA_INDEX,
  item,
});

export default {
  addTyphlomapAreaMatch,
  addTyphlomap,
  addTyphlomapBuilding,
  addTyphlomapBuildingName,
  addTyphlomapAreaDetails,
  resetTyphlomapAreaDetails,
  addTyphlomapAreas,
  resetTyphlomapAreas,
  addTyphlomapAreaIndex,
};
