import { useTranslation } from "react-i18next";

function Skiplinks() {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  return (
    <>
      <ul className="sr-only">
        <li>
          <a href="#content" tabIndex="1">
            {t("skiplink:skip-links-main-content")}
          </a>
        </li>
        <li>
          <a href="#footer" tabIndex="1">
            {t("skiplink:skip-links-footer")}
          </a>
        </li>
      </ul>
    </>
  );
}

export default Skiplinks;
