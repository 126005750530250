import types from "./types.js";

const INITIAL_OBJECTS = {
  list: [],
  sorted_objects_categories: {},
};

const objectsReducer = (state = INITIAL_OBJECTS, action) => {
  switch (action.type) {
    case types.ADD_OBJECTS_LIST:
      return {
        ...state,
        list: action.item,
      };
    case types.RESET_OBJECTS_LIST:
      return {
        ...state,
        list: [],
      };
    case types.ADD_OBJECTS_TO_SORTED_CATEGORY:
      return {
        ...state,
        sorted_objects_categories: action.item,
      };
    case types.RESET_OBJECTS_SORTED_CATEGORY:
      return {
        ...state,
        sorted_objects_categories: {},
      };
    default:
      return state;
  }
};

export default objectsReducer;
