import types from "./types.js";

const INITIAL_BUILDINGS = {
  list: [],
  sorted_buildings_categories: {},
};

const buildingsReducer = (state = INITIAL_BUILDINGS, action) => {
  switch (action.type) {
    case types.ADD_BUILDINGS_LIST:
      return {
        ...state,
        list: action.item,
      };
    case types.RESET_BUILDINGS_LIST:
      return {
        ...state,
        list: [],
      };
    case types.ADD_BUILDINGS_TO_SORTED_CATEGORY:
      return {
        ...state,
        sorted_buildings_categories: action.item,
      };
    case types.RESET_BUILDINGS_SORTED_CATEGORY:
      return {
        ...state,
        sorted_buildings_categories: {},
      };
    default:
      return state;
  }
};

export default buildingsReducer;
