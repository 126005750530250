import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

function BuildingNewsDetails({ item, mode }) {
  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  /* A hook that allows you to use cookies in React. */
  const [cookies] = useCookies(["value"]);

  const [news, setNews] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    /* Setting the title of the page. */
    document.title = t("building__news:title", {
      building_name: item.general.translations[cookies.i18next].name,
    });

    /* Fetching data from the API. */
    fetch(
      `${process.env.REACT_APP_API_URL}/news/?id=&category=&building_name=${
        item.general.translations[cookies.i18next].name
      }`,
      {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setNews((news) => [...news, ...data]);
      });

    setTimeout(() => {
      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <section aria-label={t("building__news:section_description")}>
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__news:startup_message", {
            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <h3 className="object-category-header-name mb-4">
          {t("building__news:category_header")}
        </h3>
        {news.length > 0 ? (
          <>
            <ul>
              {news.map((item) => (
                <li key={item.id}>
                  <Link to={`${t("routes:article", { id: item.id })}`}>
                    {item.translations[cookies.i18next].name}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div className="c-no-data-alert">
            {t("building__news:no_building_news", {
              building_name: item.general.translations[cookies.i18next].name,
            })}
          </div>
        )}
      </section>
    </>
  );
}

export default BuildingNewsDetails;
