import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useParams, useHistory } from "react-router-dom";

import AccessibilityInfoContainer from "../AccessibilityInfoContainer/AccessibilityInfoContainer";

import ImageContainer from "../ImageContainer/ImageContainer";

import RightNavigationArrow from "../../assets/right-navigation-arrow.svg";
import RightArrow from "../../assets/arrow-forward.svg";

function BuildingRegionDetails({ item, mode }) {
  const { id, levelId, regionId } = useParams();

  /* A hook that is used to translate the content of the page. */
  const { t } = useTranslation();

  const [cookies] = useCookies(["value"]);

  const availableToiletsTypes = [
    t("building__toilet:available_toilet_type_1"),
    t("building__toilet:available_toilet_type_2"),
  ];

  const adaptedToiletsTypes = [
    t("building__adapted_toilet:adapted_toilet_type_1"),
    t("building__adapted_toilet:adapted_toilet_type_2"),
    t("building__adapted_toilet:adapted_toilet_type_3"),
  ];

  const history = useHistory();

  const [levelName, setLevelName] = useState(null);
  const [region, setRegion] = useState(null);

  const [dressingRooms, setDressingRooms] = useState([]);
  const [lodges, setLodges] = useState([]);
  const [informationPoints, setInformationPoints] = useState([]);
  const [toilets, setToilets] = useState([]);
  const [adaptedToilets, setAdaptedToilets] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [ramps, setRamps] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [lifts, setLifts] = useState([]);
  const [stairs, setStairs] = useState([]);
  const [corridors, setCorridors] = useState([]);
  const [stairways, setStairways] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${process.env.REACT_APP_API_URL}/levels/?id=${levelId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLevelName(data[0].translations[cookies.i18next].name);
      });

    fetch(`${process.env.REACT_APP_API_URL}/regions/?id=${regionId}`, {
      signal,
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          data.forEach(async (data) => {
            setRegion(data);

            setDressingRooms([]);
            setLodges([]);
            setInformationPoints([]);
            setToilets([]);
            setAdaptedToilets([]);
            setRooms([]);
            setRamps([]);
            setParkings([]);
            setLifts([]);
            setStairs([]);
            setCorridors([]);
            setStairways([]);

            data.dressing_rooms.forEach((data) => {
              fetch(
                `${process.env.REACT_APP_API_URL}/dressing_rooms/?id=${data}&building=`,
                {
                  method: "GET",
                  credentials: "include",
                  headers: {
                    Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setDressingRooms((dressingRooms) => [
                    ...dressingRooms,
                    ...data,
                  ]);
                });
            });

            data.lodges.forEach((data) => {
              fetch(
                `${process.env.REACT_APP_API_URL}/lodges/?id=${data}&building=`,
                {
                  signal,
                  method: "GET",
                  credentials: "include",
                  headers: {
                    Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setLodges((lodges) => [...lodges, ...data]);
                });
            });

            data.information_points.forEach((data) => {
              fetch(
                `${process.env.REACT_APP_API_URL}/information_points/?id=${data}&building=`,
                {
                  signal,
                  method: "GET",
                  credentials: "include",
                  headers: {
                    Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setInformationPoints((informationPoints) => [
                    ...informationPoints,
                    ...data,
                  ]);
                });
            });

            data.toilets.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/toilets/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setToilets((toilets) => [...toilets, ...data]);
                });
            });

            data.adapted_toilets.forEach((data) => {
              fetch(
                `${process.env.REACT_APP_API_URL}/adapted_toilets/?id=${data}`,
                {
                  signal,
                  method: "GET",
                  credentials: "include",
                  headers: {
                    Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setAdaptedToilets((adaptedToilets) => [
                    ...adaptedToilets,
                    ...data,
                  ]);
                });
            });

            data.rooms.forEach((data) => {
              fetch(
                `${process.env.REACT_APP_API_URL}/rooms/?id=${data}&building=`,
                {
                  signal,
                  method: "GET",
                  credentials: "include",
                  headers: {
                    Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setRooms((rooms) => [...rooms, ...data]);
                });
            });

            data.ramps.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/ramps/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setRamps((ramps) => [...ramps, ...data]);
                });
            });

            data.parkings.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/parkings/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setParkings((parkings) => [...parkings, ...data]);
                });
            });

            data.lifts.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/lifts/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setLifts((lifts) => [...lifts, ...data]);
                });
            });

            data.stairs.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/stairs/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setStairs((stairs) => [...stairs, ...data]);
                });
            });

            data.corridors.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/corridors/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setCorridors((corridors) => [...corridors, ...data]);
                });
            });

            data.stairways.forEach((data) => {
              fetch(`${process.env.REACT_APP_API_URL}/stairways/?id=${data}`, {
                signal,
                method: "GET",
                credentials: "include",
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setStairways((stairways) => [...stairways, ...data]);
                });
            });
          });
        } else {
          history.push("/404");
        }
      });
    return () => {
      controller.abort();
    };
  }, [regionId]);

  useEffect(() => {
    setTimeout(() => {
      /* Setting the title of the page. */
      document.title = t("building__region:title", {
        region_name: region && region.translations[cookies.i18next].name,
        building_name: item.general.translations[cookies.i18next].name,
      });

      let jumpLink;

      if (mode) {
        jumpLink = document.getElementById("js-jump-link");
      } else {
        jumpLink = document.getElementById("js-jump-link-mobile");
      }

      jumpLink.focus();
      jumpLink.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, [region]);

  return (
    <>
      <section
        aria-label={`${region && region.translations[cookies.i18next].name}`}
      >
        <span
          id={mode ? "js-jump-link" : "js-jump-link-mobile"}
          className="sr-only"
          tabIndex={0}
        >
          {t("building__region:startup_message", {
            region_name: region && region.translations[cookies.i18next].name,

            building_name: item.general.translations[cookies.i18next].name,
          })}
        </span>
        <nav
          className="navigation-area"
          aria-label={t("general:nav_area_label")}
        >
          <ul>
            <li>
              <Link
                to={`${t("routes:building_structure", {
                  id: id,
                })}`}
              >
                {t("building__region:building_structure_header")}
              </Link>
              <img src={RightNavigationArrow} alt="" />
            </li>
            <li>
              <Link
                to={`${t("routes:building_level", {
                  id: id,
                  level_id: levelId,
                })}`}
              >
                {levelName}
              </Link>
              <img src={RightNavigationArrow} alt="" />
            </li>
            <li>
              <Link
                className="active"
                to={`${t("routes:building_region", {
                  id: id,
                  level_id: levelId,
                  region_id: regionId,
                })}`}
              >
                {region && region.translations[cookies.i18next].name}
              </Link>
            </li>
          </ul>
        </nav>
        {region ? <AccessibilityInfoContainer item={region} type={3} /> : null}
        <h3 className="object-category-header-name mt-3">
          {region && region.translations[cookies.i18next].name}
        </h3>
        {region && region.images.length > 0 ? (
          <ImageContainer item={region.images[0]} />
        ) : null}
        <p
          role="heading"
          aria-level="4"
          className="object-category-subheader-name"
        >
          {t("building__region:region_localization_header")}
        </p>
        <p className="text">
          {region && region.translations[cookies.i18next].location}
        </p>
        <ul className="c-clickable-list">
          {corridors && corridors.length > 0
            ? corridors
                .sort((a, b) => a.order_number - b.order_number)
                .map((corridor) => (
                  <li key={corridor.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_corridor_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            corridor_id: corridor.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{corridor.translations[cookies.i18next].name}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {stairs && stairs.length > 0
            ? stairs
                .sort((a, b) => a.order_number - b.order_number)
                .map((stairs) => (
                  <li key={stairs.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_stairs_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            stairs_id: stairs.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{t("building__stairs:label")}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {ramps && ramps.length > 0
            ? ramps
                .sort((a, b) => a.order_number - b.order_number)
                .map((ramp) => (
                  <li key={ramp.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t("routes:building_region_ramp_details", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                          ramp_id: ramp.id,
                        })}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{t("building__ramp:label")}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {stairways && stairways.length > 0
            ? stairways
                .sort((a, b) => a.order_number - b.order_number)
                .map((stairway) => (
                  <li key={stairway.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_stairway_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            stairway_id: stairway.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{stairway.translations[cookies.i18next].name}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {lifts &&
            lifts.length > 0 &&
            lifts
              .sort((a, b) => a.order_number - b.order_number)
              .map((lift, index) =>
                lift.display_in_lifts_category === "False" ? (
                  <li key={lift.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t("routes:building_region_lift_details", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                          lift_id: lift.id,
                        })}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>
                        {lift.translations[cookies.i18next].is_standard_lift ===
                        ""
                          ? t("building__lift:label")
                          : lift.translations[cookies.i18next].is_standard_lift}
                      </span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ) : null
              )}
          {lodges && lodges.length > 0
            ? lodges
                .sort((a, b) => a.order_number - b.order_number)
                .map((lodge) => (
                  <li key={lodge.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t("routes:building_region_lodge_details", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                          lodge_id: lodge.id,
                        })}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{t("building__lodge:label")}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {informationPoints && informationPoints.length > 0
            ? informationPoints
                .sort((a, b) => a.order_number - b.order_number)
                .map((informationPoint) => (
                  <li
                    key={informationPoint.id}
                    className="c-clickable-list__item"
                  >
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_information_point_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            information_point_id: informationPoint.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{t("building__information_point:label")}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {dressingRooms && dressingRooms.length > 0
            ? dressingRooms
                .sort((a, b) => a.order_number - b.order_number)
                .map((dressingRoom) => (
                  <li key={dressingRoom.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_dressing_room_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            dressing_room_id: dressingRoom.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,

                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{t("building__dressing_room:label")}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {toilets && toilets.length > 0
            ? toilets
                .sort((a, b) => a.order_number - b.order_number)
                .map((toilet) => (
                  <li key={toilet.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_toilet_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            toilet_id: toilet.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>
                        {
                          availableToiletsTypes[
                            parseInt(toilet.is_available_for) - 1
                          ]
                        }
                      </span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {adaptedToilets && adaptedToilets.length > 0
            ? adaptedToilets
                .sort((a, b) => a.order_number - b.order_number)
                .map((adaptedToilet) => (
                  <li key={adaptedToilet.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_adapted_toilet_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            adapted_toilet_id: adaptedToilet.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>
                        {
                          adaptedToiletsTypes[
                            parseInt(adaptedToilet.has_additional_purpose) - 1
                          ]
                        }
                      </span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {rooms && rooms.length > 0
            ? rooms
                .sort((a, b) => a.order_number - b.order_number)
                .map((room) => (
                  <li key={room.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t("routes:building_region_room_details", {
                          id: id,
                          level_id: levelId,
                          region_id: regionId,
                          room_id: room.id,
                        })}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{room.translations[cookies.i18next].name}</span>{" "}
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
          {parkings && parkings.length > 0
            ? parkings
                .sort((a, b) => a.order_number - b.order_number)
                .map((parking) => (
                  <li key={parking.id} className="c-clickable-list__item">
                    <Link
                      className="c-clickable-list__link"
                      to={{
                        pathname: `${t(
                          "routes:building_region_parking_details",
                          {
                            id: id,
                            level_id: levelId,
                            region_id: regionId,
                            parking_id: parking.id,
                          }
                        )}`,
                        state: {
                          level_name: levelName,
                          region_name:
                            region.translations[cookies.i18next].name,
                          category: "region",
                          building_name:
                            item.general.translations[cookies.i18next].name,
                        },
                      }}
                    >
                      <span>{t("building__parking:label")}</span>
                      <img src={RightArrow} alt="" />
                    </Link>
                  </li>
                ))
            : null}
        </ul>
      </section>
    </>
  );
}

export default BuildingRegionDetails;
