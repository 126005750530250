import types from "./types.js";

const INITIAL_SERVICES = {
  list: {},
};

const servicesReducer = (state = INITIAL_SERVICES, action) => {
  switch (action.type) {
    case types.ADD_SERVICES:
      return {
        ...state,
        list: action.item,
      };
    case types.RESET_SERVICES:
      return {
        ...state,
        list: {},
      };
    default:
      return state;
  }
};

export default servicesReducer;
